import { IConfiguration, IModelTuning } from '../interfaces'
import { formatTimestamp } from 'common/utils'
import { Input, Table } from 'common/components'
import { TAB_ADD_NOTES, TAB_CONFIGURATION, TAB_MODEL_TUNING } from '.'
import { useGetPublishLogQuery } from 'services/apis'
import { useEffect } from 'react'
import { skipToken } from '@reduxjs/toolkit/dist/query'

interface IContentProps {
  currentTab: string
  selectedRowId: number
  onVersionChanged: (version: string) => void
}

const configurationColumnConfig = [
  {
    field: 'configurationSteps',
    headerName: 'Configuration Steps',
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
  },
  {
    field: 'lastUpdated',
    headerName: 'Last Updated',
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    renderCell: (params: any) => formatTimestamp(params.value),
  },
]

const modelTuningColumnConfig = [
  {
    field: 'subthreat',
    headerName: 'Subthreat',
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
  },
  {
    field: 'lastUpdated',
    headerName: 'Last Updated',
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    renderCell: (params: any) => formatTimestamp(params.value),
  },
]

const Content = ({
  currentTab,
  onVersionChanged,
  selectedRowId,
}: IContentProps) => {
  const {
    data: publishLog,
    isLoading,
    isFetching,
    isError,
  } = useGetPublishLogQuery(selectedRowId === -1 ? skipToken : selectedRowId)

  useEffect(() => {
    if (publishLog) {
      onVersionChanged(publishLog.publishVersion)
    }
  }, [publishLog])

  const renderDataTable = (
    data: IConfiguration[] | IModelTuning[],
    config: any
  ) => (
    <Table
      variant="data-table"
      useAutoHeight={false}
      className="h-[calc(100%-6rem)] text-base"
      rows={data || []}
      columns={config}
      checkboxSelection={false}
      hasPagination={false}
      rowBorderBottomColor="#E4E5E6"
    />
  )

  const renderNotesSection = () => (
    <div className="h-full text-coolGray-900">
      <div className="mb-4 text-base font-semibold">Publish Notes</div>
      <Input
        label="notes"
        isMultiline
        rows={15}
        isLabelHidden
        defaultValue={publishLog?.notes}
      />
    </div>
  )

  const renderContent = () => {
    if (selectedRowId === -1) {
      return null
    } else if (isLoading || isFetching) {
      return <span>Loading...</span>
    } else if (isError || (publishLog && publishLog.status === 3)) {
      return (
        <span>
          Error while loading data. Please refer the summary section for details
        </span>
      )
    } else if (publishLog) {
      switch (currentTab) {
        case TAB_CONFIGURATION:
          return renderDataTable(
            publishLog.configurations,
            configurationColumnConfig
          )
        case TAB_MODEL_TUNING:
          return renderDataTable(
            publishLog.modelTunings,
            modelTuningColumnConfig
          )
        case TAB_ADD_NOTES:
          return renderNotesSection()
        default:
          return null
      }
    }
  }

  return (
    <div className="h-full">
      <div className="mb-8 text-base font-bold uppercase tracking-wide text-blue-navy">
        {currentTab}
      </div>
      {renderContent()}
    </div>
  )
}

export default Content
