import { useEffect, useState } from 'react'
import { AssetTypeOption } from '.'

const assetTypesData = [
  {
    assetTypeId: 1,
    assetTypeName: 'Main',
    isEnabled: true,
  },
  {
    assetTypeId: 2,
    assetTypeName: 'Service',
    isEnabled: false,
  },
  {
    assetTypeId: 3,
    assetTypeName: 'Riser',
    isEnabled: false,
  },
  {
    assetTypeId: 4,
    assetTypeName: 'Valve',
    isEnabled: false,
  },
  {
    assetTypeId: 5,
    assetTypeName: 'HPDistribution',
    isEnabled: false,
  },
  {
    assetTypeId: 6,
    assetTypeName: 'FarmTap',
    isEnabled: false,
  },
  {
    assetTypeId: 7,
    assetTypeName: 'Meter',
    isEnabled: false,
  },
]

const AssetTypeSelection = () => {
  const [selectedAssetTypeId, setSelectedAssetTypeId] = useState(-1)

  useEffect(() => {
    console.log('GRAB ASSET TYPE ' + selectedAssetTypeId + ' ATTRIBUTES')
  }, [selectedAssetTypeId])

  return (
    <div className="col-span-1 row-span-2">
      <div className="subtitle-bar">
        <h2>Asset Type Selection</h2>
      </div>
      <div className="overflow-hidden rounded">
        {assetTypesData.map(({ assetTypeId, assetTypeName, isEnabled }) => {
          return (
            <AssetTypeOption
              key={assetTypeId}
              assetTypeId={assetTypeId}
              assetTypeName={assetTypeName}
              isEnabled={isEnabled}
              isSelected={selectedAssetTypeId === assetTypeId}
              setSelectedAssetTypeId={setSelectedAssetTypeId}
            />
          )
        })}
      </div>
    </div>
  )
}

export default AssetTypeSelection
