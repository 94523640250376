import { ReactNode, useEffect, useMemo, useState } from 'react'
import Popover from '@mui/material/Popover'
import { debounce } from 'common/utils'
import { SxProps } from '@mui/material'
import { Checkbox, Button } from 'common/components'

export interface IResponsiveLabelsProps {
  /**
   * A list of labels.
   */
  labels: string[]
  /**
   * className applied to an individual label. Limit usage for special cases only.
   */
  className?: string
  /**
   * className applied to the container. Limit usage for special cases only.
   */
  containerClass?: string
}

/**
 * Display labels on one line. If the container's width is narrower than
 * the width that the labels occupy, they are automatically clipped and
 * a button with the number of hidden labels is shown in the clipped
 * position. Upon clicking that button, the user is able to see all of
 * the labels.
 */
const ResponsiveLabels = ({
  labels,
  className = 'mr-2 rounded-sm bg-coolGray-100 py-2 px-4 text-sm font-medium text-coolGray-900',
  containerClass = 'flex w-full flex-nowrap',
}: IResponsiveLabelsProps) => {
  const [visibleLabels, setVisibleLabels] = useState(labels)
  const [hiddenLabelCount, setHiddenLabelCount] = useState(0)
  const [node, setRef] = useState<HTMLElement | null>(null)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [popoverMinWidth, setPopoverMinWidth] = useState(0)

  useEffect(() => {
    let labelWidths: number[] = []
    if (node) {
      labelWidths = [...(node.querySelectorAll('.label') as any)].map(
        (labelEl) => labelEl.offsetWidth + 7 // additional 7px for right margin
      )
    }

    const calculateVisibleLabels = () => {
      const widthLimit = (node?.offsetWidth as number) - 50 // allocate 50px for button
      let widthAccumulator = 0

      labelWidths.every((labelWidth, i) => {
        widthAccumulator += labelWidth

        if (widthAccumulator >= widthLimit) {
          setVisibleLabels(labels.slice(0, i))
          setHiddenLabelCount(labels.length - i)
          return false
        }

        setVisibleLabels(labels)
        setHiddenLabelCount(0)
        return true
      })
    }

    const debouncedCalculateVisualLabels = debounce(calculateVisibleLabels, 250)
    calculateVisibleLabels()
    window.addEventListener('resize', debouncedCalculateVisualLabels)

    return () =>
      window.removeEventListener('resize', debouncedCalculateVisualLabels)
  }, [node, JSON.stringify(labels)])

  const handleClick = () => {
    setAnchorEl(node)
    let widthAccumulator = 0
    node?.querySelectorAll('.label').forEach((label) => {
      if (!label.classList.contains('invisible')) {
        widthAccumulator += (label as HTMLElement).offsetWidth + 7
      }
    })
    setPopoverMinWidth(widthAccumulator - 7)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const popoverStyles: SxProps = useMemo(
    (): SxProps => ({
      '.MuiPopover-paper': {
        padding: ' 10px 20px',
        marginTop: '7px',
        minWidth: popoverMinWidth,
      },
      label: {
        display: 'block',
        '.MuiCheckbox-root': {
          padding: '3px 9px 3px 2px',
        },
        '.MuiTypography-root': {
          fontSize: '0.875rem',
          opacity: '1 !important',
        },
      },
    }),
    [popoverMinWidth]
  )

  const buttonClass: string = useMemo(
    (): string =>
      `show-more-btn min-w-fit rounded-sm !px-3 ${
        hiddenLabelCount === 0 && 'hidden'
      } ${open && 'bg-coolGray-100 !text-coolGray-500'}`,
    [hiddenLabelCount, open]
  )

  const renderLabels = (): ReactNode => {
    if (labels && labels.length > 0) {
      return labels.map((label) => {
        const style: string = `label ${className} ${
          !visibleLabels.includes(label) &&
          'invisible absolute top-[-5000px] left-[-5000px]'
        }`

        return (
          <div key={label} className={style}>
            {label}
          </div>
        )
      })
    }

    return null
  }

  return (
    <div ref={setRef} className={containerClass}>
      {renderLabels()}
      <Button size="small" className={buttonClass} onClick={handleClick}>
        +{hiddenLabelCount}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={popoverStyles}>
        {labels.map((label) => (
          <Checkbox
            label={label}
            isChecked={true}
            setIsChecked={() => null}
            key={label}
            isDisabled
          />
        ))}
      </Popover>
    </div>
  )
}

export default ResponsiveLabels
