import { useState, useEffect } from 'react'
import { useAppDispatch } from 'app/hooks'
import {
  setSnackbarIsOpen,
  setSnackbar,
} from 'common/components/Snackbar/SnackbarDucks'
import { Breadcrumbs, Button, Layout, Select } from 'common/components'
import { handleFileDownload } from 'common/utils'
import { env } from 'app/runtime-config'
import { useReportYearsQuery } from 'services/apis'
import { ISelectItem } from 'common/interfaces'
import { Controller, useForm, SubmitHandler } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import ReportExportFormSchema, {
  ReportExportFormSchemaType,
} from './ReportExportFormSchema'

const reportYears = (years: number[]): ISelectItem[] => {
  const yearOptions = Array.from(years, (year) => {
    return { id: year, label: year.toString() }
  })
  return yearOptions
}

const reports: ISelectItem[] = [{ id: 0, label: 'PHMSA 7100-1.1' }]

const reportNames: { [key: number]: string } = {
  0: 'phmsa',
}

const ReportExport = () => {
  const {
    control,
    handleSubmit,
    clearErrors,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<ReportExportFormSchemaType>({
    defaultValues: { reportType: 0, reportYear: -1 },
    resolver: zodResolver(ReportExportFormSchema),
  })
  const appDispatch = useAppDispatch()

  const [isDisabled, setIsDisabled] = useState(true)

  const reportType = watch('reportType')

  const { data: years } = useReportYearsQuery(reportNames?.[reportType], {
    skip: reportType === -1,
    refetchOnMountOrArgChange: true,
  })

  useEffect(() => {
    if (years && years.length !== 0) {
      setValue('reportYear', years?.[0] ?? -1)
      setIsDisabled(false)
      /* Error messages are not displayed currently, 
      but not removing them in case there is a requirement change in the future */
      clearErrors('reportYear')
    }
  }, [years])

  const displaySnackbarMessage = (
    type: 'error' | 'info' | 'success',
    messages: string
  ) => {
    appDispatch(setSnackbar({ type, messages }))
    appDispatch(setSnackbarIsOpen(true))
  }

  const handleExportReport: SubmitHandler<
    ReportExportFormSchemaType
  > = async () => {
    setIsDisabled(true)

    const reportYear = getValues('reportYear')

    displaySnackbarMessage(
      'info',
      `Downloading ${reportNames?.[
        reportType
      ]?.toUpperCase()} report for year ${reportYear} `
    )

    const isSuccess = await handleFileDownload(
      `${env.REACT_APP_API_BASE_URL}/v1/reports/export/${reportType}?year=${reportYear}`,
      `${reportNames?.[reportType]}_report_${reportYear}`
    )
    if (isSuccess) {
      setIsDisabled(false)
      return displaySnackbarMessage(
        'success',
        `Downloaded ${reportNames?.[reportType]}_report_${reportYear} successfully `
      )
    }
    setIsDisabled(false)
    return displaySnackbarMessage(
      'error',
      `Failed downloading. Please try again `
    )
  }

  return (
    <Layout>
      <form onSubmit={handleSubmit(handleExportReport)}>
        <div className="report-export-page">
          <Breadcrumbs items={[{ title: 'Reports', to: '' }]} />
          <div className="title-bar">
            <h1>Export</h1>
          </div>
          <div className="h-full">
            <div>
              <Controller
                control={control}
                name="reportType"
                render={({ field }) => (
                  <Select
                    {...field}
                    className="w-[250px]"
                    label="Report Type*:"
                    hasRadio
                    items={reports}
                    isRequired
                    /* Error messages are not displayed currently, 
                     but not removing them in case there is a requirement change in the future */
                    hasError={Boolean(errors?.reportType)}
                    helperText={String(errors?.reportType?.message ?? '')}
                    value={String(reportType)}
                    setValue={(value) => {
                      field.onChange(value)
                    }}
                  />
                )}
              />
            </div>
            <div className="mt-[30px] mb-[30px]">
              <Controller
                control={control}
                name="reportYear"
                render={({ field }) => (
                  <Select
                    {...field}
                    className=" w-[250px]"
                    label="Select Year*:"
                    hasRadio
                    items={reportYears(years ?? [])}
                    isRequired
                    /* Error messages are not displayed currently, 
                     but not removing them in case there is a requirement change in the future */
                    hasError={Boolean(errors?.reportYear)}
                    helperText={String(errors?.reportYear?.message ?? '')}
                    value={String(getValues('reportYear'))}
                    setValue={(value) => {
                      field.onChange(value)
                    }}
                  />
                )}
              />
            </div>
            <Button isSubmit isDisabled={isDisabled}>
              Export
            </Button>
          </div>
        </div>
      </form>
    </Layout>
  )
}

export default ReportExport
