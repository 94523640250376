import { baseApi } from '.'
import {
  IAssessmentLog,
  IRisk,
  ICreateRisk,
  IPostAssessment,
  IModelVersion,
  IAssessmentErrors,
} from 'services/interfaces'

interface IPageConfig {
  PageNumber: number
  PageSize: number
  TimeStampSorting: string
}

interface IRuntimeParams {
  modelVersionId: number
  startYear: number
  endYear: number
  yearInterval: number
  firstConsecutiveYears: number
  mitigationScenarioCount: number
}

export const riskApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    assessments: builder.query<IAssessmentLog, IPageConfig>({
      query: (params) => ({
        url: `/v1/assessments`,
        params,
      }),
      providesTags: ['Risk'],
    }),
    assessment: builder.query<IRisk, number>({
      query: (id) => `/v1/assessments/${id}`,
      providesTags: ['Risk'],
    }),
    createAssessment: builder.mutation<IPostAssessment, ICreateRisk>({
      query: (body) => ({
        url: `/v1/assessments`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Risk'],
    }),
    assessmentErrors: builder.query<IAssessmentErrors, number>({
      query: (id) => `v1/assessments/errors/${id}`,
      providesTags: ['Risk'],
    }),
    getPublishVersion: builder.query<IModelVersion[], void>({
      query: () => `/v1/modelversions`,
      providesTags: ['Risk'],
    }),
    getRuntime: builder.query<number, IRuntimeParams>({
      query: ({
        modelVersionId,
        startYear,
        endYear,
        yearInterval,
        firstConsecutiveYears,
        mitigationScenarioCount,
      }) =>
        `/v1/assessments/runtime?ModelVersionId=${
          modelVersionId || 0
        }&StartYear=${startYear}&EndYear=${endYear}&YearInterval=${yearInterval}&FirstConsecutiveYears=${firstConsecutiveYears}&MitigationScenarioCount=${mitigationScenarioCount}`,
      providesTags: ['Risk'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetRuntimeQuery,
  useAssessmentsQuery,
  useAssessmentQuery,
  useAssessmentErrorsQuery,
  useGetPublishVersionQuery,
  useCreateAssessmentMutation,
} = riskApi
