import { useNavigate } from 'react-router-dom'
import { Breadcrumbs, Button, Layout } from 'common/components'
import {
  AssetTypeSelection,
  AssetAttributes,
  ValidationRulesAndCustomFields,
} from '.'

const AssetDataConfiguration = () => {
  const navigate = useNavigate()
  const handleCancel = () => {
    navigate('/configuration')
  }

  return (
    <Layout>
      <Breadcrumbs
        items={[
          { title: 'Configuration', to: '/configuration' },
          { title: 'Data Management', to: '' },
        ]}
      />
      <div className="title-bar mb-2">
        <h1>Asset Data Configuration</h1>
        <div>
          <Button
            className="mr-6"
            variant="primary-outlined"
            onClick={handleCancel}>
            Cancel
          </Button>
          <Button isSubmit variant="primary">
            Save and Apply
          </Button>
        </div>
      </div>

      <div className="grid grid-cols-5 gap-10">
        <AssetTypeSelection />
        <AssetAttributes />
        <ValidationRulesAndCustomFields />
      </div>
    </Layout>
  )
}

export default AssetDataConfiguration
