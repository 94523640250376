import { useEffect } from 'react'
import {
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
  useFormContext,
} from 'react-hook-form'
import { Cancel as CancelIcon } from '@mui/icons-material'
import { MultiSelect, SearchableSelect, Button } from 'common/components'
import { ISelectItem } from 'common/interfaces'
import { useAssetFieldsQuery } from 'services/apis'
import { ConfigSchemaType } from '../../ConfigSchema'

interface IConditionProps {
  assetTypeId: number
  condition: ConfigSchemaType['conditions'][0]
  conditionIndex: number
  isSaving: boolean
  remove: UseFieldArrayRemove
  update: UseFieldArrayUpdate<ConfigSchemaType, 'conditions'>
}

const Condition = ({
  assetTypeId,
  condition,
  conditionIndex,
  isSaving,
  remove,
  update,
}: IConditionProps) => {
  const { getValues, register } = useFormContext<ConfigSchemaType>()
  const { data: assetFields } = useAssetFieldsQuery(assetTypeId)

  useEffect(() => {
    register('conditions')
  }, [register])

  const assetFieldsInSelectFormat: ISelectItem[] =
    assetFields
      ?.filter((field) => field.assetFieldType === 'list')
      .map((item) => ({
        id: item.assetFieldId,
        label: item.assetFieldName,
      })) ?? []

  const valuesInSelectFormat: ISelectItem[] =
    assetFields
      ?.find((item) => item.assetFieldId === condition.fieldId)
      ?.values.map((value) => ({
        id: value.assetFieldDomainItemID,
        label: value.domainItemName,
      })) ?? []

  const setSearchableSelectValue = (id: number) => {
    update(conditionIndex, { fieldId: id, values: '' })
  }

  const setMultiSelectValues = (items: (string | number)[]) => {
    const conditions = getValues('conditions')
    update(conditionIndex, {
      ...conditions[conditionIndex],
      values: items
        .filter((item: string | number) => !isNaN(item as number))
        .join(),
    })
  }

  const handleRemoveCondition = () => {
    remove(conditionIndex)
  }

  return (
    <div
      key={conditionIndex}
      className="mb-[15px] flex items-center justify-between">
      <div className="mr-[24px] flex grow">
        <SearchableSelect
          isDisabled={isSaving}
          isRequired={true}
          className={'bg-lightBlue-100 font-semibold'}
          items={assetFieldsInSelectFormat}
          noDuplicate={true}
          prevSelected={getValues('conditions').map(
            (condition) => condition.fieldId ?? 0
          )}
          setValue={setSearchableSelectValue}
          value={condition.fieldId}
        />
      </div>
      <div className="flex w-[50%] max-w-[50%] grow">
        <MultiSelect
          isRequired
          items={valuesInSelectFormat}
          setValue={setMultiSelectValues}
          values={condition.values
            .split(',')
            .filter((item) => item !== '')
            .map((item) => Number(item))}
          isDisabled={isSaving}
        />
        <Button
          className={'ml-[25px] mr-[12px] h-0 w-0 self-center'}
          aria-label="close"
          iconOnly={<CancelIcon className="fill-blue-lighthouse" />}
          onClick={handleRemoveCondition}
          isDisabled={isSaving}
        />
      </div>
    </div>
  )
}

export default Condition
