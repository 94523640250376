import { Input, Modal, Select, Button } from 'common/components'

const TuningModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} title={'Execute Tuning Model'}>
      <div>
        <div className="mx-2 grid grid-cols-2 gap-x-10 gap-y-5">
          <Select
            items={[{ id: 1, label: 'External Corrosion' }]}
            label={'Subthreat:'}
          />
          <Select
            items={[{ id: 1, label: 'DB-21' }]}
            label={'Parameters ID:'}
          />
          <div>
            <div className="mb-2 font-semibold">Start and End Year:</div>
            <div className="flex items-center">
              <Input label="Start Year" isLabelHidden />
              <div className="ml-2 mr-2">to</div>
              <Input label="End Year" isLabelHidden />
            </div>
          </div>
        </div>
        <div className="mt-5 flex justify-end">
          <Button className="mr-2" variant="clear" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="primary">Run</Button>
        </div>
      </div>
    </Modal>
  )
}

export default TuningModal
