import { Button } from 'common/components'

interface IFooterProps {
  handleClose: () => void
  isDirty: boolean
  isSaving: boolean
}

const Footer = ({ handleClose, isDirty, isSaving }: IFooterProps) => {
  return (
    <>
      <Button
        className="mr-2"
        onClick={handleClose}
        variant="clear"
        isDisabled={isSaving}>
        Cancel
      </Button>
      <Button
        isDisabled={!isDirty}
        isBusy={isSaving}
        busyText="Saving & Applying..."
        isSubmit>
        Save & Apply
      </Button>
    </>
  )
}

export default Footer
