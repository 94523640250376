import { ChangeEvent, Dispatch, SetStateAction } from 'react'
import { Radios, Checkbox } from 'common/components'

const classes = {
  attributes: `grid max-h-40 grid-cols-2 overflow-auto rounded border-2 border-solid 
  border-gray-200 p-6 py-3`,
  location: `flex flex-wrap overflow-auto rounded border-2 border-solid 
  border-gray-200 p-6 py-3`,
}

interface IAttribute {
  id: number
  name: string
}

interface ILocationLevel {
  level: number
  name: string
}

interface IContentProps {
  attributes: IAttribute[]
  selectedAttributeIds: number[]
  setSelectedAttributeIds: Dispatch<SetStateAction<number[]>>
  locationLevels: ILocationLevel[]
  selectedLocationLevel: number | null
  setSelectedLocationLevel: Dispatch<SetStateAction<number | null>>
}

const Content = ({
  attributes,
  selectedAttributeIds,
  setSelectedAttributeIds,
  locationLevels,
  selectedLocationLevel,
  setSelectedLocationLevel,
}: IContentProps) => {
  const handleCheckboxChange = (attribute: IAttribute) => {
    if (selectedAttributeIds.includes(attribute.id)) {
      setSelectedAttributeIds((selectedAttributeIds) =>
        selectedAttributeIds.filter((id) => attribute.id !== id)
      )
    } else {
      setSelectedAttributeIds((selectedAttributeIds) => [
        ...selectedAttributeIds,
        attribute.id,
      ])
    }
  }

  const handleRadiosChange = (e: ChangeEvent<HTMLInputElement>) =>
    setSelectedLocationLevel(JSON.parse(e.target.value))

  return (
    <>
      <p className="my-0 font-bold text-blue-navy">CORE ATTRIBUTES</p>
      <p className="mt-10 font-bold">Typical Attributes:</p>
      <div className={classes.attributes}>
        {attributes?.map((attribute) => (
          <div key={attribute.id} className="h-11">
            <Checkbox
              isDisabled={false}
              label={attribute.name}
              isChecked={selectedAttributeIds.includes(attribute.id)}
              setIsChecked={() => handleCheckboxChange(attribute)}
            />
          </div>
        ))}
      </div>
      <p className="mt-10 font-bold">Location Level:</p>
      <div className={classes.location}>
        <Radios
          isDisabled={false}
          label="Location level"
          value={String(selectedLocationLevel)}
          name="location-level"
          items={
            locationLevels?.map((locationLevel) => ({
              value: locationLevel.level.toString(),
              label: locationLevel.name,
            })) ?? []
          }
          onChange={handleRadiosChange}
          className="grid w-full grid-cols-2"
        />
      </div>
    </>
  )
}

export default Content
