import { ReactNode, useMemo } from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import Paper from '@mui/material/Paper'
import { SxProps } from '@mui/material'
import { ISelectItem } from 'common/interfaces'

interface ISearchableSelectProps {
  /**
   * Disables the input.
   */
  isDisabled?: boolean
  /**
   * The input value is highlightable but cannot be changed.
   */
  isReadOnly?: boolean
  /**
   * Determines if input is required.
   */
  isRequired?: boolean
  /**
   * className applied to the root element. Limit usage for special cases only.
   */
  className?: string
  /**
   * An array of objects containing `id` and `label` properties for the SearchableSelect options' id and the label, respectively.
   */
  items: ISelectItem[]
  /**
   * With a series of SearchableSelect components, enabling `noDuplicate` will disable previously
   * selected options from the dropdown menu based on the items in prevSelected.
   */
  noDuplicate?: boolean
  /**
   * Ids that have already been selected.
   */
  prevSelected?: number[]
  /**
   * Callback that triggers when value is changed.
   * @param value new value to set to.
   */
  setValue: (value: number) => void
  /**
   * The current selected option.
   */
  value: number | null
}

/**
 * A normal text input enhanced by a panel of suggested options.
 */
const SearchableSelect = ({
  isDisabled = false,
  isReadOnly = false,
  isRequired = false,
  className,
  items,
  noDuplicate = false,
  prevSelected = [],
  setValue,
  value = 0,
}: ISearchableSelectProps) => {
  const filterOptions = createFilterOptions({
    stringify: (option: ISelectItem) => option.label,
    trim: true,
  })

  const autocompleteSx: SxProps = useMemo(
    () => ({
      width: '100%',
      '.MuiInputBase-root': {
        borderRadius: '0.125rem',
        boxShadow: '0 0 0 1px #D0D2D3',
        transition: 'box-shadow .150s',
        '&:not(.Mui-disabled):hover': {
          boxShadow: '0 0 0 1px #A6A8AB',
        },
        '&.Mui-focused': {
          boxShadow: '0 0 0 2px #2563eb !important',
        },
        '&.Mui-disabled': {
          backgroundColor: '#F2F3F4',
        },
        '&.Mui-error': {
          boxShadow: '0 0 0 2px #B70000 !important',
        },
        '.MuiAutocomplete-endAdornment': {
          top: 'calc(50% - 12px)',
        },
      },
      '.MuiOutlinedInput-root': {
        padding: '7.5px 10.5px 8.5px',
        fontWeight: '600',
      },
      '.MuiInputBase-input': {
        padding: '0 !important',
      },
      '.MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '.MuiAutocomplete-endAdornment': {
        display: isReadOnly ? 'none' : 'block',
      },
    }),
    [isReadOnly]
  )

  const renderOption = (props: any, option: ISelectItem): ReactNode => {
    const { id, label } = option

    if (id !== -1) {
      return (
        <li {...props} key={id}>
          {label}
        </li>
      )
    }

    return null
  }

  return (
    <Autocomplete
      disabled={isDisabled}
      disableClearable
      options={[...items, { id: -1, label: '' }]}
      getOptionDisabled={(option) =>
        noDuplicate && prevSelected.includes(option.id)
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option) => renderOption(props, option)}
      sx={autocompleteSx}
      className={className}
      renderInput={(params) => (
        <TextField required={isRequired} {...params} placeholder="Select" />
      )}
      value={items.find((item) => item.id === value) ?? { id: -1, label: '' }}
      onChange={(_, newValue) => {
        setValue(newValue?.id)
      }}
      PaperComponent={(props) => {
        return <Paper elevation={8} {...props} />
      }}
      filterOptions={filterOptions}
    />
  )
}

export default SearchableSelect
