import { useEffect, useState } from 'react'
import ArrowRightTwoToneIcon from '@mui/icons-material/ArrowRightTwoTone'
import { Table, Button } from 'common/components'
import { formatTimestamp } from 'common/utils'
import { GridRenderCellParams, GridSortModel } from '@mui/x-data-grid'
import { IColumn } from 'common/interfaces'
import { IPublishLog } from './interfaces'
import { useGetAllPublishLogsQuery } from 'services/apis'

interface IPublishLogTableProps {
  onRowSelect: any
  selectedRowId: number | undefined
}

const defaultSortModel: GridSortModel = [
  {
    field: 'publishDate',
    sort: 'desc',
  },
]

const PublishLogTable = ({
  onRowSelect,
  selectedRowId,
}: IPublishLogTableProps) => {
  const { data } = useGetAllPublishLogsQuery()
  const [sortModel, setSortModel] = useState<GridSortModel>(defaultSortModel)

  const columnConfig: IColumn[] = [
    {
      field: 'publishVersion',
      headerName: 'Publish Version',
      flex: 1.5,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'publishDate',
      headerName: 'Publish Date',
      flex: 1.2,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) =>
        formatTimestamp(params.value),
    },
    {
      field: 'user',
      headerName: 'User',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'configure',
      headerName: '',
      width: 60,
      disableColumnMenu: true,
      disableOverflowTooltip: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Button
            iconOnly={<ArrowRightTwoToneIcon />}
            onClick={() => onRowSelect(params.id as number)}
            color={'blue-lighthouse'}
          />
        )
      },
    },
  ]

  useEffect(() => {
    // No row selected: Highlight the first row in the sorted data
    if (selectedRowId === -1 && data && data.length) {
      const { field, sort } = sortModel[0]

      // get the first row from the sorted data.
      // We don't need to sort, sorting is handled by the datagrid
      const firstRow = data.reduce(
        (firstRow: IPublishLog, currentRow: IPublishLog) => {
          /**
           * We can type cast the value as string because the concerned table
           * displays only the string fields belonging to IAssessment
           * interface. We need to re-define the logic if this changes.
           */
          const firstRowVal = (
            firstRow[field as keyof IPublishLog] as string
          ).toLowerCase()

          const currentRowVal = (
            currentRow[field as keyof IPublishLog] as string
          ).toLowerCase()

          if (sort === 'desc') {
            return currentRowVal > firstRowVal ? currentRow : firstRow
          } else {
            return currentRowVal < firstRowVal ? currentRow : firstRow
          }
        }
      )

      onRowSelect(firstRow.id)
    }
  }, [selectedRowId, data])

  return (
    <Table
      variant="data-table"
      rows={data}
      columns={columnConfig}
      checkboxSelection={false}
      rowIdToHighlight={selectedRowId}
      sortModel={sortModel}
      setSortModel={setSortModel}
    />
  )
}

export default PublishLogTable
