import ConfigModal from './ConfigModal'
import ConfigSchema, { ConfigSchemaType } from './ConfigSchema'
import Content from './Content'
import Footer from './Footer'
import Sidebar from './Sidebar'

export type { ConfigSchemaType }
export { Content, ConfigSchema, Footer, Sidebar }
export default ConfigModal

// Constants
export const tabs = {
  CoreAttributes: 'core attributes',
  OtherAttributes: 'other attributes',
  Applicability: 'applicability',
} as const
export type Tabs = typeof tabs[keyof typeof tabs]
