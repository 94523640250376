import {
  ChangeEvent,
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  KeyboardEventHandler,
  ReactNode,
  useMemo,
} from 'react'
import { v4 as uuidv4 } from 'uuid'
import {
  FormControl,
  InputLabel,
  InputBase as MuiInput,
  FormHelperText,
  InputAdornment,
  SxProps,
} from '@mui/material'
import Same from 'assets/Select Same.svg'
import SameUnselect from 'assets/Unselect Same.svg'
import { Button } from 'common/components'

interface IInputProps {
  /**
   * className applied to the root element. Limit usage for special cases only.
   */
  className?: string
  /**
   * The input's label.
   */
  label: string
  /**
   * The value of the input element, required for a controlled component.
   */
  value?: string
  /**
   * Callback that triggers when focus is shifted away.
   */
  onBlur?: FocusEventHandler<HTMLInputElement>
  /**
   * Callback that triggers when value is changed.
   */
  onChange?: ChangeEventHandler<HTMLInputElement>
  /**
   * Callback that triggers when keyDown event occurs.
   */
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>
  /**
   * The default value. Use when the component is not controlled.
   */
  defaultValue?: string
  /**
   * The `label` prop will still be required for assistive technologies.
   */
  isLabelHidden?: boolean
  /**
   * Displays the label and input box in one line.
   */
  isLabelInline?: boolean
  /**
   * Disables the input.
   */
  isDisabled?: boolean
  /**
   * The short hint displayed in the input before the user enters a value.
   */
  placeholder?: string
  /**
   * Determines if input is required.
   */
  isRequired?: boolean
  /**
   * The input value is highlightable but cannot be changed.
   */
  isReadOnly?: boolean
  /**
   * Helper text that is displayed below the input box. Is usually filled when
   * `hasError` is set to true to provide additional context.
   */
  helperText?: string
  /**
   * The border of the input is displayed as red if true.
   */
  hasError?: boolean
  /**
   * Set input size.
   */
  size?: 'small' | 'medium'
  /**
   * Set type of input.
   */
  type?: 'text' | 'number' | 'password'
  /**
   * Any <a href="https://mui.com/components/material-icons/" target="_blank">MUI icon component</a>
   */
  prefixIcon?: ReactNode
  /**
   * Any <a href="https://mui.com/components/material-icons/" target="_blank">MUI icon component</a>
   */
  suffixIcon?: ReactNode
  /**
   * Similar to an HTML textarea element where use can enter multiple lines.
   */
  isMultiline?: boolean
  /**
   * When `isMultiline` is `true`, the value of `rows` determines the height of
   * the input so that it can fit the set number of lines.
   */
  rows?: number
  /**
   * The maximum string length permitted.
   */
  maxLength?: number
  /**
   * Related to the `hasSameButton` prop.
   */
  isSame?: boolean
  /**
   * Allows the input to alternate between any arbitrary value and a fixed value
   * of "Same", which is determined by the value of the `isSame` prop
   */
  hasSameButton?: boolean
  /**
   * The minimum value allowed when the `type` prop is set to `number`.
   */
  min?: number
  /**
   * The maximum value allowed when the `type` prop is set to `number`.
   */
  max?: number
  /**
   * Set the id of the input.
   */
  id?: string
}

const formControlStyles: SxProps = {
  '.MuiInputBase-root': {
    boxShadow: '0 0 0 1px #D0D2D3',
    transition: 'box-shadow .150s',
    '&:not(.Mui-disabled):hover': {
      boxShadow: '0 0 0 1px #A6A8AB',
    },
    '&.Mui-focused': {
      boxShadow: '0 0 0 2px #2563eb !important',
    },
    '&.Mui-disabled': {
      backgroundColor: '#F2F3F4',
    },
    '&.Mui-error': {
      boxShadow: '0 0 0 2px #B70000 !important',
    },
  },
  '.MuiInputLabel-root': {
    position: 'static',
    transform: 'none',
    '&.Mui-disabled': {
      color: '#929497 !important',
    },
    '&.Mui-error': {
      color: '#B70000 !important',
    },
  },
}

/**
 * General user input to be used in forms.
 */
const Input = forwardRef<HTMLInputElement, IInputProps>(
  (
    {
      className = '',
      label,
      value,
      onBlur,
      onChange,
      onKeyDown,
      defaultValue,
      isLabelHidden = false,
      isLabelInline = false,
      isDisabled = false,
      placeholder,
      isRequired = false,
      isReadOnly = false,
      helperText,
      hasError = false,
      size = 'medium',
      type = 'text',
      prefixIcon,
      suffixIcon,
      isMultiline = false,
      rows,
      maxLength,
      isSame = false,
      hasSameButton = false,
      min,
      max,
      id,
    },
    ref
  ) => {
    const inputId = uuidv4()
    const helperTextId = uuidv4()

    const sameIcon: ReactNode = useMemo((): ReactNode => {
      if (isSame) {
        return <img className="w-[40px]" src={Same} alt="Same" />
      } else {
        return <img className="w-[40px]" src={SameUnselect} alt="NotSame" />
      }
    }, [isSame])

    const getAdornment = (
      position: 'end' | 'start',
      icon: ReactNode
    ): ReactNode => {
      if (icon) {
        return <InputAdornment position={position}>{icon}</InputAdornment>
      }

      return null
    }

    const formControlClass: string = useMemo(
      (): string => ` 
    ${isLabelInline ? 'flex-row' : ''}`,
      [helperText, isLabelInline]
    )

    const inputLabelClass: string = useMemo(
      (): string =>
        `overflow-visible font-semibold text-coolGray-900 
      ${size === 'small' ? 'text-sm' : ''} 
      ${isLabelHidden ? 'sr-only' : ''} 
      ${isLabelInline ? 'mb-0 mr-4 flex items-center' : 'mb-2'}`,
      [size, isLabelHidden, isLabelInline]
    )

    const muiInputClass: string = useMemo(
      (): string => `${className} rounded-sm border-0 
        ${size === 'medium' ? 'p-1 px-3' : 'p-0 px-2 text-sm'} 
        ${isMultiline ? 'p-3' : ''}`,
      [className, size, isMultiline]
    )

    const handleSameButtonClick = (): void => {
      if (onChange) {
        const event: ChangeEvent<HTMLInputElement> = {
          target: { value: isSame ? '' : 'Same' },
        } as ChangeEvent<HTMLInputElement>

        onChange(event)
      }
    }

    return (
      <>
        <FormControl
          disabled={isDisabled}
          fullWidth={true}
          required={isRequired}
          error={hasError}
          className={formControlClass}
          sx={formControlStyles}>
          {label && (
            <InputLabel
              shrink
              htmlFor={id ?? inputId}
              className={inputLabelClass}>
              {label}
            </InputLabel>
          )}
          <div className="w-full">
            <MuiInput
              id={id ?? inputId}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              onKeyDown={onKeyDown}
              fullWidth={true}
              inputRef={ref}
              defaultValue={defaultValue}
              placeholder={placeholder}
              readOnly={isReadOnly || isSame}
              type={isSame ? 'text' : type}
              className={muiInputClass}
              startAdornment={getAdornment('start', prefixIcon)}
              endAdornment={getAdornment('end', suffixIcon)}
              multiline={isMultiline}
              rows={rows}
              inputProps={{ maxLength, min, max, step: 'any' }}
              {...(helperText ? { 'aria-describedby': helperTextId } : {})}
            />
            <FormHelperText
              id={helperTextId}
              className="ml-0 text-xs font-semibold">
              {helperText}
            </FormHelperText>
          </div>
        </FormControl>
        {hasSameButton && (
          <Button
            className={'p-0'}
            iconOnly={sameIcon}
            onClick={handleSameButtonClick}
          />
        )}
      </>
    )
  }
)

Input.displayName = 'Input'

export default Input
