import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { ISelectItem } from 'common/interfaces'
import { IDataImportOptions } from 'services/interfaces'
import { baseApi } from '.'
import {
  IAssetTypeOptions,
  IAttribute,
  IDataImport,
  IImportTypeOptions,
  IUpdateParam,
  IGetDataImportLogResponse,
  IGetDataImportLogsResponse,
  IGetDataImportLogsParam,
  IDeleteFileParam,
  IImportFile,
} from 'services/interfaces/dataImport'

export const dataImportApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getDataImportLog: builder.query<IGetDataImportLogResponse, number>({
      query: (id) => `/v1/dataImports/${id}`,
      transformResponse: (response: any) => {
        const {
          dataImportId,
          dataType,
          importFiles,
          importOption,
          importType,
          notes,
          runtime,
          status,
          timestamp,
          updatedAttributes,
          user,
        } = response

        const files: IImportFile[] = importFiles.map((item: any) => {
          const { fileId, importFileName, status, assetCount } = item

          return {
            id: fileId,
            fileName: importFileName,
            status,
            assetCount: assetCount ?? '-',
          }
        })

        return {
          id: dataImportId,
          assetType: dataType,
          importType,
          importOption,
          timestamp,
          user,
          status,
          runtime,
          notes,
          importFiles: files,
          updatedAttributes: updatedAttributes.map(
            (attr: any) => attr.attributeName
          ),
        }
      },
    }),
    getDataImportLogs: builder.query<
      IGetDataImportLogsResponse,
      IGetDataImportLogsParam
    >({
      query: (params) => ({
        url: '/v1/dataImports',
        params,
      }),
      transformResponse: (response: {
        data: any[]
        count: number
        previousPage: string | null
        nextPage: string | null
      }) => {
        if (response && response.data && response.data.length) {
          const { data, count, previousPage, nextPage } = response

          const transformedData = data.map((item) => {
            const {
              dataImportId,
              dataType,
              importType,
              status,
              timestamp,
              user,
              getUrl,
            } = item

            return {
              id: dataImportId,
              assetType: dataType,
              importType,
              status,
              timestamp,
              user,
              getUrl,
            }
          })

          return { data: transformedData, count, previousPage, nextPage }
        }

        return response
      },
      providesTags: ['DataImportLogs'],
    }),
    getImportOptions: builder.query<IDataImportOptions, void>({
      queryFn: async (_arg, _queryApi, _extraOptions, fetchWithBQ) => {
        const promises = Promise.all([
          fetchWithBQ('/v1/importDataType'),
          fetchWithBQ('/v1/importType'),
        ])

        const [assetTypeResponse, importTypeResponse] = await promises

        if (assetTypeResponse.error) {
          return {
            error: assetTypeResponse.error as FetchBaseQueryError,
          }
        } else if (importTypeResponse.error) {
          return {
            error: importTypeResponse.error as FetchBaseQueryError,
          }
        }

        return {
          data: {
            assetTypes: (assetTypeResponse.data as IAssetTypeOptions[]).map(
              (assetType) => {
                return {
                  id: assetType.importDataTypeId,
                  label: assetType.importDataTypeName,
                }
              }
            ),
            importTypes: (importTypeResponse.data as IImportTypeOptions[]).map(
              (importType) => {
                return {
                  id: importType.importTypeId,
                  label: importType.importTypeName,
                }
              }
            ),
          } as IDataImportOptions,
        }
      },
    }),
    getAttributes: builder.query<ISelectItem[], number>({
      query: (assetFieldId) => ({
        url: `/v1/attributes/dataImport?importDataTypeId=${assetFieldId}`,
      }),
      transformResponse: (response: { attributes: IAttribute[] }) => {
        return response.attributes.map((attribute) => {
          return { id: attribute.assetFieldId, label: attribute.assetFieldName }
        })
      },
    }),
    createDataImportEntry: builder.mutation<IDataImport, void>({
      query: () => ({
        url: '/v1/dataImports',
        method: 'POST',
      }),
    }),
    initiateDataImport: builder.mutation<void, number>({
      query: (dataImportId) => ({
        url: `/v1/dataImports/initiate/${dataImportId}`,
        method: 'POST',
      }),
      invalidatesTags: ['DataImportLogs'],
    }),
    updateDataImportEntry: builder.mutation<void, IUpdateParam>({
      query: (body) => ({
        url: `/v1/dataImports/${body.dataImportId}`,
        method: 'PUT',
        body,
      }),
    }),
    deleteDataImportEntry: builder.mutation<void, number>({
      query: (dataImportId) => ({
        url: `/v1/dataImports/${dataImportId}`,
        method: 'DELETE',
      }),
    }),
    deleteFile: builder.mutation<void, IDeleteFileParam>({
      query: (params) => ({
        url: `/v1/dataImports/${params.dataImportId}/file/${params.fileId}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useCreateDataImportEntryMutation,
  useDeleteDataImportEntryMutation,
  useDeleteFileMutation,
  useGetAttributesQuery,
  useGetDataImportLogQuery,
  useGetDataImportLogsQuery,
  useGetImportOptionsQuery,
  useInitiateDataImportMutation,
  useUpdateDataImportEntryMutation,
} = dataImportApi
