import { Dispatch, Fragment, ReactElement, useEffect } from 'react'
import { Control, useWatch } from 'react-hook-form'
import { CreateAssessmentFormSchemaType } from './CreateAssessmentFormSchema'
import { IDebouncedValuesAction, IDebouncedValuesState } from './Content'

interface IProps {
  debouncedValues: IDebouncedValuesState
  dispatch: Dispatch<IDebouncedValuesAction>
  control: Control<CreateAssessmentFormSchemaType>
  names: (keyof IDebouncedValuesState)[]
  duration?: number
}

/**
 * The sole purpose of this component is to contain the re-render from the `useWatch` within
 * this component, and debounce the value setters for values that are required to make the
 * request to the `assessments/runtime` endpoint in the parent.
 *
 * It is a bit of a hack since `react-hook-form` does not allow debouncing of re-renders
 * caused by `watch`. For more background: https://github.com/orgs/react-hook-form/discussions/3078
 */
const DebounceWatch = ({
  debouncedValues,
  dispatch,
  control,
  names,
  duration,
}: IProps): ReactElement => {
  const [
    modelVersionId,
    startYear,
    endYear,
    yearInterval,
    firstConsecutiveYears,
    mitigationScenarioIds,
  ] = useWatch({ control, name: names })

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (
        debouncedValues.modelVersionId !== modelVersionId ||
        debouncedValues.startYear !== startYear ||
        debouncedValues.endYear !== endYear ||
        debouncedValues.yearInterval !== yearInterval ||
        debouncedValues.firstConsecutiveYears !== firstConsecutiveYears ||
        debouncedValues.mitigationScenarioIds !== mitigationScenarioIds
      ) {
        dispatch({
          type: 'update',
          value: {
            modelVersionId: modelVersionId as number,
            startYear: startYear as number,
            endYear: endYear as number,
            yearInterval: yearInterval as number,
            firstConsecutiveYears: firstConsecutiveYears as number,
            mitigationScenarioIds: mitigationScenarioIds as number[],
          },
        })
      }
    }, duration ?? 500)

    return () => {
      clearTimeout(timeout)
    }
  }, [
    debouncedValues,
    dispatch,
    modelVersionId,
    startYear,
    endYear,
    yearInterval,
    firstConsecutiveYears,
    mitigationScenarioIds,
    duration,
  ])

  return <Fragment />
}

export default DebounceWatch
