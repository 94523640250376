import { Button } from 'common/components'

interface IFooterProps {
  onCancel: () => void
  onImport: () => void
  importDisabled: boolean
}

const Footer = ({ onCancel, onImport, importDisabled }: IFooterProps) => {
  return (
    <>
      <Button
        className="uppercase"
        onClick={onCancel}
        variant="clear"
        isDisabled={false}>
        cancel
      </Button>
      <Button
        isDisabled={importDisabled}
        className="ml-2 uppercase"
        onClick={onImport}>
        import
      </Button>
    </>
  )
}

export default Footer
