import {
  Controller,
  ControllerRenderProps,
  useFormContext,
} from 'react-hook-form'
import { Checkbox } from 'common/components'
import { ConfigSchemaType } from '../ConfigSchema'

interface IAttributesCheckboxProps {
  id: number
  isSavingCostConfig: boolean
  name: string
}

const AttributesCheckbox = ({
  id,
  isSavingCostConfig,
  name,
}: IAttributesCheckboxProps) => {
  const { control, setValue } = useFormContext<ConfigSchemaType>()

  const handleToggle = (
    field: ControllerRenderProps<ConfigSchemaType, 'selectedAttributes'>
  ) => {
    if (field.value.includes(id)) {
      setValue(
        'selectedAttributes',
        field.value.filter((item) => item !== id)
      )
    } else {
      setValue('selectedAttributes', [...field.value, id])
    }
  }

  return (
    <Controller
      control={control}
      name="selectedAttributes"
      render={({ field }) => (
        <Checkbox
          {...field}
          isDisabled={isSavingCostConfig}
          label={name}
          isChecked={field.value.includes(id)}
          setIsChecked={() => handleToggle(field)}
        />
      )}
    />
  )
}

export default AttributesCheckbox
