import { TAB_CORE_ATTRIBUTES } from '.'

const classes = {
  button: `block w-full cursor-pointer rounded border-none bg-lightBlue-100 
  py-3 px-5 text-left !font-semibold`,
}

interface ISidebarProps {
  tableType: string
}

const Sidebar = ({ tableType }: ISidebarProps) => (
  <>
    <section className="mb-16">
      <div className="mt-0 mb-2 font-bold">Table:</div>
      <div>{tableType}</div>
    </section>
    <section className="mb-16">
      <div className="mt-0 mb-2 font-bold">Section</div>
      <div className="mt-5">
        <button
          type="button"
          style={{ font: 'inherit' }}
          className={classes.button}>
          {TAB_CORE_ATTRIBUTES}
        </button>
      </div>
    </section>
  </>
)

export default Sidebar
