import { ReactNode } from 'react'

import {
  Main,
  Service,
  Riser,
  Valve,
  Meter,
  RegStation,
  FarmTap,
  RetiredPipe,
  HPDistribution,
} from 'assets/AssetTypeIcons'

const Wrapper = ({ icon }: { icon: ReactNode }) => (
  <div className="mr-[8px] w-[35px]">{icon}</div>
)

export const getAssetIcon = (
  assetType: string,
  color: string = '#707070'
): ReactNode => {
  switch (assetType) {
    case 'Main':
      return <Wrapper icon={<Main fill={color} />} />
    case 'Service':
      return <Wrapper icon={<Service fill={color} />} />
    case 'Riser':
      return <Wrapper icon={<Riser fill={color} />} />
    case 'Valve':
      return <Wrapper icon={<Valve fill={color} />} />
    case 'Meter':
      return <Wrapper icon={<Meter fill={color} />} />
    case 'RegStation':
      return <Wrapper icon={<RegStation fill={color} />} />
    case 'FarmTap':
      return <Wrapper icon={<FarmTap fill={color} />} />
    case 'RetiredPipe':
      return <Wrapper icon={<RetiredPipe fill={color} />} />
    case 'HPDistribution':
      return <Wrapper icon={<HPDistribution fill={color} />} />
  }

  return null
}
