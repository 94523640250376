import {
  Dispatch,
  SetStateAction,
  useEffect,
  useReducer,
  useState,
} from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useAppDispatch } from 'app/hooks'
import {
  Checkbox,
  setSnackbarIsOpen,
  setSnackbar,
  Input,
  Select,
  Button,
} from 'common/components'
import { ISelectItem } from 'common/interfaces'
import { formatDuration, isFetchBaseQueryError } from 'common/utils'
import {
  useGetPublishVersionQuery,
  useCreateAssessmentMutation,
  useAllScenariosQuery,
  useGetRuntimeQuery,
} from 'services/apis'
import { IError } from 'services/interfaces'
import { setSelectedRowId } from '../../RiskManagementDucks'
import {
  CreateAssessmentFormSchema,
  CreateAssessmentFormSchemaType,
  DUPLICATED_NAME,
} from '.'
import DebounceWatch from './DebounceWatch'

interface IContentProps {
  onClose: () => void
  setIsBusy: Dispatch<SetStateAction<boolean>>
}

export type IDebouncedValuesState = Omit<
  CreateAssessmentFormSchemaType,
  'name' | 'notes' | 'modelCodes'
>
export interface IDebouncedValuesAction {
  type: 'update'
  value: IDebouncedValuesState
}

function debouncedValuesReducer(
  state: IDebouncedValuesState,
  action: IDebouncedValuesAction
) {
  if (action.type === 'update') {
    return { ...state, ...action.value }
  }

  return state
}

const Content = ({ onClose, setIsBusy }: IContentProps) => {
  const dispatch = useAppDispatch()
  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors, isValid },
    register,
    setError,
    setValue,
    trigger,
    watch,
  } = useForm<CreateAssessmentFormSchemaType>({
    mode: 'onChange',
    resolver: zodResolver(CreateAssessmentFormSchema),
    defaultValues: {
      name: '',
      startYear: new Date().getFullYear(),
      endYear: new Date().getFullYear(),
      yearInterval: 1,
      firstConsecutiveYears: 1,
      mitigationScenarioIds: [],
      notes: '',
    },
  })

  const [publishVersions, setPublishVersions] = useState<ISelectItem[]>([])
  const [mitigationScenarios, setMitigationScenarios] = useState<ISelectItem[]>(
    []
  )

  /**
   * The debouncing of these values are necessary to prevent the flood of
   * requests made to the `assessments/runtime` endpoint
   */
  const [debouncedValues, debouncedValuesDispatch] = useReducer(
    debouncedValuesReducer,
    {
      modelVersionId: getValues('modelVersionId'),
      startYear: getValues('startYear'),
      endYear: getValues('endYear'),
      yearInterval: getValues('yearInterval'),
      firstConsecutiveYears: getValues('firstConsecutiveYears'),
      mitigationScenarioIds: getValues('mitigationScenarioIds'),
    }
  )

  const { data: estimatedTime, isFetching } = useGetRuntimeQuery(
    {
      modelVersionId: debouncedValues.modelVersionId,
      startYear: debouncedValues.startYear,
      endYear: debouncedValues.endYear,
      yearInterval: debouncedValues.yearInterval,
      firstConsecutiveYears: debouncedValues.firstConsecutiveYears,
      mitigationScenarioCount: debouncedValues.mitigationScenarioIds.length,
    },
    { refetchOnMountOrArgChange: true }
  )

  const { data: publishVersionsData } = useGetPublishVersionQuery()
  const { data: mitigationScenariosData } = useAllScenariosQuery()
  const [
    createAssessment,
    {
      data: createAssessmentData,
      isLoading: isCreatingAssessment,
      isSuccess,
      isError,
      error,
    },
  ] = useCreateAssessmentMutation()

  useEffect(() => {
    trigger(['endYear', 'firstConsecutiveYears', 'yearInterval'])
  }, [watch('startYear')])

  useEffect(() => {
    trigger(['startYear', 'firstConsecutiveYears', 'yearInterval'])
  }, [watch('endYear')])

  useEffect(() => {
    setIsBusy(isCreatingAssessment)
  }, [isCreatingAssessment])

  useEffect(() => {
    if (isSuccess) {
      dispatch(setSelectedRowId(createAssessmentData?.assessmentId))
      dispatch(
        setSnackbar({ type: 'success', messages: 'Assessment created.' })
      )
      dispatch(setSnackbarIsOpen(true))
      onClose()
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError && isFetchBaseQueryError(error)) {
      const errorMessage = (error.data as IError)?.detail
      const hasRepeatedName = (error.data as IError)?.title === DUPLICATED_NAME

      if (hasRepeatedName) {
        setError('name', {
          type: 'custom',
          message: errorMessage,
        })
      }
    }
  }, [isError])

  useEffect(() => {
    if (publishVersionsData) {
      const publishVersions: ISelectItem[] = publishVersionsData.map(
        (item) => ({
          id: item.modelVersionId,
          label: item.modelVersionDescription,
        })
      )

      setPublishVersions(publishVersions)
    }
  }, [publishVersionsData])

  useEffect(() => {
    // select last (assumed most recent) publish version
    if (publishVersions.length) {
      const selectedPublishVersionId: number =
        publishVersions[publishVersions.length - 1].id

      setValue('modelVersionId', selectedPublishVersionId)
    }
  }, [publishVersions])

  useEffect(() => {
    if (mitigationScenariosData?.data) {
      const mitigationScenarios: ISelectItem[] = mitigationScenariosData.data
        .filter((item) => item.isPublished)
        .map((item) => ({
          id: item.id,
          label: item.name,
        }))

      setMitigationScenarios(mitigationScenarios)
    }
  }, [mitigationScenariosData])

  const handleClose = () => {
    onClose()
  }

  const handleScenarioCheckbox = (scenario: ISelectItem) => {
    const selectedScenarioIds = getValues('mitigationScenarioIds')
    let newSelectedScenarioIds: number[] = []
    if (selectedScenarioIds.includes(scenario.id)) {
      newSelectedScenarioIds = selectedScenarioIds.filter(
        (id) => scenario.id !== id
      )
    } else {
      newSelectedScenarioIds = [...selectedScenarioIds, scenario.id]
    }
    setValue('mitigationScenarioIds', newSelectedScenarioIds)
  }

  const handleToggleSelectAll = () => {
    const selectedScenarioIds = getValues('mitigationScenarioIds')
    let newSelectedScenarioIds: number[] = []
    if (mitigationScenarios.length === selectedScenarioIds.length) {
      newSelectedScenarioIds = []
    } else {
      newSelectedScenarioIds = mitigationScenarios.map(
        (scenario: ISelectItem) => scenario.id
      )
    }
    setValue('mitigationScenarioIds', newSelectedScenarioIds)
  }

  const renderMitigationScenariosList = () => {
    if (!mitigationScenarios || mitigationScenarios.length === 0) {
      return <div>No published scenarios found.</div>
    }

    return (
      <div className="grid max-h-40 grid-cols-2 items-center overflow-auto border-2 border-solid border-gray-200 p-6 py-3">
        {mitigationScenarios.map((scenario: ISelectItem) => (
          <div key={scenario.id}>
            <Checkbox
              isChecked={watch('mitigationScenarioIds').includes(scenario.id)}
              isDisabled={isCreatingAssessment}
              label={scenario.label}
              setIsChecked={() => handleScenarioCheckbox(scenario)}
            />
          </div>
        ))}
      </div>
    )
  }

  const onSubmit: SubmitHandler<CreateAssessmentFormSchemaType> = (data) => {
    createAssessment(data)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" {...register('modelCodes', { value: [] })} />
      <div className="flex">
        <div className="mr-10 flex-1">
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                hasError={Boolean(errors.name)}
                helperText={errors?.name?.message}
                isDisabled={isCreatingAssessment}
                label="Name:"
                maxLength={50}
                placeholder="Enter assessment name..."
              />
            )}
          />
        </div>
        <div className="flex-1">
          <Controller
            name="modelVersionId"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                isDisabled={isCreatingAssessment}
                items={publishVersions}
                label="Publish Version:"
                setValue={(value) => setValue('modelVersionId', Number(value))}
                value={
                  getValues('modelVersionId')
                    ? String(getValues('modelVersionId'))
                    : ''
                }
              />
            )}
          />
        </div>
      </div>
      <div className="mt-9 mb-4 font-semibold">Run Dates</div>
      <div>
        Run from
        <div className="mx-2 my-1 inline-block max-w-[70px] align-middle">
          <Controller
            name="startYear"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                hasError={Boolean(errors.startYear) || Boolean(errors.endYear)}
                isDisabled={isCreatingAssessment}
                isLabelHidden
                isRequired
                label="Start Year"
                max={9999}
                min={1000}
                maxLength={4}
                onChange={(e) => {
                  if (e.target.value.match(/^\d{0,4}$/)) {
                    field.onChange(Number(e.target.value))
                  }
                }}
                size="small"
                type="number"
                value={String(field.value)}
              />
            )}
          />
        </div>
        to
        <div className="mx-2 my-1 inline-block max-w-[70px] align-middle">
          <Controller
            name="endYear"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                hasError={Boolean(errors.startYear) || Boolean(errors.endYear)}
                isDisabled={isCreatingAssessment}
                isLabelHidden
                isRequired
                label="End Year"
                max={9999}
                min={1000}
                onChange={(e) => {
                  if (e.target.value.match(/^\d{0,4}$/)) {
                    field.onChange(Number(e.target.value))
                  }
                }}
                size="small"
                type="number"
                value={String(field.value)}
              />
            )}
          />
        </div>
        for every
        <div className="mx-2 my-1 inline-block max-w-[50px] align-middle">
          <Controller
            name="yearInterval"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                hasError={Boolean(errors.yearInterval)}
                isDisabled={isCreatingAssessment}
                isLabelHidden
                isRequired
                label="Occurance in Years"
                onChange={(e) => {
                  if (e.target.value.match(/^[1-9]\d{0,3}$/)) {
                    field.onChange(Number(e.target.value))
                  }
                }}
                size="small"
                type="number"
                min={1}
                value={String(field.value)}
              />
            )}
          />
        </div>
        years, and each year for the first
        <div className="mx-2 my-1 inline-block max-w-[50px] align-middle">
          <Controller
            name="firstConsecutiveYears"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                hasError={Boolean(errors.firstConsecutiveYears)}
                isDisabled={isCreatingAssessment}
                isLabelHidden
                isRequired
                label="Each year for the first X years"
                onChange={(e) => {
                  if (e.target.value.match(/^\d{0,4}$/)) {
                    field.onChange(Number(e.target.value))
                  }
                }}
                size="small"
                type="number"
                min={0}
                value={String(field.value)}
              />
            )}
          />
        </div>
        years.
      </div>
      <div className="mt-9 flex items-center">
        <div className="flex-1 font-semibold">Mitigation Scenarios:</div>
        <Checkbox
          isChecked={
            mitigationScenarios.length ===
            getValues('mitigationScenarioIds').length
          }
          isDisabled={isCreatingAssessment || mitigationScenarios.length === 0}
          label="Select All"
          setIsChecked={handleToggleSelectAll}
        />
      </div>
      {renderMitigationScenariosList()}
      <div className="mt-9">
        <Controller
          name="notes"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              hasError={Boolean(errors.notes)}
              helperText={errors?.notes?.message}
              isDisabled={isCreatingAssessment}
              isMultiline
              label="Notes"
              placeholder="Enter notes here..."
              rows={4}
            />
          )}
        />
      </div>
      <div className="mt-9 flex justify-end  font-semibold">
        <div className="mr-auto flex items-center">
          Estimated Time: &nbsp;
          {isFetching ? (
            <span className="text-blue-lighthouse">Calculating...</span>
          ) : (
            <span className="text-blue-lighthouse">
              {formatDuration(estimatedTime || 0)}
            </span>
          )}
        </div>
        <Button
          className="mr-2"
          isBusy={isCreatingAssessment}
          onClick={handleClose}
          variant="clear">
          Cancel
        </Button>
        <Button
          busyText="Running..."
          isBusy={isCreatingAssessment}
          isSubmit
          isDisabled={!isValid}>
          Run
        </Button>
      </div>
      <DebounceWatch
        control={control}
        names={Object.keys(debouncedValues) as (keyof IDebouncedValuesState)[]}
        dispatch={debouncedValuesDispatch}
        debouncedValues={debouncedValues}
      />
    </form>
  )
}

export default Content
