import { useState } from 'react'
import ConfigModal from './ConfigModal'

import { GridRenderCellParams } from '@mui/x-data-grid'
import SettingsIcon from '@mui/icons-material/Settings'
import { Button } from 'common/components'

interface IConfigModalToggleProps {
  rowParams: GridRenderCellParams
  assetTypeId: number
}

const ConfigModalToggle = ({
  rowParams,
  assetTypeId,
}: IConfigModalToggleProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  return (
    <>
      <Button
        iconOnly={<SettingsIcon />}
        color="blue-lighthouse"
        size="large"
        onClick={() => setIsOpen(true)}
      />
      <ConfigModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        data={rowParams.row}
        assetTypeId={assetTypeId}
      />
    </>
  )
}

export default ConfigModalToggle
