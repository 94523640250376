import { Outlet } from 'react-router-dom'
import { Select, Button, AssetTypeLinkedTabs } from 'common/components'
import { useAssetTypesQuery } from 'services/apis'
import { useState } from 'react'
import TuningModal from './TuningModal'

const Tuning = () => {
  const { data: assetTypes, isSuccess } = useAssetTypesQuery()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  return isSuccess ? (
    <div className="mb-20">
      <div>
        <AssetTypeLinkedTabs
          tabs={
            assetTypes?.map((assetType) => ({
              label: assetType.label,
              to: assetType.label.toLowerCase(),
            })) ?? []
          }
        />
      </div>
      <div className="tabtitle-bar">
        <h2>Tuning</h2>
        <Button
          onClick={() => {
            setIsModalOpen(true)
          }}
          variant="primary-outlined"
          className="ml-auto">
          Run
        </Button>
      </div>
      <div className="mt-8 flex items-center">
        <div className="w-[335px]">
          <Select
            items={[{ id: 1, label: 'External Corrosion' }]}
            value={'1'}
            label="Subthreat:"
            isLabelInline
            className="w-[225px]"
          />
        </div>
      </div>
      <div className="subtitle-bar">
        <h3>Tuning Log</h3>
      </div>
      <TuningModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <Outlet />
    </div>
  ) : (
    <></>
  )
}

export default Tuning
