import { useState, useEffect } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Modal } from 'common/components'

import {
  useSubThreatParametersQuery,
  useCovariateCoefficientsQuery,
  useCreateParameterLogIdMutation,
  useUpdateSubThreatParametersMutation,
  useUpdateCovariateCoefficientsMutation,
} from 'services/apis'
import {
  ICovariateCoefficients,
  ISubThreatParameters,
} from 'services/interfaces'
import Content from './Content'
import EditParametersFormSchema, {
  EditParametersFormSchemaType,
} from './EditParametersFormSchema'
import { Footer, Sidebar, Tabs } from '.'

export interface IEditParametersConfigModalProps {
  assetTypeId: number
  isOpen: boolean
  modelCode: string
  onClose: () => void
  subThreatModel: string
}

const EditParametersModal = ({
  assetTypeId,
  isOpen,
  modelCode,
  onClose,
  subThreatModel,
}: IEditParametersConfigModalProps) => {
  const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.TimeDependent)

  const form = useForm<EditParametersFormSchemaType>({
    mode: 'onChange',
    resolver: zodResolver(EditParametersFormSchema),
  })

  const {
    formState: { isDirty },
    handleSubmit,
    register,
    reset,
  } = form

  const { data: subThreatParametersData } = useSubThreatParametersQuery(
    {
      assetTypeId,
      modelCode,
    },
    {
      skip: !isOpen,
    }
  )

  const { data: covariateCoefficientsData } = useCovariateCoefficientsQuery(
    {
      assetTypeId,
      modelCode,
    },
    {
      skip: !isOpen,
    }
  )

  const [createParameterLogId, { isLoading: isCreatingParameterLogId }] =
    useCreateParameterLogIdMutation()
  const [
    updateSubThreatParameters,
    { isLoading: isSubThreatParametersUpdating },
  ] = useUpdateSubThreatParametersMutation()
  const [
    updateCovariateCoefficients,
    { isLoading: isCovariateCoefficientsUpdating },
  ] = useUpdateCovariateCoefficientsMutation()

  const isSaving =
    isCreatingParameterLogId ||
    isSubThreatParametersUpdating ||
    isCovariateCoefficientsUpdating

  useEffect(() => {
    if (subThreatParametersData && covariateCoefficientsData) {
      reset({
        subThreatParameters: subThreatParametersData,
        covariateCoefficients: covariateCoefficientsData,
      })
      register('subThreatParameters')
      register('covariateCoefficients')
    }
  }, [subThreatParametersData, covariateCoefficientsData])

  const handleClose = () => {
    onClose()
    setSelectedTab(Tabs.TimeDependent)
    reset()
  }

  const handleSave: SubmitHandler<EditParametersFormSchemaType> = async (
    data
  ) => {
    const { newLogId: nextLogId } = await createParameterLogId().unwrap()

    const subThreatParametersPayload = {
      ...data.subThreatParameters,
      nextLogId,
    }
    const covariateCoefficientsPayload = {
      ...data.covariateCoefficients,
      nextLogId,
    }

    const promises = [
      updateSubThreatParameters(
        subThreatParametersPayload as ISubThreatParameters
      ).unwrap(),
      updateCovariateCoefficients(
        covariateCoefficientsPayload as ICovariateCoefficients
      ).unwrap(),
    ]
    await Promise.all(promises)

    handleClose()
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(handleSave)}>
        <Modal
          isBusy={isSaving}
          isOpen={isOpen}
          onClose={handleClose}
          title="Edit Model Parameters"
          sidebarContent={
            <Sidebar
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              subThreatModel={subThreatModel}
            />
          }
          footerContent={
            <Footer
              handleClose={handleClose}
              isDirty={isDirty}
              isSaving={isSaving}
            />
          }
          className="h-[650px]">
          <Content
            assetTypeId={assetTypeId}
            isSaving={isSaving}
            modelCode={modelCode}
            selectedTab={selectedTab}
          />
        </Modal>
      </form>
    </FormProvider>
  )
}

export default EditParametersModal
