import LighthouseLogo from 'assets/jana-lighthouse_full-inverse-tm.png'
import { useAuth0 } from '@auth0/auth0-react'
import { LoadingBar, Button } from 'common/components'

/**
 * Login "landing" that notify users that they will need to go
 * onto another domain to complete authentication process.
 *
 * Note that we have two "login" pages. One here and another
 * hosted by the authentication provider. When making changes to
 * the login pages, one should ensure that they do not forget
 * that they may also need to modify the other login page.
 * (i.e. maintaining style consistency)
 */
function LoginLandingPage() {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0()

  const handleLogin = () => {
    loginWithRedirect()
  }

  return (
    <div className="flex h-screen flex-col place-content-center bg-blue-navy">
      <div className="m-auto w-144">
        <img
          className="mx-auto block w-96"
          src={LighthouseLogo}
          alt="Lighthouse Logo"
        />
        <div className="mx-4 mt-12 rounded bg-white">
          {isLoading && <LoadingBar />}
          <div className="px-6 py-6 md:px-10 md:py-8">
            {!isAuthenticated && !isLoading && (
              <>
                <h3 className="my-8 mt-4 text-center text-lg font-semibold text-coolGray-900">
                  Authentication Required.
                </h3>

                <Button variant="primary" stretch onClick={handleLogin}>
                  Login
                </Button>
              </>
            )}
            <p className="mt-8 mb-0 px-0 text-xs text-coolGray-500 md:px-14">
              Forgot your password? Contact an admin to have it reset.
            </p>
          </div>
        </div>
      </div>
      <footer className="mx-auto my-10 flex flex-col text-xs text-white">
        <p className="mx-auto my-0">
          Lighthouse® and all associated designs are registered trademarks of
          JANA Corporation.
        </p>
        <p className="mx-auto my-1">
          Copyright ©️ 2023. JANA Corporation. All rights reserved.
        </p>
      </footer>
    </div>
  )
}

export default LoginLandingPage
