import { useEffect, useState, useMemo } from 'react'
import { Pagination, SxProps } from '@mui/material'
import { GridSortModel, useGridApiContext } from '@mui/x-data-grid'

const classes = {
  pageCount: 'absolute right-2 top-2 font-bold text-coolGray-500',
}

interface ICustomPaginationProps {
  dataReload: (pageNumber: number, sortModel: GridSortModel) => void
  hasPagination: boolean
  isPageCountHidden: boolean
  loadByPage: boolean
  page: number
  pageSize: number
  sortModel: GridSortModel
  totalCount: number
}

const CustomPagination = ({
  dataReload,
  hasPagination,
  isPageCountHidden,
  loadByPage,
  page,
  pageSize,
  sortModel,
  totalCount,
}: ICustomPaginationProps) => {
  const apiRef = useGridApiContext()

  const [currentPage, setCurrentPage] = useState(page)

  useEffect(() => {
    if (loadByPage) {
      dataReload(currentPage - 1, sortModel)
    } else {
      apiRef.current.setPage(currentPage - 1)
    }
  }, [currentPage, sortModel])

  const paginationStyles: SxProps = useMemo(
    () => ({
      '& .Mui-selected': {
        color: '#1C355E !important',
        backgroundColor: 'transparent !important',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      '.MuiPaginationItem-root.Mui-focusVisible': {
        backgroundColor: 'rgba(0,0,0,0.025) !important',
      },
      '.MuiPaginationItem-page': { fontWeight: '700', color: '#929497' },
      '.MuiPagination-ul': {
        flexWrap: 'nowrap',
        li: {
          width: 32,
        },
      },
    }),
    []
  )

  if (!hasPagination || !totalCount) {
    return null
  }

  const getPageCount = () => Math.ceil(totalCount / pageSize)

  return (
    <div className="relative mt-5 flex justify-center">
      <Pagination
        sx={paginationStyles}
        showFirstButton
        showLastButton
        color="primary"
        page={currentPage}
        count={getPageCount() || 1}
        onChange={(_event, value: number) => {
          setCurrentPage(value)
        }}
      />
      <span className={`${isPageCountHidden && 'hidden'} ${classes.pageCount}`}>
        Page {currentPage} of {getPageCount() || 1}
      </span>
    </div>
  )
}

export default CustomPagination
