import { TAB_IMPORT_OPTIONS, TAB_ADD_NOTES } from '.'

interface ISidebarProps {
  onTabChange: (tabName: string) => void
  currentTab: string
}

const Sidebar = ({ currentTab, onTabChange }: ISidebarProps) => {
  const sections = [TAB_IMPORT_OPTIONS, TAB_ADD_NOTES]

  const buttonClass = (tabName: string) =>
    `block w-full cursor-pointer rounded border-none text-left !font-semibold capitalize  ${
      currentTab === tabName
        ? `bg-lightBlue-100 text-coolGray-900`
        : 'bg-white text-coolGray-400'
    }  py-3 px-5`

  return (
    <div className="text-base font-bold capitalize text-coolGray-900">
      <p className="mb-5">Section</p>
      {sections.map((section, index) => (
        <button
          type="button"
          key={index}
          style={{ font: 'inherit' }}
          onClick={() => onTabChange(section)}
          className={buttonClass(section)}>
          {section}
        </button>
      ))}
    </div>
  )
}

export default Sidebar
