/**
 * Debounce function
 * @param {function} func - Function to be debounced
 * @param {number} ms - The number of milliseconds to wait until next event is fired
 */
function debounce<T extends (...args: any[]) => any>(func: T, ms: number) {
  let timeout: ReturnType<typeof setTimeout>
  return (...args: Parameters<T>): ReturnType<T> => {
    let result: any
    timeout && clearTimeout(timeout)
    timeout = setTimeout(() => {
      result = func(...args)
    }, ms)
    return result
  }
}

export default debounce
