import { ReactNode, MouseEvent, useState, useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { SxProps } from '@mui/material'
import MuiButton from '@mui/material/Button'
import MuiMenu from '@mui/material/Menu'
import MuiMenuItem from '@mui/material/MenuItem'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { IMenuItem } from 'common/interfaces'
import { Button } from 'common/components'

interface IMenuItemProps {
  children: ReactNode
  menuItems?: IMenuItem[]
  link?: string
  isActive?: boolean
}

const muiMenuStyles: SxProps = {
  transform: 'translateY(-8px)',
  '.MuiList-root': { minWidth: '200px' },
}

function MenuItem({
  children,
  isActive = false,
  link = '',
  menuItems = [],
}: IMenuItemProps) {
  const { pathname } = useLocation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const muiButtonClass: string = useMemo((): string => {
    const menuClosedColor: string = `text-${
      isActive ? 'white' : 'coolGray-500'
    }`

    return `px-5 normal-case tracking-wide hover:text-white focus:text-white 
    ${open ? 'text-white' : menuClosedColor}`
  }, [open, isActive])

  const renderMenuItems = (): ReactNode[] => {
    return menuItems.map((menuItem: IMenuItem, index: number): ReactNode => {
      const { label, link } = menuItem
      const backgroundColor: string = pathname.includes(link)
        ? 'rgba(0,0,0,.05)'
        : ''

      return (
        <MuiMenuItem
          key={index}
          component={Link}
          to={link}
          style={{ backgroundColor }}>
          {label}
        </MuiMenuItem>
      )
    })
  }

  const renderLink = () => {
    const color = pathname === link ? 'white' : 'coolGray-500'

    return (
      <Button
        className="hover:text-white focus:text-white"
        color={color}
        letterCase="normal-case"
        to={link}
        variant="navigation">
        {children}
      </Button>
    )
  }

  const renderMenu = () => {
    return (
      <div>
        <MuiButton
          aria-controls={open ? 'basic-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          className={muiButtonClass}
          endIcon={<ArrowDropDownIcon />}
          id="basic-button"
          onClick={handleClick}>
          {children}
        </MuiButton>
        <MuiMenu
          anchorEl={anchorEl}
          id="basic-menu"
          onClose={handleClose}
          open={open}
          sx={muiMenuStyles}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}>
          {renderMenuItems()}
        </MuiMenu>
      </div>
    )
  }

  return <>{menuItems.length > 0 ? renderMenu() : renderLink()}</>
}

export default MenuItem
