import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily:
        'Montserrat, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol',
      fontSize: '1rem',
    },
  },
  palette: {
    error: {
      main: '#B70000',
    },
    info: {
      main: '#007cba',
    },
    success: {
      main: '#4FBC8F',
    },
  },
})
