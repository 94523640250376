import Assessments from './Assessments'
import LogTable from './LogTable'
import SidePanel from './SidePanel'
import CreateAssessmentModal from './CreateAssessmentModal'

export { LogTable, SidePanel, CreateAssessmentModal }
export default Assessments

export const STATUS = {
  IN_PROGRESS: 1,
  COMPLETED: 2,
  ERROR: 3,
} as const
