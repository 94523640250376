import { Layout, Button, Breadcrumbs } from 'common/components'
import LogTable from './LogTable'
import SidePanel from './SidePanel'
import { ImportDataModal as Modal } from '.'
import { ReactNode, useState } from 'react'

const breadcrumbItems = [
  { title: 'Configuration', to: '/configuration' },
  { title: 'Data Management', to: '' },
]

const DataLoad = () => {
  const [selectedRowId, setSelectedRowId] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)

  const getSidePanelContent = (): ReactNode => {
    if (selectedRowId) {
      return <SidePanel selectedRowId={selectedRowId} />
    } else {
      return null
    }
  }

  const onModalClose = (refreshPage: boolean = false) => {
    if (refreshPage) {
      setCurrentPage(1)
      setShowModal(false)
    } else {
      setShowModal(false)
    }
  }

  return (
    <Layout sidePanel={getSidePanelContent()}>
      <Breadcrumbs items={breadcrumbItems} />
      <div className="title-bar">
        <h1>Data Load (Assets & Leaks)</h1>
        <Button variant="primary-outlined" onClick={() => setShowModal(true)}>
          Import Data
        </Button>
      </div>
      <div className="subtitle-bar mb-2">
        <h2>Data Log</h2>
      </div>
      <div className="h-full">
        <LogTable
          selectedRowId={selectedRowId}
          onRowSelect={(id: number) => setSelectedRowId(id)}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <Modal isOpen={showModal} onClose={onModalClose} />
    </Layout>
  )
}

export default DataLoad
