import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Table, ResponsiveLabels, Button } from 'common/components'
import SettingsIcon from '@mui/icons-material/Settings'
import { formatTimestamp } from 'common/utils'
import { GridRenderCellParams } from '@mui/x-data-grid'
import { useAssetTypesQuery } from 'services/apis'
import ConfigModal from '../ConfigModal'

const DUMMYDATA = [
  {
    id: 1,
    tableType: 'Consequence',
    lastUpdated: '2021-04-07T16:00:00.000Z',
    updatedBy: 'Lewis Ko',
    selectedAttributes: ['Location Category', 'Region'],
  },
  {
    id: 2,
    tableType: 'Repair',
    lastUpdated: '2022-04-07T16:00:00.000Z',
    updatedBy: 'Sreeraj Nair',
    selectedAttributes: [
      'Location Category',
      'Material',
      'Pipe Size',
      'Region',
    ],
  },
]

const LookupTablesLog = () => {
  const { assetType } = useParams()
  const { data: assetTypesData } = useAssetTypesQuery()
  const assetTypeId =
    assetTypesData?.find(
      (assetTypeData: any) =>
        assetTypeData.label.toLowerCase() === assetType?.toLowerCase()
    )?.id ?? -1
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [selectedTableType, setSelectedTableType] = useState<string>('')

  const columnConfig = [
    {
      field: 'tableType',
      flex: 1.5,
      headerName: 'TABLE TYPE',
      sortable: false,
      disableColumnMenu: true,
      cellClassName: 'font-bold',
    },
    {
      field: 'lastUpdated',
      flex: 1,
      headerName: 'LAST UPDATED',
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => {
        return <>{formatTimestamp(params.row.lastUpdated)}</>
      },
    },
    {
      field: 'updatedBy',
      flex: 1,
      headerName: 'UPDATED BY',
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'selectedAttributes',
      flex: 2,
      headerName: 'SELECTED ATTRIBUTES',
      sortable: false,
      disableColumnMenu: true,
      disableOverflowTooltip: true,
      renderCell: (params: GridRenderCellParams) => {
        return <ResponsiveLabels labels={params.value} />
      },
    },
    {
      field: 'configure',
      width: 200,
      headerName: 'CONFIGURE',
      sortable: false,
      disableColumnMenu: true,
      disableOverflowTooltip: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <>
            <Button
              iconOnly={<SettingsIcon />}
              color="blue-lighthouse"
              size="large"
              onClick={() => {
                setIsModalOpen(true)
                setSelectedTableType(params.row.tableType)
              }}
            />
          </>
        )
      },
    },
  ]
  return (
    <>
      <Table
        variant="config-table"
        columns={columnConfig}
        checkboxSelection={false}
        rows={DUMMYDATA}
        pageSize={5}
        hasPagination={false}
      />
      <ConfigModal
        assetTypeId={assetTypeId}
        tableType={selectedTableType}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  )
}

export default LookupTablesLog
