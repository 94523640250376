import { Tabs as MuiTabs, Tab as MuiTab, SxProps } from '@mui/material'
import { ReactNode, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getAssetIcon } from 'common/utils'

const MuiTabStyles: SxProps = {
  backgroundColor: '#E4E5E6',
  padding: '0',
  fontWeight: '600',
  color: '#929497',
  transition: 'background-color .2s',
  textTransform: 'none',
  borderRadius: '4px',
  '&.MuiButtonBase-root': {
    width: '180px',
  },
  '&.Mui-selected': {
    backgroundColor: '#FFFFFF',
    color: '#231F20',
  },
  '&.Mui-focusVisible': {
    outline: `5px auto ${
      navigator.userAgent.match(/firefox|fxios/i)
        ? 'Highlight'
        : '-webkit-focus-ring-color'
    }`,
  },
}

const MuiTabsStyles: SxProps = {
  padding: '6px',
  backgroundColor: '#E4E5E6',
  width: 'fit-content',
  borderRadius: '4px',
  '.MuiTabs-indicator': {
    display: 'none',
  },
}

interface ITab {
  label: string
  to: string
}

interface IAssetTypeLinkedTabsProps {
  /**
   * An array of objects containing `label` and `to` properties for the tab
   * label and the destination route, respectively.
   */
  tabs: Array<ITab>
}

/**
 * Asset Type tabs that displays the linked route in an <a href="https://reactrouter
 * .com/en/main/components/outlet" target="_blank">Outlet</a>. <hr />
 * <strong>Note:</strong> We save the last selected Asset Type to <a href=
 * "https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage"
 * target="_blank">session storage</a> so that the selection persists on any
 * page that may contain an instance of this component.
 */
const AssetTypeLinkedTabs = ({ tabs }: IAssetTypeLinkedTabsProps) => {
  const navigate = useNavigate()
  const location = useLocation()

  const activeTabIndex = (): number => {
    return parseInt(sessionStorage.getItem('tab') ?? '0')
  }

  const [value, setValue] = useState(activeTabIndex)

  const getLabel = (tab: ITab): ReactNode => {
    const { label } = tab
    return (
      <div className="flex flex-row items-center">
        {getAssetIcon(label)}
        <div className="m-0 p-0 align-middle">{label}</div>
      </div>
    )
  }

  const renderTabs = (): ReactNode =>
    tabs.map((tab) => (
      <MuiTab
        disableRipple
        key={tab.label}
        label={getLabel(tab)}
        onClick={() => navigate(tab.to)}
        className="m-0 min-h-[40px] min-w-[150px] p-2 px-6"
        sx={MuiTabStyles}
      />
    ))

  const handleTabChange = (index: number): void => {
    setValue(index)
    sessionStorage.setItem('tab', index.toString(10))
  }

  const { assetType } = useParams()

  useEffect(() => {
    if (tabs.length > 0) {
      navigate(tabs[activeTabIndex()]?.to, { replace: true })
    }
  }, [])

  useEffect(() => {
    if (!assetType) {
      navigate(tabs[activeTabIndex()]?.to, { replace: true })
    }
  }, [assetType])

  useEffect(() => {
    if (tabs.length > 0) {
      const tabIndex = tabs.findIndex((tab) =>
        location.pathname.includes(tab.to)
      )
      handleTabChange(tabIndex > -1 ? tabIndex : 0)
    }
  }, [location.pathname])

  return (
    <MuiTabs
      value={value}
      onChange={(_e, index) => {
        handleTabChange(index)
      }}
      sx={MuiTabsStyles}>
      {renderTabs()}
    </MuiTabs>
  )
}

export default AssetTypeLinkedTabs
