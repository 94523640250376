import { Values } from '.'

const MOCKDATA_LEAK_GRADE = [
  {
    id: 1,
    filePath: '/temp',
    fileName: 'Leak Grade Probability V1.5',
    uploadedOn: new Date().toISOString(),
    uploadedBy: 'Sreeraj Nair',
  },
  {
    id: 2,
    filePath: '/temp',
    fileName: 'Leak Grade Probability V1.0',
    uploadedOn: new Date().toISOString(),
    uploadedBy: 'Vikas Goyal',
  },
]

const MOCKDATA_OUTCOME = [
  {
    id: 1,
    filePath: '/temp',
    fileName: 'Outcome Probability V2.0',
    uploadedOn: new Date().toISOString(),
    uploadedBy: 'Sreeraj Nair',
  },
  {
    id: 2,
    filePath: '/temp',
    fileName: 'Leak Grade Probability V1.0',
    uploadedOn: new Date().toISOString(),
    uploadedBy: 'Vikas Goyal',
  },
]

const ScenarioValues = () => {
  return (
    <>
      <div className="tabtitle-bar">
        <h2>Scenario Values</h2>
      </div>
      <Values
        data={MOCKDATA_LEAK_GRADE}
        title="Leak Grade Values"
        currentTitle="Current Values"
        archiveTitle="Archived Leak Grade Values"
      />

      <Values
        data={MOCKDATA_OUTCOME}
        title="Outcome Values"
        currentTitle="Current Values"
        archiveTitle="Archived Outcome Values"
      />
    </>
  )
}

export default ScenarioValues
