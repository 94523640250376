import { useNavigate } from 'react-router-dom'
import ScenarioList from './ScenarioList'
import { Button, setSnackbarIsOpen } from 'common/components'
import { useAppDispatch } from 'app/hooks'

const Scenarios = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  return (
    <div className="mb-20">
      <div className="tabtitle-bar">
        <h2>Scenarios</h2>
        <div>
          <Button
            className="ml-auto"
            onClick={() => {
              dispatch(setSnackbarIsOpen(false))
              navigate(`edit?id=-1`)
            }}
            variant="primary-outlined">
            Create Scenario
          </Button>
        </div>
      </div>

      <ScenarioList />
    </div>
  )
}

export default Scenarios
