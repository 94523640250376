import z from 'zod'

const ReportExportFormSchema = z.object({
  reportYear: z.number().nonnegative({ message: 'Please choose a year' }),
  reportType: z.number({ required_error: 'Please select a report type' }),
})

type ReportExportFormSchemaType = z.infer<typeof ReportExportFormSchema>

export type { ReportExportFormSchemaType }

export default ReportExportFormSchema
