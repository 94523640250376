import ArchiveModal from './ArchiveModal'
import CloneModal from './CloneModal'
import columnConfig from './columnConfig'
import DeleteModal from './DeleteModal'
import ScenarioList from './ScenarioList'

export default ScenarioList
export { ArchiveModal, CloneModal, columnConfig, DeleteModal }

// Constants
export const STATUS_TYPES: { [key: string]: string } = {
  CREATED: 'Created',
  READYFORUSE: 'Ready for Use',
  PUBLISHING: 'Publishing',
  ARCHIVED: 'Archived',
  ERROR: 'Publish Failed',
}
