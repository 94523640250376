import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Logout = () => {
  const { isAuthenticated, logout } = useAuth0()
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated) {
      logout({
        returnTo: window.location.origin,
      })
    } else {
      navigate('/')
    }
  }, [isAuthenticated])
  return <></>
}

export default Logout
