import { FocusEventHandler, forwardRef } from 'react'
import TextField from '@mui/material/TextField'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { SxProps } from '@mui/material'

interface IDatePickerProps {
  /**
   * Callback that is triggered when the input is moved out of focus.
   */
  onBlur?: FocusEventHandler<HTMLInputElement>
  /**
   * Callback that is triggered when date value is changed.
   */
  onChange: (date: unknown, keyboardInputValue?: string | undefined) => void
  /**
   * The controlled value of string in ISO datetime format.
   */
  value: string | null
  /**
   * Disables the input.
   */
  isDisabled?: boolean
  /**
   * The input value is highlightable but cannot be changed.
   */
  isReadOnly?: boolean
}

const textFieldStyles: SxProps = {
  width: '100%',
  '.MuiInputBase-root': {
    boxShadow: '0 0 0 1px #D0D2D3',
    transition: 'box-shadow .150s',
    '&:not(.Mui-disabled):hover': {
      boxShadow: '0 0 0 1px #A6A8AB',
    },
    '&.Mui-focused': {
      boxShadow: '0 0 0 2px #2563eb !important',
    },
    '&.Mui-disabled': {
      backgroundColor: '#F2F3F4',
    },
    '&.Mui-error': {
      boxShadow: '0 0 0 2px #B70000 !important',
    },
  },
  '.MuiOutlinedInput-root': {
    paddingTop: '4px',
    paddingBottom: '5px',
  },
  '.MuiOutlinedInput-input': {
    paddingLeft: '10.5px',
    paddingRight: 0,
    paddingY: '3.5px',
  },
  '.MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '.MuiInputAdornment-root': {
    marginLeft: 0,
  },
}

/**
 * Date picker allows users to choose and input a date by manually typing the
 * date into the input field or by using a calendar-like dropdown.
 */
const BasicDatePicker = forwardRef<HTMLInputElement, IDatePickerProps>(
  ({ onBlur, onChange, value, isDisabled, isReadOnly }, ref) => {
    const hasError: boolean = value === 'Invalid Date'

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          disabled={isDisabled}
          readOnly={isReadOnly}
          inputFormat="MM/dd/yyyy"
          inputRef={ref}
          value={value}
          onChange={onChange}
          renderInput={(params) => (
            <TextField
              {...params}
              error={hasError}
              onBlur={onBlur}
              variant="outlined"
              sx={textFieldStyles}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
      </LocalizationProvider>
    )
  }
)

BasicDatePicker.displayName = 'BasicDatePicker'

export default BasicDatePicker
