import { baseApi } from '.'

export const reportsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    reportYears: builder.query<Array<number>, string>({
      query: (reportType) => `/v1/reports/${reportType}/years`,
      providesTags: ['Reports'],
    }),
  }),
  overrideExisting: false,
})

export const { useReportYearsQuery } = reportsApi
