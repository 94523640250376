import React, { ComponentType } from 'react'
import LighthouseLogo from 'assets/LighthouseLogoFullInverse.svg'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { LoadingBar } from 'common/components'

interface IProtectedRouteProps {
  component: ComponentType
}

const ProtectedRoute: React.FC<IProtectedRouteProps> = ({ component }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className="flex h-screen place-content-center bg-blue-navy">
        <div className="m-auto">
          <img
            className="mx-auto block w-64"
            src={LighthouseLogo}
            alt="Lighthouse Logo"
          />
          <div className="mx-4 mt-12 rounded bg-white">
            <LoadingBar />
            <div className="px-6 py-6 md:px-10 md:py-8">
              <p>Checking Authentication Status...</p>
            </div>
          </div>
        </div>
      </div>
    ),
  })

  return <Component />
}

export default ProtectedRoute
