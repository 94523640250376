import { useEffect, useState } from 'react'
import {
  Controller,
  UseFieldArrayReturn,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import { Select, Input } from 'common/components'
import { ISelectItem } from 'common/interfaces'
import { getSelectLabelById } from 'common/utils'
import { useScenarioTypesQuery } from 'services/apis'
import { IAssetField } from 'services/interfaces'
import { defaultOutcomeFields } from '../utils'
import { CreateEditScenarioFormSchemaType } from '.'

interface INameAndTypeInputsProps {
  allAssetFields: IAssetField[]
  assetTypes: ISelectItem[]
  isViewScreen: boolean
  rulesFieldArray: UseFieldArrayReturn<
    CreateEditScenarioFormSchemaType,
    'rules',
    'id'
  >
}

const NameAndTypeInputs = ({
  allAssetFields,
  assetTypes,
  isViewScreen,
  rulesFieldArray,
}: INameAndTypeInputsProps) => {
  const { data: mitigationTypesData } = useScenarioTypesQuery()

  const {
    control,
    getValues,
    trigger,
    formState: { dirtyFields },
  } = useFormContext<CreateEditScenarioFormSchemaType>()

  const type = useWatch({
    control,
    name: 'type',
  })

  // will be true if type was changed after component mount
  const [typeWasChanged, setTypeWasChanged] = useState(false)

  useEffect(() => {
    // these guard clauses are there to ensure that the effect runs only
    // if the mitigation type selection was made by the user and not by
    // the initial population of saved data
    const isDirty = 'type' in dirtyFields
    if (type === -1 || assetTypes.length === 0 || (!isDirty && !typeWasChanged))
      return

    setTypeWasChanged(true)

    const typeLabel: string = getSelectLabelById(
      mitigationTypesData ?? [],
      type
    )

    // Reset Outcomes
    getValues('rules').forEach((_rule, index) => {
      rulesFieldArray.update(index, {
        ...getValues('rules')[index],
        outcomes: [],
      })
    })

    // Add Outcomes
    assetTypes.forEach((assetType: ISelectItem) => {
      defaultOutcomeFields[typeLabel]?.forEach((item) => {
        const assetTypeFields = getAssetFieldsByAssetType(
          allAssetFields,
          assetType.id
        )
        const field = assetTypeFields?.find(
          (value: IAssetField) =>
            value.assetFieldName.toLowerCase() === item.toLowerCase()
        )

        if (field) {
          getValues('rules').forEach((rule, index) => {
            if (rule.assetTypeId === assetType.id) {
              rulesFieldArray.update(index, {
                ...getValues('rules')[index],
                outcomes: [
                  ...getValues('rules')[index].outcomes,
                  {
                    value: '',
                    ruleId: rule.mitigationRuleId,
                    assetFieldId: field.assetFieldId,
                  },
                ],
              })
            }
          })
        }
      })
    })
  }, [type, assetTypes])

  const getAssetFieldsByAssetType = (
    fieldConfig: IAssetField[] | undefined,
    id: number
  ) => {
    const configByAssetType: IAssetField[] = []
    fieldConfig?.forEach((config: IAssetField) => {
      if (config.assetTypes.includes(id) || config.assetTypes.includes(0))
        configByAssetType.push(config)
    })
    return configByAssetType
  }

  return (
    <div className="flex flex-row justify-start">
      <div className="mr-[32px] w-[300px]">
        <Controller
          control={control}
          name="name"
          render={({ field, fieldState: { error } }) => (
            <Input
              {...field}
              hasError={Boolean(error)}
              helperText={error?.message}
              isReadOnly={isViewScreen}
              label="Scenario Name"
              maxLength={50}
              onChange={(e) => {
                field.onChange(e)
                trigger('name')
              }}
            />
          )}
        />
      </div>
      <div className="mr-[32px] w-[300px]">
        <Controller
          control={control}
          name="type"
          render={({ field, fieldState: { error } }) => (
            <Select
              {...field}
              hasError={Boolean(error)}
              helperText={error?.message}
              hasRadio
              isReadOnly={isViewScreen}
              items={mitigationTypesData || []}
              label="Mitigation Type"
              setValue={(value) => {
                field.onChange(value)
                trigger('type')
              }}
              value={field.value === -1 ? '' : String(field.value)}
            />
          )}
        />
      </div>
    </div>
  )
}

export default NameAndTypeInputs
