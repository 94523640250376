import { Dispatch, SetStateAction } from 'react'
import { Tabs } from '.'

interface ISidebarProps {
  selectedTab: Tabs
  setSelectedTab: Dispatch<SetStateAction<Tabs>>
  subThreatModel: string
}

const Sidebar = ({
  selectedTab,
  setSelectedTab,
  subThreatModel,
}: ISidebarProps) => {
  return (
    <>
      <div>
        <p className="my-0 font-bold">Subthreat:</p>
        <p className="mt-2">{subThreatModel}</p>
      </div>
      <div className="mt-16">
        <p className="mt-0 mb-5 font-bold ">Section</p>
        {Object.values(Tabs).map((tabName) => (
          <button
            type="button"
            key={tabName}
            style={{ font: 'inherit' }}
            onClick={() => setSelectedTab(tabName)}
            className={`block w-full cursor-pointer rounded border-none text-left !font-semibold capitalize ${
              selectedTab === tabName
                ? `bg-lightBlue-100`
                : `bg-white text-coolGray-400`
            }  py-3 px-5`}>
            {tabName}
          </button>
        ))}
      </div>
    </>
  )
}

export default Sidebar
