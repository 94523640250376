import { ISubThreat } from 'services/interfaces'
import { formatTimestamp } from 'common/utils'
import { ConfigOutletCheckbox } from '.'
import SettingsIcon from '@mui/icons-material/Settings'
import { Button } from 'common/components'
import { Dispatch, Fragment, SetStateAction } from 'react'

const columns = [
  'SUBTHREAT MODEL',
  'MODEL STRUCTURE',
  'LAST UPDATED',
  'UPDATED BY',
  'ENABLE',
  'CONFIGURE',
]

const Separator = () => (
  <tr>
    <td className="py-2"></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
  </tr>
)

interface IConfigOutletTableProps {
  subThreats: ISubThreat[]
  assetTypeId: number
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
  setSelectedSubThreatName: Dispatch<SetStateAction<string>>
  setSelectedSubThreatModelCode: Dispatch<SetStateAction<string>>
}

const ConfigOutletTable = ({
  subThreats,
  assetTypeId,
  setIsModalOpen,
  setSelectedSubThreatModelCode,
  setSelectedSubThreatName,
}: IConfigOutletTableProps) => {
  const renderColumns = () =>
    columns.map((title, index) => {
      let styles = 'font-semibold text-coolGray-500'

      if (index === 0) {
        styles = 'px-4 py-5 font-semibold text-coolGray-500'
      }

      return (
        <th className={styles} key={title}>
          {title}
        </th>
      )
    })

  const renderSubThreats = () =>
    subThreats.map((subThreat) => {
      const {
        modelPropertiesId,
        SubThreatName,
        modelStructureName,
        lastUpdated,
        UpdatedBy,
        SubThreatModelCode,
        isEnabled,
      } = subThreat
      return (
        <Fragment key={modelPropertiesId}>
          <tr className="rounded-sm shadow-config">
            <td className="w-[30%] px-4 py-5 font-bold">{SubThreatName}</td>
            <td className="w-[17%]">{modelStructureName}</td>
            <td className="w-[18%]">{formatTimestamp(lastUpdated)}</td>
            <td className="w-[17%]">{UpdatedBy}</td>
            <td className="w-[9%]">
              {
                <ConfigOutletCheckbox
                  isEnabled={isEnabled}
                  modelCode={SubThreatModelCode}
                  assetTypeId={assetTypeId}
                />
              }
            </td>
            <td className="w-[9%]">
              {
                <Button
                  iconOnly={<SettingsIcon />}
                  color="blue-lighthouse"
                  size="large"
                  onClick={() => {
                    setIsModalOpen(true)
                    setSelectedSubThreatName(SubThreatName)
                    setSelectedSubThreatModelCode(SubThreatModelCode)
                  }}
                />
              }
            </td>
          </tr>
          <Separator />
        </Fragment>
      )
    })

  return (
    <table className="w-full text-left">
      <thead>
        <tr>{renderColumns()}</tr>
      </thead>
      <tbody>{renderSubThreats()}</tbody>
    </table>
  )
}

export default ConfigOutletTable
