import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { ForwardedRef, forwardRef, PropsWithChildren } from 'react'

const Alert = (
  props: PropsWithChildren<AlertProps>,
  ref: ForwardedRef<HTMLDivElement>
) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
}

const ForwardedAlert = forwardRef<HTMLDivElement, AlertProps>(Alert)

export default ForwardedAlert
