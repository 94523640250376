import { RegularTabs, Table, Card } from 'common/components'
import { formatTimestamp } from 'common/utils'
import { GridRenderCellParams } from '@mui/x-data-grid'
import { useGetPublishLogQuery } from 'services/apis'
import { ReactNode } from 'react'
import { skipToken } from '@reduxjs/toolkit/dist/query'

interface ISidePanelProps {
  configurationId: number
}

const configurationColumnConfig = [
  {
    field: 'configurationSteps',
    headerName: 'Configuration Steps',
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
  },
  {
    field: 'lastUpdated',
    headerName: 'Last Updated',
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => formatTimestamp(params.value),
  },
]

const modelTuningColumnConfig = [
  {
    field: 'subthreat',
    headerName: 'Subthreat',
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
  },
  {
    field: 'lastUpdated',
    headerName: 'Last Updated',
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => formatTimestamp(params.value),
  },
]

const SidePanel = ({ configurationId }: ISidePanelProps) => {
  const { data, isError } = useGetPublishLogQuery(
    configurationId === -1 ? skipToken : configurationId
  )

  const detailCardValues = [
    { title: 'PUBLISH VERSION', value: data?.publishVersion },
    {
      title: 'TIMESTAMP',
      value: data?.publishDate && formatTimestamp(data.publishDate),
    },
    { title: 'USER', value: data?.user },
  ]

  const getTabContent = (data: any, config: any): ReactNode => (
    <Table
      variant="data-table"
      rows={data || []}
      columns={config}
      pageSize={5}
      checkboxSelection={false}
      hasPagination={true}
      isPageCountHidden
      rowBorderBottomColor="#E4E5E6"
    />
  )

  const tabs = [
    {
      label: 'Configuration',
      children: getTabContent(data?.configurations, configurationColumnConfig),
    },
    {
      label: 'Model Tuning',
      children: getTabContent(data?.modelTunings, modelTuningColumnConfig),
    },
  ]

  const renderError = (): ReactNode => {
    let errNode: ReactNode

    if (isError) {
      errNode = <p className="my-2">Error occurred while fetching data</p>
    } else {
      errNode = (
        <>
          {data?.errors.map((error, index) => (
            <div key={index}>
              <h4 className="mb-2 font-semibold">{error.errorTitle}</h4>
              <p className="my-2">{error.errorDescription}</p>
            </div>
          ))}
        </>
      )
    }

    return (
      <div>
        <p className="mt-0 mb-4 text-center text-xl font-bold text-red-700">
          Found Errors
        </p>
        <div className="max-h-80 overflow-auto">{errNode}</div>
      </div>
    )
  }

  const hasError = (): boolean =>
    isError || (data && data.status === 3) || false

  return (
    <>
      {configurationId === -1 ? null : (
        <>
          <div className="sidepanel-title-bar">
            <h2>Publish Summary</h2>
          </div>
          <div className="sidepanel-subtitle-bar">
            <h3>Publish Details</h3>
          </div>
          <Card entries={detailCardValues} notes={data?.notes || ''} />
          {hasError() ? renderError() : <RegularTabs tabs={tabs} />}
        </>
      )}
    </>
  )
}

export default SidePanel
