import { useState } from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { Button } from 'common/components'
import { CostCard } from '.'
import { ICostFileMeta } from 'services/interfaces'

interface IValuesProps {
  data: ICostFileMeta[]
  title: string
  currentTitle: string
  archiveTitle: string
}

const Values = ({ data, title, currentTitle, archiveTitle }: IValuesProps) => {
  const [isArchivedOpen, setIsArchivedOpen] = useState(false)

  return (
    <>
      <div className="subtitle-bar">
        <h3>{title}</h3>
        <div>
          <Button className="mr-4" variant="secondary-outlined">
            Download Template
          </Button>
          <Button variant="upload" letterCase="normal-case">
            Upload Template...
          </Button>
        </div>
      </div>
      <div className="flex">
        <div className="w-1/2 pr-[70px]">
          <h3 className="text-[16px]">{currentTitle}</h3>
          {data && !!data.length && <CostCard {...data[0]} />}
        </div>
        <div className="w-1/2 pl-[70px]">
          <div className="flex">
            <h3 className="text-[16px]">{archiveTitle}</h3>
            <Button
              iconOnly={
                isArchivedOpen ? (
                  <ArrowDropDownIcon className="fill-blue-lighthouse" />
                ) : (
                  <ArrowRightIcon />
                )
              }
              onClick={() => {
                setIsArchivedOpen(!isArchivedOpen)
              }}
            />
          </div>
          {isArchivedOpen &&
            data?.map((costFile: ICostFileMeta, index: number) => {
              if (index === 0) return null
              return <CostCard key={costFile.id} {...costFile} />
            })}
        </div>
      </div>
    </>
  )
}

export default Values
