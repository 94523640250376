import { useMemo, useState } from 'react'
import { Searchbox } from 'common/components'
import {
  ILikelihoodAttribute,
  ILikelihoodAttributes,
} from 'services/interfaces'
import AttributesCheckbox from './AttributesCheckbox'

interface IOtherAttributesProps {
  attributes: ILikelihoodAttributes
  isSaving: boolean
}

const OtherAttributes = ({ attributes, isSaving }: IOtherAttributesProps) => {
  const [attributeSearchText, setAttributeSearchText] = useState('')

  const otherAttributes = useMemo(
    () =>
      attributes?.likelihoodAttributes?.filter(
        (attribute: ILikelihoodAttribute) => !attribute.isCore
      ),
    [attributes]
  )

  const filteredOtherAttributes = useMemo(
    () =>
      otherAttributes?.filter((attribute: ILikelihoodAttribute) =>
        attributeSearchText.length
          ? attribute.assetFieldName
              .toLowerCase()
              .includes(attributeSearchText.toLowerCase().trim())
          : true
      ),
    [attributeSearchText]
  )

  return (
    <>
      <div className="flex items-center justify-between">
        <p className="my-0 mr-4 font-bold uppercase text-blue-navy">
          Other Attributes
        </p>
        <Searchbox
          className="w-1/2"
          isLabelHidden
          label="Search attributes"
          placeholder="Search Attributes"
          value={attributeSearchText}
          onChange={setAttributeSearchText}
          isDisabled={isSaving}
        />
      </div>
      <div className="mt-8 grid h-[405px] auto-rows-min grid-cols-2 items-center overflow-auto whitespace-normal rounded border-2 border-solid border-gray-200 p-6 py-3">
        {filteredOtherAttributes?.map(
          ({ assetFieldId, assetFieldName }: ILikelihoodAttribute) => (
            <AttributesCheckbox
              key={assetFieldId}
              assetFieldId={assetFieldId}
              assetFieldName={assetFieldName}
              isSaving={isSaving}
            />
          )
        )}
      </div>
    </>
  )
}

export default OtherAttributes
