import ScenarioProbability from './ScenarioProbability'
import ScenarioProbabilityLookupTablesTab from './LookupTablesTab/LookupTablesTab'
import ScenarioProbabilityScenarioValuesTab from './ScenarioValuesTab/ScenarioValuesTab'
import ScenarioProbabilityLookupTables from './LookupTablesTab/LookupTables'
import ScenarioProbabilityScenarioValues from './ScenarioValuesTab/ScenarioValues'

export {
  ScenarioProbabilityLookupTablesTab,
  ScenarioProbabilityScenarioValuesTab,
  ScenarioProbabilityLookupTables,
  ScenarioProbabilityScenarioValues,
}

export default ScenarioProbability
