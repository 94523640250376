import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import ArrowRightTwoToneIcon from '@mui/icons-material/ArrowRightTwoTone'
import { Button, Checkbox } from 'common/components'
import { getAssetIcon } from 'common/utils'

interface IAssetTypeOptionProps {
  assetTypeId: number
  assetTypeName: string
  isEnabled: boolean
  isSelected: boolean
  setSelectedAssetTypeId: Dispatch<SetStateAction<number>>
}

const AssetTypeOption = ({
  assetTypeId,
  assetTypeName,
  isEnabled,
  isSelected,
  setSelectedAssetTypeId,
}: IAssetTypeOptionProps) => {
  const [isChecked, setIsChecked] = useState(isEnabled)

  useEffect(() => {
    if (isChecked !== isEnabled) {
      console.log('SEND PATCH REQUEST')
    }
  }, [isChecked])

  const getBgColorClass = () => {
    if (isSelected) return 'bg-blue-lighthouse'
    if (isChecked) return 'bg-[#E7EEFF]'
    return 'bg-gray-200'
  }

  const getTextColorClass = () => {
    if (isSelected) return 'text-white'
    if (isChecked) return 'text-blue-lighthouse'
    return 'text-[#707070]'
  }

  const getIconColor = () => {
    if (isSelected) return 'white'
    if (isChecked) return '#82ACFF'
    return undefined
  }

  const handleSelectAssetType = () => {
    setSelectedAssetTypeId(assetTypeId)
  }

  return (
    <div
      key={assetTypeId}
      className={`flex h-20 items-center py-5 pl-6 pr-2 ${getBgColorClass()}`}>
      <div className="-mr-4">
        <Checkbox
          isChecked={isChecked}
          isLabelHidden
          label={assetTypeName}
          setIsChecked={setIsChecked}
          variant={isSelected ? 'white' : undefined}
        />
      </div>
      {getAssetIcon(assetTypeName, getIconColor())}
      <span className={`flex-1 text-lg font-semibold ${getTextColorClass()}`}>
        {assetTypeName}
      </span>

      {isChecked && (
        <Button
          onClick={handleSelectAssetType}
          iconOnly={<ArrowRightTwoToneIcon />}
          color={isSelected ? 'white' : 'blue-lighthouse'}
        />
      )}
    </div>
  )
}

export default AssetTypeOption
