import { baseApi } from '.'
import { IConfigDashboardGroup } from 'services/interfaces'

export const configDashboardApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    configDashboard: builder.query<IConfigDashboardGroup[], void>({
      query: () => `/v1/configurationDashboard`,
      providesTags: ['Dashboard'],
    }),
    updateCompleted: builder.mutation<
      void,
      { configurationId: number; isCompleted: boolean }
    >({
      query: (body) => ({
        url: `/v1/configurationDashboard/info`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Dashboard'],
    }),
    resetCompleted: builder.mutation<void, void>({
      query: () => ({
        url: `/v1/configurationDashboard`,
        method: 'POST',
      }),
      invalidatesTags: ['Dashboard'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useConfigDashboardQuery,
  useUpdateCompletedMutation,
  useResetCompletedMutation,
} = configDashboardApi
