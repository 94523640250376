import { ILikelihoodAttributes } from 'services/interfaces'
import { tabs, Tabs } from '..'
import { Applicability, CoreAttributes, OtherAttributes } from '.'

interface IContentProps {
  assetTypeId: number
  attributes: ILikelihoodAttributes
  isSaving: boolean
  selectedTab: Tabs
}

const Content = ({
  assetTypeId,
  attributes,
  isSaving,
  selectedTab,
}: IContentProps) => {
  const renderContent = () => {
    switch (selectedTab) {
      case tabs.CoreAttributes:
        return <CoreAttributes attributes={attributes} isSaving={isSaving} />
      case tabs.OtherAttributes:
        return <OtherAttributes attributes={attributes} isSaving={isSaving} />
      case tabs.Applicability:
        return <Applicability assetTypeId={assetTypeId} isSaving={isSaving} />
      default:
        return null
    }
  }

  return renderContent()
}

export default Content
