import { ReactNode, useEffect, useRef, useState } from 'react'

interface IRulesOverflowContainer {
  children: ReactNode
}

const RulesOverflowContainer = ({ children }: IRulesOverflowContainer) => {
  const [isOverflowing, setIsOverflowing] = useState(false)
  const overflowContainer = useRef<HTMLDivElement>(null)
  const overflowContent = useRef<HTMLDivElement>(null)
  const floatingScroller = useRef<HTMLDivElement>(null)
  const floatingScrollerContent = useRef<HTMLDivElement>(null)

  // responsible for the resizing and positioning of the floating scroller
  useEffect(() => {
    const overflowContainerEl = overflowContainer.current as HTMLElement
    const overflowContentEl = overflowContent.current as HTMLElement
    const floatingScrollerEl = floatingScroller.current as HTMLElement
    const floatingScrollerContentEl =
      floatingScrollerContent.current as HTMLElement

    const resizeObserver = new ResizeObserver((entries) => {
      // make floating scroller inner content the same as the content
      // within the overflow container to prepare for scroll syncing
      floatingScrollerContentEl.style.width = `${overflowContentEl.scrollWidth.toString()}px`

      // set `isOverflowing` state value
      if (overflowContainerEl.offsetWidth < overflowContentEl.offsetWidth) {
        setIsOverflowing(true)
      } else {
        setIsOverflowing(false)
      }

      showHideFloatingScroller()

      for (const entry of entries) {
        // if overflow container resize is detected, resize and position
        // the floating scroller so it is where it should be
        if (entry.target === overflowContainerEl) {
          const { width, left } = overflowContainerEl.getBoundingClientRect()

          // compensate for the 2px border
          const overflowContainerWidth = width - 4
          const overflowContainerLeft = left + 2

          floatingScrollerEl.style.width = `${overflowContainerWidth.toString()}px`
          floatingScrollerEl.style.left = `${overflowContainerLeft.toString()}px`
        }
      }
    })
    resizeObserver.observe(overflowContainerEl)
    resizeObserver.observe(overflowContentEl)

    return () => resizeObserver.disconnect()
  }, [])

  // responsible for 2-way scroll syncing of floating scroller and
  // the rules overflow container
  useEffect(() => {
    const overflowContainerEl = overflowContainer.current as HTMLElement
    const floatingScrollerEl = floatingScroller.current as HTMLElement

    let timeout: ReturnType<typeof setTimeout>

    const handleScrollSync = (e: Event) => {
      clearTimeout(timeout)

      const source = e.target as HTMLElement
      const target =
        e.target === overflowContainerEl
          ? floatingScrollerEl
          : overflowContainerEl

      target.removeEventListener('scroll', handleScrollSync)
      target.scrollLeft = source.scrollLeft

      timeout = setTimeout(() => {
        target.addEventListener('scroll', handleScrollSync)
      }, 100)
    }

    overflowContainerEl.addEventListener('scroll', handleScrollSync)
    floatingScrollerEl.addEventListener('scroll', handleScrollSync)

    return () => {
      overflowContainerEl.removeEventListener('scroll', handleScrollSync)
      floatingScrollerEl.removeEventListener('scroll', handleScrollSync)
    }
  }, [])

  // responsible for hiding the floating scroller once the overflow
  // container's top is no longer in view, or floating scroller has
  // scrolled past the overflow container's bottom
  useEffect(() => {
    ;(
      document.querySelector('#main-content-area') as HTMLElement
    ).addEventListener('scroll', showHideFloatingScroller)

    return () => {
      ;(
        document.querySelector('#main-content-area') as HTMLElement
      ).removeEventListener('scroll', showHideFloatingScroller)
    }
  }, [])

  // responsible for showing/hiding gradient that indicates presence of
  // additional rules in the overflow container
  useEffect(() => {
    const overflowContainerEl = overflowContainer.current as HTMLElement
    const leftShadow = 'shadow-[inset_20px_0px_15px_-10px_rgba(0,0,0,.15)]'
    const rightShadow = 'shadow-[inset_-20px_0px_15px_-10px_rgba(0,0,0,.15)]'
    const bothShadow = `shadow-[inset_20px_0px_15px_-10px_rgba(0,0,0,.15),inset_-20px_0px_15px_-10px_rgba(0,0,0,.15)]`

    if (!isOverflowing) {
      overflowContainerEl.classList.remove(leftShadow, rightShadow, bothShadow)
      return
    }

    // add initial right shadow if container is overflowing
    overflowContainerEl.classList.add(rightShadow)

    const handleScrollGradient = () => {
      const isStartPosition = overflowContainerEl.scrollLeft === 0
      const isEndPosition =
        Math.ceil(overflowContainerEl.scrollLeft) +
          overflowContainerEl.clientWidth >=
        overflowContainerEl.scrollWidth

      const replaceShadow = (newShadow: string) => {
        overflowContainerEl.classList.replace(leftShadow, newShadow)
        overflowContainerEl.classList.replace(bothShadow, newShadow)
        overflowContainerEl.classList.replace(rightShadow, newShadow)
      }

      if (isStartPosition) {
        replaceShadow(rightShadow)
      } else if (isEndPosition) {
        replaceShadow(leftShadow)
      } else {
        replaceShadow(bothShadow)
      }
    }

    overflowContainerEl.addEventListener('scroll', handleScrollGradient)

    return () =>
      overflowContainerEl.removeEventListener('scroll', handleScrollGradient)
  }, [isOverflowing])

  /**
   * Shows/hides floating scroller based on whether bottom of overflow
   * container is visible in the viewport or not
   */
  const showHideFloatingScroller = () => {
    const overflowContainerEl = overflowContainer.current as HTMLElement
    const floatingScrollerEl = floatingScroller.current as HTMLElement

    const maxVisibleY =
      document.querySelector('#main-content-area')?.getBoundingClientRect()
        .bottom || 0

    const { top: overflowContainerTop, bottom: overflowContainerBottom } =
      overflowContainerEl.getBoundingClientRect()

    const isOverflowContainerVisible =
      overflowContainerBottom !== 0 && overflowContainerTop !== 0

    // add/remove `invisible` className only for the floating scroller that
    // is currently visible on screen, since the visibility of floating
    // scroller in each tab should be independently determined
    if (isOverflowContainerVisible) {
      const mustHide =
        overflowContainerBottom <= maxVisibleY ||
        overflowContainerTop > maxVisibleY

      if (mustHide) {
        floatingScrollerEl.classList.add('invisible')
      } else {
        floatingScrollerEl.classList.remove('invisible')
      }
    }
  }

  return (
    <>
      <div
        className="relative flex-1 overflow-auto rounded border-2 border-solid border-gray-200"
        ref={overflowContainer}>
        <div className="flex w-fit pl-4 pb-2" ref={overflowContent}>
          {children}
        </div>
      </div>

      <div
        className="fixed bottom-0 h-[18px] basis-full overflow-auto"
        ref={floatingScroller}>
        <div className="h-1" ref={floatingScrollerContent}></div>
      </div>
    </>
  )
}

export default RulesOverflowContainer
