import { ReactNode } from 'react'
import { NavBar, Snackbar } from 'common/components'

interface ILayoutProps {
  /**
   * JSX to display on the main panel of the page.
   */
  children: ReactNode
  /**
   * JSX to display on the side panel of the page.
   */
  sidePanel?: ReactNode
}

/**
 * The Layout super-component is used to structure the basic layout that is used on
 * most of the pages in our app. It abstracts away the components that are required
 * on all pages such as the `NavBar` and `Snackbar`, and only requires the JSX needed
 * to populate the page.
 */
const Layout = ({ children, sidePanel }: ILayoutProps) => {
  return (
    <>
      <NavBar />
      {!sidePanel && (
        <div
          className="h-[calc(100vh-56px)] overflow-auto"
          id="main-content-area">
          <div className="m-auto w-full max-w-[1676px] px-8 pt-8 pb-6 2xl:px-10 2xl:pt-16 2xl:pb-12">
            {children}
          </div>
        </div>
      )}
      {sidePanel && (
        <div className="flex flex-row">
          <div className="h-[calc(100vh-56px)] flex-1 overflow-auto px-8 pt-8 pb-6 2xl:px-10 2xl:pt-16 2xl:pb-12">
            <div className="mx-auto max-w-[1150px]">{children}</div>
          </div>
          <div className="h-[calc(100vh-56px)] w-1/3 min-w-[450px] max-w-[546px] bg-coolGray-100">
            <div className="h-full overflow-auto">
              <div className="px-7 pt-9 pb-12 2xl:px-10">{sidePanel}</div>
            </div>
          </div>
        </div>
      )}
      <Snackbar />
    </>
  )
}

export default Layout
