import { Tabs as MuiTabs, Tab as MuiTab, SxProps } from '@mui/material'
import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

interface ILinkedTab {
  label: string
  to: string
}

interface ILinkedTabsProps {
  /**
   * An array of objects containing `label` and `to` properties for the tab
   * label and the destination route, respectively.
   */
  tabs: ILinkedTab[]
  /**
   * Index of tab that should be selected initially.
   */
  initialTab?: number
}

const muiTabSx: SxProps = {
  backgroundColor: '#F2F7FF',
  transition: 'background-color .2s',
  textTransform: 'none',
  '&.Mui-selected': {
    backgroundColor: '#1C355E',
    color: 'white',
  },
  '&.Mui-focusVisible': {
    outline: `5px auto ${
      navigator.userAgent.match(/firefox|fxios/i)
        ? 'Highlight'
        : '-webkit-focus-ring-color'
    }`,
  },
  '&:not(.Mui-selected):hover': {
    backgroundColor: '#e5efff',
  },
  '&:not(.Mui-selected):active': {
    backgroundColor: '#d6e6ff',
  },
}

const containerSx: SxProps = {
  '.MuiTabs-indicator': {
    display: 'none',
  },
}

/**
 * Tabs that display the linked route in an <a href="https://reactrouter
 * .com/en/main/components/outlet" target="_blank">Outlet</a>.
 */
const LinkedTabs = ({ tabs, initialTab = 0 }: ILinkedTabsProps) => {
  const [value, setValue] = useState(initialTab)
  const location = useLocation()

  useEffect(() => {
    if (tabs.length > 0) {
      const tabIndex = tabs.findIndex((tab) =>
        location.pathname.includes(tab.to)
      )
      setValue(tabIndex > -1 ? tabIndex : 0)
    }
  }, [location.pathname])

  return (
    <MuiTabs
      value={value}
      onChange={(e, index) => setValue(index)}
      sx={containerSx}>
      {tabs.map((tab) => (
        <MuiTab
          disableRipple
          key={tab.label}
          label={tab.label}
          to={tab.to}
          component={Link}
          className="m-[2px] min-h-[40px] min-w-[150px] p-2 px-8 tracking-normal"
          sx={muiTabSx}
        />
      ))}
    </MuiTabs>
  )
}

export default LinkedTabs
