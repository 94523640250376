import { useState } from 'react'
import { Input } from 'common/components'

const DisplayNameInput = ({ displayName }: { displayName: string }) => {
  const [newDisplayName, setNewDisplayName] = useState(displayName)

  return (
    <Input
      label="Display Name"
      isLabelHidden={true}
      value={newDisplayName}
      onChange={(e) => setNewDisplayName(e.target.value)}
      onKeyDown={(e) => e.stopPropagation()}
    />
  )
}

export default DisplayNameInput
