import { Outlet } from 'react-router-dom'
// import { tab, useLocation} from 'react-router-dom'

import { Layout, Breadcrumbs, setSnackbarIsOpen } from 'common/components'
import { useEffect } from 'react'
import { useAppDispatch } from 'app/hooks'

const MitigationScenario = () => {
  // const location = useLocation()

  // const tabs = [
  //   {
  //     label: 'Scenarios',
  //     to: '/mitigation-scenario-config/scenarios',
  //   },
  //   // {
  //   //   label: 'Cost Configuration',
  //   //   to: '/mitigation-scenario-config/cost-config',
  //   // },
  //   // {
  //   //   label: 'Cost Values',
  //   //   to: '/mitigation-scenario-config/cost-values',
  //   // },
  // ]

  // const activeTabIndex = () => {
  //   const tabIndex = tabs.findIndex((tab) => location.pathname.includes(tab.to))

  //   if (tabIndex === -1) return 0
  //   return tabIndex
  // }

  const dispatch = useAppDispatch()

  useEffect(() => {
    return () => {
      dispatch(setSnackbarIsOpen(false))
    }
  }, [])

  return (
    <Layout>
      <>
        <Breadcrumbs
          items={[
            { title: 'Configuration', to: '/configuration' },
            { title: 'Integrity Management', to: '' },
          ]}
        />
        <div className="title-bar">
          <h1>Mitigation Scenario Configuration</h1>

          {/* <Tabs tabs={tabs} initialTab={activeTabIndex()} /> */}
        </div>

        <div>
          <Outlet />
        </div>
      </>
    </Layout>
  )
}

export default MitigationScenario
