import Content from './Content'
import Footer from './Footer'
import ImportDataModal from './ImportDataModal'
import Sidebar from './Sidebar'

export { Content, Footer, Sidebar }
export default ImportDataModal

// Constants
export const TAB_ADD_NOTES = 'add notes'
export const TAB_IMPORT_OPTIONS = 'import options'
export const IMPORT_TYPES = {
  NO_IMPORT_SELECTED: -1,
  NEW: 1,
  UPDATE: 2,
} as const
export type ImportTypes = typeof IMPORT_TYPES[keyof typeof IMPORT_TYPES]
