import IconButton from '@mui/material/IconButton'
import { SxProps } from '@mui/material'
import { IButtonProps } from 'common/interfaces'

const sxStyles: SxProps = {
  svg: { fontSize: 'inherit' },
  '&:hover': { bgcolor: 'transparent' },
}

const IconOnlyButton = ({
  'aria-label': ariaLabel,
  className = '',
  color = 'coolGray-500',
  iconOnly,
  isDisabled,
  isSubmit,
  onClick,
  size = 'medium',
}: Partial<IButtonProps>) => {
  const classList = `text-${color} ${className}`

  return (
    <IconButton
      onClick={onClick}
      size={size}
      disabled={isDisabled}
      aria-label={ariaLabel}
      className={`${classList} disabled:bg-gray-200 disabled:text-coolGray-400`}
      sx={sxStyles}
      type={isSubmit ? 'submit' : 'button'}>
      {iconOnly}
    </IconButton>
  )
}

export default IconOnlyButton
