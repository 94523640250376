/**
 * Convert minutes to a more human readable format
 *
 * @param minutes number of minutes
 * @returns human readable duration, e.g., `20 Hours, 44 Minutes`
 */
function formatDuration(minutes: number) {
  if (minutes === 0) return '0 Minutes'
  const hrs = Math.floor(minutes / 60)
  const mins = Math.floor(minutes % 60)
  let result = ''
  if (hrs) {
    result += `${hrs} Hour${hrs > 1 ? 's' : ''}`
  }
  if (mins) {
    result += `${hrs && mins ? ', ' : ''}${mins} Minute${mins > 1 ? 's' : ''}`
  }
  return result
}

export default formatDuration
