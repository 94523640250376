import { ISubThreatModel } from '../../interfaces'
import { Tabs, tabs } from '.'

const classes = {
  activeTab: 'bg-lightBlue-100',
  inactiveTab: 'text-coolGray-400 bg-white',
  tab: 'block w-full cursor-pointer rounded border-none text-left !font-semibold py-3 px-5 capitalize font-inherit',
}
interface ISidebarProps {
  currentTab: string
  onTabChange: (tabName: Tabs) => void
  subThreatModel: ISubThreatModel
}

const Sidebar = ({
  currentTab,
  onTabChange,
  subThreatModel,
}: ISidebarProps) => {
  const getTabClassName = (tabName: string) => {
    const { activeTab, inactiveTab, tab } = classes
    if (tabName === currentTab) {
      return `${tab} ${activeTab}`
    } else {
      return `${tab} ${inactiveTab}`
    }
  }

  return (
    <>
      <div>
        <p className="my-0 font-bold">Subthreat Model:</p>
        <p className="mt-2">{subThreatModel.name}</p>
      </div>
      <div className="mt-16">
        <p className="mt-0 mb-5 font-bold ">Section</p>
        {Object.values(tabs).map((tabName) => (
          <button
            className={getTabClassName(tabName)}
            key={tabName}
            onClick={() => onTabChange(tabName)}
            style={{ font: 'inherit' }}
            type="button">
            {tabName}
          </button>
        ))}
      </div>
    </>
  )
}

export default Sidebar
