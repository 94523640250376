import { Button } from 'common/components'
import { formatTimestamp } from 'common/utils'
import { ICostFileMeta } from 'services/interfaces'

const CostCard = ({ fileName, uploadedOn, uploadedBy }: ICostFileMeta) => {
  return (
    <div className="mb-[30px] flex justify-between [overflow-wrap:anywhere]">
      <div className="flex-3 pr-4">
        <h4 className="m-0 text-[14px] font-semibold text-coolGray-400">
          File Name:
        </h4>
        <p className="m-0 text-[14px]">{fileName}</p>
      </div>
      <div className="flex-2 pr-4">
        <h4 className="m-0 text-[14px] font-semibold text-coolGray-400">
          Updated:
        </h4>
        <p className="m-0 text-[14px]">{formatTimestamp(uploadedOn)}</p>
      </div>
      <div className="flex-2 pr-4">
        <h4 className="m-0 text-[14px] font-semibold text-coolGray-400">
          User:
        </h4>
        <p className="m-0 text-[14px]">{uploadedBy}</p>
      </div>
      <Button className="self-start" variant="secondary-outlined">
        View
      </Button>
    </div>
  )
}

export default CostCard
