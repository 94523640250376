import z from 'zod'

const CreateAssessmentFormSchema = z
  .object({
    name: z
      .string()
      .trim()
      .regex(/^[a-z0-9_ ]*$/gi, {
        message:
          'Name can only contain alphanumeric characters, spaces, and underscores.',
      })
      .min(1, { message: 'Name is required' }),
    modelVersionId: z.number(),
    startYear: z.number().nonnegative(),
    endYear: z.number().nonnegative(),
    yearInterval: z.number().nonnegative(),
    firstConsecutiveYears: z.number().nonnegative(),
    notes: z.union([
      z.string().length(0),
      z
        .string()
        .max(500, { message: 'Notes can contain only up to 500 characters' })
        .trim()
        .optional(),
    ]),
    mitigationScenarioIds: z.array(z.number()),
    modelCodes: z.array(z.string()),
  })
  .superRefine((schema, ctx) => {
    if (schema.startYear > schema.endYear) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['startYear'],
      })
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['endYear'],
      })
    }
    if (schema.yearInterval - 1 > schema.endYear - schema.startYear) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['yearInterval'],
      })
    }
    if (schema.firstConsecutiveYears - 1 > schema.endYear - schema.startYear) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ['firstConsecutiveYears'],
      })
    }
  })

type CreateAssessmentFormSchemaType = z.infer<typeof CreateAssessmentFormSchema>

export type { CreateAssessmentFormSchemaType }
export default CreateAssessmentFormSchema
