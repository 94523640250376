import { useEffect, useState } from 'react'
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { zodResolver } from '@hookform/resolvers/zod'
import { DevTool } from '@hookform/devtools'
import { AssetTypeRegularTabs } from 'common/components'
import { isFetchBaseQueryError } from 'common/utils'
import { IError } from 'services/interfaces'
import {
  useAllAssetFieldsQuery,
  useAssetTypesQuery,
  useCreateScenarioMutation,
  useEditScenarioMutation,
  usePublishScenarioMutation,
  useScenarioDetailsQuery,
} from 'services/apis'
import { removeEmptyConditionSelectors } from '../utils'
import {
  AssetMitigationRules,
  CreateEditScenarioFormSchema,
  CreateEditScenarioFormSchemaType,
  DUPLICATED_NAME,
  NameAndTypeInputs,
  TitleBar,
} from '.'

interface ICreateEditScenarioFormProps {
  defaultValues: CreateEditScenarioFormSchemaType
  isCreateScreen: boolean
  isEditScreen: boolean
  isViewScreen: boolean
}

const CreateEditScenarioForm = ({
  defaultValues,
  isCreateScreen,
  isEditScreen,
  isViewScreen,
}: ICreateEditScenarioFormProps) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [isPublishingScenario, setIsPublishingScenario] =
    useState<boolean>(false)

  const { data: allAssetFieldsData } = useAllAssetFieldsQuery()
  const { data: assetTypesData } = useAssetTypesQuery()
  const { data: scenarioDetailsData, error: scenarioDetailError } =
    useScenarioDetailsQuery(Number(searchParams.get('id')), {
      skip: isCreateScreen,
    })

  const [
    createScenario,
    { isLoading: isSavingCreateScenario, error: createScenarioError },
  ] = useCreateScenarioMutation()
  const [
    editScenario,
    { isLoading: isSavingEditScenario, error: editScenarioError },
  ] = useEditScenarioMutation()
  const [publishScenario, { isSuccess: isPublishScenarioSuccess }] =
    usePublishScenarioMutation()

  const form = useForm<CreateEditScenarioFormSchemaType>({
    mode: 'onSubmit',
    resolver: zodResolver(CreateEditScenarioFormSchema),
    defaultValues,
  })
  const { control, handleSubmit, register, reset, setError } = form
  const rulesFieldArray = useFieldArray({
    control,
    name: 'rules',
  })

  useEffect(() => {
    if (isCreateScreen) {
      reset(defaultValues)
      register('rules')
    }
  }, [defaultValues, reset])

  useEffect(() => {
    if (scenarioDetailsData) {
      const savedRules = scenarioDetailsData.rules.map(
        ({ assetTypeId, mitigationRuleId, conditions, outcomes }) => ({
          assetTypeId,
          mitigationRuleId,
          conditions: conditions.map(
            ({ assetFieldId, ruleId, fromInterval, toInterval, value }) => ({
              assetFieldId,
              ruleId,
              fromInterval,
              toInterval,
              value,
            })
          ),
          outcomes: outcomes.map(({ assetFieldId, ruleId, value }) => ({
            assetFieldId,
            ruleId,
            value,
          })),
        })
      )
      reset({
        id: scenarioDetailsData.id,
        name: scenarioDetailsData.name,
        type: scenarioDetailsData.type,
        rules: savedRules,
      })
      register('rules')
    }
  }, [scenarioDetailsData, reset])

  useEffect(() => {
    if (scenarioDetailError) {
      navigate('/mitigation-scenario-config/scenarios')
    }
  }, [scenarioDetailError])

  useEffect(() => {
    if (isPublishScenarioSuccess) {
      navigate('/mitigation-scenario-config/scenarios')
      setIsPublishingScenario(false)
    }
  }, [isPublishScenarioSuccess])

  useEffect(() => {
    const error = createScenarioError || editScenarioError

    if (error && isFetchBaseQueryError(error)) {
      const { title, detail: message, type } = error.data as IError
      const isDuplicateScenarioName = title === DUPLICATED_NAME

      if (isDuplicateScenarioName) {
        setError(
          'name',
          {
            type,
            message,
          },
          {
            shouldFocus: true,
          }
        )
      }
    }
    setIsPublishingScenario(false)
  }, [createScenarioError, editScenarioError])

  const onSubmit: SubmitHandler<CreateEditScenarioFormSchemaType> = async (
    data
  ) => {
    setIsPublishingScenario(true)
    const cleanedData = removeEmptyConditionSelectors(data)

    if (isCreateScreen) {
      const { mitigationScenarioId } = await createScenario(
        cleanedData
      ).unwrap()
      cleanedData.id = mitigationScenarioId
    }
    if (isEditScreen) {
      await editScenario(cleanedData).unwrap()
    }
    publishScenario(cleanedData.id as number)
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {isEditScreen && <input type="hidden" {...register('id')} />}
        <TitleBar
          assetTypes={assetTypesData ?? []}
          isCreateScreen={isCreateScreen}
          isEditScreen={isEditScreen}
          isViewScreen={isViewScreen}
          isPublishingScenario={isPublishingScenario}
          isSavingCreateScenario={isSavingCreateScenario}
          isSavingEditScenario={isSavingEditScenario}
        />
        <NameAndTypeInputs
          allAssetFields={allAssetFieldsData ?? []}
          assetTypes={assetTypesData ?? []}
          isViewScreen={isViewScreen}
          rulesFieldArray={rulesFieldArray}
        />

        <div className="mb-4 flex flex-col">
          <h2 className="mt-12 mb-4">MITIGATION RULES</h2>
          <AssetTypeRegularTabs
            tabs={
              assetTypesData?.map((assetType) => ({
                id: assetType.id,
                label: assetType.label,
                children: (
                  <AssetMitigationRules
                    assetTypeId={assetType.id}
                    isViewScreen={isViewScreen}
                    rulesFieldArray={rulesFieldArray}
                  />
                ),
              })) ?? []
            }
          />
        </div>
      </form>
      <DevTool control={control} />
    </FormProvider>
  )
}

export default CreateEditScenarioForm
