import { useState } from 'react'
import { Searchbox } from 'common/components'
import { useAttributesQuery, useLocationLevelsQuery } from 'services/apis'
import {
  IAttribute,
  ILocationLevel,
  IMitigationCostConfig,
} from 'services/interfaces'
import AttributesCheckbox from './AttributesCheckbox'

const classes = {
  otherAttributesSection:
    'mt-8 grid max-h-[405px] grid-cols-2 overflow-auto whitespace-normal rounded border-2 border-solid border-gray-200 p-6 py-3',
}

interface IOtherAttributesProps {
  assetTypeId: number
  data: IMitigationCostConfig
  isSavingCostConfig: boolean
}

const OtherAttributes = ({
  assetTypeId,
  data,
  isSavingCostConfig,
}: IOtherAttributesProps) => {
  const { data: locationLevelsData } = useLocationLevelsQuery()
  const { data: attributesData } = useAttributesQuery({
    assetTypeId,
    lookupTableId: data.id,
  })
  const [attributeSearchText, setAttributeSearchText] = useState('')

  const otherAttributes = attributesData
    ?.filter(
      (attribute: IAttribute) =>
        !locationLevelsData
          ?.map((locationLevel: ILocationLevel) => locationLevel.name)
          .includes(attribute.name)
    )
    ?.filter((attribute: IAttribute) => !attribute.isCore)

  const visibleAttributes = otherAttributes?.filter((attribute: IAttribute) => {
    if (attributeSearchText.length === 0) return true

    return attribute.name
      .toLowerCase()
      .includes(attributeSearchText.toLowerCase().trim())
  })

  return (
    <>
      <div className="flex items-center justify-between">
        <p className="my-0 mr-4 font-bold uppercase text-blue-navy">
          Other Attributes
        </p>
        <Searchbox
          className="w-1/2"
          isDisabled={isSavingCostConfig}
          label="Search attributes"
          placeholder="Search Attributes"
          isLabelHidden
          value={attributeSearchText}
          onChange={setAttributeSearchText}
        />
      </div>
      <div className={classes.otherAttributesSection}>
        {visibleAttributes?.map((attribute: IAttribute) => (
          <AttributesCheckbox
            key={attribute.id}
            id={attribute.id}
            isSavingCostConfig={isSavingCostConfig}
            name={attribute.name}
          />
        ))}
      </div>
    </>
  )
}

export default OtherAttributes
