import { Layout, Button, Breadcrumbs } from 'common/components'
import { useState } from 'react'
import { SidePanel, LogTable, PublishConfigModal } from '.'

const breadCrumbs = [
  { title: 'Configuration', to: '/configuration' },
  { title: 'Risk Configuration', to: '' },
]

const PublishRisk = () => {
  const [selectedRowId, setSelectedRowId] = useState<number>(-1)
  const [showModal, setShowModal] = useState<boolean>(false)

  return (
    <Layout sidePanel={<SidePanel configurationId={selectedRowId} />}>
      <Breadcrumbs items={breadCrumbs} />
      <div className="title-bar">
        <h1>Publish Risk Configurations</h1>
        <Button variant="primary-outlined" onClick={() => setShowModal(true)}>
          Publish
        </Button>
      </div>
      <div className="subtitle-bar">
        <h2>Publish Log</h2>
      </div>
      <LogTable onRowSelect={setSelectedRowId} selectedRowId={selectedRowId} />
      <PublishConfigModal
        selectedRowId={selectedRowId}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      />
    </Layout>
  )
}

export default PublishRisk
