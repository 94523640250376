import { Table, Card, Button } from 'common/components'
import {
  formatDuration,
  formatTimestamp,
  handleFileDownload,
} from 'common/utils'
import { useGetDataImportLogQuery } from 'services/apis'
import { env } from 'app/runtime-config'

interface ISidePanelProps {
  selectedRowId: number
}

const DataLoadSidePanel = ({ selectedRowId }: ISidePanelProps) => {
  const { data: log } = useGetDataImportLogQuery(selectedRowId, {
    refetchOnMountOrArgChange: true,
  })

  const getValue = (key: string) => {
    const dataImportLog = log as any

    if (dataImportLog) {
      switch (key) {
        case 'updatedAttributes':
          if (dataImportLog[key] && dataImportLog[key].length > 0) {
            return dataImportLog[key]
          }
          return '-'

        case 'timestamp':
          if (dataImportLog[key]) {
            return formatTimestamp(dataImportLog[key])
          }
          return '-'

        case 'runtime':
          if (
            ['in progress', 'error'].includes(
              dataImportLog.status.toLowerCase()
            )
          ) {
            return '-'
          }
          return formatDuration(dataImportLog[key])

        default:
          if (dataImportLog[key]) {
            return dataImportLog[key]
          }
          return '-'
      }
    }

    return '-'
  }

  const detailCardValues = [
    { title: 'ASSET TYPE', value: getValue('assetType'), importType: 'any' },
    { title: 'IMPORT TYPE', value: getValue('importType'), importType: 'any' },
    {
      title: 'IMPORT OPTION',
      value: getValue('importOption'),
      importType: 'new',
    },
    {
      title: 'UPDATED ATTRIBUTES',
      value: getValue('updatedAttributes'),
      importType: 'update',
    },
    {
      title: 'TIMESTAMP',
      value: getValue('timestamp'),
      importType: 'any',
    },
    { title: 'USER', value: getValue('user'), importType: 'any' },
    { title: 'STATUS', value: getValue('status'), importType: 'any' },
    { title: 'RUNTIME', value: getValue('runtime'), importType: 'any' },
  ]

  const detailsCardNotes = getValue('notes')

  const importedFilesConfig = [
    {
      field: 'fileName',
      headerName: 'File Name',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'assetCount',
      headerName: 'Asset Count',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
  ]

  const getDetailCardValues = () => {
    let importType = 'new'

    if (log?.importType?.toLowerCase() === 'update') {
      importType = 'update'
    }

    return detailCardValues.filter((item) =>
      ['any', importType].includes(item.importType)
    )
  }

  const downloadErrorData = () => {
    const fileName = `Data Import - ${log?.assetType} - Errors`
    const url = `${env.REACT_APP_API_BASE_URL}/v1/files/dataImport/${selectedRowId}/errors`
    handleFileDownload(url, fileName)
  }

  const showErrorButton = (): boolean => {
    const status = log?.status?.toLowerCase().replaceAll(' ', '')
    return status === 'error' || status === 'partiallycompleted'
  }

  return (
    <>
      <div className="sidepanel-title-bar">
        <h2>Import Summary</h2>
        {showErrorButton() && (
          <Button
            className="mr-2 uppercase"
            variant="secondary-outlined"
            onClick={downloadErrorData}>
            export errors
          </Button>
        )}
      </div>
      <div className="sidepanel-subtitle-bar">
        <h3>Import Details</h3>
      </div>
      <Card entries={getDetailCardValues()} notes={detailsCardNotes} />
      <div className="sidepanel-subtitle-bar">
        <h3>Imported Files</h3>
      </div>
      <Table
        variant="data-table"
        rows={log?.importFiles || []}
        columns={importedFilesConfig}
        pageSize={5}
        checkboxSelection={false}
        hasPagination={true}
        isPageCountHidden
        rowBorderBottomColor="#E4E5E6"
      />
    </>
  )
}

export default DataLoadSidePanel
