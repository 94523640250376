import { useState } from 'react'
import { Checkbox } from 'common/components'

const AttributeCheckbox = ({
  isMandatory,
  isEnabled,
}: {
  isMandatory: boolean
  isEnabled: boolean
}) => {
  const [isChecked, setIsChecked] = useState(isEnabled)

  return (
    <div className="pl-4">
      <Checkbox
        isChecked={isMandatory ? true : isChecked}
        isDisabled={isMandatory}
        isLabelHidden={true}
        label="Asset id"
        setIsChecked={setIsChecked}
      />
    </div>
  )
}

export default AttributeCheckbox
