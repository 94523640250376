import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import ArrowRightTwoToneIcon from '@mui/icons-material/ArrowRightTwoTone'
import { Table, Button, DEFAULT_PAGE_SIZE } from 'common/components'
import { formatTimestamp } from 'common/utils'
import { GridRenderCellParams, GridSortModel } from '@mui/x-data-grid'
import { useGetDataImportLogsQuery } from 'services/apis'

const defaultSortModel: GridSortModel = [
  {
    field: 'timestamp',
    sort: 'desc',
  },
]

interface IDataLoadLogTableProps {
  selectedRowId: number
  onRowSelect: (id: number) => void
  currentPage: number
  setCurrentPage: Dispatch<SetStateAction<number>>
}

const DataLoadLogTable = ({
  selectedRowId,
  onRowSelect,
  currentPage,
  setCurrentPage,
}: IDataLoadLogTableProps) => {
  const [sortModel, setSortModel] = useState<GridSortModel>(defaultSortModel)

  const { data: dataImportLogs } = useGetDataImportLogsQuery(
    {
      pageNumber: currentPage,
      pageSize: DEFAULT_PAGE_SIZE,
      timeStampSorting: sortModel[0].sort ?? 'desc',
    },
    { refetchOnMountOrArgChange: true }
  )

  useEffect(() => {
    if (dataImportLogs && dataImportLogs.data.length) {
      // select first record on every refetch
      onRowSelect(dataImportLogs.data[0].id)
    }
  }, [dataImportLogs])

  const columnConfig = [
    {
      field: 'assetType',
      headerName: 'Asset Type',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'importType',
      headerName: 'Import Type',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'timestamp',
      headerName: 'Timestamp',
      flex: 1.2,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) =>
        formatTimestamp(params.row.timestamp),
    },
    {
      field: 'user',
      headerName: 'User',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'configure',
      headerName: '',
      width: 60,
      disableColumnMenu: true,
      disableOverflowTooltip: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Button
            onClick={() => {
              onRowSelect(params.id as number)
            }}
            iconOnly={<ArrowRightTwoToneIcon />}
            color={'blue-lighthouse'}
          />
        )
      },
    },
  ]

  return (
    <Table
      variant="data-table"
      rows={dataImportLogs?.data}
      columns={columnConfig}
      checkboxSelection={false}
      rowIdToHighlight={selectedRowId}
      sortModel={sortModel}
      paginationObject={{ count: dataImportLogs?.count || 0, currentPage }}
      pageSize={DEFAULT_PAGE_SIZE}
      loadByPage={true}
      dataReload={(page, sort) => {
        setCurrentPage(page + 1)
        setSortModel(sort)
      }}
      setSortModel={setSortModel}
    />
  )
}

export default DataLoadLogTable
