import { useState } from 'react'
import SettingsIcon from '@mui/icons-material/Settings'
import { Button } from 'common/components'
import ProbabilityConfigModal from './ProbabilityConfigModal'
import { ILocationLevel } from 'services/interfaces'

interface ILookupTablesToggle {
  rowParams: any
  mockDataLocationLevels: ILocationLevel[]
}

const LookupTablesToggle = ({
  rowParams,
  mockDataLocationLevels,
}: ILookupTablesToggle) => {
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false)

  return (
    <>
      <Button
        iconOnly={<SettingsIcon />}
        color="blue-lighthouse"
        size="large"
        onClick={() => setIsConfigModalOpen(true)}
      />
      <ProbabilityConfigModal
        isOpen={isConfigModalOpen}
        onClose={() => setIsConfigModalOpen(false)}
        data={rowParams}
        locationLevels={mockDataLocationLevels}
      />
    </>
  )
}

export default LookupTablesToggle
