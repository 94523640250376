import { useState } from 'react'
import ArrowRightTwoToneIcon from '@mui/icons-material/ArrowRightTwoTone'
import { GridRenderCellParams, GridSortModel } from '@mui/x-data-grid'
import { Table, Button, DEFAULT_PAGE_SIZE } from 'common/components'
import { setSelectedRowId, getRiskData } from '../RiskManagementDucks'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { useAssessmentsQuery } from 'services/apis'
import { formatTimestamp } from 'common/utils'

const AssessmentsLogTable = () => {
  const dispatch = useAppDispatch()
  const { currentPageData, totalAssessments, selectedRowId } =
    useAppSelector(getRiskData)
  const [currentPage, setCurrentPage] = useState(1)
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'timestamp',
      sort: 'desc',
    },
  ])

  const { isError, error } = useAssessmentsQuery(
    {
      PageSize: DEFAULT_PAGE_SIZE,
      PageNumber: currentPage,
      TimeStampSorting: sortModel[0].sort ?? 'desc',
    },
    { refetchOnMountOrArgChange: true }
  )

  const selectRow = (id: number): void => {
    const record = currentPageData.find((row) => row.id === id)
    dispatch(setSelectedRowId(record?.id || -1))
  }

  const columnConfig = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1.5,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'modelVersion',
      headerName: 'Publish Version',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'totalRisk',
      headerName: 'Total Risk (RU)',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams) =>
        Math.round(params.row.totalRisk) || '-',
    },
    {
      field: 'timestamp',
      headerName: 'Timestamp',
      flex: 1.2,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) =>
        formatTimestamp(params.row.timestamp),
    },
    {
      field: 'user',
      headerName: 'User',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'years',
      headerName: 'Years',
      flex: 0.8,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams) =>
        `${params.row.startYear} - ${params.row.endYear}`,
    },
    {
      field: 'statusDescription',
      headerName: 'Status',
      flex: 0.8,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams) =>
        params.row.statusDescription !== 'InProgress'
          ? params.row.statusDescription
          : 'In Progress',
    },
    {
      field: 'configure',
      headerName: '',
      width: 60,
      disableColumnMenu: true,
      disableOverflowTooltip: true,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Button
            onClick={() => selectRow(params.id as number)}
            iconOnly={<ArrowRightTwoToneIcon />}
            color={'blue-lighthouse'}
          />
        )
      },
    },
  ]

  return (
    <>
      {!isError ? (
        <Table
          variant="data-table"
          rows={currentPageData}
          columns={columnConfig}
          checkboxSelection={false}
          rowIdToHighlight={selectedRowId}
          loadByPage={true}
          paginationObject={{
            currentPage,
            count: totalAssessments,
          }}
          dataReload={(page, sort) => {
            setCurrentPage(page + 1)
            setSortModel(sort)
          }}
          sortModel={sortModel}
          setSortModel={setSortModel}
        />
      ) : (
        <>
          <p>Something went wrong. ({(error as any)?.status} Error)</p>
        </>
      )}
    </>
  )
}

export default AssessmentsLogTable
