import { Button } from 'common/components'

interface IFooterProps {
  handleClose: () => void
  isSaving: boolean
}

const Footer = ({ handleClose, isSaving }: IFooterProps) => {
  return (
    <>
      <Button
        className="mr-2"
        onClick={handleClose}
        variant="clear"
        isDisabled={isSaving}>
        Cancel
      </Button>
      <Button isBusy={isSaving} busyText="Saving and Applying..." isSubmit>
        Save & Apply
      </Button>
    </>
  )
}

export default Footer
