import { ReactNode, useEffect, useState } from 'react'
import MuiSnackbar from '@mui/material/Snackbar'
import { ArrowLeft, ArrowRight, Close } from '@mui/icons-material'
import { Button, Alert, TransitionUp } from 'common/components'
import { setSnackbarIsOpen, getSnackbar } from './SnackbarDucks'
import { useAppDispatch, useAppSelector } from 'app/hooks'

/**
 * Snackbars are used to display system messages. The messages are short and straightforward.
 * <hr />
 * An instance of the Snackbar component is already inserted at the very top level in our app. In
 * order to display the snackbar, we will need to provide information on the message we want to display
 * and make it appear on screen via the Redux `setSnackbar` and the `setSnackbarIsOpen` reducers, respectively.
 * The snackbar automatically closes after a 6 second delay or if clicked anywhere outside the snackbar.
 */
const Snackbar = () => {
  const dispatch = useAppDispatch()
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const { isOpen, type, messages, traceId } = useAppSelector(getSnackbar)

  useEffect(() => {
    setCurrentIndex(0)
  }, [messages])

  const action = (
    <Button
      aria-label="close"
      variant="clear"
      color="white"
      className="relative -top-1 -right-1"
      onClick={() => dispatch(setSnackbarIsOpen(false))}
      iconOnly={<Close />}
    />
  )

  const getErrorIndexMsg = (): string =>
    `Error ${currentIndex + 1} of ${messages.length}`

  const handleErrorNav = (direction: 'left' | 'right'): void => {
    if (direction === 'left' && currentIndex > 0) {
      setCurrentIndex((prevCurrentIndex) => prevCurrentIndex - 1)
    } else if (direction === 'right' && currentIndex < messages.length - 1) {
      setCurrentIndex((prevCurrentIndex) => prevCurrentIndex + 1)
    }
  }

  const renderNavButton = (direction: 'left' | 'right'): ReactNode => {
    let isDisabled: boolean = false
    let icon: ReactNode = null

    if (direction === 'left') {
      isDisabled = currentIndex === 0
      icon = <ArrowLeft />
    } else if (direction === 'right') {
      isDisabled = currentIndex === messages.length - 1
      icon = <ArrowRight />
    }

    return (
      <Button
        className="p-0 disabled:bg-transparent"
        color="white"
        iconOnly={icon}
        isDisabled={isDisabled}
        letterCase="lowercase"
        onClick={() => handleErrorNav(direction)}
        variant="clear"
      />
    )
  }

  const renderTraceId = (): ReactNode => {
    if (traceId) {
      return <div className="mt-4 text-sm italic">Trace Id: {traceId}</div>
    }

    return null
  }

  return (
    <MuiSnackbar
      open={isOpen}
      autoHideDuration={type !== 'error' ? 6000 : null}
      TransitionComponent={TransitionUp}
      onClose={() => dispatch(setSnackbarIsOpen(false))}>
      <Alert
        action={action}
        severity={type}
        sx={{ width: '100%', maxWidth: '600px' }}
        className="text-white">
        <div className="flex flex-col">
          {messages[currentIndex]}
          {renderTraceId()}
          {messages.length > 1 && (
            <div className="mt-4 flex items-center">
              <span className="mr-2 text-sm font-light">
                {getErrorIndexMsg()}
              </span>
              {renderNavButton('left')}
              {renderNavButton('right')}
            </div>
          )}
        </div>
      </Alert>
    </MuiSnackbar>
  )
}

export default Snackbar
