import { useNavigate } from 'react-router'
import WidgetsIcon from '@mui/icons-material/Widgets'
import ResetIcon from '@mui/icons-material/Cached'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { Layout, ConfigCard, Button } from 'common/components'
import {
  useConfigDashboardQuery,
  useUpdateCompletedMutation,
  useResetCompletedMutation,
} from 'services/apis'
import { IConfiguration } from 'services/interfaces'

const ConfigurationPage = () => {
  const navigate = useNavigate()
  const { data: configDashboardData } = useConfigDashboardQuery()
  const [updateCompleted] = useUpdateCompletedMutation()
  const [resetCompleted] = useResetCompletedMutation()

  const configurationRoutes = [
    {
      id: 1,
      route: '/asset-data-config',
    },
    {
      id: 2,
      route: '/data-load',
    },
    {
      id: 3,
      route: '/likelihood-model-config',
    },
    {
      id: 4,
      route: '/likelihood-model-config/parameters',
    },
    {
      id: 5,
      route: '/consequence-model-config',
    },
    {
      id: 6,
      route: '/consequence-model-config/consequence-costs',
    },
    {
      id: 7,
      route: '/scenario-probability-config',
    },
    {
      id: 8,
      route: '/scenario-probability-config/scenario-values',
    },
    {
      id: 9,
      route: '/publish-risk-config',
    },
    {
      id: 10,
      route: '/mitigation-scenario-config',
    },
    {
      id: 11,
      route: '/mitigation-scenario-config/cost-values',
    },
  ]

  const disabledRoutes = [
    {
      id: 1,
      route: '/asset-data-config',
    },
    {
      id: 5,
      route: '/consequence-model-config',
    },
    {
      id: 6,
      route: '/consequence-model-config/consequence-costs',
    },
    {
      id: 7,
      route: '/scenario-probability-config',
    },
    {
      id: 8,
      route: '/scenario-probability-config/scenario-values',
    },
    {
      id: 9,
      route: '/publish-risk-config',
    },
    {
      id: 11,
      route: '/mitigation-scenario-config/cost-values',
    },
  ]

  const handleCompleted = async (configuration: IConfiguration) => {
    await updateCompleted({
      configurationId: configuration.configurationId,
      isCompleted: !configuration.isCompleted,
    })
  }

  const handleReset = async () => {
    await resetCompleted()
  }

  const formatDateModified = (timestamp: string) =>
    new Intl.DateTimeFormat('en-US', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).format(new Date(timestamp))

  return (
    <Layout>
      <div className="mb-4 flex flex-col items-center sm:flex-row 2xl:mb-12">
        <h1 className="text-[24px] uppercase text-blue-lighthouse">
          <WidgetsIcon fontSize="large" className="mr-4 align-sub" />
          Configuration
        </h1>
        <Button
          className="sm:ml-auto"
          variant="clear"
          prefixIcon={<ResetIcon />}
          onClick={handleReset}>
          Reset Checkmarks
        </Button>
      </div>
      <div className="block justify-between lg:flex">
        <div>
          <h2 className="mb-8 text-center text-[20px] uppercase text-blue-navy">
            Data Management
          </h2>
          <div className="grid grid-cols-1 justify-items-center">
            {configDashboardData
              ?.filter((group) => group.configurationGroupId === 1)?.[0]
              ?.configurations.map((configuration) => (
                <ConfigCard
                  key={configuration.configurationId}
                  {...configuration}
                  dateModified={formatDateModified(configuration.dateModified)}
                  toggleCompleted={() => handleCompleted(configuration)}
                  className="mx-4 mb-8 w-80"
                  linkTitleTo={
                    configurationRoutes.find(
                      (route) => route.id === configuration.configurationId
                    )?.route ?? ''
                  }
                  isDisabled={
                    disabledRoutes.findIndex(
                      (route) => route.id === configuration.configurationId
                    ) !== -1
                  }
                />
              ))}
          </div>
        </div>

        <div>
          <h2 className="mb-8 text-center text-[20px] uppercase text-blue-navy">
            Risk Configuration
          </h2>
          <div className="grid grid-cols-1 justify-items-center xl:grid-cols-2">
            {configDashboardData
              ?.filter((group) => group.configurationGroupId === 2)?.[0]
              ?.configurations.map((configuration) => (
                <ConfigCard
                  key={configuration.configurationId}
                  {...configuration}
                  dateModified={formatDateModified(configuration.dateModified)}
                  toggleCompleted={() => handleCompleted(configuration)}
                  className="mx-4 mb-8 w-80"
                  linkTitleTo={
                    configurationRoutes.find(
                      (route) => route.id === configuration.configurationId
                    )?.route ?? ''
                  }
                  isDisabled={
                    disabledRoutes.findIndex(
                      (route) => route.id === configuration.configurationId
                    ) !== -1
                  }
                />
              ))}
          </div>
        </div>

        <div>
          <h2 className="mb-8 text-center text-[20px] uppercase text-blue-navy">
            Integrity Management
          </h2>
          <div className="grid justify-items-center 2xl:grid-cols-2">
            {configDashboardData
              ?.filter((group) => group.configurationGroupId === 3)?.[0]
              ?.configurations.map((configuration) => (
                <ConfigCard
                  key={configuration.configurationId}
                  {...configuration}
                  dateModified={formatDateModified(configuration.dateModified)}
                  toggleCompleted={() => handleCompleted(configuration)}
                  className="mx-4 mb-8 w-80"
                  linkTitleTo={
                    configurationRoutes.find(
                      (route) => route.id === configuration.configurationId
                    )?.route ?? ''
                  }
                  isDisabled={
                    disabledRoutes.findIndex(
                      (route) => route.id === configuration.configurationId
                    ) !== -1
                  }
                />
              ))}
          </div>
        </div>
      </div>
      <div className="text-center lg:text-right">
        <Button
          size="large"
          suffixIcon={<ArrowForwardIcon />}
          onClick={() => {
            navigate('/assessments')
          }}>
          Run Full Risk
        </Button>
      </div>
    </Layout>
  )
}

export default ConfigurationPage
