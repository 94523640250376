import CancelIcon from '@mui/icons-material/Cancel'
import { LinearProgress } from '@mui/material'
import UploadCompleteIcon from 'assets/UploadCompleteIcon.svg'
import UploadErrorIcon from 'assets/UploadErrorIcon.svg'
import UploadingIcon from 'assets/UploadingIcon.svg'
import { IFileUploaderProps } from 'common/interfaces'
import { ReactNode, useEffect, useState } from 'react'
import { uploadFile } from 'common/utils'
import { AxiosError } from 'axios'

const PENDING = 'pending'
const ERROR = 'error'
const SUCCESS = 'success'

const FileUploader = ({
  file,
  onUploadCancel,
  onUploadComplete,
  onUploadStart,
  options,
  uploadInfo,
  url,
}: IFileUploaderProps) => {
  const [status, setStatus] = useState(PENDING)
  const [progress, setProgress] = useState(0)
  const [fileId, setFileId] = useState(-1)
  const controller = new AbortController()

  const onProgressUpdate = (progressPercentage: number): void => {
    setProgress(progressPercentage)
  }

  useEffect(() => {
    if (!uploadInfo) {
      onUploadStart().then(() => {
        uploadFile(file, url, options, onProgressUpdate, controller)
          .then((response) => {
            setStatus(SUCCESS)
            onUploadComplete(file.name, response.dataImportFileId, SUCCESS)
            setFileId(response.dataImportFileId)
          })
          .catch((error: AxiosError) => {
            setStatus(ERROR)
            onUploadComplete(
              file.name,
              fileId,
              ERROR,
              error.code === 'ERR_CANCELED'
            )
          })

        return () => {
          controller.abort()
        }
      })
    } else {
      setStatus(uploadInfo.status)
    }
  }, [])

  const renderStatus = (): ReactNode => {
    if (status === ERROR) {
      return <div className=" capitalize text-red-700">upload error</div>
    } else if (status === SUCCESS) {
      return <div className=" capitalize text-green">uploaded</div>
    }

    return <div className=" capitalize text-blue-lighthouse">uploading...</div>
  }

  const renderIcon = (): ReactNode => {
    if (status === ERROR) {
      return (
        <img
          className="mr-2 h-14 w-14"
          src={UploadErrorIcon}
          alt="Upload Error Icon"
        />
      )
    } else if (status === SUCCESS) {
      return (
        <img
          className="mr-2 h-14 w-14"
          src={UploadCompleteIcon}
          alt="Upload Complete Icon"
        />
      )
    }

    return (
      <img
        className="mr-2 h-14 w-14"
        src={UploadingIcon}
        alt="Uploading Icon"
      />
    )
  }

  const renderProgressBar = (): ReactNode => {
    if (status === ERROR) {
      return <LinearProgress variant="determinate" value={100} color="error" />
    } else if (status === SUCCESS) {
      return (
        <LinearProgress variant="determinate" value={100} color="success" />
      )
    }

    return <LinearProgress variant="determinate" value={progress} />
  }

  const convertFileSize = (size: number): string => {
    if (size >= 1000000) {
      return `${Math.round(size / 1000000)}MB`
    } else if (size >= 1000 && size < 1000000) {
      return `${Math.round(size / 1000)}KB`
    }

    return `${size}B`
  }

  return (
    <div className="my-4 flex w-full flex-row items-center justify-between px-6">
      <div className="flex w-full flex-row">
        {renderIcon()}
        <div className="flex w-full flex-col pr-6">
          <div className="flex w-full flex-row items-center justify-between pb-1">
            <div>{file.name}</div>
            <CancelIcon
              className="cursor-pointer text-coolGray-400"
              onClick={() => {
                onUploadCancel(file.name, fileId)
              }}
            />
          </div>
          {renderProgressBar()}
          <div className="flex w-full flex-row items-center justify-between pt-1">
            {renderStatus()}
            <div>{convertFileSize(file.size)}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FileUploader
