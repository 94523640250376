import { groupBy, minBy } from 'lodash'
import { FieldPath } from 'react-hook-form'
import z from 'zod'

const RuleSchema = z.object({
  assetTypeId: z.number(),
  mitigationRuleId: z.number(),
  conditions: z.array(
    z.object({
      value: z.string(),
      fromInterval: z.string(),
      toInterval: z.string(),
      assetFieldId: z.number(),
      ruleId: z.number(),
    })
  ),
  outcomes: z.array(
    z.object({
      value: z.string(),
      assetFieldId: z.number(),
      ruleId: z.number(),
    })
  ),
})

const CreateEditScenarioFormSchema = z.object({
  id: z.number().optional(),
  name: z
    .string()
    .trim()
    .regex(/^[a-z0-9_ ]*$/gi, {
      message:
        'Name can only contain alphanumeric characters, spaces, and underscores.',
    })
    .min(1, { message: 'Scenario Name is required' }),
  type: z.number().gte(0, 'Select Mitigation Type'),
  rules: z.array(RuleSchema),
})

const DraftSchema = CreateEditScenarioFormSchema.pick({
  name: true,
  type: true,
})

const RulesSchema = z.object({
  rules: z.array(RuleSchema).superRefine((rulesSchema, ctx) => {
    const groupRules = groupBy(rulesSchema, 'assetTypeId')
    const catchAllRuleIds: number[] = Object.values(groupRules).map(
      (rules) => minBy(rules, 'mitigationRuleId')?.mitigationRuleId as number
    )

    rulesSchema.forEach((rule) => {
      if (rule.conditions.length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'At least one condition necessary',
        })
      }

      const isCatchAllRule = catchAllRuleIds.includes(rule.mitigationRuleId)
      if (isCatchAllRule) {
        rule.conditions.forEach((condition) => {
          if (condition.assetFieldId === -1) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Detected empty condition field',
            })
          }
        })
      } else {
        rule.conditions.forEach((condition) => {
          if (
            condition.value === '' &&
            (condition.fromInterval === '' ||
              condition.fromInterval === 'Invalid Date' ||
              condition.toInterval === '' ||
              condition.toInterval === 'Invalid Date')
          ) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Detected empty condition value',
            })
          }
        })
      }

      rule.outcomes.forEach((outcome) => {
        if (outcome.value === '' || outcome.value === 'Invalid Date') {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `Detected empty outcome value`,
          })
        }
      })
    })
  }),
})

type CreateEditScenarioFormSchemaType = z.infer<
  typeof CreateEditScenarioFormSchema
>
type PathType = FieldPath<CreateEditScenarioFormSchemaType>

export { DraftSchema, RulesSchema }
export type { CreateEditScenarioFormSchemaType, PathType }
export default CreateEditScenarioFormSchema
