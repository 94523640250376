import z from 'zod'

const EditParametersFormSchema = z.object({
  subThreatParameters: z.object({
    assetTypeId: z.number(),
    modelCode: z.string().nullable(),
    nextLogId: z.number(),
    createdBy: z.string(),
    modelParameterValues: z.array(
      z.object({
        modelPropertiesId: z.number(),
        calculationComponentTypeId: z.number(),
        modelParameterId: z.number(),
        displayName: z.string().nullable(),
        value: z.number(),
      })
    ),
  }),

  covariateCoefficients: z.object({
    assetTypeId: z.number(),
    modelCode: z.string().nullable(),
    nextLogId: z.number(),
    createdBy: z.string(),
    modelCovariateCoeffValues: z.array(
      z.object({
        assetFieldId: z.number(),
        assetFieldName: z.string().nullable(),
        assetFieldDomainItemId: z.number().nullable(),
        domainItemName: z.string().nullable(),
        gammaValue: z.number(),
      })
    ),
  }),
})

type EditParametersFormSchemaType = z.infer<typeof EditParametersFormSchema>

export type { EditParametersFormSchemaType }
export default EditParametersFormSchema
