import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { DatePicker, Input, MultiSelect } from 'common/components'
import { useAssetFieldsQuery } from 'services/apis'
import { CreateEditScenarioFormSchemaType } from '../..'

interface IConditionFieldProps {
  assetTypeId: number
  conditionIndex: number
  isCatchAllRule: boolean
  isViewScreen: boolean
  ruleIndex: number
  runAssetValidation: () => void
}

const ConditionField = ({
  assetTypeId,
  conditionIndex,
  isCatchAllRule,
  isViewScreen,
  ruleIndex,
  runAssetValidation,
}: IConditionFieldProps) => {
  const { control } = useFormContext<CreateEditScenarioFormSchemaType>()

  const { data: assetFieldsData } = useAssetFieldsQuery(assetTypeId)

  const condition = useWatch({
    control,
    name: `rules.${ruleIndex}.conditions.${conditionIndex}`,
  })

  const assetField = assetFieldsData?.find(
    ({ assetFieldId }) => assetFieldId === condition.assetFieldId
  )

  const assetFieldItems =
    assetField?.values?.map((value) => ({
      id: value.assetFieldDomainItemID,
      label: value.domainItemName,
    })) ?? []

  switch (assetField?.assetFieldType) {
    case 'list':
    case 'bool':
      return (
        <div className="ml-1 flex h-14 w-full grow flex-row items-center 2xl:h-[53px]">
          <Controller
            control={control}
            name={`rules.${ruleIndex}.conditions.${conditionIndex}.value`}
            render={({ field }) => (
              <MultiSelect
                {...field}
                isDisabled={isCatchAllRule}
                isReadOnly={isViewScreen}
                items={assetFieldItems}
                setValue={(items: any) => {
                  field.onChange(
                    items
                      .filter((item: string | number) => !isNaN(item as number))
                      .toString()
                  )
                  runAssetValidation()
                }}
                values={field.value.split(',').map((item) => parseInt(item))}
              />
            )}
          />
        </div>
      )
    case 'float':
      return (
        <Controller
          control={control}
          name={`rules.${ruleIndex}.conditions.${conditionIndex}`}
          render={({ field }) => {
            return (
              <div className="ml-1 flex h-14 w-full flex-row items-center 2xl:h-[53px]">
                <Input
                  ref={field.ref}
                  isDisabled={isCatchAllRule}
                  isLabelHidden
                  isReadOnly={isViewScreen}
                  label={`${assetField.assetFieldName} from`}
                  onBlur={field.onBlur}
                  onChange={(e) => {
                    field.onChange({
                      ...field.value,
                      fromInterval: e.target.value,
                    })
                    runAssetValidation()
                  }}
                  type="number"
                  value={field.value.fromInterval}
                />
                <p className="ml-[10px] mr-[10px]">
                  &le;&nbsp;&times;&nbsp;&lt;
                </p>
                <Input
                  isDisabled={isCatchAllRule}
                  isLabelHidden
                  isReadOnly={isViewScreen}
                  onBlur={field.onBlur}
                  onChange={(e) => {
                    field.onChange({
                      ...field.value,
                      toInterval: e.target.value,
                    })
                    runAssetValidation()
                  }}
                  label={`${assetField.assetFieldName} to`}
                  type="number"
                  value={field.value.toInterval}
                />
              </div>
            )
          }}
        />
      )
    case 'date':
      return (
        <Controller
          control={control}
          name={`rules.${ruleIndex}.conditions.${conditionIndex}`}
          render={({ field }) => (
            <div className="ml-1 flex h-14 w-full grow flex-row items-center 2xl:h-[53px]">
              <DatePicker
                ref={field.ref}
                isDisabled={isCatchAllRule}
                isReadOnly={isViewScreen}
                onBlur={field.onBlur}
                onChange={(date) => {
                  const dateValue = isNaN((date as Date)?.getTime())
                    ? 'Invalid Date'
                    : (date as Date)?.toISOString()
                  field.onChange({ ...field.value, fromInterval: dateValue })
                  runAssetValidation()
                }}
                value={field.value.fromInterval || null}
              />
              <p className="ml-[10px] mr-[10px]">to</p>
              <DatePicker
                isReadOnly={isViewScreen}
                isDisabled={isCatchAllRule}
                onBlur={field.onBlur}
                onChange={(date) => {
                  const dateValue = isNaN((date as Date)?.getTime())
                    ? 'Invalid Date'
                    : (date as Date)?.toISOString()
                  field.onChange({ ...field.value, toInterval: dateValue })
                  runAssetValidation()
                }}
                value={field.value.toInterval || null}
              />
            </div>
          )}
        />
      )
    default:
      return (
        <div className="ml-1 flex h-14 w-full grow flex-row items-center 2xl:h-[53px]">
          <Controller
            control={control}
            name={`rules.${ruleIndex}.conditions.${conditionIndex}.value`}
            render={({ field }) => {
              return (
                <Input
                  {...field}
                  isDisabled={isCatchAllRule}
                  isLabelHidden
                  isReadOnly={isViewScreen || !assetField}
                  label={`${assetField?.assetFieldName}`}
                  onChange={(e) => {
                    field.onChange(e)
                    runAssetValidation()
                  }}
                />
              )
            }}
          />
        </div>
      )
  }
}

export default ConditionField
