import { ChangeEventHandler, forwardRef, useMemo } from 'react'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import {
  FormControlLabel,
  Radio as MuiRadio,
  RadioGroup,
  SxProps,
} from '@mui/material'

interface IRadioItem {
  value: string
  label: string
}

interface IRadiosProps {
  /**
   * The current selected radio item.
   */
  value: string
  /**
   * Callback that triggers when value is changed.
   */
  onChange: ChangeEventHandler
  /**
   * Name of the radio group.
   */
  name: string
  /**
   * The radio group's label required for assistive technologies.
   */
  label: string
  /**
   * Disables the radios.
   */
  isDisabled?: boolean
  /**
   * An array of objects containing `value` and `label` properties for the
   * radio item's value and the label , respectively.
   */
  items: IRadioItem[]
  /**
   * CSS classes for wrapper element.
   */
  className?: string
  /**
   * CSS classes for individual radios.
   */
  radioClassName?: string
  /**
   * Display label in bold font weight.
   */
  isLabelBold?: boolean
}

/**
 * Radios let users select a single option from two or more radio items.
 */
const Radios = forwardRef<HTMLInputElement, IRadiosProps>(
  (
    {
      className = '',
      isDisabled = false,
      isLabelBold = false,
      items,
      label,
      name,
      onChange,
      radioClassName = '',
      value,
    },
    ref
  ) => {
    const formControlLabelStyles: SxProps = useMemo(
      (): SxProps => ({
        '.MuiSvgIcon-root': {
          fontSize: '1.8rem',
          color: '#82ACFF',
        },
        '&.Mui-disabled .MuiTypography-root': {
          opacity: 0.5,
        },
        '&.Mui-disabled .MuiSvgIcon-root': {
          opacity: 0.5,
          color: '#aaa',
        },
        '.MuiRadio-root:not(.Mui-checked) .MuiSvgIcon-root': {
          color: '#A6A8AB',
        },
        '.MuiTypography-root': {
          fontWeight: isLabelBold ? 600 : 500,
          marginLeft: '-3px',
        },
      }),
      [isLabelBold]
    )

    return (
      <RadioGroup
        aria-label={label}
        name={name}
        value={value}
        onChange={onChange}
        className={className}>
        {items.map(({ value, label }) => (
          <FormControlLabel
            key={value}
            value={value}
            inputRef={ref}
            sx={formControlLabelStyles}
            control={
              <MuiRadio
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<RadioButtonCheckedIcon />}
              />
            }
            label={label}
            disabled={isDisabled}
            className={radioClassName}
          />
        ))}
      </RadioGroup>
    )
  }
)

Radios.displayName = 'Radios'

export default Radios
