import { FormEvent, useState } from 'react'
import { Button, Checkbox, Modal, Radios } from 'common/components'
import { IAttribute, ILocationLevel } from 'services/interfaces'
import { useLocationLevelsQuery } from 'services/apis'

const attributesData = [
  {
    id: 1,
    name: 'Asset Type Id',
    isCore: true,
  },
  {
    id: 2,
    name: 'Asset Status Id',
    isCore: false,
  },
  {
    id: 3,
    name: 'Business District Indicator Id',
    isCore: false,
  },
  {
    id: 4,
    name: 'GIS Connect Id',
    isCore: false,
  },
  {
    id: 5,
    name: 'Install Date',
    isCore: false,
  },
  {
    id: 6,
    name: 'Hierarchy Node 1 Id',
    isCore: false,
  },
  {
    id: 7,
    name: 'Hierarchy Node 2 Id',
    isCore: false,
  },
  {
    id: 8,
    name: 'Hierarchy Node 3 Id',
    isCore: false,
  },
  {
    id: 9,
    name: 'Hierarchy Node 4 Id',
    isCore: false,
  },
  {
    id: 10,
    name: 'Hierarchy Node 5 Id',
    isCore: false,
  },
  {
    id: 11,
    name: 'Hierarchy Node 6 Id',
    isCore: false,
  },
  {
    id: 12,
    name: 'Map Node',
    isCore: false,
  },
  {
    id: 13,
    name: 'Above or Below Ground Id',
    isCore: false,
  },
  {
    id: 14,
    name: 'Coating Category Id',
    isCore: false,
  },
  {
    id: 15,
    name: 'Coating Flag Id',
    isCore: false,
  },
  {
    id: 16,
    name: 'Coating Type Id',
    isCore: false,
  },
  {
    id: 17,
    name: 'Cost Model Location Category Id',
    isCore: false,
  },
  {
    id: 18,
    name: 'CP Effectiveness',
    isCore: false,
  },
  {
    id: 19,
    name: 'CP Protection Date',
    isCore: false,
  },
  {
    id: 20,
    name: 'CP Protection Date Flag Id',
    isCore: false,
  },
  {
    id: 21,
    name: 'CP Status Id',
    isCore: false,
  },
  {
    id: 22,
    name: 'CP Type Id',
    isCore: false,
  },
  {
    id: 23,
    name: 'Customer Specific External Corrosion Index',
    isCore: false,
  },
  {
    id: 24,
    name: 'Depth of Cover',
    isCore: false,
  },
  {
    id: 25,
    name: 'Earth Movement Potential',
    isCore: false,
  },
  {
    id: 26,
    name: 'Earthquake Fault Risk',
    isCore: false,
  },
  {
    id: 27,
    name: 'EFV Installed Indicator Id',
    isCore: false,
  },
  {
    id: 28,
    name: 'Farm Tap Indicator Id',
    isCore: false,
  },
  {
    id: 29,
    name: 'Fitting Density',
    isCore: false,
  },
  {
    id: 30,
    name: 'Flood Potential Id',
    isCore: false,
  },
  {
    id: 31,
    name: 'Flood Potential Rating',
    isCore: false,
  },
  {
    id: 32,
    name: 'Install Year Flag Id',
    isCore: false,
  },
  {
    id: 33,
    name: 'Joint Failure Potential',
    isCore: false,
  },
  {
    id: 34,
    name: 'Joint Type Id',
    isCore: false,
  },
  {
    id: 35,
    name: 'Land Use Id',
    isCore: false,
  },
  {
    id: 36,
    name: 'Leak Survey Frequency Id',
    isCore: false,
  },
  {
    id: 37,
    name: 'Length',
    isCore: false,
  },
  {
    id: 38,
    name: 'Lightning Strike Intensity in Area',
    isCore: false,
  },
  {
    id: 39,
    name: 'Line Locate System Type Id',
    isCore: false,
  },
  {
    id: 40,
    name: 'Location Category Id',
    isCore: true,
  },
  {
    id: 41,
    name: 'Main to Main Connections',
    isCore: false,
  },
  {
    id: 42,
    name: 'MAOP',
    isCore: false,
  },
  {
    id: 43,
    name: 'Material Id',
    isCore: true,
  },
  {
    id: 44,
    name: 'Material Grade Id',
    isCore: false,
  },
  {
    id: 45,
    name: 'Material Flag Id',
    isCore: false,
  },
  {
    id: 46,
    name: 'NPS Flag Id',
    isCore: false,
  },
  {
    id: 47,
    name: 'Number of Active Leaks',
    isCore: false,
  },
  {
    id: 48,
    name: 'Number of Feeds Id',
    isCore: false,
  },
  {
    id: 49,
    name: 'Number of Fittings',
    isCore: false,
  },
  {
    id: 50,
    name: 'Number of Services',
    isCore: false,
  },
  {
    id: 51,
    name: 'On Federal Land Id',
    isCore: false,
  },
  {
    id: 52,
    name: 'One Call Density in Area',
    isCore: false,
  },
  {
    id: 53,
    name: 'One Call Effectiveness in Area',
    isCore: false,
  },
  {
    id: 54,
    name: 'One Call Service Area Indicator Id',
    isCore: false,
  },
  {
    id: 55,
    name: 'Operating Pressure',
    isCore: false,
  },
  {
    id: 56,
    name: 'Operating Pressure Flag Id',
    isCore: false,
  },
  {
    id: 57,
    name: 'Operating Pressure Name Id',
    isCore: false,
  },
  {
    id: 58,
    name: 'Parent Main',
    isCore: false,
  },
  {
    id: 59,
    name: 'Pipe Size NPS',
    isCore: false,
  },
  {
    id: 60,
    name: 'Pipe Size NPS Band',
    isCore: true,
  },
  {
    id: 61,
    name: 'Pressure Class Id',
    isCore: false,
  },
  {
    id: 62,
    name: 'Pressure Band Id',
    isCore: false,
  },
  {
    id: 63,
    name: 'Rail Cross Indicator Id',
    isCore: false,
  },
  {
    id: 64,
    name: 'Road Cross Indicator Id',
    isCore: false,
  },
  {
    id: 65,
    name: 'Seismic Intensity in Area',
    isCore: false,
  },
  {
    id: 66,
    name: 'Small Diameter Fraction',
    isCore: false,
  },
  {
    id: 67,
    name: 'Soil Type Id',
    isCore: false,
  },
  {
    id: 68,
    name: 'Usage Type Id',
    isCore: false,
  },
  {
    id: 69,
    name: 'Wall Thickness',
    isCore: false,
  },
  {
    id: 70,
    name: 'Watch and Protect Indicator Id',
    isCore: false,
  },
]

const classes = {
  sectionButton: `block w-full cursor-pointer rounded border-none 
  py-3 px-5 text-left font-semibold `,
  selected: `bg-lightBlue-100 text-coolGray-900`,
  notSelected: `bg-white text-coolGray-400`,
  attributesData: `overflow-auto rounded border-2 border-solid border-gray-200 p-6 
  py-3 grid max-h-40 grid-cols-2`,
  locationLevelData: `overflow-auto rounded border-2 border-solid border-gray-200 
  p-6 py-3 flex flex-wrap`,
}

export interface ILookupTableConfigModal {
  isOpen: boolean
  onClose: () => void
  tableType: string
  assetTypeId: number
}

const LookupTableConfigModal = ({
  isOpen,
  onClose,
  tableType,
}: ILookupTableConfigModal) => {
  const { data: locationLevelsData } = useLocationLevelsQuery()

  const [selectedAttributeIds, setSelectedAttributeIds] = useState<number[]>([])
  const [selectedLocationLevel, setSelectedLocationLevel] = useState<
    number | null
  >(0)
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0)

  const sections = ['Core Attributes']

  const applyInitialData = () => {
    setSelectedAttributeIds([])
    setSelectedLocationLevel(null)
  }

  const handleClose = () => {
    applyInitialData()
    onClose()
  }

  const handleSave = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onClose()
  }

  const sidebarContent = (
    <>
      <p className="mt-0 mb-5 font-bold">Table:</p>
      <p className="mt-2">{tableType}</p>
      <div className="mt-16">
        <p className="mt-0 mb-5 font-bold">Section</p>
        {sections.map((section, i) => (
          <Button
            variant="clear"
            letterCase="normal-case"
            key={i}
            onClick={() => setCurrentSectionIndex(i)}
            className={`${classes.sectionButton} ${
              currentSectionIndex === i ? classes.selected : classes.notSelected
            }`}>
            {section}
          </Button>
        ))}
      </div>
    </>
  )

  const footerContent = (
    <>
      <Button className="mr-2" onClick={handleClose} variant="clear">
        Cancel
      </Button>
      <Button isSubmit>{'Save & Apply'}</Button>
    </>
  )

  const handleChecked = (id: number) => {
    if (selectedAttributeIds.includes(id)) {
      setSelectedAttributeIds((selectedAttributeIds) =>
        selectedAttributeIds.filter((selectedId) => id !== selectedId)
      )
    } else {
      setSelectedAttributeIds((selectedAttributeIds) => [
        ...selectedAttributeIds,
        id,
      ])
    }
  }

  return (
    <form onSubmit={handleSave}>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title="Lookup Table Configuration"
        sidebarContent={sidebarContent}
        footerContent={footerContent}
        className="h-[650px]">
        <>
          {currentSectionIndex === 0 && (
            <div className="body">
              <p className="my-0 font-bold text-blue-navy">CORE ATTRIBUTES</p>
              <p className="mt-10 font-bold">Typical Attributes:</p>
              <div className={classes.attributesData}>
                {attributesData
                  ?.filter((attribute: IAttribute) => attribute.isCore)
                  ?.map((attribute: IAttribute) => (
                    <div key={attribute.id} className="h-11">
                      <Checkbox
                        label={attribute.name}
                        isChecked={selectedAttributeIds.includes(attribute.id)}
                        setIsChecked={() => handleChecked(attribute.id)}
                      />
                    </div>
                  ))}
              </div>

              <p className="mt-10 font-bold">Location Level:</p>
              <div className={classes.locationLevelData}>
                <Radios
                  label="Location level"
                  value={String(selectedLocationLevel)}
                  name="location-level"
                  items={
                    locationLevelsData?.map(
                      (locationLevel: ILocationLevel) => ({
                        value: locationLevel.level.toString(),
                        label: locationLevel.name,
                      })
                    ) ?? []
                  }
                  onChange={(e) =>
                    setSelectedLocationLevel(
                      JSON.parse((e.target as HTMLInputElement).value)
                    )
                  }
                  className="grid w-full grid-cols-2"
                  radioClassName="mr-0 basis-1/2"
                />
              </div>
            </div>
          )}
        </>
      </Modal>
    </form>
  )
}

export default LookupTableConfigModal
