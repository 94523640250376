import { useState } from 'react'
import { useParams } from 'react-router'
import { Accordion } from 'common/components'
import ConfigModal from '../ConfigModal'
import { ILikelihoodConfiguration } from 'services/interfaces'
import {
  useLikelihoodConfigurationsQuery,
  useAssetTypesQuery,
} from 'services/apis'
import { ConfigOutletTable } from '.'

const ConfigOutlet = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [selectedSubThreatName, setSelectedSubThreatName] = useState<string>('')
  const [selectedSubThreatModelCode, setSelectedSubThreatModelCode] =
    useState<string>('')

  const { assetType } = useParams()
  const { data: assetTypesData } = useAssetTypesQuery()
  const assetTypeId =
    assetTypesData?.find(
      (assetTypeData) =>
        assetTypeData.label.toLowerCase() === assetType?.toLowerCase()
    )?.id ?? -1

  const { data: likelihoodConfigurations } =
    useLikelihoodConfigurationsQuery(assetTypeId)

  return (
    <div className="mt-10">
      <Accordion
        items={
          likelihoodConfigurations?.map(
            (configuration: ILikelihoodConfiguration) => {
              const { majorThreatName, subThreats } = configuration

              return {
                label: majorThreatName,
                children: (
                  <ConfigOutletTable
                    subThreats={subThreats}
                    assetTypeId={assetTypeId}
                    setIsModalOpen={setIsModalOpen}
                    setSelectedSubThreatName={setSelectedSubThreatName}
                    setSelectedSubThreatModelCode={
                      setSelectedSubThreatModelCode
                    }
                  />
                ),
              }
            }
          ) ?? []
        }
      />

      <ConfigModal
        assetTypeId={assetTypeId}
        subThreatModel={{
          name: selectedSubThreatName,
          code: selectedSubThreatModelCode,
        }}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  )
}

export default ConfigOutlet
