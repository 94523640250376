import { useState } from 'react'
import { Modal } from 'common/components'
import { IModalProps } from 'common/interfaces'
import { Content } from '.'

const CreateAssessmentModal = ({
  isOpen,
  onClose,
}: Omit<IModalProps, 'children'>) => {
  const [isBusy, setIsBusy] = useState(false)

  return (
    <Modal
      isBusy={isBusy}
      isOpen={isOpen}
      onClose={onClose}
      title="Create Assessment">
      <Content onClose={onClose} setIsBusy={setIsBusy} />
    </Modal>
  )
}

export default CreateAssessmentModal
