import { auth0Token } from 'common/utils'

/**
 * Facilitate downloading of file
 * @param url API endpoint that returns a blob
 * @param fileName Name of downloaded file
 * @param method HTTP method to use
 * @param body JSON payload
 */
async function handleFileDownload(
  url: string,
  fileName: string,
  method: 'GET' | 'POST' = 'GET',
  body?: any
) {
  try {
    const token = await auth0Token.getAccessTokenSilently()()
    if (token) {
      let response: Response

      if (method === 'GET') {
        response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      } else if (method === 'POST' && body) {
        response = await fetch(url, {
          method,
          body: JSON.stringify(body),
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
      } else {
        return false
      }

      if (response.status !== 200) {
        return false
      } else {
        const file = await response.blob()
        const fileUrl = window.URL.createObjectURL(file)
        const fileLink = document.createElement('a')
        fileLink.href = fileUrl
        fileLink.download = fileName
        fileLink.click()
        fileLink.remove()
        return true
      }
    }
  } catch (err) {
    console.error(err)
    return false
  }
}

export default handleFileDownload
