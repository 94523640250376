import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export enum MockApiTags {
  RiskConfigurations = 'RiskConfigurations',
}

const mockApi = createApi({
  reducerPath: 'mockApi',
  tagTypes: [MockApiTags.RiskConfigurations],
  baseQuery: fetchBaseQuery({
    baseUrl: 'http://localhost:5000',
  }),
  endpoints: () => ({}),
})

export default mockApi
