import { Outlet, useLocation } from 'react-router-dom'
import { Layout, Breadcrumbs, LinkedTabs } from 'common/components'

const ScenarioProbability = () => {
  const location = useLocation()

  const tabs = [
    {
      label: 'Lookup Tables',
      to: '/scenario-probability-config/lookup-tables',
    },
    {
      label: 'Scenario Values',
      to: '/scenario-probability-config/scenario-values',
    },
  ]

  const activeTabIndex = () => {
    const tabIndex = tabs.findIndex((tab) => location.pathname.includes(tab.to))

    if (tabIndex === -1) return 0
    return tabIndex
  }

  return (
    <Layout>
      <>
        <Breadcrumbs
          items={[
            { title: 'Configuration', to: '/configuration' },
            { title: 'Risk Configuration', to: '' },
          ]}
        />
        <div className="title-bar">
          <h1>Scenario Probability Configuration</h1>

          <LinkedTabs tabs={tabs} initialTab={activeTabIndex()} />
        </div>

        <div>
          <Outlet />
        </div>
      </>
    </Layout>
  )
}

export default ScenarioProbability
