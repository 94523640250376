import { useParams } from 'react-router-dom'
import { GridRenderCellParams } from '@mui/x-data-grid'

import { Table, ResponsiveLabels } from 'common/components'
import { formatTimestamp } from 'common/utils'
import ConfigModalToggle from './ConfigModalToggle'
import { IAttribute } from 'services/interfaces'
import {
  useAssetTypesQuery,
  useCostConfigQuery,
  useLocationLevelsQuery,
} from 'services/apis'

const CostConfigLookupTable = () => {
  const { assetType } = useParams()
  const { data: assetTypesData } = useAssetTypesQuery()
  const { data: locationLevelsData } = useLocationLevelsQuery()

  const assetTypeId =
    assetTypesData?.find(
      (assetTypeData) =>
        assetTypeData.label.toLowerCase() === assetType?.toLowerCase()
    )?.id ?? -1

  // @TODO: configurationId is hardcoded. Replace once endpoint is ready.
  const { data } = useCostConfigQuery(
    { configurationId: 11, assetTypeId },
    {
      skip: assetTypeId === -1,
    }
  )

  const columnConfig = [
    {
      field: 'tableType',
      flex: 1.5,
      headerName: 'TABLE TYPE',
      sortable: false,
      disableColumnMenu: true,
      cellClassName: 'font-bold',
    },
    {
      field: 'lastUpdated',
      flex: 1,
      headerName: 'LAST UPDATED',
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => {
        return <>{formatTimestamp(params.row.lastUpdated)}</>
      },
    },
    {
      field: 'updatedBy',
      flex: 1,
      headerName: 'UPDATED BY',
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'selectedAttributes',
      flex: 2,
      headerName: 'SELECTED ATTRIBUTES',
      sortable: false,
      disableColumnMenu: true,
      disableOverflowTooltip: true,
      renderCell: (params: GridRenderCellParams) => {
        if (
          params.row.attributes.length === 1 &&
          params.row.attributes[0] === null
        ) {
          return
        }
        const selectedLocationLevel = params.row.selectedLocationLevel
        const selectedAttributes = params.row.attributes.map(
          (attribute: IAttribute) => attribute?.name
        )
        if (selectedLocationLevel) {
          selectedAttributes.push(
            locationLevelsData?.find(
              (locationLevel) => locationLevel.level === selectedLocationLevel
            )?.name
          )
        }
        return <ResponsiveLabels labels={selectedAttributes} />
      },
    },
    {
      field: 'configure',
      width: 200,
      headerName: 'CONFIGURE',
      sortable: false,
      disableColumnMenu: true,
      disableOverflowTooltip: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <ConfigModalToggle rowParams={params} assetTypeId={assetTypeId} />
        )
      },
    },
  ]

  return (
    <Table
      variant="config-table"
      hasPagination={false}
      columns={columnConfig}
      rows={data}
      checkboxSelection={false}
      pageSize={3}
    />
  )
}

export default CostConfigLookupTable
