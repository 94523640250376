import { useCallback, useRef, useState } from 'react'
import { Searchbox } from 'common/components'
import { debounce } from 'common/utils'
import { AssetAttributesTable } from '.'

const mandatoryAttributesData = [
  {
    id: 1,
    systemName: 'asset_ID',
    displayName: 'Asset ID',
    dataType: 'String',
    isEnabled: false,
  },
  {
    id: 2,
    systemName: 'asset_type',
    displayName: 'Asset Type',
    dataType: 'String',
    isEnabled: false,
  },
  {
    id: 3,
    systemName: 'asset_status',
    displayName: 'Asset Status',
    dataType: 'String',
    isEnabled: false,
  },
  {
    id: 4,
    systemName: 'geographic_location_code',
    displayName: 'Geographic Location Code',
    dataType: 'String',
    isEnabled: false,
  },
  {
    id: 5,
    systemName: 'length',
    displayName: 'Length',
    dataType: 'Number',
    isEnabled: false,
  },
  {
    id: 6,
    systemName: 'installation_year',
    displayName: 'Installation Year',
    dataType: 'Date',
    isEnabled: false,
  },
  {
    id: 7,
    systemName: 'material',
    displayName: 'Material',
    dataType: 'String',
    isEnabled: false,
  },
  {
    id: 8,
    systemName: 'operating_pressure',
    displayName: 'Operating Pressure',
    dataType: 'Number',
    isEnabled: false,
  },
  {
    id: 9,
    systemName: 'geographic_location_code',
    displayName: 'Geographic Location Code',
    dataType: 'String',
    isEnabled: false,
  },
  {
    id: 10,
    systemName: 'length',
    displayName: 'Length',
    dataType: 'Number',
    isEnabled: false,
  },

  {
    id: 11,
    systemName: 'length',
    displayName: 'Length',
    dataType: 'Number',
    isEnabled: false,
  },
  {
    id: 12,
    systemName: 'installation_year',
    displayName: 'Installation Year',
    dataType: 'Date',
    isEnabled: false,
  },
  {
    id: 13,
    systemName: 'material',
    displayName: 'Material',
    dataType: 'String',
    isEnabled: false,
  },
  {
    id: 14,
    systemName: 'operating_pressure',
    displayName: 'Operating Pressure',
    dataType: 'Number',
    isEnabled: false,
  },
  {
    id: 15,
    systemName: 'geographic_location_code',
    displayName: 'Geographic Location Code',
    dataType: 'String',
    isEnabled: false,
  },
  {
    id: 16,
    systemName: 'length',
    displayName: 'Length',
    dataType: 'Number',
    isEnabled: false,
  },
]

const optionalAttributesData = [
  {
    id: 11,
    systemName: 'location_category_1',
    displayName: 'Region',
    dataType: 'String',
    isEnabled: true,
  },
  {
    id: 12,
    systemName: 'location_category_2',
    displayName: 'State',
    dataType: 'String',
    isEnabled: false,
  },
  {
    id: 13,
    systemName: 'location_category_3',
    displayName: 'District',
    dataType: 'String',
    isEnabled: true,
  },
  {
    id: 14,
    systemName: 'location_category_4',
    displayName: 'City',
    dataType: 'Number',
    isEnabled: true,
  },
  {
    id: 15,
    systemName: 'location_category_5',
    displayName: 'Town',
    dataType: 'String',
    isEnabled: false,
  },
  {
    id: 16,
    systemName: 'location_category_6',
    displayName: 'Neighbourhood',
    dataType: 'String',
    isEnabled: false,
  },
  {
    id: 17,
    systemName: 'joining_coating_type',
    displayName: 'Joining Coating Type',
    dataType: 'String',
    isEnabled: false,
  },
  {
    id: 18,
    systemName: 'soil_rock_content',
    displayName: 'Soil Rock Content',
    dataType: 'String',
    isEnabled: false,
  },
  {
    id: 19,
    systemName: 'joining_coating_type',
    displayName: 'Joining Coating Type',
    dataType: 'String',
    isEnabled: false,
  },
  {
    id: 20,
    systemName: 'soil_rock_content',
    displayName: 'Soil Rock Content',
    dataType: 'String',
    isEnabled: false,
  },
]

const AssetAttributes = () => {
  const [debouncedSearchText, setDebouncedSearchText] = useState('')
  const searchRef = useRef<HTMLInputElement>(null)

  const filteredData = (data: typeof mandatoryAttributesData) => {
    return data.filter(
      (attribute) =>
        attribute.systemName
          .toLowerCase()
          .includes(debouncedSearchText.toLowerCase().trim()) ||
        attribute.displayName
          .toLowerCase()
          .includes(debouncedSearchText.toLowerCase().trim())
    )
  }

  const handleSearch = useCallback(
    debounce((searchText) => {
      setDebouncedSearchText(searchText)
      if (searchRef?.current) {
        searchRef.current.value = searchText
      }
    }, 300),
    []
  )

  return (
    <div className="col-span-4">
      <div className="subtitle-bar mb-2">
        <h2>Asset Attributes</h2>
        <Searchbox
          ref={searchRef}
          className="w-80"
          isLabelHidden
          label="select attributes"
          onChange={handleSearch}
          placeholder="Search Fields"
        />
      </div>
      <div className="grid grid-cols-2 gap-8">
        <div>
          <h3>MANDATORY</h3>
          <AssetAttributesTable
            data={filteredData(mandatoryAttributesData)}
            isMandatory={true}
          />
        </div>

        <div>
          <h3>OPTIONAL</h3>
          <AssetAttributesTable
            data={filteredData(optionalAttributesData)}
            isMandatory={false}
          />
        </div>
      </div>
    </div>
  )
}

export default AssetAttributes
