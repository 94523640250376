import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { useAppSelector } from 'app/hooks'
import { Button } from 'common/components'
import { ISelectItem } from 'common/interfaces'
import { isFetchBaseQueryError } from 'common/utils'
import {
  useCreateScenarioMutation,
  useEditScenarioMutation,
} from 'services/apis'
import { IError } from 'services/interfaces'
import { removeEmptyConditionSelectors } from '../utils'
import {
  CreateEditScenarioFormSchemaType,
  DraftSchema,
  DUPLICATED_NAME,
  PathType,
} from '.'

interface ITitleBarProps {
  assetTypes: ISelectItem[]
  isCreateScreen: boolean
  isEditScreen: boolean
  isViewScreen: boolean
  isPublishingScenario: boolean
  isSavingCreateScenario: boolean
  isSavingEditScenario: boolean
}

const TitleBar = ({
  assetTypes,
  isCreateScreen,
  isEditScreen,
  isViewScreen,
  isPublishingScenario,
}: ITitleBarProps) => {
  const navigate = useNavigate()
  const { getValues, setError } =
    useFormContext<CreateEditScenarioFormSchemaType>()

  const [
    createScenario,
    {
      isLoading: isSavingCreateScenario,
      isSuccess: isCreateScenarioSuccess,
      error: createScenarioError,
    },
  ] = useCreateScenarioMutation()
  const [
    editScenario,
    {
      isLoading: isSavingEditScenario,
      isSuccess: isEditScenarioSuccess,
      error: editScenarioError,
    },
  ] = useEditScenarioMutation()

  const isFormBusy =
    isSavingCreateScenario || isSavingEditScenario || isPublishingScenario
  const [isPublishable, setIsPublishable] = useState(false)

  const { validAssetTypeIds } = useAppSelector((state) => state.mitigationData)

  useEffect(() => {
    if (assetTypes.length) {
      const totalAssetTypes = assetTypes.length
      setIsPublishable(validAssetTypeIds.length === totalAssetTypes)
    }
  }, [assetTypes, validAssetTypeIds])

  useEffect(() => {
    if (isCreateScenarioSuccess || isEditScenarioSuccess) {
      navigate('/mitigation-scenario-config/scenarios')
    }
  }, [isCreateScenarioSuccess, isEditScenarioSuccess])

  useEffect(() => {
    const error = createScenarioError || editScenarioError

    if (error && isFetchBaseQueryError(error)) {
      const { title, detail: message, type } = error.data as IError
      const isDuplicateScenarioName = title === DUPLICATED_NAME

      if (isDuplicateScenarioName) {
        setError(
          'name',
          {
            type,
            message,
          },
          {
            shouldFocus: true,
          }
        )
      }
    }
  }, [createScenarioError, editScenarioError])

  const handleCancelForm = () => {
    navigate('/mitigation-scenario-config/scenarios')
  }

  const handleSaveAsDraft = async () => {
    const data = getValues()
    const draftValidationResult = DraftSchema.safeParse(data)

    if (!draftValidationResult.success) {
      const { issues } = draftValidationResult.error

      issues.forEach((issue, index) => {
        const { code: type, message, path } = issue
        setError(
          path.join() as PathType,
          {
            type,
            message,
          },
          { shouldFocus: index === 0 }
        )
      })
    } else {
      const cleanedData = removeEmptyConditionSelectors(data)
      if (isCreateScreen) {
        createScenario(cleanedData)
      }
      if (isEditScreen) {
        editScenario(cleanedData)
      }
    }
  }

  return (
    <div className="title-bar">
      <h1>
        {isCreateScreen && 'Create'}
        {isEditScreen && 'Edit'}
        {isViewScreen && 'View'} Mitigation Scenario
      </h1>
      <div>
        {isViewScreen ? (
          <Button variant="primary-outlined" onClick={handleCancelForm}>
            Back
          </Button>
        ) : (
          <>
            <Button
              className="mr-4"
              isDisabled={isFormBusy}
              variant="primary-outlined"
              onClick={handleCancelForm}>
              Cancel
            </Button>
            <Button
              busyText="Saving As Draft..."
              className="mr-4"
              isBusy={
                (isSavingCreateScenario || isSavingEditScenario) &&
                !isPublishingScenario
              }
              isDisabled={isFormBusy}
              onClick={() => {
                handleSaveAsDraft()
              }}>
              Save As Draft
            </Button>
            <Button
              isSubmit
              busyText="Publishing..."
              isDisabled={!isPublishable || isFormBusy}
              isBusy={isPublishingScenario}>
              Publish
            </Button>
          </>
        )}
      </div>
    </div>
  )
}

export default TitleBar
