import { Outlet, useLocation } from 'react-router-dom'

import { Layout, LinkedTabs, Breadcrumbs } from 'common/components'

const LikelihoodModelConfig = () => {
  const location = useLocation()

  const tabs = [
    {
      label: 'Configuration',
      to: '/likelihood-model-config/configuration',
    },
    {
      label: 'Parameters',
      to: '/likelihood-model-config/parameters',
    },
    // {
    //   label: 'Tuning',
    //   to: '/likelihood-model-config/tuning',
    // },
  ]

  const activeTabIndex = () => {
    const tabIndex = tabs.findIndex((tab) => location.pathname.includes(tab.to))

    if (tabIndex === -1) return 0
    return tabIndex
  }

  return (
    <Layout>
      <Breadcrumbs
        items={[
          { title: 'Configuration', to: '/configuration' },
          { title: 'Risk Configuration', to: '' },
        ]}
      />
      <div className="title-bar">
        <h1>Likelihood Model Configuration</h1>

        <LinkedTabs tabs={tabs} initialTab={activeTabIndex()} />
      </div>

      <div>
        <Outlet />
      </div>
    </Layout>
  )
}

export default LikelihoodModelConfig
