import { Dispatch, SetStateAction } from 'react'
import { GridRenderCellParams } from '@mui/x-data-grid'

import PublishedScenarioButtons from './PublishedScenarioButtons'
import UnpublishedScenarioButtons from './UnpublishedScenarioButtons'

import { formatTimestamp } from 'common/utils'
import { STATUS_TYPES } from '.'

interface IColumnConfigProps {
  setIdToArchive: Dispatch<SetStateAction<number>>
  setIdToClone: Dispatch<SetStateAction<number>>
  setIdToDelete: Dispatch<SetStateAction<number>>
  setIsArchiveDialogOpen: Dispatch<SetStateAction<boolean>>
  setIsCloneDialogOpen: Dispatch<SetStateAction<boolean>>
  setIsDeleteDialogOpen: Dispatch<SetStateAction<boolean>>
  showArchived: boolean
}

const columnConfig = ({
  setIdToArchive,
  setIdToClone,
  setIdToDelete,
  setIsArchiveDialogOpen,
  setIsCloneDialogOpen,
  setIsDeleteDialogOpen,
  showArchived,
}: IColumnConfigProps) => [
  {
    disableColumnMenu: true,
    field: 'name',
    flex: 1,
    headerName: 'Name',
    sortable: false,
  },
  {
    disableColumnMenu: true,
    field: 'typeDescription',
    flex: 1,
    headerName: 'Mitigation Type',
    sortable: false,
  },
  {
    disableColumnMenu: true,
    field: 'timestamp',
    flex: 1,
    headerName: 'Timestamp',
    renderCell: (params: GridRenderCellParams) => {
      return <>{formatTimestamp(params.row.timestamp)}</>
    },
  },
  {
    disableColumnMenu: true,
    field: 'user',
    flex: 1,
    headerName: 'User',
    sortable: false,
  },
  {
    disableColumnMenu: true,
    field: 'isPublished',
    flex: 1,
    headerName: 'Status',
    hide: showArchived,
    sortable: false,
    valueGetter: (params: GridRenderCellParams) => {
      return STATUS_TYPES[params.row.stateDescription?.toUpperCase()]
    },
  },
  {
    disableColumnMenu: true,
    disableOverflowTooltip: true,
    field: 'scenarioOptions',
    headerName: 'Scenario Options',
    minWidth: 300,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <>
          {params.row.stateDescription === 'Created' ? (
            <UnpublishedScenarioButtons
              id={params.row.id}
              setIsDeleteDialogOpen={setIsDeleteDialogOpen}
              setIdToDelete={setIdToDelete}
              setIsCloneDialogOpen={setIsCloneDialogOpen}
              setIdToClone={setIdToClone}
            />
          ) : (
            <PublishedScenarioButtons
              isArchiveButtonHidden={
                params.row.stateDescription === 'Archived' ||
                params.row.stateDescription === 'Publishing'
              }
              id={params.row.id}
              setIsArchiveDialogOpen={setIsArchiveDialogOpen}
              setIdToArchive={setIdToArchive}
              setIsCloneDialogOpen={setIsCloneDialogOpen}
              setIdToClone={setIdToClone}
            />
          )}
        </>
      )
    },
    sortable: false,
  },
]

export default columnConfig
