import { Dispatch, ReactNode, SetStateAction, useState } from 'react'
import { Box, Tabs as MuiTabs, Tab as MuiTab, SxProps } from '@mui/material'
import { Check as CheckIcon } from '@mui/icons-material'
import { getAssetIcon } from 'common/utils'
import { useAppSelector } from 'app/hooks'

interface ITab {
  id: number
  label: string
  children: ReactNode
}

interface IRegularTabsProps {
  /**
   * An array of objects containing `label` and `children` properties for the
   * tab label and the JSX to be displayed, respectively.
   */
  tabs: Array<ITab>
}

const MuiTabsStyles: SxProps = {
  padding: '6px',
  backgroundColor: '#E4E5E6',
  width: 'fit-content',
  borderRadius: '4px',
  '.MuiTabs-indicator': {
    display: 'none',
  },
}

const MuiTabStyles: SxProps = {
  backgroundColor: '#E4E5E6',
  padding: '0',
  fontWeight: '600',
  color: '#929497',
  transition: 'background-color .2s',
  textTransform: 'none',
  borderRadius: '4px',
  '&.MuiButtonBase-root': {
    minWidth: '180px',
  },
  '&.Mui-selected': {
    backgroundColor: '#FFFFFF',
    color: '#231F20',
  },
  '&.Mui-focusVisible': {
    outline: `5px auto ${
      navigator.userAgent.match(/firefox|fxios/i)
        ? 'Highlight'
        : '-webkit-focus-ring-color'
    }`,
  },
}

const BoxStyles: SxProps = { width: '100%' }

const renderAssetStatusIcon = (isChecked: boolean | undefined) => {
  if (isChecked) {
    return <CheckIcon color="success" fontSize="large" className="mr-1" />
  }
}

interface ITabsProps {
  tabs: ITab[]
  value: number
  setValue: Dispatch<SetStateAction<number>>
}

const Tabs = ({ tabs, value, setValue }: ITabsProps) => {
  const { validAssetTypeIds } = useAppSelector((state) => state.mitigationData)

  const getLabel = (tab: ITab): ReactNode => {
    const { label, id } = tab
    const isChecked = validAssetTypeIds.includes(id)
    return (
      <div className="mx-4 flex flex-row items-center">
        {renderAssetStatusIcon(isChecked)}
        {getAssetIcon(label)}
        <div className="m-0 p-0 align-middle">{label}</div>
      </div>
    )
  }

  const a11yProps = (index: number) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  })
  return (
    <MuiTabs
      sx={MuiTabsStyles}
      value={value}
      onChange={(_e, index) => setValue(index)}>
      {tabs?.map((tab, index) => (
        <MuiTab
          key={tab.id}
          disableRipple
          label={getLabel(tab)}
          sx={MuiTabStyles}
          {...a11yProps(index)}
        />
      ))}
    </MuiTabs>
  )
}

/**
 * Asset Type tabs that displays the provided JSX.
 */
export default function AssetTypeRegularTabs({ tabs }: IRegularTabsProps) {
  const [value, setValue] = useState<number>(0)

  const renderTabPanels = (): ReactNode =>
    tabs?.map((tab, index) => (
      <div data-testid="tab-content" hidden={value !== index} key={tab.id}>
        {tab.children}
      </div>
    ))

  return (
    <Box sx={BoxStyles}>
      <Tabs tabs={tabs} value={value} setValue={setValue} />
      {renderTabPanels()}
    </Box>
  )
}
