import CreateEditScenario from './CreateEditScenario'
import CreateEditScenarioForm from './CreateEditScenarioForm'
import CreateEditScenarioFormSchema, {
  CreateEditScenarioFormSchemaType,
  DraftSchema,
  RulesSchema,
  PathType,
} from './CreateEditScenarioFormSchema'
import TitleBar from './TitleBar'
import NameAndTypeInputs from './NameAndTypeInputs'
import AssetMitigationRules from './AssetMitigationRules'

export default CreateEditScenario
export {
  AssetMitigationRules,
  CreateEditScenarioForm,
  CreateEditScenarioFormSchema,
  DraftSchema,
  RulesSchema,
  TitleBar,
  NameAndTypeInputs,
}
export type { CreateEditScenarioFormSchemaType, PathType }

// constants
export const DUPLICATED_NAME = 'Duplicated Mitigation Scenario Name'
