import { IMitigationCostConfig } from 'services/interfaces'
import { tabs, Tabs } from '..'
import { CoreAttributes, OtherAttributes } from '.'

interface IContentProps {
  assetTypeId: number
  data: IMitigationCostConfig
  isSavingCostConfig: boolean
  selectedTab: Tabs
}

const Content = ({
  assetTypeId,
  data,
  isSavingCostConfig,
  selectedTab,
}: IContentProps) => {
  const renderContent = () => {
    switch (selectedTab) {
      case tabs.CoreAttributes:
        return (
          <CoreAttributes
            assetTypeId={assetTypeId}
            data={data}
            isSavingCostConfig={isSavingCostConfig}
          />
        )
      case tabs.OtherAttributes:
        return (
          <OtherAttributes
            assetTypeId={assetTypeId}
            data={data}
            isSavingCostConfig={isSavingCostConfig}
          />
        )
      default:
        return null
    }
  }

  return renderContent()
}

export default Content
