import { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import AppsIcon from '@mui/icons-material/Apps'
import { RootState } from 'app/store'
import LighthouseLogo from 'assets/LighthouseLogo.svg'
import { LoadingBar, MenuItem } from 'common/components'
import { IMenuItem, INavItem } from 'common/interfaces'

const navItems: INavItem[] = [
  // {
  //   children: 'Dashboard',
  //   link: '/dashboard',
  // },
  {
    children: 'Configuration',
    link: '/configuration',
  },
  {
    children: 'Data Management',
    menuItems: [
      // { label: 'Asset Data Configuration', link: '/asset-data-config' },
      // { label: 'Data Updates', link: '/data-updates' },
      { label: 'Data Load (Assets & Leaks)', link: '/data-load' },
    ],
  },
  {
    children: 'Risk Management',
    menuItems: [
      // { label: 'Current Assessment', link: '/assessment-current' },
      { label: 'Assessments', link: '/assessments' },
      // { label: 'Publish Versions', link: '/publish-versions' },
    ],
  },
  {
    children: 'Reports',
    menuItems: [
      { label: 'Export', link: '/export' },
      // { label: 'Integration', link: '/integration' },
    ],
  },
]

/**
 * This non-customizable component is present virtually throughout
 * the app. It is a wrapper that positions branding, navigation, and
 * other elements into a concise header.
 */
const NavigationBar = () => {
  const { pathname } = useLocation()
  const { user } = useAuth0()

  const isQueryLoading = useSelector((state: RootState): boolean =>
    Object.values(state.apiCalls.queries).some(
      (query: any) => query.status === 'pending'
    )
  )

  const isMutationLoading = useSelector((state: RootState): boolean =>
    Object.values(state.apiCalls.mutations).some(
      (mutation: any) => mutation.status === 'pending'
    )
  )

  const getActiveStatus = (navItem: INavItem): boolean => {
    const { link, menuItems } = navItem

    if (link && pathname === link) {
      return true
    } else if (menuItems && menuItems.length > 0) {
      return menuItems
        .map((menuItem: IMenuItem) => menuItem.link)
        .includes(pathname)
    }

    return false
  }

  const renderNavItems = (): ReactNode[] => {
    return navItems.map((navItem: INavItem, index: number) => {
      const { children, menuItems, link } = navItem

      return (
        <MenuItem
          key={index}
          isActive={getActiveStatus(navItem)}
          menuItems={menuItems}
          link={link}>
          {children}
        </MenuItem>
      )
    })
  }

  return (
    <>
      <div className="flex h-[56px] flex-row items-center bg-blue-navy">
        <span className="ml-[15px] mr-[8px] text-xs text-blue-lighthouse">
          V23.1
        </span>
        <img
          className="mr-[46px] block w-[40px]"
          src={LighthouseLogo}
          alt="Lighthouse Logo"
        />
        {renderNavItems()}
        <div className="ml-auto flex items-center">
          <MenuItem menuItems={[{ label: 'Logout', link: '/logout' }]}>
            <AccountCircleIcon
              fontSize="large"
              className="mr-[5px] fill-white"
            />
            {user?.name ?? 'JANA'}
          </MenuItem>
          <AppsIcon fontSize="large" className="mr-[30px] fill-white" />
        </div>
      </div>
      {(isQueryLoading || isMutationLoading) && <LoadingBar />}
    </>
  )
}

export default NavigationBar
