import { Table, Button } from 'common/components'
import { formatTimestamp } from 'common/utils'
import { useState } from 'react'
import { GridRenderCellParams, GridSortModel } from '@mui/x-data-grid'

const DUMMYDATA = [
  {
    id: 1,
    runId: 'Run-70',
    subthreat: 'External Corrosion',
    year: '2015 - 2023',
    status: 'In Progress',
    parametersId: 'DB-21',
    timestamp: '2022-07-23T16:30:00.000Z',
    user: 'Sreeraj Nair',
  },
  {
    id: 2,
    runId: 'Run-69',
    subthreat: 'External Corrosion',
    year: '2015 - 2022',
    status: 'Complete',
    parametersId: 'DB-20',
    timestamp: '2022-08-29T11:33:00.000Z',
    user: 'Melody Cheng',
  },
  {
    id: 3,
    runId: 'Run-68',
    subthreat: 'External Corrosion',
    year: '2015 - 2021',
    status: 'Complete',
    parametersId: 'DB-54',
    timestamp: '2022-08-28T11:53:00.000Z',
    user: 'Vikas Goyal',
  },
  {
    id: 4,
    runId: 'Run-67',
    subthreat: 'External Corrosion',
    year: '2015 - 2020',
    status: 'Inomplete',
    parametersId: 'AC-34',
    timestamp: '2018-03-29T03:11:00.000Z',
    user: 'Lewis Ko',
  },
  {
    id: 5,
    runId: 'Run-66',
    subthreat: 'External Corrosion',
    year: '2015 - 2019',
    status: 'Complete',
    parametersId: 'AC-10',
    timestamp: '2010-02-23T07:33:00.000Z',
    user: 'Lewis Ko',
  },
  {
    id: 6,
    runId: 'Run-65',
    subthreat: 'External Corrosion',
    year: '2015 - 2019',
    status: 'Complete',
    parametersId: 'AC-10',
    timestamp: '2010-02-23T07:33:00.000Z',
    user: 'Lewis Ko',
  },
  {
    id: 7,
    runId: 'Run-64',
    subthreat: 'External Corrosion',
    year: '2015 - 2019',
    status: 'Complete',
    parametersId: 'AC-10',
    timestamp: '2010-02-23T07:33:00.000Z',
    user: 'Lewis Ko',
  },
  {
    id: 8,
    runId: 'Run-63',
    subthreat: 'External Corrosion',
    year: '2015 - 2019',
    status: 'Complete',
    parametersId: 'AC-10',
    timestamp: '2010-02-23T07:33:00.000Z',
    user: 'Lewis Ko',
  },
  {
    id: 9,
    runId: 'Run-62',
    subthreat: 'External Corrosion',
    year: '2015 - 2019',
    status: 'Complete',
    parametersId: 'AC-10',
    timestamp: '2010-02-23T07:33:00.000Z',
    user: 'Lewis Ko',
  },
  {
    id: 10,
    runId: 'Run-61',
    subthreat: 'External Corrosion',
    year: '2015 - 2019',
    status: 'Complete',
    parametersId: 'AC-10',
    timestamp: '2010-02-23T07:33:00.000Z',
    user: 'Lewis Ko',
  },
  {
    id: 11,
    runId: 'Run-60',
    subthreat: 'External Corrosion',
    year: '2015 - 2019',
    status: 'Complete',
    parametersId: 'AC-10',
    timestamp: '2010-02-23T07:33:00.000Z',
    user: 'Lewis Ko',
  },
  {
    id: 12,
    runId: 'Run-59',
    subthreat: 'External Corrosion',
    year: '2015 - 2019',
    status: 'Complete',
    parametersId: 'AC-10',
    timestamp: '2010-02-23T07:33:00.000Z',
    user: 'Lewis Ko',
  },
]

const TuningLog = () => {
  const columnConfig = [
    {
      field: 'runId',
      flex: 1,
      headerName: 'Run ID',
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'subthreat',
      flex: 1,
      headerName: 'Subthreat',
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'year',
      flex: 1,
      headerName: 'Year',
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'status',
      flex: 1,
      headerName: 'Status',
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'parametersId',
      flex: 1,
      headerName: 'Parameters ID',
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'timestamp',
      flex: 1,
      headerName: 'Timestamp',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => {
        return <>{formatTimestamp(params.row.timestamp)}</>
      },
    },
    {
      field: 'user',
      flex: 1,
      headerName: 'User',
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'tuningOptions',
      minWidth: 200,
      headerName: 'Tuning Options',
      renderCell: () => {
        return (
          <Button variant="secondary" size="small" className="mr-2">
            View Results
          </Button>
        )
      },
      sortable: false,
      disableColumnMenu: true,
    },
  ]

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'timestamp',
      sort: 'desc',
    },
  ])

  return (
    <Table
      variant="data-table"
      columns={columnConfig}
      checkboxSelection={false}
      rows={DUMMYDATA}
      hasPagination
      sortModel={sortModel}
      setSortModel={setSortModel}
    />
  )
}

export default TuningLog
