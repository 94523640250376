import { Button } from 'common/components'

interface IFooterProps {
  isSavingCostConfig: boolean
  onClose: () => void
}

const Footer = ({ isSavingCostConfig, onClose }: IFooterProps) => {
  return (
    <>
      <Button
        className="mr-2"
        isDisabled={isSavingCostConfig}
        onClick={onClose}
        variant="clear">
        Cancel
      </Button>
      <Button
        busyText="Saving & Applying..."
        isBusy={isSavingCostConfig}
        isSubmit>
        Save & Apply
      </Button>
    </>
  )
}

export default Footer
