import { Button } from 'common/components'

const ValidationRulesAndCustomFields = () => {
  return (
    <div className="col-span-4">
      <div className="subtitle-bar">
        <h2>Validation Rules & Custom Fields</h2>
      </div>
      <div className="flex items-start">
        <div className="mr-8 flex-1 text-coolGray-500">
          The valid values & custom fields template file will be created based
          on the asset attributes selected above.
        </div>
        <div className="flex-2">
          <Button className="mr-4" variant="secondary-outlined">
            Download Template
          </Button>
          <Button variant="upload" letterCase="normal-case">
            Upload Template...
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ValidationRulesAndCustomFields
