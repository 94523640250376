import Content from './Content'
import CreateAssessmentModal from './CreateAssessmentModal'
import CreateAssessmentFormSchema, {
  CreateAssessmentFormSchemaType,
} from './CreateAssessmentFormSchema'

export { Content, CreateAssessmentFormSchema }
export type { CreateAssessmentFormSchemaType }
export default CreateAssessmentModal

// Constants
export const DUPLICATED_NAME = 'Duplicated Assessment Name'
