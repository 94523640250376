import React from 'react'
import ReactDOM from 'react-dom'
import 'index.css'
import App from 'app/App'
import { store } from 'app/store'
import { Provider } from 'react-redux'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { theme } from 'theme'
import { BrowserRouter } from 'react-router-dom'
import { CssBaseline } from '@mui/material'
import '@fontsource/montserrat/latin.css'
import Auth0ProviderWithRedirect from 'app/auth/Auth0ProviderWithRedirect'

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithRedirect>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <StyledEngineProvider injectFirst>
              <CssBaseline />
              <App />
            </StyledEngineProvider>
          </ThemeProvider>
        </Provider>
      </Auth0ProviderWithRedirect>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('ldimp-root')
)
