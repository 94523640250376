import { setSnackbar, setSnackbarIsOpen } from 'common/components'

import { isErrorWithMessage, isFetchBaseQueryError } from 'common/utils'

import { isRejectedWithValue } from '@reduxjs/toolkit'
import type { Middleware, MiddlewareAPI } from '@reduxjs/toolkit'
import { IError } from 'services/interfaces'

/**
 * Display Error Snackbar
 */
export const globalErrorSnackbar: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    const { dispatch } = api

    if (isRejectedWithValue(action)) {
      const error = action.payload
      let errorMessage: string | string[] = ''

      if (isFetchBaseQueryError(error)) {
        if ('error' in error) {
          errorMessage = error.error
        } else {
          // handle server returned error
          const data: IError = error?.data as IError

          if (data?.errors) {
            errorMessage = Object.values(data?.errors).flat() as string[]
          } else if (data?.file) {
            errorMessage = data?.file?.flat() ?? 'File validation failed.'
          } else {
            errorMessage = `${error.status} Error: ${
              data?.title ?? 'Please try again.'
            }`
          }
        }
      } else if (isErrorWithMessage(error)) {
        errorMessage =
          error.message || 'Something went wrong. Please try again.'
      }

      dispatch(
        setSnackbar({
          messages: errorMessage,
          traceId: error?.data?.traceId,
          type: 'error',
        })
      )
      dispatch(setSnackbarIsOpen(true))
    }

    return next(action)
  }
