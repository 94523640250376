import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'

import { baseApi, mockApi } from 'services/apis'
import { globalErrorSnackbar } from './middleware'
import riskDataReducer from 'views/RiskManagement/RiskManagementDucks'
import mitigationDataReducer from 'views/IntegrityManagement/MitigationScenario/MitigationScenarioDucks'
import snackbarSlice from 'common/components/Snackbar/SnackbarDucks'

export const store = configureStore({
  reducer: {
    riskData: riskDataReducer,
    mitigationData: mitigationDataReducer,
    snackbar: snackbarSlice,
    [baseApi.reducerPath]: baseApi.reducer,
    [mockApi.reducerPath]: mockApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(globalErrorSnackbar)
      .concat(baseApi.middleware)
      .concat(mockApi.middleware),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
