// Components
import ConfigModal from './ProbabilityConfigModal'
import Content from './Content'
import Sidebar from './Sidebar'
import Footer from './Footer'

export { Content, Sidebar, Footer }
export default ConfigModal

// Constants
export const TAB_CORE_ATTRIBUTES = 'Core Attributes'
