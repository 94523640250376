import { createSlice } from '@reduxjs/toolkit'
import { riskApi } from 'services/apis'
import { IRisk } from 'services/interfaces'
import { RootState } from 'app/store'

const initialState: {
  selectedRowId: number
  totalAssessments: number
  currentPageData: IRisk[]
} = {
  selectedRowId: -1,
  totalAssessments: 0,
  currentPageData: [],
}

export const riskDataSlice = createSlice({
  name: 'riskData',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSelectedRowId: (state, { payload }) => {
      state.selectedRowId = payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      riskApi.endpoints.assessments.matchFulfilled,
      (state, { payload }) => {
        state.currentPageData = payload.data
        state.totalAssessments = payload.count
      }
    )
  },
})

export const { setSelectedRowId } = riskDataSlice.actions

// selectors
export const getRiskData = (state: RootState) => state.riskData

export default riskDataSlice.reducer
