import { Controller, useFormContext } from 'react-hook-form'
import { Radios } from 'common/components'
import { useAttributesQuery, useLocationLevelsQuery } from 'services/apis'
import {
  IAttribute,
  ILocationLevel,
  IMitigationCostConfig,
} from 'services/interfaces'
import { ConfigSchemaType } from '../ConfigSchema'
import AttributesCheckbox from './AttributesCheckbox'

const classes = {
  section: 'rounded border-2 border-solid border-gray-200 p-6 py-3',
  typicalAttributesSection: 'grid grid-cols-2 overflow-auto max-h-40',
}

interface ICoreAttributesProps {
  assetTypeId: number
  data: IMitigationCostConfig
  isSavingCostConfig: boolean
}

const CoreAttributes = ({
  assetTypeId,
  data,
  isSavingCostConfig,
}: ICoreAttributesProps) => {
  const { control, setValue } = useFormContext<ConfigSchemaType>()

  const { data: locationLevelsData } = useLocationLevelsQuery()
  const { data: attributesData } = useAttributesQuery({
    assetTypeId,
    lookupTableId: data.id,
  })

  const coreAttributes = attributesData?.filter(
    (attribute: IAttribute) => attribute.isCore
  )

  const locationLevels =
    locationLevelsData?.map((locationLevel: ILocationLevel) => ({
      value: String(locationLevel.level),
      label: locationLevel.name,
    })) ?? []

  return (
    <>
      <p className="my-0 font-bold uppercase text-blue-navy">Core Attributes</p>
      <p className="mt-10 font-bold">Typical Attributes:</p>
      <section
        className={`${classes.section} ${classes.typicalAttributesSection}`}>
        {coreAttributes?.map((attribute: IAttribute) => (
          <AttributesCheckbox
            key={attribute.id}
            id={attribute.id}
            isSavingCostConfig={isSavingCostConfig}
            name={attribute.name}
          />
        ))}
      </section>

      <p className="mt-10 font-bold">Location Level:</p>
      <section className={classes.section}>
        <Controller
          control={control}
          name="selectedLocationLevel"
          render={({ field }) => (
            <Radios
              {...field}
              isDisabled={isSavingCostConfig}
              label="Location level"
              value={String(field.value)}
              name="location-level"
              items={locationLevels}
              onChange={(e) =>
                setValue(
                  'selectedLocationLevel',
                  JSON.parse((e.target as HTMLInputElement).value)
                )
              }
              className="grid grid-cols-2"
            />
          )}
        />
      </section>
    </>
  )
}

export default CoreAttributes
