import { Dispatch, SetStateAction } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, setSnackbarIsOpen } from 'common/components'
import { useAppDispatch } from 'app/hooks'

const UnpublishedScenarioButtons = ({
  id,
  setIdToClone,
  setIdToDelete,
  setIsCloneDialogOpen,
  setIsDeleteDialogOpen,
}: {
  id: number
  setIdToClone: Dispatch<SetStateAction<number>>
  setIdToDelete: Dispatch<SetStateAction<number>>
  setIsCloneDialogOpen: Dispatch<SetStateAction<boolean>>
  setIsDeleteDialogOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  return (
    <>
      <Button
        className="mr-2 w-24"
        onClick={() => {
          dispatch(setSnackbarIsOpen(false))
          navigate(`edit?id=${id}`)
        }}
        size="small"
        variant="secondary">
        Edit
      </Button>
      <Button
        className="mr-2 w-24"
        onClick={() => {
          dispatch(setSnackbarIsOpen(false))
          setIsCloneDialogOpen(true)
          setIdToClone(id)
        }}
        size="small"
        variant="secondary">
        Clone
      </Button>
      <Button
        className="w-24"
        onClick={() => {
          dispatch(setSnackbarIsOpen(false))
          setIsDeleteDialogOpen(true)
          setIdToDelete(id)
        }}
        size="small"
        variant="secondary">
        Delete
      </Button>
    </>
  )
}

export default UnpublishedScenarioButtons
