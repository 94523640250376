import { SyntheticEvent, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { GridRenderCellParams, GridSortModel } from '@mui/x-data-grid'
import { useAppDispatch } from 'app/hooks'
import { env } from 'app/runtime-config'
import { ISubThreat, IParameter } from 'services/interfaces'
import EditParametersModal from './EditParametersModal'
import { formatTimestamp, handleFileDownload } from 'common/utils'
import {
  Table,
  Select,
  Button,
  setSnackbar,
  setSnackbarIsOpen,
  DEFAULT_PAGE_SIZE,
} from 'common/components'
import {
  useLikelihoodConfigurationsQuery,
  useParametersQuery,
  useUploadParametersTemplateMutation,
  useAssetTypesQuery,
} from 'services/apis'

const ParametersLog = () => {
  const { assetType } = useParams()
  const dispatch = useAppDispatch()
  const [uploadTemplate, { isLoading: isTemplateUploading }] =
    useUploadParametersTemplateMutation()
  const { data: assetTypesData } = useAssetTypesQuery()
  // @TODO: replace hardcoded value with query to endpoint if and when ready
  const configurationId = 4
  const assetTypeId =
    assetTypesData?.find(
      (assetTypeData) =>
        assetTypeData.label.toLowerCase() === assetType?.toLowerCase()
    )?.id ?? -1
  const [subThreats, setSubThreats] = useState<ISubThreat[]>([])
  const { data: likelihoodConfigurationsData } =
    useLikelihoodConfigurationsQuery(assetTypeId)

  const [currentPage, setCurrentPage] = useState(0)
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'dateCreated',
      sort: 'desc',
    },
  ])
  const [isEditParametersModalOpen, setIsEditParametersModalOpen] =
    useState(false)
  const [currentSubThreat, setCurrentSubThreat] = useState<ISubThreat>()
  const { data: parametersData } = useParametersQuery(
    {
      modelCode: currentSubThreat?.SubThreatModelCode ?? 'All',
      assetTypeId,
      PageNumber: currentPage + 1,
      PageSize: DEFAULT_PAGE_SIZE,
      TimeStampSorting: sortModel[0].sort ?? 'desc',
    },
    {
      skip: assetTypeId === -1,
      refetchOnMountOrArgChange: true,
    }
  )

  useEffect(() => {
    setSubThreats(
      likelihoodConfigurationsData
        ?.map((majorThreat) => {
          return majorThreat.subThreats
        })
        ?.flat()
        .filter((subThreat) => subThreat.isEnabled) ?? []
    )
  }, [likelihoodConfigurationsData])

  useEffect(() => {
    // Setting the initial subthreat to "All"
    setCurrentSubThreat(undefined)
  }, [subThreats, assetType])

  const handleTemplateUpload = async (e: SyntheticEvent) => {
    const formData = new FormData()
    // @ts-expect-error
    const newTemplateFile = (e.target as HTMLInputElement)?.files[0]
    formData.append('file', newTemplateFile)
    formData.append('configurationId', String(configurationId))
    formData.append('assetTypeId', String(assetTypeId))

    try {
      await uploadTemplate(formData).unwrap()
      dispatch(setSnackbar({ type: 'success', messages: 'Upload successful.' }))
      dispatch(setSnackbarIsOpen(true))
      setCurrentSubThreat(undefined)
    } catch (error: any) {
      const validationErrors = error?.data?.File
      if (validationErrors) {
        dispatch(
          setSnackbar({
            type: 'error',
            messages: error?.data?.File.flat() ?? 'File validation failed',
          })
        )
      } else {
        dispatch(
          setSnackbar({
            type: 'error',
            messages: error?.error ?? 'Could not upload file.',
          })
        )
      }
      dispatch(setSnackbarIsOpen(true))
    }

    // ensures the onChange event fires when the same file is selected again
    ;(e.target as HTMLInputElement).value = ''
  }

  const columnConfig = [
    {
      field: 'modelParameterLogId',
      flex: 0.7,
      headerName: 'Parameters ID',
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'modelName',
      flex: 1,
      headerName: 'Subthreat',
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'publishVersion',
      flex: 0.7,
      headerName: 'Publish Version',
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'dateCreated',
      flex: 1,
      headerName: 'Timestamp',
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => {
        return <>{formatTimestamp(params.row.dateCreated)}</>
      },
    },
    {
      field: 'createdByDisplayName',
      flex: 1,
      headerName: 'User',
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'parameterOptions',
      minWidth: 200,
      headerName: 'Parameter Options',
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Button
            variant="secondary"
            size="small"
            className="mr-2 w-24"
            onClick={() => {
              const logId = params.row.modelParameterLogId
              const queryParam = currentSubThreat
                ? `?modelCode=${currentSubThreat.SubThreatModelCode}`
                : ``
              handleFileDownload(
                `${env.REACT_APP_API_BASE_URL}/v1/files/configuration/likelihood/log/${logId}/assetType/${assetTypeId}${queryParam}`,
                `Parameter Log ${logId}${
                  currentSubThreat
                    ? ` - ${currentSubThreat.SubThreatModelCode}`
                    : ``
                } - ${assetType}`
              )
            }}>
            View
          </Button>
        )
      },
      sortable: false,
      disableColumnMenu: true,
    },
  ]

  return (
    <>
      <div className="tabtitle-bar">
        <h2>Parameters</h2>

        <Button
          onClick={() => {
            setIsEditParametersModalOpen(true)
          }}
          variant="primary-outlined"
          className="ml-auto"
          isDisabled={!currentSubThreat}>
          Edit Parameters
        </Button>
        <EditParametersModal
          assetTypeId={assetTypeId}
          isOpen={isEditParametersModalOpen}
          modelCode={currentSubThreat?.SubThreatModelCode ?? ''}
          onClose={() => setIsEditParametersModalOpen(false)}
          subThreatModel={currentSubThreat?.SubThreatName ?? ''}
        />
      </div>
      <div className="mt-9 mb-12 flex items-center justify-between">
        <div className="min-w-[370px]">
          <Select
            items={
              [
                ...subThreats?.map((subThreat) => ({
                  id: subThreat.modelPropertiesId,
                  label: subThreat.SubThreatName,
                })),
                {
                  id: -1,
                  label: 'All',
                },
              ] ?? []
            }
            label="Subthreat:"
            isLabelInline
            value={String(currentSubThreat?.modelPropertiesId ?? -1)}
            setValue={(value) => {
              if (value === -1) {
                setCurrentSubThreat(undefined)
              } else {
                setCurrentSubThreat(
                  subThreats?.find(
                    (subThreat) => subThreat.modelPropertiesId === value
                  )
                )
              }
            }}
          />
        </div>
        <div className="flex">
          <Button
            variant="secondary-outlined"
            className="mr-5"
            isDisabled={subThreats.length <= 0}
            onClick={() =>
              handleFileDownload(
                `${env.REACT_APP_API_BASE_URL}/v1/files/configuration/likelihood/assetType/${assetTypeId}/template`,
                `Parameters Template - ${assetType}`
              )
            }>
            Parameters Template
          </Button>
          <Button
            variant="upload"
            letterCase="normal-case"
            onChange={handleTemplateUpload}
            isDisabled={isTemplateUploading}>
            {isTemplateUploading ? 'Uploading...' : 'Upload Template...'}
          </Button>
        </div>
      </div>
      <div className="subtitle-bar">
        <h3>Parameters Log</h3>
      </div>
      <Table
        variant="data-table"
        columns={columnConfig}
        checkboxSelection={false}
        rows={parametersData?.data ?? []}
        hasPagination
        loadByPage={true}
        sortModel={sortModel}
        setSortModel={setSortModel}
        paginationObject={{
          currentPage: currentPage + 1,
          count: parametersData?.count ?? 1,
        }}
        dataReload={(page, sort) => {
          setCurrentPage(page)
          setSortModel(sort)
        }}
        getRowId={(row: IParameter) => row.modelParameterLogId}
      />
    </>
  )
}

export default ParametersLog
