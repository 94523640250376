import {
  Input,
  Select,
  Checkbox,
  Button,
  Searchbox,
  FileUploader,
} from 'common/components'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { ImportTypes, IMPORT_TYPES, TAB_ADD_NOTES, TAB_IMPORT_OPTIONS } from '.'
import {
  useGetImportOptionsQuery,
  useGetAttributesQuery,
  useCreateDataImportEntryMutation,
  useDeleteFileMutation,
  useUpdateDataImportEntryMutation,
} from 'services/apis'
import { env } from 'app/runtime-config'
import { handleFileDownload } from 'common/utils'
import {
  setSnackbarIsOpen,
  setSnackbar,
} from 'common/components/Snackbar/SnackbarDucks'
import { useAppDispatch } from 'app/hooks'
import { IDataImport } from 'services/interfaces/dataImport'
import { IUploadInfo } from 'common/interfaces'

const styles = {
  attributes: `mt-6 grid h-64 w-full auto-rows-min grid-cols-2 items-start 
  overflow-y-auto rounded-sm border-2 border-solid border-gray-200 px-8 py-2`,
}

interface IContentProps {
  currentTab: string
  updateState: any
  assetType: number
  dataImportId: number
  importType: ImportTypes
  isOverwrite: boolean
  isUploading: boolean
  notes: string
  selectedAttributes: number[]
}

const Content = ({
  currentTab,
  updateState,
  assetType,
  dataImportId,
  importType,
  isOverwrite,
  isUploading,
  notes,
  selectedAttributes,
}: IContentProps) => {
  const { NO_IMPORT_SELECTED, NEW, UPDATE } = IMPORT_TYPES
  const {
    data: importOptions,
    isLoading: isLoadingOptions,
    isFetching: isFetchingOptions,
  } = useGetImportOptionsQuery()

  const [createDataImportEntry, { data: dataImport }] =
    useCreateDataImportEntryMutation()
  const [updateDataImportEntry] = useUpdateDataImportEntryMutation()
  const [deleteFile] = useDeleteFileMutation()
  const dispatch = useAppDispatch()
  const [searchText, setSearchText] = useState('')

  // list of files selected by the user
  const [files, setFiles] = useState<File[]>([])

  const [overrideExisting, setOverrideExisting] = useState(isOverwrite)

  // keep track of all the cancel operations triggerred by the user
  const cancelingUpload = useRef<string[]>([])

  // list of files whose upload is complete and the status is
  // either 'success' or 'error'
  const [uploadedFiles, setUploadedFiles] = useState<IUploadInfo[]>([])

  const [notesIsOverLimit, setNotesIsOverLimit] = useState(false)

  const { data: attributes } = useGetAttributesQuery(assetType, {
    skip:
      assetType === -1 ||
      importType === NO_IMPORT_SELECTED ||
      importType !== UPDATE,
    refetchOnMountOrArgChange: true,
  })

  useEffect(() => {
    createDataImportEntry()
      .unwrap()
      .then((dataImport: IDataImport) => {
        updateState({ dataImportId: dataImport.dataImportId })
      })
  }, [])

  useEffect(() => {
    if (importOptions) {
      const { assetTypes, importTypes } = importOptions

      if (assetType === -1) {
        updateState({ assetType: assetTypes[0].id })
      }

      if (importType === NO_IMPORT_SELECTED) {
        updateState({ importType: importTypes[0].id })
      }
    }
  }, [importOptions])

  useEffect(() => {
    updateState({ selectedAttributes: [] })
  }, [assetType, importType])

  useEffect(() => {
    updateState({ isOverwrite: overrideExisting })
  }, [overrideExisting])

  useEffect(() => {
    if (importType === NEW) {
      // the import button should be enabled when at least
      // one file is uploaded and there are no upload errors
      updateState({
        importDisabled:
          uploadedFiles.length === 0 ||
          checkIsErrorFile(uploadedFiles) ||
          notesIsOverLimit,
      })
    } else if (importType === UPDATE) {
      // the import button should be enabled when at least
      // one file is uploaded, there are no upload errors
      // and at least one selected attribute is selected
      updateState({
        importDisabled:
          uploadedFiles.length === 0 ||
          checkIsErrorFile(uploadedFiles) ||
          selectedAttributes.length === 0 ||
          notesIsOverLimit,
      })
    }
  }, [uploadedFiles, selectedAttributes, importType, notesIsOverLimit])

  const checkIsErrorFile = (files: IUploadInfo[]): boolean => {
    const errorFile = files.find(
      (file) => file?.status?.toLowerCase() === 'error'
    )

    if (errorFile) {
      return true
    }

    return false
  }

  const downloadTemplate = () => {
    if (
      !importOptions ||
      assetType === -1 ||
      importType === NO_IMPORT_SELECTED
    ) {
      return
    }

    const { assetTypes, importTypes } = importOptions

    const importTypeLabel = importTypes.find(
      (item) => item.id === importType
    )?.label

    const assetTypeLabel = assetTypes.find(
      (item) => item.id === assetType
    )?.label

    const fileName = `Data Import Template - ${assetTypeLabel} - ${importTypeLabel}`
    const url = `${env.REACT_APP_API_BASE_URL}/v1/files/dataImport/importDataType/${assetType}/template/${importTypeLabel}`

    if (importType === NEW) {
      handleFileDownload(url, fileName)
    } else if (importType === UPDATE) {
      const body = {
        attributes: selectedAttributes.map((Id) => {
          return {
            assetFieldId: Id,
            assetFieldName: attributes?.find((attr) => attr.id === Id)?.label,
          }
        }),
      }

      handleFileDownload(url, fileName, 'POST', body)
    }
  }

  const displaySnackbarMessage = (
    type: 'error' | 'info' | 'success',
    messages: string | string[]
  ) => {
    dispatch(setSnackbar({ type, messages }))
    dispatch(setSnackbarIsOpen(true))
  }

  const handleUpload = (event: any) => {
    const fileList: File[] = Array.from(event.target.files)
    const newFiles: File[] = []
    const existingFiles: string[] = []

    // clear file input control
    event.target.value = null

    // extract list of existing file names
    const fileNames = files.map((file) => file.name)

    // pick only those files from the new list
    // that are not present in the existing list
    fileList.forEach((file) => {
      if (fileNames.includes(file.name)) {
        existingFiles.push(file.name)
      } else {
        newFiles.push(file)
      }
    })

    if (newFiles.length) {
      // add new files to the list after discarding
      // those files that already exist
      setFiles([...files, ...newFiles])
    }

    if (existingFiles.length) {
      const errorMessages = existingFiles.map(
        (fileName) =>
          `'${fileName}' already exists. Please select a file with a different name.`
      )

      displaySnackbarMessage('error', errorMessages)
    }
  }

  const renderButtons = (allowMultiple: boolean = false): ReactNode => {
    const margin = importType === NEW ? 'mt-16' : 'mt-5'

    return (
      <div className={`${margin} flex w-full flex-row space-x-5`}>
        <div className="w-1/2">
          <Button
            variant="secondary-outlined"
            className="w-full"
            onClick={downloadTemplate}
            isDisabled={
              importType === UPDATE && selectedAttributes.length <= 0
            }>
            download template
          </Button>
        </div>
        <div className="w-1/2">
          <Button
            variant="upload"
            allowMultiple={allowMultiple}
            className="flex w-full flex-row justify-between capitalize"
            onChange={handleUpload}
            isDisabled={isUploading}>
            {!isUploading ? 'select files...' : 'uploading...'}
          </Button>
        </div>
      </div>
    )
  }

  const removeFile = (fileName: string): void => {
    // remove file from actual file list
    const updatedFiles = files.filter((file) => file.name !== fileName)
    setFiles(updatedFiles)

    // remove file from uploaded list
    const filteredFiles = uploadedFiles.filter(
      (item) => item.fileName !== fileName
    )

    setUploadedFiles(filteredFiles)

    if (updatedFiles.length === filteredFiles.length) {
      updateState({ isUploading: false })
    }
  }

  const removeFromCancelingUpload = (fileName: string): void => {
    if (cancelingUpload.current.indexOf(fileName) > -1) {
      cancelingUpload.current = cancelingUpload.current.filter(
        (currFileName) => currFileName !== fileName
      )
    }
  }

  const onUploadCancel = (fileName: string, fileId: number): void => {
    if (cancelingUpload.current.indexOf(fileName) > -1) {
      return
    }

    if (dataImport && fileId !== -1) {
      cancelingUpload.current.push(fileName)
      // upload completed. Delete from azure
      deleteFile({ dataImportId: dataImport.dataImportId, fileId })
        .unwrap()
        .then(() => {
          removeFromCancelingUpload(fileName)
          removeFile(fileName)
        })
        .catch(() => {
          removeFromCancelingUpload(fileName)
          displaySnackbarMessage(
            'error',
            'Error occurred while deleting the file'
          )
        })
    } else if (fileId === -1) {
      // upload is still in progress. Operation was aborted in the
      // clean-up function of file uploader due to user action.
      removeFile(fileName)
    }
  }

  const onUploadComplete = (
    fileName: string,
    fileId: number,
    status: 'error' | 'success',
    isCancelled: boolean = false
  ): void => {
    if (isCancelled) {
      return
    }

    let updatedList: IUploadInfo[] = []
    setUploadedFiles((prevUploadedFiles: IUploadInfo[]): IUploadInfo[] => {
      updatedList = [...prevUploadedFiles, { fileName, status, fileId }]
      return updatedList
    })

    if (updatedList.length === files.length) {
      updateState({ isUploading: false })
    }
  }

  const onUploadStart = () => {
    updateState({ isUploading: true })

    const bodyParams = {
      dataImportId,
      importDataTypeId: assetType,
      importTypeId: importType,
      isOverwrite,
      notes: '',
      selectedAttributeIds: selectedAttributes,
    }

    // update data import entry
    return updateDataImportEntry(bodyParams)
  }

  const renderFileUploadStatus = (): ReactNode => {
    if (!dataImport) {
      return null
    }

    const url = `${env.REACT_APP_API_BASE_URL}/v1/files/dataImport/azureblob`
    const options = {
      configurationId: 2,
      dataImportId: dataImport.dataImportId,
    }

    return (
      <div className="mt-6 h-64 w-full overflow-y-auto rounded-sm border-2 border-solid border-gray-200">
        {files.map((file: File) => {
          return (
            <FileUploader
              key={file.name}
              options={options}
              url={url}
              file={file}
              onUploadStart={onUploadStart}
              onUploadCancel={onUploadCancel}
              onUploadComplete={onUploadComplete}
              uploadInfo={uploadedFiles.find(
                (item) => item.fileName === file.name
              )}
            />
          )
        })}
      </div>
    )
  }

  const renderNewUploadView = (): ReactNode => {
    return (
      <>
        <div className="float-right text-coolGray-400">
          <Checkbox
            label="Override Existing Assets"
            isChecked={overrideExisting}
            setIsChecked={setOverrideExisting}
          />
        </div>
        {renderButtons(true)}
        {renderFileUploadStatus()}
      </>
    )
  }

  const handleSelections = (id: number, isChecked: boolean): void => {
    if (isChecked) {
      updateState({
        selectedAttributes: selectedAttributes.filter((item) => item !== id),
      })
    } else {
      updateState({ selectedAttributes: [...selectedAttributes, id] })
    }
  }

  const renderAttributes = (): ReactNode => {
    if (attributes) {
      const filteredAttributes = attributes.filter((attribute) =>
        attribute.label.toLowerCase().includes(searchText.toLowerCase().trim())
      )

      return filteredAttributes.map((attribute) => {
        const { id, label } = attribute
        const isChecked = selectedAttributes.includes(id)

        return (
          <Checkbox
            key={id}
            label={label}
            setIsChecked={() => handleSelections(id, isChecked)}
            isChecked={isChecked}
          />
        )
      })
    }

    return null
  }

  const renderUpdateView = (): ReactNode => (
    <>
      <div className="mt-6 flex w-full flex-row items-center space-x-5">
        <div className="w-1/2 font-semibold capitalize">select attributes:</div>
        <Searchbox
          className="w-1/2"
          isLabelHidden
          label="select attributes"
          onChange={setSearchText}
          placeholder="Search Attribute"
          value={searchText}
        />
      </div>
      <div className={styles.attributes}>{renderAttributes()}</div>
      {renderButtons(true)}
      {renderFileUploadStatus()}
    </>
  )

  const resetSelectedFiles = (): Promise<any> => {
    const promises: Promise<any>[] = []

    uploadedFiles.forEach((file) => {
      const { fileId } = file

      if (dataImport && fileId !== -1) {
        promises.push(
          deleteFile({ dataImportId: dataImport.dataImportId, fileId })
        )
      }
    })

    return Promise.allSettled(promises)
  }

  // if import type changes, the uploaded files
  // are no longer valid.
  const onImportTypeChange = (id: number) => {
    resetSelectedFiles().then(() => {
      setFiles([])
      setUploadedFiles([])
      updateState({ importType: id })
    })
  }

  // if asset type changes, the uploaded files
  // are no longer valid.
  const onAssetTypeChange = (id: number) => {
    resetSelectedFiles().then(() => {
      setFiles([])
      setUploadedFiles([])
      updateState({ assetType: id })
    })
  }

  const renderView = (): ReactNode => {
    if (importType === NEW) {
      return renderNewUploadView()
    } else if (importType === UPDATE) {
      return renderUpdateView()
    }

    return null
  }

  const renderImportOptions = (): ReactNode => {
    return (
      <>
        <div className="flex flex-row space-x-5">
          <Select
            items={importOptions?.assetTypes || []}
            label="Asset Type:"
            hasRadio={true}
            value={assetType.toString()}
            setValue={(id: number) => onAssetTypeChange(id)}
          />
          <Select
            items={importOptions?.importTypes || []}
            label="Import Type:"
            hasRadio={true}
            value={importType.toString()}
            setValue={(id: number) => onImportTypeChange(id)}
          />
        </div>
        {renderView()}
      </>
    )
  }

  const renderNotes = (): ReactNode => (
    <Input
      className="mt-4"
      label="notes"
      isMultiline
      rows={18}
      isLabelHidden
      value={notes}
      onChange={(e) => {
        updateState({ notes: e.target.value })
        if (e.target.value.length > 500) {
          setNotesIsOverLimit(true)
        } else {
          setNotesIsOverLimit(false)
        }
      }}
      hasError={notesIsOverLimit}
      helperText={
        notesIsOverLimit
          ? 'Notes can contain only up to 500 characters'
          : undefined
      }
    />
  )

  const renderContent = (): ReactNode => {
    if (
      !importOptions ||
      assetType === -1 ||
      importType === NO_IMPORT_SELECTED ||
      isLoadingOptions ||
      isFetchingOptions
    ) {
      return null
    }

    if (currentTab === TAB_IMPORT_OPTIONS) {
      return renderImportOptions()
    } else if (currentTab === TAB_ADD_NOTES) {
      return renderNotes()
    }

    return null
  }

  return (
    <>
      <div className="mb-8 text-base font-bold uppercase tracking-wide text-blue-navy">
        {currentTab}
      </div>
      <div className="text-coolGray-900">{renderContent()}</div>
    </>
  )
}

export default Content
