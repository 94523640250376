import { Outlet } from 'react-router-dom'

import { Layout, LinkedTabs, Breadcrumbs } from 'common/components'

const tabs = [
  {
    label: 'Lookup Tables',
    to: '/consequence-model-config/lookup-tables',
  },
  {
    label: 'Consequence Costs',
    to: '/consequence-model-config/consequence-costs',
  },
]

const activeTabIndex = () => {
  const tabIndex = tabs.findIndex((tab) => location.pathname.includes(tab.to))

  if (tabIndex === -1) return 0
  return tabIndex
}

const ConsequenceModel = () => {
  return (
    <Layout>
      <Breadcrumbs
        items={[
          { title: 'Configuration', to: '/configuration' },
          { title: 'Risk Configuration', to: '' },
        ]}
      />
      <div className="title-bar">
        <h1>Consequence Model Configuration</h1>

        <LinkedTabs tabs={tabs} initialTab={activeTabIndex()} />
      </div>
      <div>
        <Outlet />
      </div>
    </Layout>
  )
}

export default ConsequenceModel
