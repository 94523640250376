import { ReactNode, useState, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router'
import MuiAccordion from '@mui/material/Accordion'
import {
  AccordionDetails,
  AccordionSummary,
  IconButton,
  SxProps,
} from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import { v4 as uuidv4 } from 'uuid'

interface IAccordionItem {
  label: string
  children: ReactNode
}

export interface IAccordionProps {
  /**
   * An array of objects containing `label` and `children` properties for the
   * accordion label and the JSX to be displayed upon expanding, respectively.
   */
  items: IAccordionItem[]
}

const AccordionSummaryStyles: SxProps = {
  backgroundColor: '#F7F7F7',
  color: '#707070',
  '&.Mui-expanded': {
    backgroundColor: '#82ACFF',
    color: 'white',
  },
  '.MuiAccordionSummary-content': {
    textTransform: 'uppercase',
    paddingLeft: '16px',
    fontWeight: 600,
    fontSize: '18px',
  },
  '.MuiAccordionSummary-expandIconWrapper': {
    marginRight: '16px',
  },
}

/**
 * Accordions are used to group similar content and hide or show it depending
 * on user needs or preferences. Accordions give users more granular control
 * over the interface and help digest content in stages, rather than all at once.
 */
const Accordion = ({ items }: IAccordionProps) => {
  const location = useLocation()
  const [expand, setExpand] = useState(Array(items.length).fill(false))

  // when route is changed the accordion collapses
  useEffect(() => {
    setExpand(expand.fill(false))
  }, [location])

  // Assign a unique id to each accordion item
  const itemsWithId = useMemo(() => {
    return items.map((item) => ({
      ...item,
      id: uuidv4(),
    }))
  }, [items])

  const toggleAccordion = (index: number) => {
    setExpand((prevState) => {
      const newArray = [...prevState]
      newArray[index] = !prevState[index]
      return newArray
    })
  }

  const getClassName = (index: number): string => {
    const isFirstItem: boolean = index === 0
    const isLastItem: boolean = index === items.length - 1
    const isExpanded: boolean = expand[index]
    const baseClass: string = 'h-[65px] shadow-none'
    const itemClass: string =
      'border-0 border-t-[3px] border-solid border-coolGray-300'

    if (isFirstItem) {
      return `${baseClass} rounded-t`
    } else if (isLastItem && isExpanded) {
      return `${baseClass} ${itemClass} rounded-none`
    } else if (isLastItem && !isExpanded) {
      return `${baseClass} ${itemClass} rounded-b`
    } else {
      return `${baseClass} ${itemClass}`
    }
  }

  const getIcon = (index: number): ReactNode => {
    if (expand[index]) {
      return <RemoveCircleIcon fontSize="large" className="text-white" />
    }

    return <AddCircleIcon fontSize="large" className="text-coolGray-400" />
  }

  const renderAccordions = (): ReactNode[] => {
    return itemsWithId.map((item, index) => {
      const { label, children, id } = item

      return (
        <MuiAccordion
          key={id}
          expanded={expand[index] ?? false}
          disableGutters
          className="shadow-none before:bg-transparent"
          onChange={() => toggleAccordion(index)}>
          <AccordionSummary
            className={getClassName(index)}
            expandIcon={
              <IconButton className="p-0">{getIcon(index)}</IconButton>
            }
            sx={AccordionSummaryStyles}>
            {label}
          </AccordionSummary>
          <AccordionDetails className="px-12">{children}</AccordionDetails>
        </MuiAccordion>
      )
    })
  }

  return items && items.length > 0 ? (
    <div data-testid="accordion">{renderAccordions()}</div>
  ) : null
}

export default Accordion
