import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Breadcrumbs, Layout } from 'common/components'
import { useAssetTypesQuery } from 'services/apis'
import { CreateEditScenarioForm, CreateEditScenarioFormSchemaType } from '.'

const CreateEditScenario = () => {
  const [searchParams] = useSearchParams()
  const isCreateScreen = searchParams.get('id') === '-1'
  const isViewScreen = searchParams.get('view') === '1'
  const isEditScreen = searchParams.get('id') !== '-1' && !isViewScreen

  const { data: assetTypesData } = useAssetTypesQuery()

  const [initialFormState, setInitialFormState] =
    useState<CreateEditScenarioFormSchemaType>({
      name: '',
      type: -1,
      rules: [],
    })

  useEffect(() => {
    if (assetTypesData && isCreateScreen) {
      setInitialFormState({
        name: '',
        type: -1,
        rules: assetTypesData?.map((assetType, index) => ({
          assetTypeId: assetType.id,
          mitigationRuleId: index + 1,
          conditions: [],
          outcomes: [],
        })),
      })
    }
  }, [assetTypesData])

  return (
    <Layout>
      <Breadcrumbs
        items={[
          { title: 'Configuration', to: '/configuration' },
          { title: 'Integrity Management', to: '' },
        ]}
      />
      <CreateEditScenarioForm
        isCreateScreen={isCreateScreen}
        isEditScreen={isEditScreen}
        isViewScreen={isViewScreen}
        defaultValues={initialFormState}
      />
    </Layout>
  )
}

export default CreateEditScenario
