import { Input } from 'common/components'
import { ChangeEvent, ForwardedRef, forwardRef, KeyboardEvent } from 'react'
import SearchIcon from '@mui/icons-material/Search'

interface ISearchProps {
  className?: string
  isDisabled?: boolean
  isLabelHidden?: boolean
  label: string
  placeholder?: string
  value?: string
  onChange?: (searchText: string) => void
}

const Searchbox = forwardRef(
  (
    {
      className = 'w-full',
      isDisabled = false,
      isLabelHidden = false,
      label,
      placeholder = '',
      value,
      onChange,
    }: ISearchProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e.target.value)
      }
    }

    const handleSearchKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      e.stopPropagation()

      if (!onChange) {
        return
      }

      if (e.key === 'Escape') {
        onChange('')
      }

      if (e.key === 'Enter') {
        e.preventDefault()
      }
    }

    return (
      <div className={className} onKeyDown={handleSearchKeyDown}>
        <Input
          ref={ref}
          isDisabled={isDisabled}
          isLabelHidden={isLabelHidden}
          label={label}
          placeholder={placeholder}
          suffixIcon={<SearchIcon />}
          value={value}
          onChange={handleOnChange}
        />
      </div>
    )
  }
)

Searchbox.displayName = 'Searchbox'

export default Searchbox
