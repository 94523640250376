import { ReactElement } from 'react'
import { GridRenderCellParams } from '@mui/x-data-grid'
import { IMitigationScenariosData, IRiskSummaryData } from 'services/interfaces'
import { formatDuration, formatTimestamp } from 'common/utils'
import { RegularTabs, Table, Card } from 'common/components'
import { IColumn } from 'common/interfaces'
import { useAssessmentQuery, useAssessmentErrorsQuery } from 'services/apis'

interface ISidePanelProps {
  assessmentId: number
}

const AssessmentsSidePanel = ({ assessmentId }: ISidePanelProps) => {
  const { data: assessmentData, isFetching } = useAssessmentQuery(
    assessmentId,
    {
      skip: assessmentId === -1,
    }
  )

  const { data: assessmentErrors } = useAssessmentErrorsQuery(assessmentId, {
    skip: assessmentId === -1 || isFetching || assessmentData?.status !== 3,
  })

  const detailCardValues = [
    { title: 'NAME', value: assessmentData?.name },
    { title: 'PUBLISH VERSION', value: assessmentData?.modelVersion },
    {
      title: 'TOTAL RISK (RU)',
      value: assessmentData?.totalRisk
        ? Math.round(assessmentData?.totalRisk)
        : '-',
    },
    {
      title: 'TIMESTAMP',
      value: assessmentData?.timestamp
        ? formatTimestamp(assessmentData.timestamp)
        : '-',
    },
    { title: 'USER', value: assessmentData?.user },
    {
      title: 'YEARS',
      value: `${assessmentData?.startYear} - ${assessmentData?.endYear}`,
    },
    {
      title: 'STATUS',
      value:
        assessmentData?.statusDescription !== 'InProgress'
          ? assessmentData?.statusDescription
          : 'In Progress',
    },
    {
      title: 'RUNTIME',
      value: assessmentData?.runtime
        ? formatDuration(Number(assessmentData.runtime))
        : '-',
    },
  ]

  const detailsCardNotes = assessmentData?.notes || ''

  const riskSummaryColumnConfig: IColumn[] = [
    {
      field: 'assetType',
      headerName: 'Asset Type',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'count',
      headerName: 'Count',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'length',
      headerName: 'Length',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'risk',
      headerName: 'Risk (RU)',
      valueGetter: (params: GridRenderCellParams) =>
        Math.round(params.row.risk),
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
  ]

  const mitigationScenariosColumnConfig: IColumn[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'typeDescription',
      headerName: 'Type',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'mitigatedRisk',
      headerName: 'Mitigated Risk',
      valueGetter: (params: GridRenderCellParams) =>
        Math.round(params.row.mitigatedRisk),
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
  ]

  const getTabContent = (
    rows: IMitigationScenariosData[] | IRiskSummaryData[] | undefined,
    columns: IColumn[],
    rowIdField: string
  ): ReactElement => (
    <Table
      variant="data-table"
      rows={rows || []}
      columns={columns}
      pageSize={5}
      checkboxSelection={false}
      hasPagination={true}
      getRowId={(row: any) => row[rowIdField]}
      isPageCountHidden
      rowBorderBottomColor="#E4E5E6"
    />
  )

  const tabs = [
    {
      label: 'Risk Summary',
      children: getTabContent(
        assessmentData?.riskSummaries,
        riskSummaryColumnConfig,
        'riskSummaryId'
      ),
    },
    {
      label: 'Mitigation Scenarios',
      children: getTabContent(
        assessmentData?.mitigationScenarios,
        mitigationScenariosColumnConfig,
        'mitigationScenarioId'
      ),
    },
  ]

  const hasErrors = (): boolean =>
    !assessmentData || assessmentData.status === 3

  const showErrors = (): ReactElement => (
    <div>
      <p className="mt-0 mb-4 text-center text-xl font-bold text-red-700">
        Found Errors
      </p>
      <div className="max-h-80 overflow-auto">
        {assessmentErrors &&
          assessmentErrors?.data?.map((error, index) => (
            <div key={index}>
              <h4 className="mb-2 font-semibold">{error.errorTitle}</h4>
              <p className="mt-2 mb-2">{error.errorDescription}</p>
            </div>
          ))}
        {assessmentErrors && assessmentErrors?.data?.length > 10 && (
          <div className="mt-4 font-bold text-blue-lighthouse">
            and {assessmentErrors?.count} more
          </div>
        )}
      </div>
    </div>
  )

  return (
    <>
      {assessmentId === -1 ? null : (
        <>
          <div className="sidepanel-title-bar">
            <h2>Assessment Summary</h2>
            {/*
            <Button
              variant="secondary-outlined"
              className="text-sm"
              onClick={() => alert('Will open BI Report in a new tab')}
              isDisabled={isDisabled()}>
              View Reports
            </Button>
            */}
          </div>
          <div className="sidepanel-subtitle-bar">
            <h3>Assessment Details</h3>
          </div>
          <Card entries={detailCardValues} notes={detailsCardNotes} />
          {/* TODO: Make pagination dynamic, i.e., fit within viewport without creating scrollbar */}
          {hasErrors() ? showErrors() : <RegularTabs tabs={tabs} />}
        </>
      )}
    </>
  )
}

export default AssessmentsSidePanel
