import { env } from 'app/runtime-config'
import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppState, Auth0Provider } from '@auth0/auth0-react'

const Auth0ProviderWithRedirect = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate()

  const domain = env.REACT_APP_AUTH0_DOMAIN as string
  const clientId = env.REACT_APP_AUTH0_CLIENT_ID as string
  const audience = env.REACT_APP_AUTH0_AUDIENCE as string

  const onRedirectCallback = (appState?: AppState): void => {
    navigate(appState?.returnTo ?? window.location.pathname)
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={audience}
      // auth0-react#101
      // useRefreshTokens
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderWithRedirect
