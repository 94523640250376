import { IPublishLog } from 'views/RiskConfiguration/PublishRisk/interfaces'
import { mockApi, MockApiTags } from '.'

export const publishLogsApi = mockApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllPublishLogs: builder.query<IPublishLog[], void>({
      query: () => '/publishLogs',
      providesTags: [MockApiTags.RiskConfigurations],
    }),
    getPublishLog: builder.query<IPublishLog, number>({
      query: (id: number) => `/publishLogs/${id}`,
      providesTags: [MockApiTags.RiskConfigurations],
    }),
  }),
})

export const { useGetAllPublishLogsQuery, useGetPublishLogQuery } =
  publishLogsApi
