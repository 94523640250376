import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'app/store'

interface ISnackbarProps {
  type: 'info' | 'error' | 'success'
  messages: string | string[]
  traceId?: string
}

interface ISnackbarState {
  type: 'info' | 'error' | 'success'
  isOpen: boolean
  messages: string[]
  traceId: string
}

const initialState: ISnackbarState = {
  type: 'info',
  isOpen: false,
  messages: [],
  traceId: '',
}

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSnackbarIsOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload
    },
    setSnackbar: (state, action: PayloadAction<ISnackbarProps>) => {
      state.type = action.payload.type
      if (typeof action.payload.messages === 'string') {
        state.messages = [action.payload.messages]
      } else {
        state.messages = action.payload.messages
      }
      state.traceId = action.payload.traceId
        ? action.payload.traceId
        : initialState.traceId
    },
    addSnackbarMessage: (state, action: PayloadAction<string[]>) => {
      state.messages = [...state.messages, ...action.payload]
    },
  },
})

export const { setSnackbarIsOpen, setSnackbar, addSnackbarMessage } =
  snackbarSlice.actions

// selectors
export const getSnackbar = (state: RootState) => state.snackbar

export default snackbarSlice.reducer
