import { FormEvent, useEffect, useMemo, useState } from 'react'

import {
  Button,
  Input,
  Modal,
  setSnackbar,
  setSnackbarIsOpen,
} from 'common/components'
import { containsSpecialCharacter, isFetchBaseQueryError } from 'common/utils'
import {
  useCreateScenarioMutation,
  useScenarioDetailsQuery,
} from 'services/apis'
import { useAppDispatch } from 'app/hooks'
import { IError } from 'services/interfaces'

interface ICloneModalProps {
  idToClone: number
  isOpen: boolean
  onClose: () => void
  resetPage: () => void
}

const CloneModal = ({
  idToClone,
  isOpen,
  onClose,
  resetPage,
}: ICloneModalProps) => {
  const dispatch = useAppDispatch()
  const [newScenarioNameForClone, setNewScenarioNameForClone] =
    useState<string>('')

  const [isNameRepeated, setIsNameRepeated] = useState<boolean>(false)
  const isNameEmpty = !newScenarioNameForClone.trim().length
  const [errorMessage, setErrorMessage] = useState<string>('')
  const nameHasSpecialCharacter = useMemo(
    () => containsSpecialCharacter(newScenarioNameForClone),
    [newScenarioNameForClone]
  )

  const { data: scenarioDetailData } = useScenarioDetailsQuery(idToClone, {
    skip: idToClone === -1,
  })

  const [
    cloneScenario,
    {
      error: cloningError,
      isError: cloningHasError,
      isLoading: isCloningScenario,
      isSuccess: cloningIsSuccess,
    },
  ] = useCreateScenarioMutation()

  useEffect(() => {
    if (nameHasSpecialCharacter) {
      setErrorMessage(
        'Name can only contain alphanumeric characters, spaces, and underscores.'
      )
    } else {
      setErrorMessage('')
    }
  }, [newScenarioNameForClone])

  useEffect(() => {
    if (cloningIsSuccess) {
      onClose()
      setNewScenarioNameForClone('')
      setIsNameRepeated(false)
      setErrorMessage('')
      dispatch(
        setSnackbar({
          type: 'success',
          messages: 'Scenario cloned',
        })
      )
      dispatch(setSnackbarIsOpen(true))
      resetPage()
    }
  }, [cloningIsSuccess])

  useEffect(() => {
    if (cloningError) {
      if (isFetchBaseQueryError(cloningError)) {
        const hasRepeatedName =
          (cloningError?.data as IError)?.title ===
          'Duplicated Mitigation Scenario Name'

        if (hasRepeatedName) {
          setErrorMessage((cloningError?.data as IError)?.detail)
          setIsNameRepeated(true)
        }
      }
    }
  }, [cloningHasError])

  const handleClone = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (scenarioDetailData) {
      cloneScenario({
        ...scenarioDetailData,
        name: newScenarioNameForClone,
      })
    }
  }

  const handleCloneCancel = () => {
    setNewScenarioNameForClone('')
    setErrorMessage('')
    setIsNameRepeated(false)
    onClose()
  }

  return (
    <Modal
      isBusy={isCloningScenario}
      isOpen={isOpen}
      onClose={handleCloneCancel}
      title="Scenario Option">
      <form onSubmit={handleClone}>
        <p className="mt-0 text-lg font-bold">Clone Scenario</p>
        <Input
          hasError={nameHasSpecialCharacter || isNameRepeated}
          helperText={errorMessage}
          isDisabled={isCloningScenario}
          isLabelInline
          isRequired
          onChange={(e) => {
            const value = e.target.value
            if (isNameRepeated) {
              setIsNameRepeated(false)
              setErrorMessage('')
            }

            setNewScenarioNameForClone(value)
          }}
          label="New Scenario Name:"
          placeholder="Enter Scenario Name..."
          value={newScenarioNameForClone}
        />
        <div className="mt-8 text-right">
          <Button
            className="mr-2"
            isDisabled={isCloningScenario}
            onClick={handleCloneCancel}
            variant="clear">
            Cancel
          </Button>
          <Button
            busyText="Cloning..."
            isBusy={isCloningScenario}
            isDisabled={
              nameHasSpecialCharacter || isNameRepeated || isNameEmpty
            }
            isSubmit>
            Clone
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default CloneModal
