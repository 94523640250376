import { useMemo } from 'react'
import { SxProps } from '@mui/material'
import { DataGrid, GridSortModel } from '@mui/x-data-grid'
import CustomPagination from './CustomPagination'
import { IColumn } from 'common/interfaces'

interface IDataTableProps {
  checkboxSelection: boolean
  className?: string
  columns: IColumn[]
  currentPage: number
  dataReload?: (page: number, sortModel: GridSortModel) => void
  getRowId?: any
  hasPagination?: boolean
  isPageCountHidden?: boolean
  loadByPage?: boolean
  pageSize: number
  rowBorderBottomColor?: string
  rowIdToHighlight?: number
  rows: any
  setSortModel?: (sortModel: GridSortModel) => void
  sortModel?: GridSortModel
  totalCount: number
  useAutoHeight?: boolean
}

const DataTable = ({
  checkboxSelection,
  className = '',
  columns,
  currentPage,
  dataReload = () => {},
  getRowId,
  hasPagination = true,
  isPageCountHidden = false,
  loadByPage = false,
  pageSize,
  rowBorderBottomColor = '#F2F3F4',
  rowIdToHighlight,
  rows = [],
  setSortModel = () => {},
  sortModel = [],
  totalCount,
  useAutoHeight = true,
}: IDataTableProps) => {
  const styles: SxProps = useMemo(
    () => ({
      borderWidth: '0',
      '.MuiDataGrid-cell': {
        height: '48px',
        borderBottomWidth: '2px',
        borderBottomColor: `${rowBorderBottomColor} !important`,
        fontSize: '14px/14px',
        font: 'MontSerrat',
        '&:focus': {
          outline: 'none',
        },
        '&:focus-within': {
          outline: 'none',
        },
      },
      '.MuiDataGrid-iconSeparator': { visibility: 'hidden' },
      '.MuiDataGrid-columnHeaderTitle': {
        fontSize: '16px/14px',
        fontWeight: '600',
      },
      '.MuiDataGrid-columnHeaders': {
        borderBottomWidth: '2px',
        borderBottomColor: '#D0D2D3',
        '.MuiDataGrid-columnHeader': {
          '&:focus': {
            backgroundColor: 'rgba(0,0,0,0.025)',
            outline: 'none',
          },
          '&:focus-within': {
            outline: 'none',
          },
        },
      },
      '.MuiDataGrid-footerContainer': { display: 'block' },
      '.MuiDataGrid-virtualScrollerRenderZone': {
        transform: 'none !important',
      },
    }),
    []
  )

  return (
    <DataGrid
      autoHeight={useAutoHeight}
      disableSelectionOnClick
      sortingMode={loadByPage ? 'server' : 'client'}
      sortingOrder={['desc', 'asc']}
      sortModel={sortModel}
      onSortModelChange={(newSortModel) => {
        setSortModel(newSortModel)
      }}
      sx={styles}
      className={`${className} w-full justify-center`}
      rows={rows}
      columns={columns}
      pageSize={pageSize}
      checkboxSelection={checkboxSelection}
      getRowClassName={({ id }) =>
        rowIdToHighlight === id ? 'bg-[#82ACFF33]' : ''
      }
      components={{
        Footer: () => (
          <CustomPagination
            dataReload={dataReload}
            hasPagination={hasPagination}
            isPageCountHidden={isPageCountHidden}
            loadByPage={loadByPage}
            page={currentPage}
            pageSize={pageSize}
            sortModel={sortModel}
            totalCount={totalCount}
          />
        ),
      }}
      getRowId={getRowId}
    />
  )
}

export default DataTable
