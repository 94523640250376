import { Modal } from 'common/components'
import { useReducer } from 'react'
import {
  useDeleteDataImportEntryMutation,
  useInitiateDataImportMutation,
  useUpdateDataImportEntryMutation,
} from 'services/apis'
import {
  setSnackbarIsOpen,
  setSnackbar,
} from 'common/components/Snackbar/SnackbarDucks'
import { useAppDispatch } from 'app/hooks'
import { Content, Footer, ImportTypes, Sidebar } from '.'

interface IImportDataModalProps {
  isOpen: boolean
  onClose: (refreshPage: boolean) => void
}

interface IState {
  selectedTab: string
  assetType: number
  importType: ImportTypes
  isOverwrite: boolean
  isUploading: boolean
  selectedAttributes: number[]
  dataImportId: number
  notes: string
  importDisabled: boolean
}

interface IAction {
  type: 'update'
  value: Partial<IState>
}

const initialState: IState = {
  selectedTab: 'import options',
  assetType: -1,
  importType: -1,
  isOverwrite: false,
  isUploading: false,
  selectedAttributes: [],
  dataImportId: -1,
  notes: '',
  importDisabled: true,
}

function reducer(state: IState, action: IAction) {
  if (action.type === 'update') {
    return { ...state, ...action.value }
  }

  return state
}

const ImportDataModal = ({ isOpen, onClose }: IImportDataModalProps) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const appDispatch = useAppDispatch()
  const [deleteDataImportEntry] = useDeleteDataImportEntryMutation()
  const [initiateDataImport] = useInitiateDataImportMutation()
  const [updateDataImportEntry] = useUpdateDataImportEntryMutation()

  const updateState = (newState: Partial<IState>) => {
    dispatch({ type: 'update', value: newState })
  }

  const closeModal = (refreshPage: boolean = false) => {
    updateState(initialState)
    onClose(refreshPage)
  }

  const handleModalClose = () => {
    if (state.dataImportId !== -1) {
      deleteDataImportEntry(state.dataImportId).then(() => {
        closeModal()
      })
    } else {
      closeModal()
    }
  }

  const displaySnackbarMessage = (
    type: 'error' | 'info' | 'success',
    messages: string | string[],
    traceId?: string
  ) => {
    appDispatch(setSnackbar({ type, messages, traceId }))
    appDispatch(setSnackbarIsOpen(true))
  }

  const handleImport = () => {
    const {
      assetType,
      dataImportId,
      importType,
      notes,
      isOverwrite,
      selectedAttributes,
    } = state

    if (dataImportId !== -1) {
      const infoMsg = `Importing data. Please don't close the modal.`
      displaySnackbarMessage('info', infoMsg)

      const bodyParams = {
        dataImportId,
        importDataTypeId: assetType,
        importTypeId: importType,
        isOverwrite,
        notes,
        selectedAttributeIds: selectedAttributes,
      }

      // update data import entry
      updateDataImportEntry(bodyParams)
        .unwrap()
        .then(() => {
          // initiate data import task
          initiateDataImport(dataImportId)
            .unwrap()
            .then(() => {
              displaySnackbarMessage('success', 'Import completed successfully')
              closeModal(true)
            })
        })
    } else {
      displaySnackbarMessage(
        'error',
        'Error in importing data. Unable to fetch data import id.'
      )
    }
  }

  const onTabChange = (tabName: string) => {
    if (state.isUploading) {
      displaySnackbarMessage('info', 'Please wait for the upload to complete')
    } else {
      updateState({ selectedTab: tabName })
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      title="Import Data"
      onClose={handleModalClose}
      sidebarContent={
        <Sidebar
          currentTab={state.selectedTab}
          onTabChange={(tabName: string) => onTabChange(tabName)}
        />
      }
      footerContent={
        <Footer
          onCancel={handleModalClose}
          onImport={handleImport}
          importDisabled={state?.importDisabled || state?.isUploading}
        />
      }>
      <Content
        currentTab={state.selectedTab}
        updateState={updateState}
        assetType={state.assetType}
        importType={state.importType}
        dataImportId={state.dataImportId}
        isOverwrite={state.isOverwrite}
        isUploading={state.isUploading}
        notes={state.notes}
        selectedAttributes={state.selectedAttributes}
      />
    </Modal>
  )
}

export default ImportDataModal
