import z from 'zod'
import { FieldPath } from 'react-hook-form'

const UnitConfigurationFormSchema = z.object({
  storage: z
    .string({ required_error: 'Enter storage unit' })
    .min(1, { message: 'Enter storage unit' }),
  largeDisplay: z
    .string({ required_error: 'Enter large display unit' })
    .min(1, { message: 'Enter large display unit' }),
  smallDisplay: z
    .string({ required_error: 'Enter small display unit' })
    .min(1, { message: 'Enter small display unit' }),
  smallConversionRate: z
    .number({
      required_error: 'Enter a conversion rate',
    })
    .nonnegative({ message: 'Rate cannot be less than 0' }),

  storageConversionRate: z
    .number({
      required_error: 'Enter a conversion rate',
    })
    .nonnegative({ message: 'Rate cannot be less than 0' }),
  threshold: z
    .number({ required_error: 'Enter a threshold value' })
    .nonnegative({ message: 'Threshold cannot be less than 0' }),
  diameter: z.string().optional(),
  operatingPressure: z.string().optional(),
  wallThickness: z.string().optional(),
  riskUnitDisplay: z.number({ required_error: 'Select risk unit display' }),
  dollarConversionRate: z
    .number({
      required_error: 'Enter a conversion rate',
    })
    .nonnegative({ message: 'Rate cannot be less than 0' }),
  normalizedLengthDisplay: z.number({
    required_error: 'Select normalized length display',
  }),
  costPerLengthDisplay: z.number({
    required_error: 'Select cost per length display',
  }),
})

type UnitConfigurationFormSchemaType = z.infer<
  typeof UnitConfigurationFormSchema
>

type PathType = FieldPath<UnitConfigurationFormSchemaType>

export type { UnitConfigurationFormSchemaType, PathType }
export default UnitConfigurationFormSchema
