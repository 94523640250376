import { FormEvent, useEffect } from 'react'
import {
  Button,
  Modal,
  setSnackbar,
  setSnackbarIsOpen,
} from 'common/components'
import { useDeleteScenarioMutation } from 'services/apis'
import { useAppDispatch } from 'app/hooks'

interface IDeleteModalProps {
  idToDelete: number
  isOpen: boolean
  onClose: () => void
}

const DeleteModal = ({ idToDelete, isOpen, onClose }: IDeleteModalProps) => {
  const dispatch = useAppDispatch()

  const [
    deleteScenario,
    { isLoading: isDeletingScenario, isSuccess: deletingIsSuccess },
  ] = useDeleteScenarioMutation()

  useEffect(() => {
    if (deletingIsSuccess) {
      onClose()
      dispatch(
        setSnackbar({
          messages: 'Scenario deleted.',
          type: 'success',
        })
      )
      dispatch(setSnackbarIsOpen(true))
    }
  }, [deletingIsSuccess])

  const handleDelete = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    deleteScenario(idToDelete)
  }

  return (
    <Modal
      isBusy={isDeletingScenario}
      isOpen={isOpen}
      onClose={onClose}
      title="Scenario Option">
      <form onSubmit={handleDelete}>
        <p className="mt-0 text-lg font-bold text-red-700">Delete Scenario</p>
        <p>Are you sure you want to delete this scenario?</p>
        <div className="mt-8 text-right">
          <Button
            className="mr-2"
            isDisabled={isDeletingScenario}
            onClick={onClose}
            variant="clear">
            Cancel
          </Button>
          <Button busyText="Deleting..." isBusy={isDeletingScenario} isSubmit>
            Delete
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default DeleteModal
