import { CreateEditScenarioFormSchemaType } from './CreateEditScenario'

type outcomeDictionary = {
  [key: string]: string[]
}
export const defaultOutcomeFields: outcomeDictionary = {
  Replacement: [
    'Install date',
    'Material',
    'Pipe Size NPS',
    'Small Diameter Fraction',
    'Operating Pressure',
    'Coating Type',
    'Wall thickness',
    'Depth of cover',
    'Leak Survey Frequency',
    'Watch and Protect Indicator',
  ],
  'Watch & Protect': ['Watch and Protect Indicator'],
  'Leak Survey': ['Leak survey frequency'],
}

export const validateAssetRules = (
  rules: CreateEditScenarioFormSchemaType['rules']
) => {
  return rules.every((rule, ruleIndex) => {
    const isCatchAllRule = ruleIndex === 0

    return (
      rule.conditions.every((condition) => {
        if (isCatchAllRule) {
          return condition.assetFieldId !== -1
        } else {
          return (
            condition.value !== '' ||
            (condition.fromInterval !== '' &&
              condition.fromInterval !== 'Invalid Date' &&
              condition.toInterval !== '' &&
              condition.toInterval !== 'Invalid Date' &&
              condition.assetFieldId !== -1)
          )
        }
      }) &&
      rule.conditions.length !== 0 &&
      rule.outcomes.every(
        (outcome) => outcome.value !== '' && outcome.value !== 'Invalid Date'
      )
    )
  })
}

export const removeEmptyConditionSelectors = (
  data: CreateEditScenarioFormSchemaType
) => {
  return {
    ...data,
    rules: data.rules.map((rule) => {
      return {
        ...rule,
        conditions: rule.conditions.filter(
          (condition) => condition.assetFieldId !== -1
        ),
        outcomes: [...rule.outcomes],
      }
    }),
  }
}
