import { Outlet } from 'react-router-dom'
import { AssetTypeLinkedTabs } from 'common/components'
import { useAssetTypesQuery } from 'services/apis'

const ConsequenceCosts = () => {
  const { data: assetTypes, isSuccess } = useAssetTypesQuery()

  return isSuccess ? (
    <div className="mb-20">
      <div className="flex">
        <AssetTypeLinkedTabs
          tabs={
            assetTypes?.map((assetType) => ({
              label: assetType.label,
              to: assetType.label.toLowerCase(),
            })) ?? []
          }
        />
      </div>
      <Outlet />
    </div>
  ) : (
    <></>
  )
}

export default ConsequenceCosts
