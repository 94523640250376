import z from 'zod'

const ConfigSchema = z.object({
  selectedAttributeIds: z.array(z.number()),
  conditions: z.array(
    z.object({
      fieldId: z.number().nullable(),
      values: z.string(),
    })
  ),
})

type ConfigSchemaType = z.infer<typeof ConfigSchema>

export type { ConfigSchemaType }
export default ConfigSchema
