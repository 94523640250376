import { baseApi } from '.'
import {
  IApplicabilityFilter,
  IAssetField,
  ICovariateCoefficients,
  ILikelihoodAttributes,
  ILikelihoodConfiguration,
  IParametersLogs,
  ISubThreatAttributes,
  ISubThreatParameters,
} from 'services/interfaces'

export const likelihoodApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    likelihoodConfigurations: builder.query<ILikelihoodConfiguration[], number>(
      {
        query: (assetTypeId) =>
          `/v1/likelihoodConfigurations?assetTypeId=${assetTypeId}`,
        providesTags: ['Likelihood'],
      }
    ),
    updateIsEnabled: builder.mutation<
      null,
      { modelCode: string; assetTypeId: number; body: { isEnabled: boolean } }
    >({
      query: ({ body, ...params }) => ({
        url: `/v1/likelihoodConfigurations/subthreat`,
        params,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Likelihood'],
    }),
    parameters: builder.query<
      IParametersLogs,
      {
        modelCode?: string
        assetTypeId: number
        PageNumber: number
        PageSize: number
        TimeStampSorting?: 'desc' | 'asc'
      }
    >({
      query: (arg) => {
        const params = { ...arg }
        if (arg.modelCode === 'All') {
          // remove modelCode from request to query all modelCodes
          delete params.modelCode
        }
        return {
          url: `/v1/likelihoodParametersLogs`,
          params,
        }
      },
      providesTags: ['Likelihood'],
    }),
    createParameterLogId: builder.mutation<{ newLogId: number }, void>({
      query: () => ({
        url: `/v1/likelihoodParametersLogs`,
        method: 'POST',
      }),
    }),
    subThreatParameters: builder.query<
      ISubThreatParameters,
      { modelCode: string; assetTypeId: number }
    >({
      query: (params) => ({
        url: `/v1/parameters/likelihood`,
        params,
      }),
      providesTags: ['Likelihood'],
    }),
    updateSubThreatParameters: builder.mutation<void, ISubThreatParameters>({
      query: (body) => ({
        url: `/v1/parameters/likelihood`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Likelihood'],
    }),
    subThreatAttributes: builder.query<
      ISubThreatAttributes,
      {
        modelCode: string
        assetTypeId: number
      }
    >({
      query: (params) => ({
        url: `/v1/attributes/likelihood`,
        params,
      }),
      providesTags: ['Likelihood'],
    }),
    covariateCoefficients: builder.query<
      ICovariateCoefficients,
      {
        modelCode: string
        assetTypeId: number
      }
    >({
      query: (params) => ({
        url: `/v1/covariateCoefficients/likelihood`,
        params,
      }),
      providesTags: ['Likelihood'],
    }),
    updateCovariateCoefficients: builder.mutation<void, ICovariateCoefficients>(
      {
        query: (body) => ({
          url: `/v1/covariateCoefficients/likelihood`,
          method: 'POST',
          body,
        }),
        invalidatesTags: ['Likelihood'],
      }
    ),
    likelihoodAttributes: builder.query<
      ILikelihoodAttributes,
      { assetTypeId: number; modelCode: string }
    >({
      query: (params) => ({
        url: `/v1/attributes/likelihood`,
        params,
      }),
      providesTags: ['Likelihood'],
    }),
    updateLikelihoodAttributes: builder.mutation<
      void,
      { assetTypeId: number; modelCode: string; body: ILikelihoodAttributes }
    >({
      query: ({ body, ...params }) => ({
        url: `/v1/attributes/likelihood`,
        params,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Likelihood'],
    }),
    assetFields: builder.query<IAssetField[], number>({
      query: (assetTypeId) =>
        `/v1/assetlookups/assetFields?assetTypeId=${assetTypeId}`,
    }),
    applicabilityFilters: builder.query<
      IApplicabilityFilter[],
      { assetTypeId: number; modelCode: string }
    >({
      query: (params) => ({
        url: `/v1/applicabilityFilters`,
        params,
      }),
      transformResponse: (response: any) => {
        return response.applicabilityFilters
      },
      providesTags: ['Likelihood'],
    }),
    updateApplicability: builder.mutation<
      void,
      {
        assetTypeId: number
        modelCode: string
        body: { applicabilityFilters: IApplicabilityFilter[] }
      }
    >({
      query: ({ body, ...params }) => ({
        url: `/v1/applicabilityFilters`,
        params,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Likelihood'],
    }),
    uploadParametersTemplate: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: `/v1/files/likelihood/azureblob`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: ['Likelihood'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useLikelihoodConfigurationsQuery,
  useUpdateIsEnabledMutation,
  useLikelihoodAttributesQuery,
  useUpdateLikelihoodAttributesMutation,
  useAssetFieldsQuery,
  useParametersQuery,
  useSubThreatParametersQuery,
  useUpdateSubThreatParametersMutation,
  useSubThreatAttributesQuery,
  useCovariateCoefficientsQuery,
  useUpdateCovariateCoefficientsMutation,
  useCreateParameterLogIdMutation,
  useApplicabilityFiltersQuery,
  useUpdateApplicabilityMutation,
  useUploadParametersTemplateMutation,
} = likelihoodApi
