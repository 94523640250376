import MuiButton from '@mui/material/Button'
import { IButtonProps } from 'common/interfaces'
import { useSize, useVariant } from 'common/hooks'

const DefaultButton = ({
  busyText = '',
  children,
  className = '',
  color = 'coolGray-500',
  isBusy = false,
  isDisabled,
  isSubmit,
  letterCase = 'uppercase',
  onClick,
  prefixIcon,
  size = 'medium',
  stretch = false,
  suffixIcon,
  variant = 'primary',
}: Partial<IButtonProps>) => {
  const variantClasses: string = useVariant(variant, color)
  const textSize: string = useSize(size)
  const classList: string = `${className} ${variantClasses} ${letterCase} ${textSize}`

  return (
    <MuiButton
      className={`${classList} px-5 tracking-wide disabled:bg-gray-200 disabled:text-coolGray-400 disabled:shadow-none`}
      fullWidth={stretch}
      size={size}
      onClick={onClick}
      disabled={isDisabled || isBusy}
      startIcon={prefixIcon}
      endIcon={suffixIcon}
      type={isSubmit ? 'submit' : 'button'}>
      {isBusy ? busyText : children}
    </MuiButton>
  )
}

export default DefaultButton
