import { ReactNode, useMemo, useState } from 'react'
import { Tabs as MuiTabs, Tab as MuiTab, SxProps } from '@mui/material'
import Box from '@mui/material/Box'

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

interface IRegularTab {
  label: string
  children: ReactNode
}

interface IRegularTabsProps {
  /**
   * An array of objects containing `label` and `children` properties for the
   * tab label and the JSX to be displayed, respectively.
   */
  tabs: IRegularTab[]
}

const tabsSx: SxProps = {
  '.MuiTabs-indicator': {
    display: 'none',
  },
}

/**
 * Tabs that display the provided JSX.
 */
function RegularTabs({ tabs }: IRegularTabsProps) {
  const [value, setValue] = useState(0)
  const tabWidth = 100 / tabs.length

  const muiTabSx: SxProps = useMemo(
    () => ({
      backgroundColor: '#F2F7FF',
      transition: 'background-color .2s',
      textTransform: 'none',
      '&.MuiButtonBase-root': {
        width: `${tabWidth}%`,
      },
      '&.Mui-selected': {
        backgroundColor: '#1C355E',
        color: 'white',
      },
      '&.Mui-focusVisible': {
        outline: `5px auto ${
          navigator.userAgent.match(/firefox|fxios/i)
            ? 'Highlight'
            : '-webkit-focus-ring-color'
        }`,
      },
      '&:not(.Mui-selected):hover': {
        backgroundColor: '#e5efff',
      },
      '&:not(.Mui-selected):active': {
        backgroundColor: '#d6e6ff',
      },
    }),
    [tabWidth]
  )

  return (
    <Box sx={{ width: '100%' }}>
      <MuiTabs
        sx={tabsSx}
        value={value}
        onChange={(_, index) => setValue(index)}>
        {tabs.map((tab, i) => (
          <MuiTab
            disableRipple
            key={i}
            label={tab.label}
            className="min-h-[40px] min-w-[150px] p-2 px-6"
            sx={muiTabSx}
            {...a11yProps(i)}
          />
        ))}
      </MuiTabs>
      {tabs.map((tab, index) => (
        <div key={index} hidden={value !== index}>
          {tab.children}
        </div>
      ))}
    </Box>
  )
}

export default RegularTabs
