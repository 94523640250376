import { useMemo } from 'react'
import { SxProps } from '@mui/material'
import { DataGrid, GridSortModel } from '@mui/x-data-grid'
import CustomPagination from './CustomPagination'
import { IColumn } from 'common/interfaces'

interface IConfigTableProps {
  checkboxSelection: boolean
  className?: string
  columns: IColumn[]
  currentPage: number
  dataReload?: (page: number, sortModel: GridSortModel) => void
  getRowId?: any
  hasPagination?: boolean
  isPageCountHidden?: boolean
  loadByPage?: boolean
  pageSize: number
  rows: any
  setSortModel?: (sortModel: GridSortModel) => void
  sortModel?: GridSortModel
  totalCount: number
  useAutoHeight?: boolean
}

const ConfigTable = ({
  checkboxSelection,
  className = '',
  columns,
  currentPage,
  dataReload = () => {},
  getRowId,
  hasPagination = true,
  isPageCountHidden = false,
  loadByPage = false,
  pageSize,
  rows = [],
  setSortModel = () => {},
  sortModel = [],
  totalCount,
  useAutoHeight = true,
}: IConfigTableProps) => {
  const styles: SxProps = useMemo(
    () => ({
      borderWidth: '0',
      '.MuiDataGrid-cell': {
        paddingLeft: '24px',
        borderBottomWidth: '2px',
        borderBottomColor: 'transparent',
        '&:focus': {
          outline: 'transparent !important',
        },
        ':focus-within': {
          outline: 'transparent !important',
        },
      },
      '.MuiDataGrid-row': {
        width: '98%',
        border: '2px',
        borderColor: 'red',
        borderRadius: 'defaultBorderRadius',
        marginTop: '8px',
        marginBottom: '8px',
        boxShadow: '0px 0px 8px #00000026',
        '&:hover': {
          bgcolor: 'transparent',
        },
      },
      '.MuiDataGrid-iconSeparator': { visibility: 'hidden' },
      '.MuiDataGrid-columnHeaders': {
        borderBottomWidth: '0',
        paddingLeft: '20px',
        paddingBottom: '0 !important',
        height: '48px',
      },
      '.MuiDataGrid-columnHeaderTitle': {
        fontSize: '16px/14px',
        fontWeight: '600',
        color: '#929497',
      },
      '.MuiDataGrid-footerContainer': { display: 'block' },
      '.MuiDataGrid-virtualScrollerContent': {
        margin: '5px',
        height: `calc(${pageSize}*70px) !important`,
      },
      '.MuiDataGrid-virtualScrollerRenderZone': {
        transform: 'none !important',
      },
    }),
    []
  )

  return (
    <DataGrid
      autoHeight={useAutoHeight}
      disableSelectionOnClick
      sortingMode={loadByPage ? 'server' : 'client'}
      sortingOrder={['desc', 'asc']}
      sortModel={sortModel}
      onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
      sx={styles}
      className={`${className} w-full justify-center`}
      rows={rows}
      columns={columns}
      pageSize={pageSize}
      checkboxSelection={checkboxSelection}
      components={{
        Footer: () => (
          <CustomPagination
            dataReload={dataReload}
            hasPagination={hasPagination}
            isPageCountHidden={isPageCountHidden}
            loadByPage={loadByPage}
            page={currentPage}
            pageSize={pageSize}
            sortModel={sortModel}
            totalCount={totalCount}
          />
        ),
      }}
      getRowId={getRowId}
    />
  )
}

export default ConfigTable
