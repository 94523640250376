/**
 * Convert ISO date-time string that we receive from API (for
 * timestamps) to the format that is required to display on screen
 * for LH-DIMP
 *
 * @param timestamp ISO date-time string
 * @returns date-time string in the following format: `07/19/2022, 2:59 PM`
 */
function formatTimestamp(timestamp: string): string {
  const formattedTimestamp = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
  }).format(new Date(timestamp))

  return formattedTimestamp
}

export default formatTimestamp
