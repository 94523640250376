import { FormEvent, useEffect, useState } from 'react'
import { Modal } from 'common/components'
import { Content, Sidebar, Footer } from '.'

export interface ILocationLevel {
  level: number
  name: string
}

export interface IProbabilityConfigModal {
  isOpen: boolean
  onClose: () => void
  data: any
  locationLevels: ILocationLevel[]
}

const MOCKDATA_ATTRIBUTES = [
  {
    id: 0,
    name: 'Location Category',
  },
  {
    id: 1,
    name: 'Operating Pressure',
  },
  {
    id: 2,
    name: 'Usage Type',
  },
]

const ProbabilityConfigModal = ({
  isOpen,
  onClose,
  data,
  locationLevels,
}: IProbabilityConfigModal) => {
  const [selectedAttributeIds, setSelectedAttributeIds] = useState<number[]>([])
  const [selectedLocationLevel, setSelectedLocationLevel] = useState<
    number | null
  >(0)

  const applyInitialData = () => {
    if (data.attributes.length === 1 && data.attributes[0] === null) {
      setSelectedAttributeIds([])
    } else {
      setSelectedAttributeIds(
        data.attributes.map((attribute: any) => attribute.id)
      )
    }

    setSelectedLocationLevel(data.selectedLocationLevel)
  }

  useEffect(() => {
    applyInitialData()
  }, [data])

  const handleClose = () => {
    applyInitialData()
    onClose()
  }

  const handleSave = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    // Save logic here
  }

  return (
    <form onSubmit={handleSave}>
      <Modal
        isOpen={isOpen}
        onClose={handleClose}
        title="Lookup Table Configuration"
        sidebarContent={<Sidebar tableType={data.tableType} />}
        footerContent={<Footer onCancel={handleClose} />}
        className="h-[650px]">
        <Content
          attributes={MOCKDATA_ATTRIBUTES}
          selectedAttributeIds={selectedAttributeIds}
          setSelectedAttributeIds={setSelectedAttributeIds}
          locationLevels={locationLevels}
          selectedLocationLevel={selectedLocationLevel}
          setSelectedLocationLevel={setSelectedLocationLevel}
        />
      </Modal>
    </form>
  )
}

export default ProbabilityConfigModal
