import { useEffect, useState } from 'react'

interface ISizeMap {
  small: string
  medium: string
  large: string
}

const sizeMap: ISizeMap = {
  small: 'text-sm',
  medium: '',
  large: 'text-lg',
}

export function useSize(size: string): string {
  const [textSize, setTextSize] = useState<string>('')

  useEffect(() => {
    setTextSize(sizeMap[size as keyof ISizeMap])
  }, [size])

  return textSize
}
