import { v4 as uuidv4 } from 'uuid'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SxProps,
} from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
import {
  RadioButtonCheckedOutlined as RadioChecked,
  RadioButtonUncheckedOutlined as RadioUnchecked,
} from '@mui/icons-material'
import TransitionComponent from '@mui/material/Fade'
import { ISelectItem } from 'common/interfaces'
import { FocusEventHandler, forwardRef, ReactNode, useMemo } from 'react'

interface ISelectProps {
  /**
   * className applied to the root element. Limit usage for special cases only.
   */
  className?: string
  /**
   * An array of objects containing id and label properties for the item id and the label, respectively.
   */
  items: ISelectItem[]
  /**
   * The current selected option.
   */
  value?: string
  /**
   * If component is not controlled.
   */
  defaultValue?: string
  /**
   * It is absolutely required to put a value here for accessibility compliance. If you
   * don't want it displayed on screen, you can set the `isLabelHidden` prop to `true`.
   */
  label: string
  /**
   * Hides the label. The `label` prop will still be required for assistive technologies.
   */
  isLabelHidden?: boolean
  /**
   * Displays the label and input box in one line.
   */
  isLabelInline?: boolean
  /**
   * Displays the label in a bold font weight.
   */
  isLabelBold?: boolean
  /**
   * Callback that triggers when value is changed.
   * @param value New value to set to.
   */
  setValue?: (value: any) => void
  /**
   * Displays radio buttons beside the options in the dropdown. Purely aesthetic.
   */
  hasRadio?: boolean
  /**
   * Set size.
   */
  size?: 'small' | 'medium'
  /**
   * The label text and border of the Select input is displayed as red if true.
   */
  hasError?: boolean
  /**
   * Helper text that is displayed below the Select input box. Is usually filled when `hasError` is set to true to provide additional context.
   */
  helperText?: string
  /**
   * Disables the input.
   */
  isDisabled?: boolean
  /**
   * Determines if input is required.
   */
  isRequired?: boolean
  /**
   * The input is focusable but the value cannot be changed.
   */
  isReadOnly?: boolean
  /**
   * With a series of Select components, enabling `noDuplicate` will remove previously
   * selected options from the dropdown menu based on the items in `prevSelected`
   */
  noDuplicate?: boolean
  /**
   * Callback that triggers when focus is shifted away.
   */
  onBlur?: FocusEventHandler<HTMLInputElement>
  /**
   * Ids that have already been selected.
   */
  prevSelected?: number[]
}

const uncheckedSx: SxProps = {
  '.Mui-selected &': {
    display: 'none',
  },
}

const checkedSx: SxProps = {
  '.Mui-selected &': {
    display: 'inline-block !important',
    color: '#82ACFF',
  },
}

/**
 * Form select component used to select from group of options in a form.
 */
const Select = forwardRef<HTMLSelectElement, ISelectProps>(
  (
    {
      className = '',
      items,
      value,
      defaultValue,
      label,
      isLabelHidden = false,
      isLabelInline = false,
      isLabelBold = false,
      setValue,
      hasRadio = false,
      size = 'medium',
      hasError = false,
      helperText,
      isDisabled = false,
      isRequired = false,
      isReadOnly = false,
      noDuplicate = false,
      onBlur,
      prevSelected = [],
    },
    ref
  ) => {
    const labelId = uuidv4()
    const inputId = uuidv4()

    const handleChange = (event: SelectChangeEvent) => {
      setValue && setValue(event.target.value)
    }

    const formSx: SxProps = useMemo(
      (): SxProps => ({
        '.MuiSelect-icon': {
          display: isReadOnly ? 'none' : 'block',
        },
        '.MuiInputBase-root': {
          width: '100%',
          padding: '4px 0 5px',
          boxShadow: '0 0 0 1px #D0D2D3',
          transition: 'box-shadow .150s',
          '&:not(.Mui-disabled):hover': {
            boxShadow: '0 0 0 1px #A6A8AB',
          },
          '&.Mui-focused': {
            boxShadow: '0 0 0 2px #2563eb !important',
          },
          '&.Mui-disabled': {
            backgroundColor: '#F2F3F4',
          },
          '&.Mui-error': {
            boxShadow: '0 0 0 2px #B70000 !important',
          },
        },
        '.MuiInputLabel-root': {
          position: 'static',
          transform: 'none',
          '&.Mui-disabled': {
            color: '#929497 !important',
          },
          '&.Mui-error': {
            color: '#B70000 !important',
          },
        },
        '.MuiSelect-select': {
          svg: {
            display: 'none',
          },
        },
        fieldset: {
          display: 'none',
        },
      }),
      [isReadOnly]
    )

    const inputClass: string = useMemo(
      (): string =>
        `overflow-visible text-coolGray-900 ${size === 'small' && 'text-sm'} ${
          isLabelHidden && 'sr-only'
        } ${isLabelInline ? 'mb-0 mr-4 flex items-center' : 'mb-2'} ${
          isLabelBold ? 'font-bold' : 'font-semibold'
        }`,
      [size, isLabelHidden, isLabelInline, isLabelBold]
    )

    const renderRadios = () => {
      if (hasRadio) {
        return (
          <>
            <RadioUnchecked
              fontSize={size}
              className="uncheckedIcon"
              sx={uncheckedSx}
            />
            <RadioChecked
              fontSize={size}
              className="checkedIcon"
              sx={checkedSx}
            />
          </>
        )
      }

      return null
    }

    const renderItems = (): ReactNode => {
      return items.map(({ id, label }) => {
        const menuItemSx: SxProps = {
          fontSize: size === 'small' ? '0.875rem' : '1rem',
          display:
            (noDuplicate && !prevSelected.includes(id)) || !noDuplicate
              ? 'block'
              : 'none',
          svg: {
            mr: '7px',
            color: '#00000099',
            verticalAlign: 'text-bottom',
            fontSize: '1.2rem',
            '&.checkedIcon': {
              display: 'none',
            },
          },
        }

        return (
          <MenuItem sx={menuItemSx} value={id} key={id} disableRipple>
            {renderRadios()}
            {label}
          </MenuItem>
        )
      })
    }

    const getComponentProps = () => ({
      input: {
        className: `${size === 'medium' ? 'py-1 pl-3' : 'py-0 pl-2 text-sm'}`,
      },
    })

    return (
      <FormControl
        disabled={isDisabled}
        fullWidth
        error={hasError}
        className={isLabelInline ? 'flex-row' : ''}
        sx={formSx}>
        <InputLabel shrink id={labelId} className={inputClass}>
          {label}
        </InputLabel>

        <div className="relative w-full">
          <MuiSelect
            displayEmpty
            readOnly={isReadOnly}
            required={isRequired}
            labelId={labelId}
            id={inputId}
            inputRef={ref}
            value={value}
            defaultValue={defaultValue}
            onBlur={onBlur}
            onChange={handleChange}
            className={`${className} rounded-sm`}
            componentsProps={getComponentProps()}
            MenuProps={{ TransitionComponent }}>
            <MenuItem sx={{ display: 'none' }} value="" disableRipple>
              <span className="text-coolGray-400">Select</span>
            </MenuItem>
            {renderItems()}
          </MuiSelect>
          <FormHelperText className="ml-0 text-xs font-semibold">
            {helperText}
          </FormHelperText>
        </div>
      </FormControl>
    )
  }
)

Select.displayName = 'Select'

export default Select
