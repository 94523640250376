import { SyntheticEvent, useState } from 'react'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { env } from 'app/runtime-config'
import { Button } from 'common/components'
import { formatTimestamp, handleFileDownload } from 'common/utils'
import {
  useUploadTemplateMutation,
  useAssetTypesQuery,
  useCostFilesQuery,
} from 'services/apis'
import { useParams } from 'react-router-dom'
import { ICostFileMeta } from 'services/interfaces'
import {
  setSnackbarIsOpen,
  setSnackbar,
} from 'common/components/Snackbar/SnackbarDucks'
import { useDispatch } from 'react-redux'

const CostCard = ({
  filePath,
  fileName,
  uploadedOn,
  uploadedBy,
}: ICostFileMeta) => {
  return (
    <div className="mb-[30px] flex justify-between [overflow-wrap:anywhere]">
      <div className="flex-3 pr-4">
        <h4 className="m-0 text-[14px] font-semibold text-coolGray-400">
          File Name:
        </h4>
        <p className="m-0 text-[14px]">{fileName}</p>
      </div>
      <div className="flex-2 pr-4">
        <h4 className="m-0 text-[14px] font-semibold text-coolGray-400">
          Updated:
        </h4>
        <p className="m-0 text-[14px]">{formatTimestamp(uploadedOn)}</p>
      </div>
      <div className="flex-2 pr-4">
        <h4 className="m-0 text-[14px] font-semibold text-coolGray-400">
          User:
        </h4>
        <p className="m-0 text-[14px]">{uploadedBy}</p>
      </div>
      <Button
        className="self-start"
        variant="secondary-outlined"
        onClick={() => handleFileDownload(filePath, fileName)}>
        view
      </Button>
    </div>
  )
}

const CostValuesOutlet = () => {
  const dispatch = useDispatch()
  const { assetType } = useParams()
  const { data: assetTypesData } = useAssetTypesQuery()
  // @TODO: replace hardcoded value with query to endpoint when ready
  const configurationId = 11
  const assetTypeId =
    assetTypesData?.find(
      (assetTypeData) =>
        assetTypeData.label.toLowerCase() === assetType?.toLowerCase()
    )?.id ?? -1
  const { data: costFilesData, isSuccess: isCostFilesDataSuccess } =
    useCostFilesQuery({
      configurationId,
      assetTypeId,
    })

  const [uploadTemplate, { isLoading: isTemplateUploading }] =
    useUploadTemplateMutation()
  const [isArchivedChecked, setIsArchivedChecked] = useState(false)

  const handleTemplateUpload = async (e: SyntheticEvent) => {
    const formData = new FormData()
    // @ts-expect-error
    const newTemplateFile = (e.target as HTMLInputElement)?.files[0]
    formData.append('file', newTemplateFile)
    formData.append('configurationId', String(configurationId))
    formData.append('assetTypeId', String(assetTypeId))

    try {
      await uploadTemplate(formData).unwrap()
      dispatch(setSnackbar({ type: 'success', messages: 'Upload successful.' }))
      dispatch(setSnackbarIsOpen(true))
    } catch (error: any) {
      const validationErrors = error?.data?.File
      if (validationErrors) {
        dispatch(
          setSnackbar({
            type: 'error',
            messages: error?.data?.File.flat() ?? 'File validation failed',
          })
        )
      } else {
        dispatch(
          setSnackbar({
            type: 'error',
            messages: error?.error ?? 'Could not upload file.',
          })
        )
      }
      dispatch(setSnackbarIsOpen(true))
    }

    // ensures the onChange event fires when the same file is selected again
    ;(e.target as HTMLInputElement).value = ''
  }

  return (
    <>
      <div className="tabtitle-bar">
        <h2>Cost Values</h2>
      </div>
      <div className="subtitle-bar">
        <h3>Mitigation Cost Values</h3>
        <div>
          <Button
            className="mr-4"
            variant="secondary-outlined"
            onClick={() =>
              handleFileDownload(
                `${env.REACT_APP_API_BASE_URL}/v1/files/configuration/${configurationId}/assetType/${assetTypeId}/Template`,
                `Mitigation Cost Template - ${assetType}`
              )
            }>
            Download Template
          </Button>
          <Button
            variant="upload"
            letterCase="normal-case"
            onChange={handleTemplateUpload}
            isDisabled={isTemplateUploading}>
            {isTemplateUploading ? 'Uploading...' : 'Upload Template...'}
          </Button>
        </div>
      </div>
      <div className="flex">
        <div className="w-1/2 pr-[70px]">
          <h3 className="text-[16px]">Current Costs</h3>
          {isCostFilesDataSuccess &&
            costFilesData &&
            costFilesData?.length !== 0 && <CostCard {...costFilesData[0]} />}
        </div>
        <div className="w-1/2 pl-[70px]">
          <div className="flex">
            <h3 className="text-[16px]">Archived Costs</h3>
            <Button
              iconOnly={
                isArchivedChecked ? (
                  <ArrowDropDownIcon className="fill-blue-lighthouse" />
                ) : (
                  <ArrowRightIcon />
                )
              }
              onClick={() => {
                setIsArchivedChecked(!isArchivedChecked)
              }}
            />
          </div>
          {isArchivedChecked &&
            isCostFilesDataSuccess &&
            costFilesData?.map((costFile: ICostFileMeta, index: number) => {
              if (index === 0) return null
              return <CostCard key={costFile.id} {...costFile} />
            })}
        </div>
      </div>
    </>
  )
}

export default CostValuesOutlet
