import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IMitigationScenarioDetails } from 'services/interfaces'
import { mitigationApi } from 'services/apis'

type MitigationState = {
  showArchived: boolean
  scenariosCurrentPageData: IMitigationScenarioDetails[]
  totalScenarios: number
  validAssetTypeIds: number[]
}

const initialState: MitigationState = {
  showArchived: false,
  scenariosCurrentPageData: [],
  totalScenarios: 0,
  validAssetTypeIds: [],
}

export const mitigationDataSlice = createSlice({
  name: 'mitigationData',
  initialState,
  reducers: {
    setShowArchived: (state, action) => {
      state.showArchived = action.payload
    },
    setValidAssetTypeIds: (state, action: PayloadAction<number[]>) => {
      state.validAssetTypeIds = action.payload
    },
    addValidAssetTypeId: (state, action: PayloadAction<number>) => {
      state.validAssetTypeIds = [
        ...new Set([...state.validAssetTypeIds, action.payload]),
      ]
    },
    removeValidAssetTypeId: (state, action: PayloadAction<number>) => {
      state.validAssetTypeIds = state.validAssetTypeIds.filter(
        (id) => id !== action.payload
      )
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      mitigationApi.endpoints.scenarios.matchFulfilled,
      (state, { payload }) => {
        state.scenariosCurrentPageData = payload.data
        state.totalScenarios = payload.count
      }
    )
  },
})

export const {
  setValidAssetTypeIds,
  addValidAssetTypeId,
  removeValidAssetTypeId,
  setShowArchived,
} = mitigationDataSlice.actions

export default mitigationDataSlice.reducer
