import { Outlet } from 'react-router-dom'
import { useAssetTypesQuery } from 'services/apis'
import { AssetTypeLinkedTabs } from 'common/components'

const Config = () => {
  const { data: assetTypes, isSuccess } = useAssetTypesQuery()

  return isSuccess ? (
    <div className="mb-20">
      <AssetTypeLinkedTabs
        tabs={
          assetTypes?.map((assetType) => ({
            label: assetType.label,
            to: assetType.label.toLowerCase(),
          })) ?? []
        }
      />
      <div className="tabtitle-bar">
        <h2>Configuration</h2>
      </div>
      <Outlet />
    </div>
  ) : (
    <></>
  )
}

export default Config
