import { useState } from 'react'
import { Checkbox } from 'common/components'

const SelectAllAttributeCheckbox = () => {
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false)
  return (
    <div className="pl-4">
      <Checkbox
        label="Select All"
        isLabelHidden
        isChecked={isSelectAllChecked}
        setIsChecked={setIsSelectAllChecked}
      />
    </div>
  )
}

export default SelectAllAttributeCheckbox
