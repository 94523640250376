import { useEffect, useState } from 'react'
import {
  Layout,
  Button,
  Breadcrumbs,
  DEFAULT_PAGE_SIZE,
} from 'common/components'
import { LogTable, SidePanel, CreateAssessmentModal, STATUS } from '.'
import { setSelectedRowId, getRiskData } from '../RiskManagementDucks'
import { useAppSelector, useAppDispatch } from 'app/hooks'
import { useAssessmentsQuery } from 'services/apis'

const Assessments = () => {
  const { IN_PROGRESS } = STATUS
  const dispatch = useAppDispatch()
  const { currentPageData, selectedRowId } = useAppSelector(getRiskData)
  const [isCreateAssessmentModalOpen, setIsCreateAssessmentModalOpen] =
    useState(false)
  const [isCreateAssessmentDisabled, setIsCreateAssessmentDisabled] =
    useState(false)

  useEffect(() => {
    if (currentPageData && currentPageData.length) {
      if (selectedRowId === -1) {
        dispatch(setSelectedRowId(currentPageData[0].id))
      }
    }
  }, [currentPageData, selectedRowId])

  const { data: firstPageData } = useAssessmentsQuery({
    PageSize: DEFAULT_PAGE_SIZE,
    PageNumber: 1,
    TimeStampSorting: 'desc',
  })

  /**
   * This effect is responsible for disabling the "Create Assessment" button if there
   * is currently an assessment still in progress for 4 hours or less as mentioned in
   * the user story.
   */
  useEffect(() => {
    if (firstPageData?.data.length) {
      const assessmentInProgress = firstPageData.data.find(
        (item) => item.status === IN_PROGRESS
      )

      if (assessmentInProgress) {
        const HOURS_TO_ADD = 4
        const MILLISECONDS_PER_HOUR = 3600000

        const originalTimestamp = new Date(assessmentInProgress.timestamp)
        const timestampToCompare = new Date(
          originalTimestamp.valueOf() + HOURS_TO_ADD * MILLISECONDS_PER_HOUR
        )

        // if originalTimestamp + 4 hrs is greater than current datetime
        if (timestampToCompare > new Date()) {
          // disable create assessment button
          setIsCreateAssessmentDisabled(true)
        } else {
          setIsCreateAssessmentDisabled(false)
        }
      }
    }
  }, [firstPageData])

  return (
    <Layout sidePanel={<SidePanel assessmentId={selectedRowId} />}>
      <Breadcrumbs items={[{ title: 'Risk Management', to: '' }]} />
      <div className="title-bar">
        <h1>Assessments</h1>
        <div>
          <Button
            isBusy={isCreateAssessmentDisabled}
            busyText="Assessment already in progress"
            variant="primary-outlined"
            onClick={() => setIsCreateAssessmentModalOpen(true)}>
            Create assessment
          </Button>
          <CreateAssessmentModal
            isOpen={isCreateAssessmentModalOpen}
            onClose={() => setIsCreateAssessmentModalOpen(false)}
          />
        </div>
      </div>
      <div className="subtitle-bar">
        <h2> Assessment Log </h2>
      </div>
      <div className="h-full">
        <LogTable />
      </div>
    </Layout>
  )
}

export default Assessments
