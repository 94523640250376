import { useFieldArray, useFormContext } from 'react-hook-form'
import { Add as AddIcon } from '@mui/icons-material'
import { Button } from 'common/components'
import { ConfigSchemaType } from '../../ConfigSchema'
import Condition from './Condition'

interface IApplicabilityProps {
  assetTypeId: number
  isSaving: boolean
}

const Applicability = ({ assetTypeId, isSaving }: IApplicabilityProps) => {
  const { control, getValues } = useFormContext<ConfigSchemaType>()
  const { append, update, remove } = useFieldArray({
    control,
    name: 'conditions',
  })

  return (
    <>
      <p className="my-0 font-bold uppercase text-blue-navy">Applicability</p>
      <div className="mt-[34px] flex flex-col">
        {getValues('conditions').map((condition, index) => (
          <Condition
            key={condition.fieldId}
            assetTypeId={assetTypeId}
            condition={condition}
            conditionIndex={index}
            isSaving={isSaving}
            remove={remove}
            update={update}
          />
        ))}
      </div>
      <Button
        className={'mt-[15px] ml-auto flex font-semibold'}
        variant="clear"
        onClick={() => {
          append({ fieldId: null, values: '' })
        }}
        prefixIcon={<AddIcon />}
        isDisabled={
          isSaving ||
          getValues('conditions').some(
            (condition) => condition.fieldId === null
          )
        }
        color="blue-lighthouse">
        add condition
      </Button>
    </>
  )
}

export default Applicability
