import { Dispatch, forwardRef, SetStateAction } from 'react'
import {
  SquareRounded,
  CheckBoxOutlineBlank,
  CheckBox,
} from '@mui/icons-material'
import {
  Checkbox as MuiCheckbox,
  FormControlLabel,
  SxProps,
} from '@mui/material'

interface ICheckboxProps {
  /**
   * The controlled value for the checkbox.
   */
  isChecked?: boolean
  /**
   * The state setter function when checked state value changes.
   */
  setIsChecked: Dispatch<SetStateAction<boolean>>
  /**
   * The Checkbox's label.
   */
  label: string
  /**
   * The `label` prop will still be required for assistive technologies.
   */
  isLabelHidden?: boolean
  /**
   * Sets the label to a bold font.
   */
  isLabelBold?: boolean
  /**
   * Disables the checkbox.
   */
  isDisabled?: boolean
  /**
   * The border of the checkbox is displayed as red if true.
   */
  hasError?: boolean
  /**
   * Setting it to `white` displays the checked state with a white background.
   */
  variant?: 'white'
}

/**
 * Checkboxes give people a way to select one or more items from a group,
 * or switch between two mutually exclusive options (checked or unchecked).
 */
const Checkbox = forwardRef<HTMLInputElement, ICheckboxProps>(
  (
    {
      isChecked = false,
      setIsChecked,
      label,
      isLabelHidden = false,
      isLabelBold = false,
      isDisabled = false,
      hasError = false,
      variant,
    },
    ref
  ) => {
    const labelStyles: SxProps = {
      '.MuiSvgIcon-root': {
        fontSize: '1.8rem',
        color: `${variant === 'white' ? 'white' : '#82ACFF'}`,
      },
      '&.Mui-disabled .MuiTypography-root': {
        opacity: 0.5,
      },
      '&.Mui-disabled .MuiSvgIcon-root': {
        opacity: 0.5,
        color: '#aaa',
      },
      '.MuiCheckbox-root:not(.Mui-checked) .MuiSvgIcon-root': {
        color: `${hasError ? '#B70000' : '#A6A8AB'}`,
      },
      '.MuiTypography-root': {
        fontWeight: isLabelBold ? 600 : 500,
        marginLeft: '-3px',
      },
    }

    return (
      <FormControlLabel
        label={label}
        disabled={isDisabled}
        sx={labelStyles}
        control={
          <MuiCheckbox
            checked={isChecked}
            onChange={() => setIsChecked((v) => !v)}
            icon={isDisabled ? <SquareRounded /> : <CheckBoxOutlineBlank />}
            checkedIcon={<CheckBox />}
            inputRef={ref}
          />
        }
        componentsProps={{
          typography: { className: isLabelHidden ? 'sr-only' : '' },
        }}
      />
    )
  }
)

Checkbox.displayName = 'Checkbox'

export default Checkbox
