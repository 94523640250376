import { Fragment, ReactNode, Key } from 'react'
import { IBreadcrumbItemProps } from 'common/interfaces'
import { BreadcrumbItem } from 'common/components'

export interface IBreadcrumbsProps {
  /**
   * An array of objects containing `title` and `to` properties for the
   * title and the destination route, respectively. If `to` is set to
   * `null`, the associated title is displayed as non-linked text.
   */
  items: IBreadcrumbItemProps[]
}

/**
 * Secondary navigation scheme which appears at the top of most pages
 * revealing the user’s location in the application.
 */
const Breadcrumbs = ({ items }: IBreadcrumbsProps) => {
  const renderItems = (): ReactNode =>
    items.map((item: IBreadcrumbItemProps, index) => {
      const { to, title, hideIcon } = item
      const isLastItem = index === items.length - 1

      return (
        <Fragment key={index as Key}>
          <BreadcrumbItem
            to={to}
            title={title}
            hideIcon={hideIcon || isLastItem}
          />
        </Fragment>
      )
    })

  return (
    <div
      data-testid="breadcrumbs-container"
      className="flex font-medium uppercase">
      {renderItems()}
    </div>
  )
}

export default Breadcrumbs
