import { useEffect, useState } from 'react'
import {
  FieldArrayWithId,
  UseFieldArrayReturn,
  useFormContext,
} from 'react-hook-form'
import { cloneDeep } from 'lodash'
import AddIcon from '@mui/icons-material/Add'
import { useAppDispatch } from 'app/hooks'
import { Button, Input } from 'common/components'
import { useAssetFieldsQuery } from 'services/apis'
import {
  addValidAssetTypeId,
  removeValidAssetTypeId,
} from '../../MitigationScenarioDucks'
import { CreateEditScenarioFormSchemaType, RulesSchema } from '..'
import ConditionSelector from './ConditionSelector'
import Rule from './Rule'
import RulesOverflowContainer from './RulesOverflowContainer'

interface IAssetMitigationRulesProps {
  assetTypeId: number
  isViewScreen: boolean
  rulesFieldArray: UseFieldArrayReturn<
    CreateEditScenarioFormSchemaType,
    'rules',
    'id'
  >
}

const AssetMitigationRules = ({
  assetTypeId,
  isViewScreen,
  rulesFieldArray,
}: IAssetMitigationRulesProps) => {
  const dispatch = useAppDispatch()
  const { getValues, setFocus } =
    useFormContext<CreateEditScenarioFormSchemaType>()

  const { data: assetFieldsData } = useAssetFieldsQuery(assetTypeId)

  const [prevSelected, setPrevSelected] = useState<number[]>([])

  const correspondingGlobalRuleIndexes: number[] = []
  getValues('rules').forEach((rule, ruleIndex) => {
    if (rule.assetTypeId === assetTypeId) {
      correspondingGlobalRuleIndexes.push(ruleIndex)
    }
  })

  const runAssetValidation = () => {
    const assetRules = getValues('rules').filter(
      (rule) => rule.assetTypeId === assetTypeId
    )

    const assetValidationResult = RulesSchema.safeParse({ rules: assetRules })

    if (assetValidationResult.success) {
      dispatch(addValidAssetTypeId(assetTypeId))
    } else {
      dispatch(removeValidAssetTypeId(assetTypeId))
    }
  }

  useEffect(() => {
    if (rulesFieldArray.fields.length) {
      runAssetValidation()
    }
  }, [rulesFieldArray.fields])

  const generateIncrementalRuleId = (
    rules: CreateEditScenarioFormSchemaType['rules']
  ) => {
    const ruleIds = rules.map((rule) => rule.mitigationRuleId)
    return Math.max(...ruleIds, 0) + 1
  }

  const handleAddRule = () => {
    const rules = getValues('rules')
    const newId = generateIncrementalRuleId(rules)
    const currentAssetFirstRule = rules.find(
      (rule) => rule.assetTypeId === assetTypeId
    )
    if (currentAssetFirstRule) {
      const assetRuleClone = cloneDeep(currentAssetFirstRule)
      assetRuleClone.mitigationRuleId = newId
      assetRuleClone.conditions.forEach((condition) => {
        condition.ruleId = newId
        condition.fromInterval = ''
        condition.toInterval = ''
        condition.value = ''
      })
      assetRuleClone.outcomes.forEach((outcome) => {
        outcome.ruleId = newId
        outcome.value = ''
      })

      getValues(`rules`)
      rulesFieldArray.append(assetRuleClone, {
        shouldFocus: false,
      })

      setTimeout(() => {
        setFocus(`rules.${rules.length}.outcomes.0.value`)
        setFocus(`rules.${rules.length}.conditions.0.value`)
        setFocus(`rules.${rules.length}.conditions.0`)
      }, 0)
    }
  }

  const handleAddCondition = () => {
    getValues('rules').forEach((rule, index) => {
      if (rule.assetTypeId === assetTypeId) {
        rulesFieldArray.update(index, {
          ...rule,
          conditions: [
            ...rule.conditions,
            {
              assetFieldId: -1,
              fromInterval: '',
              ruleId: rule.mitigationRuleId,
              toInterval: '',
              value: '',
            },
          ],
        })
      }
    })
  }

  const handleRemoveCondition = (indexToRemove: number) => {
    setPrevSelected((oldValues) => {
      const newValues = [...oldValues]
      newValues.splice(indexToRemove, 1)
      return newValues
    })

    getValues('rules').forEach((rule, index) => {
      if (rule.assetTypeId === assetTypeId) {
        rulesFieldArray.update(index, {
          ...rule,
          conditions: [
            ...rule.conditions.filter(
              (_condition, conditionIndex) => conditionIndex !== indexToRemove
            ),
          ],
        })
      }
    })
  }

  const renderConditionSelector = () => {
    return getValues('rules')
      .find((rule) => rule.assetTypeId === assetTypeId)
      ?.conditions.map((_condition, conditionIndex) => (
        <ConditionSelector
          key={`condition-selector-${conditionIndex}`}
          assetTypeId={assetTypeId}
          conditionIndex={conditionIndex}
          correspondingGlobalRuleIndexes={correspondingGlobalRuleIndexes}
          isViewScreen={isViewScreen}
          prevSelected={prevSelected}
          removeCondition={handleRemoveCondition}
          runAssetValidation={runAssetValidation}
          setPrevSelected={setPrevSelected}
        />
      ))
  }

  const renderOutcomes = () => {
    return getValues('rules')
      .find((rule) => rule.assetTypeId === assetTypeId)
      ?.outcomes.map((outcome) => {
        const assetField = assetFieldsData?.find(
          (assetField) =>
            assetField.assetFieldId.toString() ===
            outcome.assetFieldId.toString()
        )
        return (
          <div
            className="ml-1 h-14 2xl:h-[53px]"
            key={`outcome-name-${outcome.assetFieldId}`}>
            <Input
              className={'bg-lightBlue-100 font-semibold'}
              isLabelHidden={true}
              isReadOnly
              label={`${assetField?.assetFieldName}`}
              value={assetField?.assetFieldName ?? ''}
            />
          </div>
        )
      })
  }

  const renderRule = (
    assetRule: FieldArrayWithId<
      CreateEditScenarioFormSchemaType,
      'rules',
      'id'
    >,
    assetRuleIndex: number
  ) => {
    return (
      <Rule
        key={assetRule.id}
        assetRuleIndex={assetRuleIndex}
        assetTypeId={assetRule.assetTypeId}
        correspondingGlobalRuleIndexes={correspondingGlobalRuleIndexes}
        isViewScreen={isViewScreen}
        rulesFieldArray={rulesFieldArray}
        runAssetValidation={runAssetValidation}
      />
    )
  }

  const renderCatchAllRule = () => {
    return rulesFieldArray.fields
      .filter((rule) => rule.assetTypeId === assetTypeId)
      .map((assetRule, assetRuleIndex) => {
        if (assetRuleIndex !== 0) return null
        return renderRule(assetRule, assetRuleIndex)
      })
  }

  const renderNonCatchAllRules = () => {
    return rulesFieldArray.fields
      .filter((rule) => rule.assetTypeId === assetTypeId)
      .map((assetRule, assetRuleIndex) => {
        if (assetRuleIndex === 0) return null
        return renderRule(assetRule, assetRuleIndex)
      })
  }

  return (
    <>
      <div className="mb-2 mt-2 flex">
        <Button
          className={`ml-auto w-[150px] self-end !text-blue-lighthouse ${
            isViewScreen && 'hidden'
          }`}
          onClick={handleAddRule}
          prefixIcon={<AddIcon />}
          variant="clear">
          Add Rule
        </Button>
      </div>

      <div className="flex flex-wrap">
        <div className="mr-6 flex w-72 flex-col 2xl:mr-8 2xl:w-80">
          <p className="mt-2 mb-[4px] text-[20px] font-semibold">Conditions</p>
          {renderConditionSelector()}
          <Button
            className={`ml-auto mr-4 !text-blue-lighthouse ${
              isViewScreen && 'hidden'
            }`}
            onClick={handleAddCondition}
            prefixIcon={<AddIcon />}
            variant="clear">
            Add Condition
          </Button>

          <p className="mb-[12px] text-[20px] font-semibold">Outcomes</p>
          {renderOutcomes()}
        </div>

        {renderCatchAllRule()}
        <RulesOverflowContainer>
          {renderNonCatchAllRules()}
        </RulesOverflowContainer>
      </div>
    </>
  )
}

export default AssetMitigationRules
