import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { IBreadcrumbItemProps } from 'common/interfaces'

const BreadcrumbItem = ({
  hideIcon = false,
  title,
  to = '',
}: IBreadcrumbItemProps) => {
  const renderLink = (): ReactNode => {
    if (to) {
      return (
        <Link to={to} className="text-blue-lighthouse no-underline">
          {title}
        </Link>
      )
    }

    return <span className="text-blue-navy">{title}</span>
  }

  const renderIcon = (): ReactNode => {
    if (!hideIcon) {
      return <ChevronRightIcon className="mx-2 fill-blue-lighthouse" />
    }

    return null
  }

  return (
    <>
      {renderLink()}
      {renderIcon()}
    </>
  )
}

export default BreadcrumbItem
