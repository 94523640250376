import { useEffect, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Modal } from 'common/components'
import { useUpdateCostConfigMutation } from 'services/apis'
import { IAttribute, IMitigationCostConfig } from 'services/interfaces'
import {
  ConfigSchema,
  ConfigSchemaType,
  Content,
  Footer,
  Sidebar,
  tabs,
  Tabs,
} from '.'

interface ILookupTableConfigModalProps {
  assetTypeId: number
  data: IMitigationCostConfig
  isOpen: boolean
  onClose: () => void
}

const LookupTableConfigModal = ({
  assetTypeId,
  data,
  isOpen,
  onClose,
}: ILookupTableConfigModalProps) => {
  const formMethods = useForm<ConfigSchemaType>({
    mode: 'onChange',
    resolver: zodResolver(ConfigSchema),
    defaultValues: {
      id: data.id,
      selectedAttributes: [],
      selectedLocationLevel: null,
    },
  })
  const { handleSubmit, setValue } = formMethods
  const [
    updateCostConfig,
    { isLoading: isSavingCostConfig, isSuccess: isCostConfigSuccess },
  ] = useUpdateCostConfigMutation()

  const [selectedTab, setSelectedTab] = useState<Tabs>(tabs.CoreAttributes)

  useEffect(() => {
    applyInitialState()
  }, [data])

  useEffect(() => {
    if (isCostConfigSuccess) {
      handleModalClose()
    }
  }, [isCostConfigSuccess])

  const applyInitialState = () => {
    if (data.attributes.length === 1 && data.attributes[0] === null) {
      setValue('selectedAttributes', [])
    } else {
      setValue(
        'selectedAttributes',
        data.attributes.map((attribute: IAttribute) => attribute.id)
      )
    }
    setValue('selectedLocationLevel', data.selectedLocationLevel)
    setSelectedTab(tabs.CoreAttributes)
  }

  const handleTabChange = (tabName: Tabs) => setSelectedTab(tabName)

  const handleModalClose = () => {
    onClose()
    applyInitialState()
  }

  const handleSave: SubmitHandler<ConfigSchemaType> = (data) => {
    updateCostConfig({
      lookupTableId: data.id,
      body: data,
    })
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(handleSave)}>
        <Modal
          className="h-[650px]"
          footerContent={
            <Footer
              isSavingCostConfig={isSavingCostConfig}
              onClose={handleModalClose}
            />
          }
          isBusy={isSavingCostConfig}
          isOpen={isOpen}
          onClose={handleModalClose}
          sidebarContent={
            <Sidebar
              currentTab={selectedTab}
              onTabChange={handleTabChange}
              tableType={data.tableType}
            />
          }
          title="Lookup Table Configuration">
          <Content
            assetTypeId={assetTypeId}
            data={data}
            isSavingCostConfig={isSavingCostConfig}
            selectedTab={selectedTab}
          />
        </Modal>
      </form>
    </FormProvider>
  )
}

export default LookupTableConfigModal
