import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import { ReactNode } from 'react'
import { ResponsiveLabels } from 'common/components'

interface ICardEntry {
  title: string | undefined
  value: string | number | string[] | undefined
}

interface ICardProps {
  /**
   * className applied to the root element. Limit use for special cases only.
   */
  className?: string
  /**
   * An array of objects containing `title` and `value` properties for the
   * card information titles and their corresponding values, respectively.
   */
  entries: ICardEntry[]
  /**
   * Text that goes into the Notes section.
   */
  notes: string
}

const classes = {
  title: 'mb-2 text-sm font-semibold uppercase text-blue-lighthouse',
  card: 'mt-[20px] mb-[40px] px-[12px] shadow-[0_0_8px_0_rgba(0,0,0,0.1)]',
}

/**
 * Informational card that is usually placed within a side panel to show
 * detailed information about a selected item.
 */
function BasicCard({ className = '', entries, notes }: ICardProps) {
  const getGridContent = (entry: ICardEntry): ReactNode => {
    const { title, value } = entry

    return (
      <>
        <p className={classes.title}>{title}</p>
        {Array.isArray(value) ? (
          <ResponsiveLabels
            labels={value}
            className="bg-inherit mr-2 py-1 text-coolGray-500"
            containerClass="flex w-3/4 flex-nowrap"
          />
        ) : (
          <p className="mt-2 break-words text-coolGray-500">{value}</p>
        )}
      </>
    )
  }

  return (
    <Card data-testid="card" className={`${className} ${classes.card}`}>
      <CardContent>
        <Grid container rowSpacing={1} columnSpacing={2}>
          {entries.map((entry) => (
            <Grid data-testid="entries" item xs={6} key={entry.title}>
              {getGridContent(entry)}
            </Grid>
          ))}
          <Grid data-testid="notes" item xs={12}>
            {getGridContent({ title: 'notes', value: notes })}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default BasicCard
