import { IButtonProps } from 'common/interfaces'
import {
  DefaultButton,
  IconOnlyButton,
  NavigationButton,
  UploadButton,
} from './Variants'

/**
 * A button triggers an action or event when activated.
 */
const Button = ({
  'aria-label': ariaLabel,
  allowMultiple = false,
  busyText = '',
  children,
  className,
  color = 'coolGray-500',
  iconOnly,
  isBusy = false,
  isDisabled,
  isSubmit,
  letterCase = 'uppercase',
  onChange,
  onClick,
  prefixIcon,
  size = 'medium',
  stretch = false,
  suffixIcon,
  to = '',
  variant = 'primary',
}: IButtonProps) => {
  if (iconOnly) {
    return (
      <IconOnlyButton
        aria-label={ariaLabel}
        className={className}
        color={color}
        isDisabled={isDisabled}
        iconOnly={iconOnly}
        isSubmit={isSubmit}
        onClick={onClick}
        size={size}
      />
    )
  }

  if (variant === 'upload') {
    return (
      <UploadButton
        busyText={busyText}
        allowMultiple={allowMultiple}
        className={className}
        isDisabled={isDisabled}
        isBusy={isBusy}
        letterCase={letterCase}
        onChange={onChange}
        prefixIcon={prefixIcon}
        size={size}
        stretch={stretch}
        suffixIcon={suffixIcon}>
        {children}
      </UploadButton>
    )
  }

  if (variant === 'navigation') {
    return (
      <NavigationButton
        busyText={busyText}
        className={className}
        color={color}
        isDisabled={isDisabled}
        isBusy={isBusy}
        isSubmit={isSubmit}
        letterCase={letterCase}
        prefixIcon={prefixIcon}
        size={size}
        stretch={stretch}
        suffixIcon={suffixIcon}
        to={to}>
        {children}
      </NavigationButton>
    )
  }

  return (
    <DefaultButton
      busyText={busyText}
      className={className}
      color={color}
      isDisabled={isDisabled}
      isBusy={isBusy}
      isSubmit={isSubmit}
      letterCase={letterCase}
      onClick={onClick}
      prefixIcon={prefixIcon}
      size={size}
      stretch={stretch}
      suffixIcon={suffixIcon}
      variant={variant}>
      {children}
    </DefaultButton>
  )
}

export default Button
