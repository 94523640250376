import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { baseApi } from '.'
import {
  IMitigationScenarioType,
  IMitigationScenarioLog,
  IMitigationCostConfig,
  IScenarioDetails,
  IAssetField,
  IAssetType,
  ILocationLevel,
  IAttribute,
  ICostFileMeta,
  IPostScenario,
} from 'services/interfaces'
import { ISelectItem } from 'common/interfaces'

interface IPageConfig {
  PageNumber: number
  PageSize: number
  OnlyArchived: boolean
  TimeStampSorting: string
}
export const mitigationApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    /**
     *  The `allScenarios` query will be a result of two calls which:
     *    1. gets the count in the first call, and
     *    2. returns all scenarios in one page in the second call.
     */
    allScenarios: builder.query<IMitigationScenarioLog, void>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const initialResult = await fetchWithBQ(`/v1/mitigationScenarios`)
        if (initialResult.error) throw initialResult.error
        const count = (initialResult?.data as IMitigationScenarioLog)?.count + 1
        const result = await fetchWithBQ(
          `/v1/mitigationScenarios?PageSize=${count}`
        )

        return result.data
          ? { data: result.data as IMitigationScenarioLog }
          : { error: result.error as FetchBaseQueryError }
      },
      providesTags: ['Mitigation'],
    }),
    scenarios: builder.query<IMitigationScenarioLog, IPageConfig>({
      query: (params) => ({
        url: `/v1/mitigationScenarios`,
        params,
      }),
      providesTags: ['Mitigation'],
    }),
    scenarioTypes: builder.query<ISelectItem[], void>({
      query: () => `/v1/mitigationScenarios/types`,
      transformResponse: (types: IMitigationScenarioType[]) => {
        return types.map((type) => ({
          id: type.mitigationTypeId,
          label: type.mitigationTypeName,
        }))
      },
    }),
    assetTypes: builder.query<ISelectItem[], void>({
      query: () => `/v1/assetlookups/assetTypes`,
      transformResponse: (types: IAssetType[]) => {
        return types.map((type) => ({
          id: type.assetTypeId,
          label: type.assetTypeName,
        }))
      },
    }),
    allAssetFields: builder.query<IAssetField[], void>({
      query: () => `/v1/assetlookups/assetFields`,
    }),
    scenarioDetails: builder.query<IScenarioDetails, number>({
      query: (id) => `/v1/mitigationScenarios/${id}`,
      providesTags: ['Mitigation'],
    }),
    costConfig: builder.query<
      IMitigationCostConfig[],
      { configurationId: number; assetTypeId: number }
    >({
      query: (params) => ({
        url: `/v1/mitigationCost/configurations`,
        params,
      }),
      providesTags: ['Mitigation'],
    }),
    updateCostConfig: builder.mutation<
      IMitigationCostConfig,
      {
        lookupTableId: number
        body: {
          id: number
          selectedAttributes: number[]
          selectedLocationLevel: number | null
        }
      }
    >({
      query: ({ lookupTableId, body }) => ({
        url: `/v1/mitigationCost/configurations/${lookupTableId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Mitigation'],
    }),
    locationLevels: builder.query<ILocationLevel[], void>({
      query: () => `/v1/mitigationCost/locationLevels`,
      transformResponse: (response: any) => {
        const none = {
          level: 'null',
          name: 'None',
        }
        return [...response, none] || [none]
      },
      providesTags: ['Mitigation'],
    }),
    attributes: builder.query<
      IAttribute[],
      { assetTypeId: number; lookupTableId: number }
    >({
      query: (params) => ({
        url: `/v1/mitigationCost/attributes`,
        params,
      }),
      providesTags: ['Mitigation'],
    }),
    costFiles: builder.query<
      ICostFileMeta[],
      { configurationId: number; assetTypeId: number }
    >({
      query: ({ configurationId, assetTypeId }) =>
        `/v1/files/configuration/${configurationId}/assetType/${assetTypeId}`,
      providesTags: ['Mitigation'],
    }),
    uploadTemplate: builder.mutation<void, any>({
      query: (body) => {
        return {
          url: `/v1/files/azureblob`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: ['Mitigation'],
    }),
    downloadTemplate: builder.query<
      IAttribute[],
      { configurationId: number; assetTypeId: number }
    >({
      query: ({ configurationId, assetTypeId }) => {
        return `/v1/files/configuration/${configurationId}/assetType/${assetTypeId}/Template`
      },
      providesTags: ['Mitigation'],
    }),
    createScenario: builder.mutation<IPostScenario, IScenarioDetails>({
      query: (body) => ({
        url: `/v1/mitigationScenarios`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Mitigation'],
    }),
    editScenario: builder.mutation<IPostScenario, IScenarioDetails>({
      query: (body) => ({
        url: `/v1/mitigationScenarios/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Mitigation'],
    }),
    publishScenario: builder.mutation<void, number>({
      query: (id) => ({
        url: `/v1/mitigationScenarios/publish/${id}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Mitigation'],
    }),
    archiveScenario: builder.mutation<void, number>({
      query: (id) => ({
        url: `/v1/mitigationScenarios/archive/${id}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Mitigation'],
    }),
    deleteScenario: builder.mutation<void, number>({
      query: (id) => ({
        url: `/v1/mitigationScenarios/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Mitigation'],
    }),
  }),
  overrideExisting: true,
})

export const {
  useScenarioTypesQuery,
  useScenariosQuery,
  useCreateScenarioMutation,
  useScenarioDetailsQuery,
  useCostConfigQuery,
  useUpdateCostConfigMutation,
  useLocationLevelsQuery,
  useAttributesQuery,
  useAssetTypesQuery,
  useAllAssetFieldsQuery,
  usePublishScenarioMutation,
  useEditScenarioMutation,
  useArchiveScenarioMutation,
  useDeleteScenarioMutation,
  useAllScenariosQuery,
  useCostFilesQuery,
  useUploadTemplateMutation,
  useDownloadTemplateQuery,
} = mitigationApi
