import { Table, ResponsiveLabels } from 'common/components'
import { formatTimestamp } from 'common/utils'
import LookupTablesToggle from './LookupTablesToggle'

const MOCKDATA = [
  {
    id: 1,
    tableType: 'Leak Grade',
    lastUpdated: '2021-04-07T16:00:00.000Z',
    updatedBy: 'Lewis Ko',
    attributes: [
      {
        id: 0,
        name: 'Location Category',
        isCore: true,
      },
      {
        id: 1,
        name: 'Operating Pressure',
        isCore: true,
      },
      {
        id: 2,
        name: 'Usage Type',
        isCore: true,
      },
    ],
    selectedLocationLevel: 6,
  },
  {
    id: 2,
    tableType: 'Outcome',
    lastUpdated: '2022-04-07T16:00:00.000Z',
    updatedBy: 'Vikas Goyal',
    attributes: [
      {
        id: 0,
        name: 'Location Category',
        isCore: true,
      },
      {
        id: 1,
        name: 'Operating Pressure',
        isCore: true,
      },
      {
        id: 2,
        name: 'Usage Type',
        isCore: true,
      },
    ],
    selectedLocationLevel: 8,
  },
]

const MOCKDATA_LOCATION_LEVELS = [
  { level: 6, name: 'Hierarchy Node 1 Id' },
  { level: 7, name: 'Hierarchy Node 2 Id' },
  { level: 8, name: 'Hierarchy Node 3 Id' },
  { level: 9, name: 'Hierarchy Node 4 Id' },
  { level: 10, name: 'Hierarchy Node 5 Id' },
  { level: 11, name: 'Hierarchy Node 6 Id' },
  {
    level: 0,
    name: 'None',
  },
]

const columnConfig = [
  {
    field: 'tableType',
    flex: 1.5,
    headerName: 'TABLE TYPE',
    sortable: false,
    disableColumnMenu: true,
    cellClassName: 'font-bold',
  },
  {
    field: 'lastUpdated',
    flex: 1,
    headerName: 'LAST UPDATED',
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params: any) => {
      return <>{formatTimestamp(params.row.lastUpdated)}</>
    },
  },
  {
    field: 'updatedBy',
    flex: 1,
    headerName: 'UPDATED BY',
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'selectedAttributes',
    flex: 2,
    headerName: 'SELECTED ATTRIBUTES',
    sortable: false,
    disableColumnMenu: true,
    disableOverflowTooltip: true,
    renderCell: (params: any) => {
      if (
        params.row.attributes.length === 1 &&
        params.row.attributes[0] === null
      ) {
        return
      }
      const selectedLocationLevel = params.row.selectedLocationLevel
      const selectedAttributes = params.row.attributes.map(
        (attribute: any) => attribute?.name
      )
      if (selectedLocationLevel) {
        selectedAttributes.push(
          MOCKDATA_LOCATION_LEVELS?.find(
            (locationLevel) => locationLevel.level === selectedLocationLevel
          )?.name
        )
      }
      return <ResponsiveLabels labels={selectedAttributes} />
    },
  },
  {
    field: 'configure',
    width: 200,
    headerName: 'CONFIGURE',
    sortable: false,
    disableColumnMenu: true,
    disableOverflowTooltip: true,
    renderCell: (params: any) => {
      return (
        <LookupTablesToggle
          rowParams={params}
          mockDataLocationLevels={MOCKDATA_LOCATION_LEVELS}
        />
      )
    },
  },
]

const LookupTables = () => {
  return (
    <>
      <Table
        variant="config-table"
        columns={columnConfig}
        checkboxSelection={false}
        rows={MOCKDATA}
        pageSize={5}
        hasPagination={false}
      />
    </>
  )
}

export default LookupTables
