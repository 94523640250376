import { Dispatch, SetStateAction, useEffect } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import CancelIcon from '@mui/icons-material/Cancel'
import { Button, SearchableSelect } from 'common/components'
import { useAssetFieldsQuery } from 'services/apis'
import { CreateEditScenarioFormSchemaType } from '..'

interface IConditionSelectorProps {
  assetTypeId: number
  conditionIndex: number
  correspondingGlobalRuleIndexes: number[]
  isViewScreen: boolean
  prevSelected: number[]
  removeCondition: (indexToRemove: number) => void
  runAssetValidation: () => void
  setPrevSelected: Dispatch<SetStateAction<number[]>>
}

const ConditionSelector = ({
  assetTypeId,
  conditionIndex,
  correspondingGlobalRuleIndexes,
  isViewScreen,
  prevSelected,
  removeCondition,
  runAssetValidation,
  setPrevSelected,
}: IConditionSelectorProps) => {
  const { control, getValues, setValue } =
    useFormContext<CreateEditScenarioFormSchemaType>()

  const { data: assetFieldsData } = useAssetFieldsQuery(assetTypeId)

  const assetCatchAllRuleIndex = correspondingGlobalRuleIndexes[0]

  const assetFieldId = useWatch({
    control,
    name: `rules.${assetCatchAllRuleIndex}.conditions.${conditionIndex}.assetFieldId`,
  })

  useEffect(() => {
    if (assetFieldId) {
      const selectedConditionIds =
        getValues(`rules.${correspondingGlobalRuleIndexes[0]}.conditions`)?.map(
          (condition) => condition.assetFieldId
        ) ?? []
      setPrevSelected(selectedConditionIds)
    }
  }, [assetFieldId])

  const assetFieldItems =
    assetFieldsData?.map((item) => ({
      label: item.assetFieldName,
      id: item.assetFieldId,
    })) ?? []

  const handleSetValue = (assetFieldId: number) => {
    setPrevSelected((oldValues) => {
      const newValues = [...oldValues]
      newValues[conditionIndex] = assetFieldId
      return newValues
    })
    getValues('rules').forEach((rule, ruleIndex) => {
      if (rule.assetTypeId === assetTypeId) {
        setValue(`rules.${ruleIndex}.conditions.${conditionIndex}`, {
          assetFieldId,
          value: '',
          fromInterval: '',
          toInterval: '',
          ruleId: ruleIndex + 1,
        })
      }
    })
    runAssetValidation()
  }

  return (
    <div className="ml-1 flex h-14 w-full items-center 2xl:h-[53px]">
      <SearchableSelect
        isReadOnly={isViewScreen}
        className={'bg-lightBlue-100 font-semibold'}
        items={assetFieldItems}
        noDuplicate={true}
        prevSelected={prevSelected}
        setValue={handleSetValue}
        value={assetFieldId}
      />

      <Button
        className={`ml-5 h-0 w-0 self-center ${isViewScreen && 'hidden'}`}
        aria-label="Remove Condition"
        iconOnly={<CancelIcon className="fill-blue-lighthouse" />}
        onClick={() => {
          removeCondition(conditionIndex)
        }}
      />
    </div>
  )
}

export default ConditionSelector
