import { Table } from 'common/components'
import { IColumn } from 'common/interfaces'
import {
  AttributeCheckbox,
  DisplayNameInput,
  SelectAllAttributeCheckbox,
} from '.'

interface IAssetAttributesTableProps {
  data: {
    id: number
    systemName: string
    displayName: string
    dataType: string
    isEnabled: boolean
  }[]
  isMandatory?: boolean
}

const AssetAttributesTable = ({
  data,
  isMandatory = true,
}: IAssetAttributesTableProps) => {
  const columnConfig: IColumn[] = [
    {
      field: 'systemName',
      headerName: 'System Name',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'displayName',
      headerName: 'Display Name',
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      disableOverflowTooltip: true,
      renderCell: (params: any) => (
        <DisplayNameInput displayName={params.value} />
      ),
    },
    {
      field: 'dataType',
      headerName: 'Data Type',
      flex: 0.6,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: 'isEnabled',
      headerName: '',
      renderHeader: isMandatory
        ? undefined
        : () => <SelectAllAttributeCheckbox />,
      flex: 0.35,
      disableColumnMenu: true,
      sortable: false,
      disableOverflowTooltip: true,
      renderCell: (params) => (
        <AttributeCheckbox
          isMandatory={isMandatory}
          isEnabled={params.row.isEnabled}
        />
      ),
    },
  ]

  return (
    <Table
      variant="scrollable-table"
      useAutoHeight={false}
      className="h-[472px] text-base"
      rows={data ?? []}
      columns={columnConfig}
      checkboxSelection={false}
      hasPagination={false}
      rowBorderBottomColor="#E4E5E6"
    />
  )
}

export default AssetAttributesTable
