import { Checkbox } from 'common/components'
import { useEffect, useState } from 'react'
import { useUpdateIsEnabledMutation } from 'services/apis'

interface IConfigOutletCheckboxProps {
  assetTypeId: number
  isEnabled: boolean
  modelCode: string
}

interface IUpdateParams {
  modelCode: string
  body: { isEnabled: boolean }
}

function ConfigOutletCheckbox({
  assetTypeId,
  isEnabled,
  modelCode,
}: IConfigOutletCheckboxProps) {
  const [updateIsEnabled] = useUpdateIsEnabledMutation()

  const handleUpdateIsEnabled = ({ modelCode, body }: IUpdateParams) => {
    updateIsEnabled({
      modelCode,
      assetTypeId,
      body,
    })
  }

  const [isChecked, setIsChecked] = useState(isEnabled)

  useEffect(() => {
    if (isEnabled !== isChecked) {
      handleUpdateIsEnabled({
        modelCode,
        body: {
          isEnabled: isChecked,
        },
      })
    }
  }, [isChecked])

  return (
    <Checkbox
      label="Enable"
      isLabelHidden
      isChecked={isChecked}
      setIsChecked={setIsChecked}
    />
  )
}

export default ConfigOutletCheckbox
