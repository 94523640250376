import { useState } from 'react'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Button } from 'common/components'
import { formatTimestamp } from 'common/utils'
import { ICostFileMeta } from 'services/interfaces'

const CURRENTDUMMYDATA = {
  id: 1,
  filePath: '/temp',
  fileName: 'Revised Consequence Costs V3.0',
  uploadedOn: new Date().toISOString(),
  uploadedBy: 'Sreeraj Nair',
}

const ARCHIVEDDUMMYDATA = [
  {
    id: 1,
    filePath: '/temp',
    fileName: 'Consequence Costs V3.0',
    uploadedOn: new Date().toISOString(),
    uploadedBy: 'Dawa Bokhang',
  },
  {
    id: 1,
    filePath: '/temp',
    fileName: 'Consequence Costs V3.0',
    uploadedOn: new Date().toISOString(),
    uploadedBy: 'Dawa Bokhang',
  },
]

const CostCard = ({ fileName, uploadedOn, uploadedBy }: ICostFileMeta) => {
  return (
    <div className="mb-[30px] flex justify-between [overflow-wrap:anywhere]">
      <div className="flex-3 pr-4">
        <h4 className="m-0 text-[14px] font-semibold text-coolGray-400">
          File Name:
        </h4>
        <p className="m-0 text-[14px]">{fileName}</p>
      </div>
      <div className="flex-2 pr-4">
        <h4 className="m-0 text-[14px] font-semibold text-coolGray-400">
          Updated:
        </h4>
        <p className="m-0 text-[14px]">{formatTimestamp(uploadedOn)}</p>
      </div>
      <div className="flex-2 pr-4">
        <h4 className="m-0 text-[14px] font-semibold text-coolGray-400">
          User:
        </h4>
        <p className="m-0 text-[14px]">{uploadedBy}</p>
      </div>
      <Button className="self-start" variant="secondary-outlined">
        View
      </Button>
    </div>
  )
}

const ConsequenceCostValuesOutlet = () => {
  const [isArchivedChecked, setIsArchivedChecked] = useState<boolean>(false)

  return (
    <>
      <div className="tabtitle-bar">
        <h2>Consequence Costs</h2>
      </div>
      <div className="subtitle-bar">
        <h3>Consequence Cost Values</h3>
        <div>
          <Button className="mr-4" variant="secondary-outlined">
            Download Template
          </Button>
          <Button variant="upload" letterCase="normal-case">
            Upload Template...
          </Button>
        </div>
      </div>
      <div className="flex">
        <div className="w-1/2 pr-[70px]">
          <h3 className="text-[16px]">Current Costs</h3>
          <CostCard {...CURRENTDUMMYDATA} />
        </div>
        <div className="w-1/2 pl-[70px]">
          <div className="flex">
            <h3 className="text-[16px]">Archived Consequence Cost Values</h3>
            <Button
              iconOnly={
                isArchivedChecked ? (
                  <ArrowDropDownIcon className="fill-blue-lighthouse" />
                ) : (
                  <ArrowRightIcon />
                )
              }
              onClick={() => {
                setIsArchivedChecked(!isArchivedChecked)
              }}
            />
          </div>
          {isArchivedChecked &&
            ARCHIVEDDUMMYDATA?.map((costFile: ICostFileMeta) => {
              return <CostCard key={costFile.id} {...costFile} />
            })}
        </div>
      </div>
    </>
  )
}

export default ConsequenceCostValuesOutlet
