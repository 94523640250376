import MuiButton from '@mui/material/Button'
import { SxProps } from '@mui/material'
import { useSize } from 'common/hooks'
import { IButtonProps } from 'common/interfaces'

const sxStyles: SxProps = {
  '&:hover .text-blue-lighthouse': {
    color: '#3d7af5 !important',
  },
}

const UploadButton = ({
  allowMultiple = false,
  busyText = '',
  children,
  className = '',
  isBusy = false,
  isDisabled,
  letterCase = 'uppercase',
  onChange,
  prefixIcon,
  size = 'medium',
  stretch = false,
  suffixIcon,
}: Partial<IButtonProps>) => {
  const textSize: string = useSize(size)
  const disabledClass: string =
    isDisabled || isBusy
      ? `bg-gray-200 shadow-none`
      : `bg-white shadow-[0_0_0_1px]`
  const classList: string = `${className} ${letterCase} ${textSize} ${disabledClass}`

  return (
    <label>
      <MuiButton
        className={`${classList} px-5 tracking-wide text-coolGray-400 hover:text-coolGray-500`}
        fullWidth={stretch}
        size={size}
        disabled={isDisabled || isBusy}
        startIcon={prefixIcon}
        endIcon={suffixIcon}
        component="span"
        sx={sxStyles}>
        <span className="tracking-normal">{isBusy ? busyText : children}</span>
        <span className="text-blue-lighthouse transition">&nbsp; BROWSE</span>
        <input
          hidden
          type="file"
          multiple={allowMultiple}
          accept=".xlsx, .xls, .csv"
          disabled={isDisabled || isBusy}
          onChange={onChange}
        />
      </MuiButton>
    </label>
  )
}

export default UploadButton
