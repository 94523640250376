import { SxProps } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'

const linearProgressStyles: SxProps = {
  backgroundColor: '#82ACFF',
  height: `4px`,
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: '#3d7af5',
  },
}

const LoadingBar = () => {
  return (
    <div className="h-0 overflow-visible">
      <LinearProgress sx={linearProgressStyles} />
    </div>
  )
}

export default LoadingBar
