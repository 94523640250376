import { TAB_CONFIGURATION, TAB_MODEL_TUNING, TAB_ADD_NOTES } from '.'

const classes = {
  title: 'mb-14 text-base font-bold capitalize text-coolGray-900',
  tab: `cursor-pointer rounded px-4 py-3 font-normal capitalize 
  text-coolGray-400 first:mt-4`,
  activeTab: 'bg-lightBlue-100 font-semibold text-coolGray-900',
}

interface ISidebarProps {
  onTabChange: (tabName: string) => void
  currentTab: string
  version: string
}

const Sidebar = ({ currentTab, onTabChange, version }: ISidebarProps) => {
  const getTabClass = (tabName: string): string =>
    `${classes.tab} ${currentTab === tabName ? classes.activeTab : ''}`

  return (
    <>
      <section className={classes.title}>
        publish version:
        <div className="mt-2 font-normal">{version}</div>
      </section>
      <section className={classes.title}>
        section
        <div
          className={getTabClass(TAB_CONFIGURATION)}
          onClick={() => onTabChange(TAB_CONFIGURATION)}
        >
          {TAB_CONFIGURATION}
        </div>
        <div
          className={getTabClass(TAB_MODEL_TUNING)}
          onClick={() => onTabChange(TAB_MODEL_TUNING)}
        >
          {TAB_MODEL_TUNING}
        </div>
        <div
          className={getTabClass(TAB_ADD_NOTES)}
          onClick={() => onTabChange(TAB_ADD_NOTES)}
        >
          {TAB_ADD_NOTES}
        </div>
      </section>
    </>
  )
}

export default Sidebar
