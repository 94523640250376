import { ReactNode, useMemo } from 'react'
import { SxProps } from '@mui/material'
import { Close } from '@mui/icons-material'
import MuiModal, { ModalProps } from '@mui/material/Modal'
import { Button } from 'common/components'
import { IModalProps } from 'common/interfaces'

const muiModalStyles: SxProps = {
  '.Modal': {
    transform: 'translate(-50%, -50%)',
  },
  '.MuiBackdrop-root': {
    backgroundColor: '#1C355ECC',
  },
}

const classes = {
  footerClass: `col-span-6 mt-auto flex justify-end border-0 border-t 
  border-solid border-coolGray-300 p-5`,
  sidebarClass: `col-span-2 border-0 border-r border-solid 
  border-coolGray-300 p-7`,
  titleClass: `col-span-6 border-0 border-b border-solid 
  border-coolGray-300 p-4 px-6 text-lg font-bold`,
}

/**
 * Modals are used to reveal critical information, show information without
 * losing context, or when the system requires a user response. Modals can
 * also fragment a complex workflow into simpler steps and should serve a
 * single purpose dedicated to completing the user’s task.
 */
const Modal = ({
  children,
  className = '',
  footerContent,
  isBusy = false,
  isOpen,
  onClose,
  sidebarContent,
  title,
}: IModalProps) => {
  const { footerClass, sidebarClass, titleClass } = classes
  const onCloseWrapper: ModalProps['onClose'] = (_, reason) => {
    if (reason && ['backdropClick', 'escapeKeyDown'].includes(reason)) {
      return
    }

    onClose()
  }

  const containerClass: string = useMemo(
    (): string =>
      `Modal absolute top-1/2 left-1/2 grid max-h-[98%] w-[98%] grid-cols-6 
      rounded bg-white shadow ${sidebarContent ? `max-w-5xl` : `max-w-2xl`} 
      ${title ? `grid-rows-[min-content_1fr_min-content]` : ''} 
      ${className}`,
    [sidebarContent, title, className]
  )

  const contentClass: string = useMemo(
    (): string =>
      `flex flex-col overflow-x-hidden
      ${sidebarContent ? 'col-span-4' : 'col-span-6'}`,
    [sidebarContent]
  )

  const getSection = (content: ReactNode, classList: string): ReactNode => {
    if (content) {
      return <div className={classList}>{content}</div>
    }

    return null
  }

  return (
    <MuiModal
      open={isOpen}
      onClose={onCloseWrapper}
      disablePortal
      sx={muiModalStyles}>
      <div className={containerClass}>
        {getSection(title, titleClass)}
        {getSection(sidebarContent, sidebarClass)}
        <div className={contentClass}>
          <div className="h-fit overflow-y-auto overflow-x-hidden p-7 py-5">
            {children}
          </div>
        </div>
        {getSection(footerContent, footerClass)}
        <div className="absolute top-[8px] right-2">
          <Button
            aria-label="close"
            iconOnly={<Close />}
            onClick={onClose}
            isDisabled={isBusy}
          />
        </div>
      </div>
    </MuiModal>
  )
}

export default Modal
