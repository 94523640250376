import { Modal } from 'common/components'
import { useState } from 'react'
import { Content, Sidebar, Footer, TAB_CONFIGURATION } from '.'

interface IPublishConfigModalProps {
  selectedRowId: number
  isOpen: boolean
  onClose: () => void
}

const PublishConfigModal = ({
  selectedRowId,
  isOpen,
  onClose,
}: IPublishConfigModalProps) => {
  const [selectedTab, setSelectedTab] = useState(TAB_CONFIGURATION)
  const [publishVersion, setPublishVersion] = useState<string>('-')

  const handleTabChange = (tabName: string): void => setSelectedTab(tabName)

  const handleModalClose = () => {
    setSelectedTab(TAB_CONFIGURATION)
    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleModalClose}
      title="Publish Configuration"
      sidebarContent={
        <Sidebar
          onTabChange={handleTabChange}
          currentTab={selectedTab}
          version={publishVersion}
        />
      }
      footerContent={<Footer onCancel={handleModalClose} />}
      className="h-4/5 outline-none">
      <Content
        currentTab={selectedTab}
        selectedRowId={selectedRowId}
        onVersionChanged={setPublishVersion}
      />
    </Modal>
  )
}

export default PublishConfigModal
