import { FormEvent, useEffect } from 'react'
import {
  Button,
  Modal,
  setSnackbar,
  setSnackbarIsOpen,
} from 'common/components'
import { useArchiveScenarioMutation } from 'services/apis'
import { useAppDispatch } from 'app/hooks'

interface IArchiveModalProps {
  idToArchive: number
  isOpen: boolean
  onClose: () => void
}

const ArchiveModal = ({ idToArchive, isOpen, onClose }: IArchiveModalProps) => {
  const dispatch = useAppDispatch()

  const [
    archiveScenario,
    { isLoading: isArchivingScenario, isSuccess: archivingIsSuccess },
  ] = useArchiveScenarioMutation()

  useEffect(() => {
    if (archivingIsSuccess) {
      onClose()
      dispatch(
        setSnackbar({
          messages: 'Scenario archived.',
          type: 'success',
        })
      )
      dispatch(setSnackbarIsOpen(true))
    }
  }, [archivingIsSuccess])

  const handleArchive = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    archiveScenario(idToArchive)
  }

  return (
    <Modal
      isBusy={isArchivingScenario}
      isOpen={isOpen}
      onClose={onClose}
      title="Scenario Option">
      <form onSubmit={handleArchive}>
        <p className="mt-0 text-lg font-bold">Archive Scenario</p>
        <p>Are you sure you want to archive this scenario?</p>
        <div className="mt-8 text-right">
          <Button
            className="mr-2"
            isDisabled={isArchivingScenario}
            onClick={onClose}
            variant="clear">
            Cancel
          </Button>
          <Button busyText="Archiving..." isBusy={isArchivingScenario} isSubmit>
            Archive
          </Button>
        </div>
      </form>
    </Modal>
  )
}

export default ArchiveModal
