import { useMemo } from 'react'
import { SxProps } from '@mui/material'
import { DataGrid, GridSortModel } from '@mui/x-data-grid'
import { IColumn } from 'common/interfaces'

interface IScrollableTableProps {
  checkboxSelection: boolean
  className?: string
  columns: IColumn[]
  currentPage: number
  dataReload?: (page: number, sortModel: GridSortModel) => void
  getRowId?: any
  hasPagination?: boolean
  isPageCountHidden?: boolean
  rowBorderBottomColor?: string
  rowIdToHighlight?: number
  rows: any
  setSortModel?: (sortModel: GridSortModel) => void
  sortModel?: GridSortModel
  totalCount: number
  useAutoHeight?: boolean
}

const ScrollableTable = ({
  checkboxSelection,
  className = '',
  columns,
  getRowId,
  rowBorderBottomColor = '#F2F3F4',
  rowIdToHighlight,
  rows = [],
  setSortModel = () => {},
  sortModel = [],
}: IScrollableTableProps) => {
  const styles: SxProps = useMemo(
    () => ({
      borderWidth: '0',
      boxShadow: '0px 0px 8px #00000028',
      borderRadius: '2px',
      '.MuiDataGrid-cell': {
        height: '48px',
        borderBottomWidth: '2px',
        borderBottomColor: `${rowBorderBottomColor} !important`,
        fontSize: '14px/14px',
        font: 'MontSerrat',
        '&:first-of-type': {
          paddingLeft: '18px',
        },
        '&:focus': {
          outline: 'none',
        },
        '&:focus-within': {
          outline: 'none',
        },
      },
      '.MuiDataGrid-iconSeparator': { visibility: 'hidden' },
      '.MuiDataGrid-columnHeaderTitle': {
        fontSize: '16px/14px',
        fontWeight: '600',
      },
      '.MuiDataGrid-columnHeaders': {
        borderBottomWidth: '2px',
        borderBottomColor: '#D0D2D3',
        backgroundColor: '#F5F5F8',
        '.MuiDataGrid-columnHeader': {
          '&:first-of-type': {
            paddingLeft: '18px',
          },
          '&:focus': {
            backgroundColor: 'rgba(0,0,0,0)',
            outline: 'none',
          },
          '&:focus-within': {
            outline: 'none',
          },
        },
      },
      '.MuiDataGrid-footerContainer': { display: 'block' },
      '.MuiDataGrid-virtualScrollerRenderZone': {
        transform: 'none !important',
      },
    }),
    []
  )

  return (
    <DataGrid
      disableVirtualization
      disableSelectionOnClick
      sortingMode="client"
      sortingOrder={['desc', 'asc']}
      sortModel={sortModel}
      onSortModelChange={(newSortModel) => {
        setSortModel(newSortModel)
      }}
      sx={styles}
      className={`${className} w-full justify-center`}
      rows={rows}
      columns={columns}
      pageSize={100}
      checkboxSelection={checkboxSelection}
      getRowClassName={({ id }) =>
        rowIdToHighlight === id ? 'bg-[#82ACFF33]' : ''
      }
      components={{
        Footer: () => null,
      }}
      getRowId={getRowId}
    />
  )
}

export default ScrollableTable
