import { Button } from 'common/components'

interface IFooterProps {
  onCancel: () => void
}

const Footer = ({ onCancel }: IFooterProps) => (
  <>
    <Button className="mr-2" onClick={onCancel} variant="clear">
      Cancel
    </Button>
    <Button isSubmit>Save & Apply</Button>
  </>
)

export default Footer
