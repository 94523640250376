import { Dispatch, SetStateAction } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, setSnackbarIsOpen } from 'common/components'
import { useAppDispatch } from 'app/hooks'

const PublishedScenarioButtons = ({
  id,
  isArchiveButtonHidden,
  setIdToArchive,
  setIdToClone,
  setIsArchiveDialogOpen,
  setIsCloneDialogOpen,
}: {
  id: number
  isArchiveButtonHidden?: boolean
  setIdToArchive: Dispatch<SetStateAction<number>>
  setIdToClone: Dispatch<SetStateAction<number>>
  setIsArchiveDialogOpen: Dispatch<SetStateAction<boolean>>
  setIsCloneDialogOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  return (
    <>
      <Button
        className="mr-2 w-24"
        onClick={() => {
          dispatch(setSnackbarIsOpen(false))
          navigate(`edit?id=${id}&view=1`)
        }}
        size="small"
        variant="secondary">
        View
      </Button>
      <Button
        className="mr-2 w-24"
        onClick={() => {
          dispatch(setSnackbarIsOpen(false))
          setIsCloneDialogOpen(true)
          setIdToClone(id)
        }}
        size="small"
        variant="secondary">
        Clone
      </Button>
      {!isArchiveButtonHidden && (
        <Button
          className="w-24"
          onClick={() => {
            dispatch(setSnackbarIsOpen(false))
            setIsArchiveDialogOpen(true)
            setIdToArchive(id)
          }}
          size="small"
          variant="secondary">
          Archive
        </Button>
      )}
    </>
  )
}

export default PublishedScenarioButtons
