import { useMemo } from 'react'
import {
  ILikelihoodAttribute,
  ILikelihoodAttributes,
} from 'services/interfaces'
import AttributesCheckbox from './AttributesCheckbox'

interface ICoreAttributesProps {
  attributes: ILikelihoodAttributes
  isSaving: boolean
}

const CoreAttributes = ({ attributes, isSaving }: ICoreAttributesProps) => {
  const coreAttributes = useMemo(
    () =>
      attributes?.likelihoodAttributes?.filter(
        (attribute: ILikelihoodAttribute) => attribute.isCore
      ),
    [attributes]
  )

  const renderCoreAttributes = () => {
    if (!coreAttributes || coreAttributes?.length === 0) {
      return <div className="mt-[12px]">No core attributes found.</div>
    }

    return (
      <section className="mt-[34px] grid grid-cols-2 overflow-auto rounded border-2 border-solid border-gray-200 p-6 py-3">
        {coreAttributes?.map(
          ({ assetFieldId, assetFieldName }: ILikelihoodAttribute) => (
            <AttributesCheckbox
              key={assetFieldId}
              assetFieldId={assetFieldId}
              assetFieldName={assetFieldName}
              isSaving={isSaving}
            />
          )
        )}
      </section>
    )
  }

  return (
    <>
      <p className="my-0 font-bold uppercase text-blue-navy">Core Attributes</p>
      {renderCoreAttributes()}
    </>
  )
}

export default CoreAttributes
