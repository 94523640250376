import { Button } from 'common/components'

interface IFooterProps {
  onCancel: () => void
}

const Footer = ({ onCancel }: IFooterProps) => {
  return (
    <>
      <Button onClick={onCancel} variant="clear" isDisabled={false}>
        Cancel
      </Button>
      <Button isDisabled={false} className="ml-2">
        Publish
      </Button>
    </>
  )
}

export default Footer
