import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { env } from 'app/runtime-config'
import { auth0Token } from 'common/utils'

// initialize an empty api service that we'll inject endpoints into later as needed
const baseApi = createApi({
  reducerPath: 'apiCalls',
  tagTypes: [
    'Risk',
    'Mitigation',
    'Dashboard',
    'Likelihood',
    'DataImportLogs',
    'Reports',
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: env.REACT_APP_API_BASE_URL,
    prepareHeaders: async (headers) => {
      const token = await auth0Token.getAccessTokenSilently()()
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  endpoints: () => ({}),
})

export default baseApi
