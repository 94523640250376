import { useEffect, useState } from 'react'
import { Routes, Route, Link, Navigate } from 'react-router-dom'

import ProtectedRoute from 'app/auth/ProtectedRoute'
import { useAuth0 } from '@auth0/auth0-react'
import { auth0Token } from 'common/utils'

import { env } from 'app/runtime-config'
import Configuration from 'views/Configuration'
import CostConfig from 'views/IntegrityManagement/MitigationScenario/CostConfig'
import CostValues from 'views/IntegrityManagement/MitigationScenario/CostValues'
import CreateEditScenario from 'views/IntegrityManagement/MitigationScenario/CreateEditScenario'
import LoginPage from 'views/Auth/LoginLandingPage'
import Logout from 'views/Auth/Logout'
import MitigationScenario from 'views/IntegrityManagement/MitigationScenario'
import RiskAssessment from 'views/RiskManagement/Assessments'
import Scenarios from 'views/IntegrityManagement/MitigationScenario/Scenarios'
import CostConfigLookupTable from 'views/IntegrityManagement/MitigationScenario/CostConfig/LookupTable'
import CostValuesOutlet from 'views/IntegrityManagement/MitigationScenario/CostValues/CostValuesOutlet'
import LikelihoodModel from 'views/RiskConfiguration/LikelihoodModel'
import Config from 'views/RiskConfiguration/LikelihoodModel/Config'
import ConfigOutlet from 'views/RiskConfiguration/LikelihoodModel/Config/ConfigOutlet'
import Parameters from 'views/RiskConfiguration/LikelihoodModel/Parameters'
import ParametersOutlet from 'views/RiskConfiguration/LikelihoodModel/Parameters/ParametersOutlet'
import Tuning from 'views/RiskConfiguration/LikelihoodModel/Tuning'
import TuningLog from 'views/RiskConfiguration/LikelihoodModel/Tuning/TuningLog'
import ConsequenceModel from 'views/RiskConfiguration/ConsequenceModel'
import LookupTables from 'views/RiskConfiguration/ConsequenceModel/LookupTables'
import LookupTablesLog from 'views/RiskConfiguration/ConsequenceModel/LookupTables/LookupTablesLog'
import ConsequenceCosts from 'views/RiskConfiguration/ConsequenceModel/ConsequenceCosts'
import ConsequenceCostValuesOutlet from 'views/RiskConfiguration/ConsequenceModel/ConsequenceCosts/ConsequenceCostValuesOutlet'
import ScenarioProbability, {
  ScenarioProbabilityLookupTablesTab,
  ScenarioProbabilityScenarioValuesTab,
  ScenarioProbabilityLookupTables,
  ScenarioProbabilityScenarioValues,
} from 'views/RiskConfiguration/ScenarioProbability'
import PublishRisk from 'views/RiskConfiguration/PublishRisk'
import AssetDataConfiguration from 'views/DataManagement/AssetDataConfiguration'
import DataLoad from 'views/DataManagement/DataLoad'
import ReportExport from 'views/Reports/Export'
import UnitConfiguration from 'views/DataManagement/UnitConfiguration'

function App() {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0()
  const [token, setToken] = useState('')
  if (isAuthenticated) {
    auth0Token.setAccessTokenSilently(getAccessTokenSilently)
    auth0Token
      .getAccessTokenSilently()()
      .then((token) => setToken(token))
  }

  useEffect(() => {
    if (token) {
      fetch(env.REACT_APP_API_BASE_URL + '/v1/users', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).catch((error) => {
        console.error('Error:', error)
      })
    }
  }, [token])

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            isAuthenticated ? (
              <Navigate replace to="configuration" />
            ) : (
              <LoginPage />
            )
          }
        />
        <Route path="logout" element={<Logout />} />
        <Route
          path="configuration"
          element={<ProtectedRoute component={Configuration} />}
        />
        <Route
          path="export"
          element={<ProtectedRoute component={ReportExport} />}
        />
        <Route
          path="asset-data-config"
          element={<ProtectedRoute component={AssetDataConfiguration} />}
        />
        <Route
          path="data-load"
          element={<ProtectedRoute component={DataLoad} />}
        />
        <Route
          path="assessments"
          element={<ProtectedRoute component={RiskAssessment} />}
        />
        <Route
          path="unit-configurations"
          element={<ProtectedRoute component={UnitConfiguration} />}
        />
        <Route
          path="publish-risk-config"
          element={<ProtectedRoute component={PublishRisk} />}
        />
        <Route
          path="likelihood-model-config"
          element={<ProtectedRoute component={LikelihoodModel} />}>
          <Route
            index
            element={
              <Navigate replace to={'/likelihood-model-config/configuration'} />
            }
          />
          <Route
            path="configuration"
            element={<ProtectedRoute component={Config} />}>
            <Route
              path=":assetType"
              element={<ProtectedRoute component={ConfigOutlet} />}
            />
          </Route>
          <Route
            path="parameters"
            element={<ProtectedRoute component={Parameters} />}>
            <Route
              path=":assetType"
              element={<ProtectedRoute component={ParametersOutlet} />}
            />
          </Route>
          <Route path="tuning" element={<ProtectedRoute component={Tuning} />}>
            <Route
              path=":assetType"
              element={<ProtectedRoute component={TuningLog} />}
            />
          </Route>
        </Route>
        <Route
          path="consequence-model-config"
          element={<ProtectedRoute component={ConsequenceModel} />}>
          <Route
            index
            element={
              <Navigate
                replace
                to={'/consequence-model-config/lookup-tables'}
              />
            }
          />
          <Route
            path="lookup-tables"
            element={<ProtectedRoute component={LookupTables} />}>
            <Route
              path=":assetType"
              element={<ProtectedRoute component={LookupTablesLog} />}
            />
          </Route>
          <Route
            path="consequence-costs"
            element={<ProtectedRoute component={ConsequenceCosts} />}>
            <Route
              path=":assetType"
              element={
                <ProtectedRoute component={ConsequenceCostValuesOutlet} />
              }
            />
          </Route>
        </Route>
        <Route
          path="mitigation-scenario-config"
          element={<ProtectedRoute component={MitigationScenario} />}>
          <Route
            index
            element={
              <Navigate replace to={'/mitigation-scenario-config/scenarios'} />
            }
          />
          <Route
            path="scenarios"
            element={<ProtectedRoute component={Scenarios} />}
          />
          <Route
            path="cost-config"
            element={<ProtectedRoute component={CostConfig} />}>
            <Route
              path=":assetType"
              element={<ProtectedRoute component={CostConfigLookupTable} />}
            />
          </Route>
          <Route
            path="cost-values"
            element={<ProtectedRoute component={CostValues} />}>
            <Route
              path=":assetType"
              element={<ProtectedRoute component={CostValuesOutlet} />}
            />
          </Route>
        </Route>
        <Route
          path="mitigation-scenario-config/scenarios/edit"
          element={<ProtectedRoute component={CreateEditScenario} />}
        />
        <Route
          path="scenario-probability-config"
          element={<ProtectedRoute component={ScenarioProbability} />}>
          <Route
            index
            element={
              <Navigate
                replace
                to={'/scenario-probability-config/lookup-tables'}
              />
            }
          />
          <Route
            path="lookup-tables"
            element={
              <ProtectedRoute component={ScenarioProbabilityLookupTablesTab} />
            }>
            <Route
              path=":assetType"
              element={
                <ProtectedRoute component={ScenarioProbabilityLookupTables} />
              }
            />
          </Route>
          <Route
            path="scenario-values"
            element={
              <ProtectedRoute
                component={ScenarioProbabilityScenarioValuesTab}
              />
            }>
            <Route
              path=":assetType"
              element={
                <ProtectedRoute component={ScenarioProbabilityScenarioValues} />
              }
            />
          </Route>
        </Route>
        <Route
          path="*"
          element={
            <ProtectedRoute
              component={() => (
                <main className="m-20 text-center">
                  <p className="text-2xl font-bold">
                    This page doesn&apos;t exist.
                  </p>
                  <p>
                    Go back to the
                    <Link to="/configuration" className="ml-1">
                      Configuration page.
                    </Link>
                  </p>
                </main>
              )}
            />
          }
        />
      </Routes>
    </>
  )
}

export default App
