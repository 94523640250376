import Content from './Content'
import EditParametersModal from './EditParametersModal'
import EditParametersFormSchema, {
  EditParametersFormSchemaType,
} from './EditParametersFormSchema'
import Footer from './Footer'
import Sidebar from './Sidebar'

export type { EditParametersFormSchemaType }
export { Content, EditParametersFormSchema, Footer, Sidebar }
export default EditParametersModal

// constants
export const columnHeaders = [
  'parameter',
  'current value',
  'new value',
] as const
export enum Tabs {
  TimeDependent = 'time dependent',
  LengthFactor = 'length factor',
  GammaVector = 'gamma vector',
}

// Parameter data that will be displayed as coefficients in Gamma Vector tab
export const ParameterCoefficients = [
  {
    modelParameterId: 9,
    assetFieldId: 66,
    assetFieldName: 'pipe size nps',
    modelFieldName: 'max pipe size nps',
  },
  {
    modelParameterId: 11,
    assetFieldId: 76,
    assetFieldName: 'standard dimension ratio',
    modelFieldName: 'max standard dimension ratio',
  },
  {
    modelParameterId: 10,
    assetFieldId: 79,
    assetFieldName: 'wall thickness',
    modelFieldName: 'max wall thickness',
  },
]
