import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { DatePicker, Input, Select } from 'common/components'
import { useAssetFieldsQuery } from 'services/apis'
import { CreateEditScenarioFormSchemaType } from '../..'

interface IOutcomeFieldProps {
  assetTypeId: number
  isViewScreen: boolean
  outcomeIndex: number
  ruleIndex: number
  runAssetValidation: () => void
}

const OutcomeField = ({
  assetTypeId,
  isViewScreen,
  outcomeIndex,
  ruleIndex,
  runAssetValidation,
}: IOutcomeFieldProps) => {
  const { control } = useFormContext<CreateEditScenarioFormSchemaType>()

  const { data: assetFieldsData } = useAssetFieldsQuery(assetTypeId)

  const outcome = useWatch({
    control,
    name: `rules.${ruleIndex}.outcomes.${outcomeIndex}`,
  })

  const assetField = assetFieldsData?.find(
    ({ assetFieldId }) => assetFieldId === outcome.assetFieldId
  )

  const assetFieldItems =
    assetField?.values?.map((value) => ({
      id: value.assetFieldDomainItemID,
      label: value.domainItemName,
    })) ?? []

  switch (assetField?.assetFieldType) {
    case 'list':
      return (
        <div className="ml-1 flex h-14 w-full grow flex-row items-center 2xl:h-[53px]">
          <Controller
            control={control}
            name={`rules.${ruleIndex}.outcomes.${outcomeIndex}.value`}
            render={({ field }) => (
              <Select
                {...field}
                isLabelHidden
                isReadOnly={isViewScreen}
                items={[
                  { id: 'Same' as unknown as number, label: 'Same' },
                  ...assetFieldItems,
                ]}
                label={`${assetField?.assetFieldName}`}
                setValue={(id) => {
                  field.onChange(id.toString())
                  runAssetValidation()
                }}
              />
            )}
          />
        </div>
      )
    case 'float':
      return (
        <div className="ml-1 flex h-14 w-full flex-row items-center 2xl:h-[53px]">
          <Controller
            control={control}
            name={`rules.${ruleIndex}.outcomes.${outcomeIndex}.value`}
            render={({ field }) => {
              return (
                <Input
                  {...field}
                  hasSameButton={!isViewScreen}
                  isLabelHidden
                  isReadOnly={isViewScreen}
                  isSame={/^same$/i.test(field.value)}
                  label={`${assetField?.assetFieldName}`}
                  onChange={(e) => {
                    field.onChange(e)
                    runAssetValidation()
                  }}
                  type="number"
                />
              )
            }}
          />
        </div>
      )
    case 'date':
      return (
        <div className="ml-1 flex h-14 w-full grow flex-row items-center 2xl:h-[53px]">
          <Controller
            control={control}
            name={`rules.${ruleIndex}.outcomes.${outcomeIndex}.value`}
            render={({ field }) => (
              <DatePicker
                {...field}
                isReadOnly={isViewScreen}
                onChange={(date) => {
                  const dateValue = isNaN((date as Date)?.getTime())
                    ? 'Invalid Date'
                    : (date as Date)?.toISOString()
                  field.onChange(dateValue)
                  runAssetValidation()
                }}
                value={field.value || null}
              />
            )}
          />
        </div>
      )
    default:
      return (
        <div className="ml-1 flex h-14 w-full grow flex-row items-center 2xl:h-[53px]">
          <Controller
            control={control}
            name={`rules.${ruleIndex}.outcomes.${outcomeIndex}.value`}
            render={({ field }) => (
              <Input
                {...field}
                isLabelHidden
                isReadOnly={isViewScreen}
                label={`${assetField?.assetFieldName}`}
                onChange={(e) => {
                  field.onChange(e)
                  runAssetValidation()
                }}
              />
            )}
          />
        </div>
      )
  }
}

export default OutcomeField
