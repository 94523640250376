import { useRef, useState, useEffect } from 'react'
import Tooltip from '@mui/material/Tooltip'

/**
 * In cases where the container width is narrower than the containing text,
 * it renders an ellipses at the clipped location to indicate that there is
 * more text. Upon hover, a tooltip is displayed that shows the full text.
 * This is especially helpful for text inside a table cell which has the
 * potential to have a narrow width which obscures the containing text.
 */
const OverflowTip = ({ children }: { children: string }) => {
  const [isOverflowed, setIsOverflow] = useState(false)
  const textElementRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (textElementRef.current)
      setIsOverflow(
        textElementRef.current.scrollWidth > textElementRef.current?.clientWidth
      )
  }, [])

  return (
    <Tooltip title={children} disableHoverListener={!isOverflowed}>
      <div
        ref={textElementRef}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}>
        {children}
      </div>
    </Tooltip>
  )
}

export default OverflowTip
