import { useState } from 'react'
import { GridSortModel } from '@mui/x-data-grid'
import { ArchiveModal, CloneModal, columnConfig, DeleteModal } from '.'
import {
  Checkbox,
  DEFAULT_PAGE_SIZE,
  Table,
  setSnackbarIsOpen,
} from 'common/components'
import { useAppSelector, useAppDispatch } from 'app/hooks'
import { useScenariosQuery } from 'services/apis'

const ScenarioList = () => {
  const dispatch = useAppDispatch()
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState<boolean>(false)
  const [showArchived, setShowArchived] = useState<boolean>(false)
  const [idToArchive, setIdToArchive] = useState<number>(-1)

  const [isCloneDialogOpen, setIsCloneDialogOpen] = useState<boolean>(false)
  const [idToClone, setIdToClone] = useState<number>(-1)

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false)
  const [idToDelete, setIdToDelete] = useState<number>(-1)

  const handleShowArchived = () => {
    dispatch(setSnackbarIsOpen(false))
    setShowArchived(!showArchived)
    setLocalCurrentPage(0)
  }

  const handleResetPageOnceCloned = () => {
    setLocalCurrentPage(0)
    setShowArchived(false)
  }

  const { scenariosCurrentPageData, totalScenarios } = useAppSelector(
    (state) => state.mitigationData
  )
  const [localCurrentPage, setLocalCurrentPage] = useState<number>(0)
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'timestamp',
      sort: 'desc',
    },
  ])
  useScenariosQuery(
    {
      OnlyArchived: showArchived,
      PageSize: DEFAULT_PAGE_SIZE,
      PageNumber: localCurrentPage + 1,
      TimeStampSorting: sortModel[0].sort ?? 'desc',
    },
    { refetchOnMountOrArgChange: true }
  )

  return (
    <>
      <div className="subtitle-bar">
        <h3>Scenario List</h3>

        <Checkbox
          isChecked={showArchived}
          isLabelBold={true}
          label="Archived Scenarios"
          setIsChecked={handleShowArchived}
        />
      </div>
      <Table
        checkboxSelection={false}
        columns={columnConfig({
          setIdToArchive,
          setIdToClone,
          setIdToDelete,
          setIsArchiveDialogOpen,
          setIsCloneDialogOpen,
          setIsDeleteDialogOpen,
          showArchived,
        })}
        dataReload={(page, sort) => {
          setLocalCurrentPage(page)
          setSortModel(sort)
        }}
        loadByPage={true}
        paginationObject={{
          currentPage: localCurrentPage + 1,
          count: totalScenarios,
        }}
        rows={scenariosCurrentPageData ?? []}
        setSortModel={setSortModel}
        sortModel={sortModel}
        variant="data-table"
      />

      <ArchiveModal
        idToArchive={idToArchive}
        isOpen={isArchiveDialogOpen}
        onClose={() => setIsArchiveDialogOpen(false)}
      />
      <CloneModal
        idToClone={idToClone}
        isOpen={isCloneDialogOpen}
        onClose={() => setIsCloneDialogOpen(false)}
        resetPage={handleResetPageOnceCloned}
      />
      <DeleteModal
        idToDelete={idToDelete}
        isOpen={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
      />
    </>
  )
}

export default ScenarioList
