import { useEffect, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Modal } from 'common/components'
import { IApplicabilityFilter, ILikelihoodAttribute } from 'services/interfaces'
import {
  useLikelihoodAttributesQuery,
  useUpdateLikelihoodAttributesMutation,
  useApplicabilityFiltersQuery,
  useUpdateApplicabilityMutation,
} from 'services/apis'
import { ICondition, ISubThreatModel } from '../../interfaces'
import {
  ConfigSchema,
  ConfigSchemaType,
  Content,
  Footer,
  Sidebar,
  Tabs,
  tabs,
} from '.'
import { DevTool } from '@hookform/devtools'

interface ILookupTableConfigModalProps {
  assetTypeId: number
  subThreatModel: ISubThreatModel
  isOpen: boolean
  onClose: () => void
}

const LookupTableConfigModal = ({
  assetTypeId,
  subThreatModel,
  isOpen,
  onClose,
}: ILookupTableConfigModalProps) => {
  const formMethods = useForm<ConfigSchemaType>({
    mode: 'onChange',
    resolver: zodResolver(ConfigSchema),
    defaultValues: {
      selectedAttributeIds: [],
      conditions: [],
    },
  })
  const { control, handleSubmit, setValue } = formMethods

  const [selectedTab, setSelectedTab] = useState<Tabs>(tabs.CoreAttributes)

  const { data: attributesData, isSuccess: attributesLoad } =
    useLikelihoodAttributesQuery(
      { assetTypeId, modelCode: subThreatModel.code },
      {
        skip: assetTypeId === -1 || !subThreatModel.code,
        refetchOnMountOrArgChange: true,
      }
    )
  const [
    updateLikelihoodAttributes,
    { isLoading: isUpdatingLikelihoodAttributes },
  ] = useUpdateLikelihoodAttributesMutation()
  const [updateApplicability, { isLoading: isUpdatingApplicability }] =
    useUpdateApplicabilityMutation()
  const isSaving = isUpdatingLikelihoodAttributes || isUpdatingApplicability
  const { data: applicabilityFiltersData } = useApplicabilityFiltersQuery(
    { assetTypeId, modelCode: subThreatModel.code },
    {
      skip: assetTypeId === -1 || !subThreatModel.code,
      refetchOnMountOrArgChange: true,
    }
  )

  useEffect(() => {
    applyInitialState()
  }, [attributesData, applicabilityFiltersData])

  const applyInitialState = () => {
    setValue(
      'selectedAttributeIds',
      attributesData?.likelihoodAttributes
        ?.filter((attribute: ILikelihoodAttribute) => attribute.isEnabled)
        .map(
          (enabledAtrribute: ILikelihoodAttribute) =>
            enabledAtrribute.assetFieldId
        ) ?? []
    )

    const applicabilityFilters: ICondition[] = []
    const uniqueIds = applicabilityFiltersData
      ?.map((data) => data.assetFieldId)
      .filter((item, i, ar) => ar.indexOf(item) === i)
    uniqueIds?.forEach((id: number) => {
      applicabilityFilters.push({
        fieldId: id,
        values:
          applicabilityFiltersData
            ?.filter((filter) => filter.assetFieldId === id)
            .map((filter) => filter.assetFieldDomainItemId)
            .toString() ?? '',
      })
    })
    setValue('conditions', applicabilityFilters)
    setSelectedTab(tabs.CoreAttributes)
  }

  const handleClose = () => {
    applyInitialState()
    onClose()
  }

  const handleSave: SubmitHandler<ConfigSchemaType> = async (formData) => {
    const postAttributesData = {
      ...attributesData,
      likelihoodAttributes:
        attributesData?.likelihoodAttributes?.filter((attribute) =>
          formData.selectedAttributeIds.includes(attribute.assetFieldId)
        ) ?? [],
    }
    const postApplicabilityData: IApplicabilityFilter[] = []
    formData.conditions.forEach((condition) => {
      condition.values.split(',').forEach((value) => {
        if (!isNaN(parseInt(value))) {
          postApplicabilityData.push({
            assetFieldId: condition.fieldId ?? 0,
            assetFieldDomainItemId: parseInt(value),
          })
        }
      })
    })
    try {
      console.log('enter try block')
      const promises = [
        updateLikelihoodAttributes({
          assetTypeId,
          modelCode: subThreatModel.code,
          body: postAttributesData,
        }).unwrap(),
        updateApplicability({
          assetTypeId,
          modelCode: subThreatModel.code,
          body: { applicabilityFilters: postApplicabilityData },
        }).unwrap(),
      ]
      await Promise.all(promises)
      console.log('got here')
      handleClose()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(handleSave)}>
        {attributesLoad && (
          <Modal
            isOpen={isOpen}
            onClose={handleClose}
            title="Configuration Model"
            sidebarContent={
              <Sidebar
                currentTab={selectedTab}
                onTabChange={(tabName) => setSelectedTab(tabName)}
                subThreatModel={subThreatModel}
              />
            }
            footerContent={
              <Footer handleClose={handleClose} isSaving={isSaving} />
            }
            className="h-[650px]"
            isBusy={isSaving}>
            <Content
              assetTypeId={assetTypeId}
              attributes={attributesData ?? []}
              selectedTab={selectedTab}
              isSaving={isSaving}
            />
          </Modal>
        )}
      </form>
      <DevTool control={control} />
    </FormProvider>
  )
}

export default LookupTableConfigModal
