import { Fragment, useEffect, useMemo, useState } from 'react'
import {
  columnHeaders,
  EditParametersFormSchemaType,
  ParameterCoefficients,
  Tabs,
} from '..'
import {
  useCovariateCoefficientsQuery,
  useSubThreatAttributesQuery,
  useSubThreatParametersQuery,
} from 'services/apis'
import { Input, Select } from 'common/components'
import { Controller, useFormContext } from 'react-hook-form'

interface IContentProps {
  assetTypeId: number
  isSaving: boolean
  modelCode: string
  selectedTab: Tabs
}

const Content = ({
  selectedTab,
  assetTypeId,
  isSaving,
  modelCode,
}: IContentProps) => {
  const [selectedAttributeId, setSelectedAttributeId] = useState('')
  const { control } = useFormContext<EditParametersFormSchemaType>()

  const { data: subThreatParametersData } = useSubThreatParametersQuery({
    assetTypeId,
    modelCode,
  })

  const { data: subThreatAttributesData } = useSubThreatAttributesQuery({
    assetTypeId,
    modelCode,
  })

  const { data: covariateCoefficientsData } = useCovariateCoefficientsQuery({
    assetTypeId,
    modelCode,
  })

  const isTimeDependentTab = selectedTab === Tabs.TimeDependent
  const isLengthFactorTab = selectedTab === Tabs.LengthFactor
  const isGammaVectorTab = selectedTab === Tabs.GammaVector

  const selectedAttributeCoefficientsData =
    covariateCoefficientsData?.modelCovariateCoeffValues
      ?.map((coefficient, index) => ({ ...coefficient, index }))
      ?.filter((data) => data.assetFieldId === Number(selectedAttributeId))

  const attributeSelectItems = useMemo(() => {
    return (
      subThreatAttributesData?.likelihoodAttributes
        ?.filter((attribute) => attribute.isEnabled)
        ?.map((attribute) => ({
          id: attribute.assetFieldId,
          label: attribute.assetFieldName,
        })) ?? []
    )
  }, [subThreatAttributesData])

  // Data object of selected attribute
  const selectedAttributeData =
    useMemo(
      () =>
        subThreatAttributesData?.likelihoodAttributes?.find(
          (attribute) => attribute.assetFieldId === Number(selectedAttributeId)
        ),
      [selectedAttributeId]
    ) ?? null

  // Finding parameter coefficient of selected attribute based on attributeName or attributeId
  // Adding additional name condition check as sometimes when new attributes are added to the database the attributeFieldIds are changed
  const selectedParameterCoefficient =
    ParameterCoefficients?.find(
      (coefficient) =>
        coefficient.assetFieldId === Number(selectedAttributeId) ||
        coefficient.assetFieldName ===
          selectedAttributeData?.assetFieldName?.toLowerCase()
    ) ?? null

  const parametersData =
    subThreatParametersData?.modelParameterValues
      ?.map((parameter, index) => ({ ...parameter, index }))
      .filter(
        ({ calculationComponentTypeId, modelParameterId, displayName }) => {
          if (isTimeDependentTab) return calculationComponentTypeId === 1
          if (isLengthFactorTab) return calculationComponentTypeId === 3
          if (isGammaVectorTab)
            return (
              calculationComponentTypeId === 2 &&
              (modelParameterId ===
                selectedParameterCoefficient?.modelParameterId ||
                displayName?.toLowerCase() ===
                  selectedParameterCoefficient?.modelFieldName)
            )

          return null
        }
      ) ?? []

  // the first attribute is selected as default
  useEffect(() => {
    if (attributeSelectItems) {
      setSelectedAttributeId(String(attributeSelectItems?.[0]?.id ?? ''))
    }
  }, [attributeSelectItems])

  const renderAttributeSelector = () => (
    <div className="mx-4 mb-9 mt-0 w-[315px] font-bold">
      <Select
        isDisabled={isSaving}
        items={attributeSelectItems}
        label="Attribute:"
        isLabelInline
        isLabelBold
        setValue={setSelectedAttributeId}
        value={String(selectedAttributeId)}
      />
    </div>
  )

  const renderParameterRows = () => (
    <>
      {parametersData.length === 0 && !isGammaVectorTab && (
        <>
          <div>N/A</div>
          <div>N/A</div>
          <div>N/A</div>
        </>
      )}
      {parametersData.map((data) => {
        return (
          <Fragment key={data.modelParameterId}>
            <p className="my-0 text-[12px] font-semibold text-coolGray-900">
              {data.displayName}
            </p>
            <Input
              isDisabled
              label={`Current ${data.displayName} Value`}
              isLabelHidden
              value={String(data.value ?? '')}
            />
            <Controller
              control={control}
              name={`subThreatParameters.modelParameterValues.${data.index}.value`}
              render={({ field }) => (
                <Input
                  {...field}
                  isDisabled={isSaving}
                  label={`New ${data.displayName} Value`}
                  isLabelHidden
                  type="number"
                  onChange={(e) => {
                    field.onChange(Number(e.target.value))
                  }}
                  value={String(field.value ?? '')}
                />
              )}
            />
          </Fragment>
        )
      })}
    </>
  )

  const renderAttributeCoefficientRows = () =>
    selectedAttributeCoefficientsData?.map((data) => {
      return (
        <Fragment key={`${data.assetFieldName}-${data.domainItemName}`}>
          <p className="my-0 text-[12px] font-semibold text-coolGray-900">
            {data.domainItemName ??
              attributeSelectItems.find(
                (item) => item.id === Number(selectedAttributeId)
              )?.label}
          </p>
          <Input
            isDisabled
            label={`Current ${data.domainItemName} Value`}
            isLabelHidden
            value={String(data.gammaValue ?? '')}
          />
          <Controller
            control={control}
            name={`covariateCoefficients.modelCovariateCoeffValues.${data.index}.gammaValue`}
            render={({ field }) => (
              <Input
                {...field}
                isDisabled={isSaving}
                label={`New ${data.domainItemName} Value`}
                isLabelHidden
                type="number"
                onChange={(e) => {
                  field.onChange(Number(e.target.value))
                }}
                value={String(field.value ?? '')}
              />
            )}
          />
        </Fragment>
      )
    })

  const isAttributeSelectItemsNotEmpty = () => {
    return isGammaVectorTab && attributeSelectItems?.length !== 0
  }

  return (
    <>
      <p className="mx-4 mb-8 mt-0 font-bold uppercase text-blue-navy">
        {selectedTab}
      </p>

      {isAttributeSelectItemsNotEmpty() && renderAttributeSelector()}

      {isAttributeSelectItemsNotEmpty() || !isGammaVectorTab ? (
        <div className="mx-4 mt-3 grid grid-cols-3 items-center gap-x-8 gap-y-6">
          {columnHeaders.map((header) => (
            <p
              key={header}
              className="my-0 mb-2 text-[14px] font-semibold capitalize text-coolGray-400">
              {header}
            </p>
          ))}

          {renderParameterRows()}
          {isGammaVectorTab && renderAttributeCoefficientRows()}
        </div>
      ) : (
        <p className="mx-4">No attributes are configured for this subthreat</p>
      )}
    </>
  )
}

export default Content
