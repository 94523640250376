import { useEffect, useState } from 'react'
import { IButtonProps } from 'common/interfaces'

export function useVariant(
  variant: IButtonProps['variant'],
  color: string
): string {
  const [variantClasses, setVariantClasses] = useState<string>('')

  useEffect(() => {
    const classes: () => string = () => {
      if (variant === 'primary')
        return 'shadow-none bg-blue-lighthouse text-white hover:bg-blue-navy'
      if (variant === 'primary-outlined')
        return 'shadow-[0_0_0_1px] bg-white text-blue-lighthouse hover:bg-blue-navy hover:text-white hover:shadow-blue-navy'
      if (variant === 'secondary')
        return 'shadow-none bg-lightBlue-100 text-blue-lighthouse hover:bg-blue-navy hover:text-white'
      if (variant === 'secondary-outlined')
        return 'shadow-[0_0_0_1px] bg-lightBlue-100 text-blue-lighthouse hover:bg-blue-navy hover:text-white hover:shadow-blue-navy'
      if (variant === 'clear' || variant === 'navigation')
        return `shadow-none text-${color}`
      return ''
    }

    setVariantClasses(classes())
  }, [variant, color])

  return variantClasses
}
