import axios, { AxiosProgressEvent } from 'axios'
import { auth0Token } from 'common/utils'

export default async function uploadFile(
  file: File,
  url: string,
  options: any,
  onProgressUpdate?: any,
  controller?: AbortController
) {
  const token = await auth0Token.getAccessTokenSilently()()
  const formData = new FormData()
  formData.append('file', file)
  const entries = Object.entries(options)
  entries.forEach(([key, value]) => formData.append(key, value as string))
  const response = await axios.post(url, formData, {
    headers: { Authorization: `Bearer ${token}` },
    onUploadProgress: (progressEvent) => {
      if (onProgressUpdate) {
        onProgressUpdate(getProgress(progressEvent))
      }
    },
    signal: controller?.signal,
  })
  return response.data
}

function getProgress(progressEvent: AxiosProgressEvent): number {
  const { loaded, total } = progressEvent
  return Math.floor((loaded / (total || 1)) * 100)
}
