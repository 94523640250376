import {
  GridSortModel,
  GridRenderCellParams,
  GridRowsProp,
} from '@mui/x-data-grid'
import { OverflowTip, DEFAULT_PAGE_SIZE } from 'common/components'
import { IColumn } from 'common/interfaces'
import ConfigTable from './ConfigTable'
import DataTable from './DataTable'
import ScrollableTable from './ScrollableTable'

interface IPaginationDetails {
  currentPage: number
  count: number
}

export interface ITableProps {
  /**
   * Set a table variant.
   */
  variant: 'data-table' | 'config-table' | 'scrollable-table'
  /**
   * Allows the data grid to size according to its content.
   */
  useAutoHeight?: boolean
  /**
   * className applied to the root element. Limit use for special cases only.
   */
  className?: string
  /**
   * Array of rows to display.
   */
  rows: GridRowsProp | undefined
  /**
   * Array of columns to display. Check the <a href="https://mui.com/x/api/data-grid/grid-col-def/" target="_blank">MUI docs</a> for possible column properties.
   */
  columns: IColumn[]
  /**
   * Number of rows to show per page.
   */
  pageSize?: number
  /**
   * Provides a checkbox selection for each row.
   */
  checkboxSelection: boolean
  /**
   * Determines whether to show pagination.
   */
  hasPagination?: boolean
  /**
   * Highlight a specific row.
   */
  rowIdToHighlight?: number
  /**
   * Return the id of a given row.
   */
  getRowId?: any
  /**
   * Hide page count at the bottom right of table.
   */
  isPageCountHidden?: boolean
  /**
   * Pages are mapped to API endpoints
   */
  loadByPage?: boolean
  /**
   * Contains `currentPage` and `count` properties.
   */
  paginationObject?: IPaginationDetails
  /**
   * Reload data with new values passed in.
   */
  dataReload?: (page: number, sortModel: GridSortModel) => void
  /**
   * HTML color to set the row border bottom to.
   */
  rowBorderBottomColor?: string
  /**
   * Set the sort model of the grid.
   */
  sortModel?: GridSortModel
  /**
   * Callback fired when the sort model changes before a column is sorted.
   */
  setSortModel?: (sortModel: GridSortModel) => void
}

/**
 * A Table displays sets of two-dimensional data.
 */
const Table = ({
  variant,
  useAutoHeight = true,
  className = '',
  rows = [],
  columns,
  pageSize,
  checkboxSelection,
  hasPagination = true,
  rowIdToHighlight,
  getRowId,
  isPageCountHidden = false,
  loadByPage = false,
  paginationObject,
  dataReload = () => {},
  rowBorderBottomColor = '#F2F3F4',
  sortModel = [],
  setSortModel = () => {},
}: ITableProps) => {
  const totalCount: number = paginationObject?.count ?? rows.length
  const currentPage: number = paginationObject?.currentPage ?? 1

  const overflowTooltipColumns = columns.map((column) => {
    if (column?.disableOverflowTooltip) {
      return column
    }

    return {
      ...column,
      renderCell: (params: GridRenderCellParams) => {
        let originalRenderCell = null

        if (column.renderCell) {
          originalRenderCell = column.renderCell(params)
        }

        return (
          <OverflowTip>{originalRenderCell ?? params?.value ?? ''}</OverflowTip>
        )
      },
    }
  })

  const commonProps = {
    checkboxSelection,
    className,
    columns: overflowTooltipColumns,
    currentPage,
    dataReload,
    getRowId,
    hasPagination,
    isPageCountHidden,
    loadByPage,
    pageSize: pageSize || DEFAULT_PAGE_SIZE,
    rows,
    setSortModel,
    sortModel,
    totalCount,
    useAutoHeight,
  }

  const renderTable = () => {
    if (variant === 'data-table') {
      return (
        <DataTable
          {...commonProps}
          rowBorderBottomColor={rowBorderBottomColor}
          rowIdToHighlight={rowIdToHighlight}
        />
      )
    } else if (variant === 'config-table') {
      return <ConfigTable {...commonProps} />
    } else if (variant === 'scrollable-table') {
      return <ScrollableTable {...commonProps} hasPagination={false} />
    } else {
      return null
    }
  }

  return <div className="h-full w-full">{renderTable()}</div>
}

export default Table
