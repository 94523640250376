import { FocusEventHandler, forwardRef, useMemo } from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import UncheckedIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckedIcon from '@mui/icons-material/CheckBox'
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SxProps,
} from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'
import { getSelectLabelById } from 'common/utils'
import { ISelectItem } from 'common/interfaces'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
}

const menuSx: SxProps = {
  p: '6px',
  px: '16px',
  '.MuiCheckbox-root': {
    padding: '0',
    paddingLeft: 0,
  },
  svg: {
    mr: '7px',
    color: '#00000099',
    fontSize: '1.4rem',
  },
}

interface IMultiSelect {
  /**
   * An array of objects containing `id` and `label` properties for the
   * item id and the item label, respectively.
   */
  items: ISelectItem[]
  /**
   * ids of selected values.
   */
  values: (number | string)[]
  /**
   * Callback that triggers when values are selected or deselected.
   */
  setValue: (arg0: (number | string)[]) => void
  /**
   * Disables the input.
   */
  isDisabled?: boolean
  /**
   * The border of the input is displayed as red if true.
   */
  hasError?: boolean
  /**
   * The input is focusable but cannot be changed.
   */
  isReadOnly?: boolean
  /**
   * Determines if input is required.
   */
  isRequired?: boolean
  /**
   * Callback that triggers when focus is shifted away.
   */
  onBlur?: FocusEventHandler<HTMLInputElement>
}

/**
 * Form select component used to select multiple items from a group of
 * options in a form.
 */
const MultiSelect = forwardRef<HTMLInputElement, IMultiSelect>(
  (
    {
      items = [],
      values,
      setValue,
      hasError,
      isDisabled,
      isReadOnly,
      isRequired = true,
      onBlur,
    },
    ref
  ) => {
    const handleChange = (event: SelectChangeEvent<typeof values>) => {
      const {
        target: { value },
      } = event
      setValue(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value
      )
    }

    const formSx: SxProps = useMemo(
      () => ({
        margin: 0,
        width: '100%',
        '.MuiSelect-icon': {
          display: isReadOnly ? 'none' : 'block',
        },
        '.MuiSelect-select': { padding: '7.5px 10.5px 8.5px' },
        '.MuiInputLabel-root': { display: 'none' },
        '.MuiOutlinedInput-notchedOutline': {
          border: 'none',
          transition: 'box-shadow .150s',
        },
        '.MuiInputBase-root': {
          width: '100%',
          boxShadow: '0 0 0 1px #D0D2D3',
          transition: 'box-shadow .150s',
          '&:not(.Mui-disabled):hover': {
            boxShadow: '0 0 0 1px #A6A8AB',
          },
          '&.Mui-focused': {
            boxShadow: '0 0 0 2px #2563eb !important',
          },
          '&.Mui-disabled': {
            backgroundColor: '#F2F3F4',
          },
          '&.Mui-error': {
            boxShadow: '0 0 0 2px #DD668A !important',
          },
        },
        'MuiSelect-nativeInput': {
          '&:invalid': { backgroundColor: 'red' },
        },
      }),
      [isReadOnly]
    )

    const getRenderValue = (selected: (string | number)[]) => {
      if (selected.length === 1 && isNaN(Number(selected[0]))) {
        return <span className="text-coolGray-400">Select</span>
      }

      const nonEmptyLabels = selected
        .filter((item: string | number) => item !== '')
        .map((id: string | number) =>
          getSelectLabelById(items ?? [], Number(id))
        )

      return nonEmptyLabels.join(', ')
    }

    const isChecked = (item: ISelectItem): boolean =>
      values.findIndex((value) => value.toString() === item.id.toString()) > -1

    return (
      <FormControl sx={formSx} error={hasError}>
        <InputLabel id="component-multiselect-label">
          MultiSelect-Component
        </InputLabel>
        <Select
          displayEmpty
          inputRef={ref}
          disabled={isDisabled}
          readOnly={isReadOnly}
          required={isRequired}
          labelId="component-multiselect-label"
          id="demo-multiple-checkbox"
          multiple
          value={values}
          onBlur={onBlur}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) => getRenderValue(selected)}
          MenuProps={MenuProps}
          className="rounded-sm">
          {items.map((item) => (
            <MenuItem sx={menuSx} key={item.id} value={item.id}>
              <Checkbox
                checked={isChecked(item)}
                icon={<UncheckedIcon />}
                checkedIcon={
                  <CheckedIcon sx={{ color: '#82ACFF !important' }} />
                }
                disableRipple
              />
              <ListItemText primary={item.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }
)

MultiSelect.displayName = 'MultiSelect'

export default MultiSelect
