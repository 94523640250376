import {
  useFieldArray,
  UseFieldArrayReturn,
  useFormContext,
} from 'react-hook-form'
import CloseIcon from '@mui/icons-material/Close'
import { Button } from 'common/components'
import { CreateEditScenarioFormSchemaType } from '../..'
import { ConditionField, OutcomeField } from '.'

interface IRuleProps {
  assetRuleIndex: number
  correspondingGlobalRuleIndexes: number[]
  assetTypeId: number
  isViewScreen: boolean
  rulesFieldArray: UseFieldArrayReturn<
    CreateEditScenarioFormSchemaType,
    'rules',
    'id'
  >
  runAssetValidation: () => void
}

const Rule = ({
  assetRuleIndex,
  correspondingGlobalRuleIndexes,
  assetTypeId,
  isViewScreen,
  rulesFieldArray,
  runAssetValidation,
}: IRuleProps) => {
  const isCatchAllRule = assetRuleIndex === 0
  const correspondingGlobalRuleIndex =
    correspondingGlobalRuleIndexes[assetRuleIndex]

  const { control } = useFormContext<CreateEditScenarioFormSchemaType>()
  const conditionsFieldArray = useFieldArray({
    control,
    name: `rules.${correspondingGlobalRuleIndex}.conditions`,
  })
  const outcomesFieldArray = useFieldArray({
    control,
    name: `rules.${correspondingGlobalRuleIndex}.outcomes`,
  })

  const handleRemoveRule = () => {
    rulesFieldArray.remove(correspondingGlobalRuleIndex)
  }

  const renderConditionFields = () => {
    return conditionsFieldArray.fields.map((condition, conditionIndex) => (
      <div className="flex flex-row" key={condition.id}>
        <ConditionField
          assetTypeId={assetTypeId}
          conditionIndex={conditionIndex}
          isCatchAllRule={isCatchAllRule}
          isViewScreen={isViewScreen}
          ruleIndex={correspondingGlobalRuleIndex}
          runAssetValidation={runAssetValidation}
        />
      </div>
    ))
  }

  const renderOutcomeFields = () => {
    return outcomesFieldArray.fields.map((outcome, outcomeIndex) => (
      <div className="flex flex-row" key={outcome.id}>
        <OutcomeField
          assetTypeId={assetTypeId}
          isViewScreen={isViewScreen}
          outcomeIndex={outcomeIndex}
          ruleIndex={correspondingGlobalRuleIndex}
          runAssetValidation={runAssetValidation}
        />
      </div>
    ))
  }

  return (
    <div
      className={`w-72 shrink-0 2xl:w-[296px] ${
        isCatchAllRule ? 'mr-4 2xl:mr-6' : 'mr-6 2xl:mr-8'
      }`}>
      <div
        className={`relative flex flex-row items-center justify-center rounded-md`}>
        <p
          className={`mt-2 text-[20px] font-semibold text-blue-navy ${
            isCatchAllRule ? 'mb-[4px]' : 'mb-[2px]'
          }`}>
          {isCatchAllRule ? 'Catch All Rule' : `Rule ${assetRuleIndex}`}
        </p>

        <Button
          aria-label="Remove Rule"
          className={`absolute right-0 top-3 p-0 ${
            (isCatchAllRule || isViewScreen) && 'hidden'
          }`}
          iconOnly={<CloseIcon className="bg-blue-lighthouse fill-white" />}
          onClick={handleRemoveRule}
        />
      </div>

      {renderConditionFields()}

      <div className={isViewScreen ? 'mt-[57px] 2xl:mt-[53px]' : 'mt-[90px]'}>
        {renderOutcomeFields()}
      </div>
    </div>
  )
}

export default Rule
