import {
  Controller,
  ControllerRenderProps,
  useFormContext,
} from 'react-hook-form'
import { Checkbox } from 'common/components'
import { ConfigSchemaType } from '..'

interface IAttributesCheckboxProps {
  assetFieldId: number
  assetFieldName: string
  isSaving: boolean
}

const AttributesCheckbox = ({
  assetFieldId,
  assetFieldName,
  isSaving,
}: IAttributesCheckboxProps) => {
  const { control, setValue } = useFormContext<ConfigSchemaType>()

  const handleToggle = (
    field: ControllerRenderProps<ConfigSchemaType, 'selectedAttributeIds'>
  ) => {
    if (field.value.includes(assetFieldId)) {
      setValue(
        'selectedAttributeIds',
        field.value.filter((id) => assetFieldId !== id)
      )
    } else {
      setValue('selectedAttributeIds', [...field.value, assetFieldId])
    }
  }

  return (
    <Controller
      control={control}
      name="selectedAttributeIds"
      render={({ field }) => (
        <Checkbox
          {...field}
          isChecked={field.value.includes(assetFieldId)}
          isDisabled={isSaving}
          label={assetFieldName}
          setIsChecked={() => handleToggle(field)}
        />
      )}
    />
  )
}

export default AttributesCheckbox
