import z from 'zod'

const ConfigSchema = z.object({
  id: z.number(),
  selectedLocationLevel: z.number().nullable(),
  selectedAttributes: z.array(z.number()),
})

type ConfigSchemaType = z.infer<typeof ConfigSchema>

export type { ConfigSchemaType }
export default ConfigSchema
