import { Layout, Breadcrumbs, Input, Select, Button } from 'common/components'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useNavigate } from 'react-router-dom'
import { ISelectItem } from 'common/interfaces'
import {
  UnitConfigurationFormSchema,
  UnitConfigurationFormSchemaType,
  PathType,
} from '.'

interface IUnitConfigurationField {
  label: string
  name: PathType
  type?: 'number' | 'text' | 'select'
  options?: ISelectItem[]
}

interface IUnitConfigurationSection {
  label: string
  fields: IUnitConfigurationField[]
}

const UnitConfiguration = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<UnitConfigurationFormSchemaType>({
    resolver: zodResolver(UnitConfigurationFormSchema),
  })

  const navigate = useNavigate()

  const sections: IUnitConfigurationSection[] = [
    {
      label: 'Risk Units',
      fields: [
        { label: 'Storage*:', name: 'storage' },
        {
          label: 'Large Display*:',
          name: 'largeDisplay',
        },
        {
          label: 'Small Display*:',
          name: 'smallDisplay',
        },
        {
          label: 'Large ↔ Small Conversion Rate*:',
          name: 'smallConversionRate',
          type: 'number',
        },
        {
          label: 'Storage ↔ Small Conversion Rate*:',
          name: 'storageConversionRate',
          type: 'number',
        },
        {
          label: 'Threshold for Small to Large*:',
          name: 'threshold',
          type: 'number',
        },
      ],
    },
    {
      label: 'Main & Service Length Units',
      fields: [
        { label: 'Diameter:', name: 'diameter' },
        {
          label: 'Operating Pressure:',
          name: 'operatingPressure',
        },
        { label: 'Wall Thickness:', name: 'wallThickness' },
      ],
    },
    {
      label: 'Other Numeric Field Units',
      fields: [
        {
          label: 'Risk Unit Display*:',
          name: 'riskUnitDisplay',
          type: 'select',
          options: [{ id: 1, label: 'RU' }],
        },
        {
          label: 'RU ↔ Dollars Conversion Rate*:',
          name: 'dollarConversionRate',
          type: 'number',
        },
        {
          label: 'Normalized Length Display*:',
          name: 'normalizedLengthDisplay',
          type: 'select',
          options: [
            { id: 1, label: 'Small' },
            { id: 2, label: 'Large' },
          ],
        },
        {
          label: 'Cost per Length Display*:',
          name: 'costPerLengthDisplay',
          type: 'select',
          options: [
            { id: 1, label: 'Small' },
            { id: 2, label: 'Large' },
          ],
        },
      ],
    },
  ]

  const handleCancel = () => {
    navigate('/configuration')
  }

  const handleSaveAndApply: SubmitHandler<UnitConfigurationFormSchemaType> = (
    data
  ) => {
    console.log(data)
    // Should be re-directed to the configuration page once Api is implemented
    // navigate('/configuration')
  }
  return (
    <Layout>
      <form onSubmit={handleSubmit(handleSaveAndApply)}>
        <Breadcrumbs
          items={[
            { title: 'Configuration', to: '/configuration' },
            { title: 'Data Management', to: '' },
          ]}
        />
        <div className="title-bar">
          <h1>Unit Configurations</h1>
          <div>
            <Button
              className="mr-6"
              variant="primary-outlined"
              onClick={handleCancel}>
              Cancel
            </Button>
            <Button isSubmit variant="primary">
              Save and Apply
            </Button>
          </div>
        </div>
        {sections?.map((section) => {
          return (
            <div className="mt-4" key={section.label}>
              <div className="subtitle-bar">
                <h3>{section.label}</h3>
              </div>
              <div className="flex flex-wrap">
                {section?.fields?.map((sectionField) => {
                  return (
                    <div key={sectionField.name} className="mr-4 mt-3 w-72">
                      <Controller
                        control={control}
                        name={sectionField.name}
                        key={sectionField.name}
                        render={({ field }) =>
                          sectionField?.type === 'select' ? (
                            <Select
                              {...field}
                              items={sectionField?.options ?? []}
                              label={sectionField?.label}
                              hasError={Boolean(errors?.[sectionField?.name])}
                              helperText={String(
                                errors?.[sectionField?.name]?.message ?? ''
                              )}
                              setValue={(value) => {
                                field.onChange(value)
                              }}
                              value={String(field.value ?? '')}
                            />
                          ) : (
                            <Input
                              {...field}
                              label={sectionField?.label}
                              type={sectionField?.type ?? 'text'}
                              hasError={Boolean(errors?.[sectionField?.name])}
                              onChange={(e) => {
                                field.onChange(
                                  sectionField?.type === 'number'
                                    ? Number(e.target.value)
                                    : e.target.value
                                )
                              }}
                              value={String(field.value ?? '')}
                              helperText={String(
                                errors?.[sectionField?.name]?.message ?? ''
                              )}
                            />
                          )
                        }
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
      </form>
    </Layout>
  )
}

export default UnitConfiguration
