import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { SxProps } from '@mui/material'
import { Check } from '@mui/icons-material'
import Checkbox from '@mui/material/Checkbox'

export interface IConfigCardProps {
  /** The title of the card. */
  configurationName: string
  /** The destination route to navigate to. */
  linkTitleTo: string
  /** User who last updated the linked configuration. */
  modifiedBy: string
  /** The date when the corresponding configuration was last updated. Should be in the format MM/DD/YYYY */
  dateModified: string
  /** Determines whether the checkbox that indicates completion is checked or not. */
  isCompleted?: boolean
  /** Callback that gets triggered when the checkbox value changes. */
  toggleCompleted: () => void
  /** className applied to the root element, if required. */
  className?: string
  /** Disables the link, checkbox, and applies a gray hue to the overall component. */
  isDisabled?: boolean
}

const UncheckedIcon = () => (
  <div className="UncheckedIcon h-6 w-6 rounded-full border-2 border-solid border-coolGray-300 bg-white transition"></div>
)

const CheckedIcon = () => (
  <Check className="rounded-full border-2 border-solid border-green bg-green text-white" />
)

const DisabledIcon = () => (
  <div className="UncheckedIcon h-6 w-6 rounded-full border-2 border-solid border-coolGray-300 bg-gray-200 transition"></div>
)

const checkboxStyles: SxProps = {
  '&:hover .UncheckedIcon': {
    borderColor: '#A6A8AB !important',
  },
}

/**
 * Card that contain links that navigate to various configuration screens. It also contains a checkmark component to indicate completion.
 */
const ConfigCard = ({
  configurationName,
  linkTitleTo,
  modifiedBy,
  dateModified,
  isCompleted = false,
  toggleCompleted,
  className = '',
  isDisabled = false,
}: IConfigCardProps) => {
  const sectionClass: string = useMemo(
    (): string =>
      `relative flex min-h-[120px] flex-col rounded border-2 border-solid p-6 py-5 transition-all ${
        isDisabled
          ? 'border-none bg-gray-200'
          : isCompleted
          ? 'border-green'
          : 'border-coolGray-300'
      } ${className}`,
    [isCompleted, className, isDisabled]
  )

  const checkboxClass: string = useMemo(
    (): string =>
      `absolute top-6 -left-[12px] bg-white p-0 transition-colors ${
        isDisabled
          ? 'text-coolGray-500'
          : isCompleted
          ? 'text-green'
          : 'text-coolGray-300 hover:text-coolGray-400'
      }`,
    [isCompleted, isDisabled]
  )

  const linkClass: string = useMemo(
    (): string =>
      `text-lg font-semibold no-underline ${
        isDisabled
          ? 'text-coolGray-500 pointer-events-none cursor-default'
          : isCompleted
          ? 'text-green'
          : 'text-blue-lighthouse'
      }`,
    [isCompleted, isDisabled]
  )

  return (
    <section data-testid="config-card" className={sectionClass}>
      <Checkbox
        checked={isCompleted}
        onChange={toggleCompleted}
        icon={isDisabled ? <DisabledIcon /> : <UncheckedIcon />}
        checkedIcon={<CheckedIcon />}
        className={checkboxClass}
        sx={checkboxStyles}
        disabled={isDisabled}
      />
      <h3 className="mt-0 text-base">
        <Link to={linkTitleTo} className={linkClass}>
          {configurationName}
        </Link>
      </h3>
      <div className="mt-auto flex justify-between text-xs text-coolGray-500">
        <div>Updated By: {modifiedBy}</div>
        <div className="ml-3">{dateModified}</div>
      </div>
    </section>
  )
}

export default ConfigCard
